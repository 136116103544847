import React from 'react'
import BasicBadge from '../Badge/Basic/BasicBadge'
import './ImageCard.scss'

export default function ImageCard(props) {
	const { badgeType = 'THUMBNAIL', imageCardType = 'sold', imageUrl, mallInfo, badges = [] } = props
	const BadgeComponents = () => {
		let components = []
		if (badges && badges.length > 0)
			components = components.concat(...badges.filter(item => item.content && item.type === badgeType).map((badge, index) => (<BasicBadge key={index} type="only" badge={badge}></BasicBadge>)))
		return components
	}

	return (
		<div className="image-area">
			<img className="image" src={imageUrl} alt="" />
			{
				imageCardType === 'sold'
					? BadgeComponents()
					: <></>
			}

			{
				imageCardType !== 'sold' ?
					<div className="live-information-wrapper">
						<span className="live-target-mall-name">{ mallInfo.name }</span>
						<p className="live-description">{ mallInfo.description }</p>
					</div>
					:
					<></>
			}
		</div>
	)
}
