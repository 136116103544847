import React from 'react'
import BookingFooter from '../../../Share/Booking/BookingFooter/BookingFooter'
import { useHistory, useParams } from 'react-router-dom'
import { useFooter, useReservation, useTimer } from '../../../../store'

export default function InfoFooter() {
	const history = useHistory()
	const { mallUri } = useParams()
	const { active, reset: resetFooter } = useFooter()
	const { resetCategory } = useReservation()
	const { resetTimer } = useTimer()

	const moveCategory = () => {
		resetFooter()
		resetCategory()
		resetTimer()
		history.push(`/booking/${mallUri}/step/category`)
	}

	return <BookingFooter text="확인" action={moveCategory} active={active}></BookingFooter>
}
