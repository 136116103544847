import React, { useState, useEffect } from 'react'
import BasicBadge from '../../../../Share/Badge/Basic/BasicBadge'
import './CircleImageItem.scss'

export default function CircleImageItem(props) {
	const { badgeType = 'SERVICE', item, customFunction = (() => {}) } = props

	const makeSimpleInfo = (product) => {
		return [product.service.category].join(',')
	}

	const getBadgeStyle = (badge) => {
		return {
			label: badge.content,
			labelStyle: {
				fontFamily: 'AppleSanDolGothicNeo',
				fontSize: '10px',
				fontWeight: 'bold',
				fontStretch: 'normal',
				fontStyle: 'normal',
				lineHeight: 'normal',
				letterSpacing: 'normal',
				textAlign: 'left',
				color: badge.fontColor
			},
			style: {
				display: 'flex',
				justifyContent: 'flex-start',
				alignItems: 'flex-start',
				padding: '3px 5px',
				borderRadius: '3px',
				backgroundColor: badge.backgroundColor
			}
		}
	}

	const getOneBadge = (badges) => {
		if (!badges || badges.length === 0)
			return <></>
		const filteredBadges = badges.filter(badge => badge.content)
		const badge = filteredBadges.find(badgeItem => badgeItem.type === badgeType)
		if (badge)
			return <BasicBadge customStyle={getBadgeStyle(badge)}></BasicBadge>
		return null
	}

	return (
		<div
			className={
				item.service.isExpired ?
					'circle-image-item-wrapper'
					: 'circle-image-item-wrapper clickable'
			}
			onClick={
				item.service.isExpired
					? (() => {})
					: (() => customFunction(item.brand.id))
			}
		>
			{
				item ?
					(<div className="circle-item-image-wrapper" style={item.product
						? { backgroundColor: item.product.thumbnail.backgroundColor }
						: {}}>
						<img className="circle-item-image" src={item.product
							? item.product.thumbnail.link
							: '#'} alt={item.service.name}></img>
					</div>)
					: <></>
			}
			<div className="circle-item-information-wrapper">
				<span className="circle-item-brand-category">{makeSimpleInfo(item)}</span>
				<span className="circle-item-brand-name">{item.service.name}</span>
				{getOneBadge(item.badges)}
			</div>
		</div>
	)
}
