import { create } from 'zustand'
import CheckUserFormData from '../utils/CheckUserFormData'

/**
 * undefined - 사용자 인터렉션이 이루어지지 않은 검증 비활성화 상태
 * false - 사용자 인터렉션이 무조건 이루어져야 하는 Block 상태 || 검증에 통과하지 못한 상태
 * true - 검증에 통과된 상태
 * string - 에러 문구를 표시하는 상태
 */
const initialState = {
	name: undefined,
	phone: undefined,
	phoneAuth: false,
	email: true,
	detailPartySize: undefined,
	menus: undefined,
	additionalInfo: undefined,
	payment: undefined,
	agreement: false,
}

const useValidation = create((set) => ({
	// State
	...initialState,

	// Setter
	setValidation: (type, value) => set({ [type]: value }),

	// Util
	validateFormData: (e) => {
		const { name, value } = e.target
		if (name === 'email' && value === '')
			set({ email: true })
		else if (/^(name|phone)$/.test(name))
			set({ [name]: CheckUserFormData(name, value) })
	},

	// Reset
	resetValidation: () => set(initialState),
}))

export default useValidation
