import React from 'react'
import BookingSubtitle from '../../../Share/Booking/BookingSubtitle/BookingSubtitle'
import { ReactComponent as Info } from '../../../../assets/icon/info.svg'
import { useReservation } from '../../../../store'
import useDynamicComponent from '../../../../hooks/useDynamicComponent'

export default function RequestForm() {
	const { request, setReservation } = useReservation()
	const { renderComponent } = useDynamicComponent()

	const sendParentEvent = (e) => {
		setReservation('request', e.target.value.trim())
		window.parent.postMessage({ func: 'hideKeyPad' }, '*')
	}

	return (
		<div className="request-form-container">
			<BookingSubtitle subtitle="요청사항" />
			<textarea
				className="request-textarea"
				placeholder="(선택) 요청사항을 입력해주세요."
				defaultValue={request}
				maxLength="100"
				rows="4"
				onBlur={(e) => sendParentEvent(e)}
			/>
			<div className="description b3">
				{renderComponent({
					default: (
						<>
							<Info className="icon" />
							<span className="notice">
								매장 상황에 따라 요청사항이 반영되지 않을 수 있습니다. 요청사항에 대한 확인은 매장에 직접 문의하시기
								바랍니다.
							</span>
						</>
					),
					gyeongjuro: (
						<ul>
							<li>매장 상황에 따라 요청사항이 반영되지 않을 수 있습니다.</li>
							<li>요청 사항에 대한 확인은 매장에 직접 문의하시기 바랍니다.</li>
						</ul>
					)
				})}
			</div>
		</div>
	)
}
