import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import QuestionList from '../../../Components/Faq/QuestionList/QuestionList'
import { QuestionAPI } from '../../../lib/question'

export default function FaqPage() {
	const [questions, setQuestions] = useState([])
	const { brandId } = useParams()
	const getQuestion = new QuestionAPI()
	useEffect(() => {
		if (brandId) {
			getQuestion._get(brandId).then((res) => {
				setQuestions(res.data.list)
			})
		}
	}, [brandId])

	return (
		<div className='faqpage-wrapper'>
			{questions.map((data, index) => {
				return (
					<QuestionList item={data} itemIndex={index} key={index} />
				)
			})}
		</div>
	)
}
