import React from 'react'
import { useVoucherContext } from '../../../../context/Common/voucher'
import BookingSubtitle from '../../../Share/Booking/BookingSubtitle/BookingSubtitle'
import './ProductInfo.scss'

export default function ProductInfo() {
	const { purchaseList } = useVoucherContext()
	if (!purchaseList)
		return null
	return (
		<div className="product-info-container">
			<BookingSubtitle subtitle="구매 상품"></BookingSubtitle>
			<div className="info-box">
				<div className="label b1">구매상품</div>
				<div className="value b1">{purchaseList.map((item, idx) =>
					<span key={idx}>{item.name} {item.purchaseCount}장</span>)}</div>
			</div>
			<div className="info-box purchase-amount-wrap">
				<div className="label b1">구매금액</div>
				<div className="value b1">{purchaseList.filter(item => item.purchaseCount > 0).reduce((a, b) => a + b.salePrice * b.purchaseCount, 0).toLocaleString()}원</div>
			</div>
		</div>
	)
}
