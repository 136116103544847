import classNames from 'classnames'
import moment from 'moment'
import React from 'react'
import { useMall, useReservation } from '../../../store'
import { NoticeParagraph } from './ReservationInfo.styles'

const ReservationInfo = (props) => {
	const { isSplitView = false, isDetailView } = props
	const {
		name,
		phone,
		request,
		totalPartySize,
		detailPartySize,
		targetDate = moment(),
		localePrice,
		price,
	} = useReservation((state) => state)
	const { mall_name: mallName } = useMall((state) => state)
	const partySizeString = Object.entries(detailPartySize)
		.filter((ele) => ele[1] > 0)
		.map((item) => `${item[0]} ${item[1]}명`)
		.join(', ')
	const reservationData = [
		{ name: '매장명', value: mallName },
		{
			name: '예약일시',
			value: targetDate.format('LL (dd) ∙ LT'),
		},
		{ name: '예약인원', value: `${totalPartySize}명 (${partySizeString})` },
		{ name: '예약금액', value: localePrice() },
		{ name: '예약자', value: name },
		{
			name: '연락처',
			value: (() => {
				if (!phone)
					return null
				const phoneArr = phone.split('-')
				phoneArr[1] = '*'.repeat(phoneArr[1].length)
				return phoneArr.join('-')
			})(),
		},
		{ name: '요청사항', value: request ?? '없음' },
	]

	const filteredReservationData = (() => {
		const outputData = [...reservationData]

		if (price <= 0) {
			outputData.splice(
				outputData.findIndex((item) => item.name === '예약금액'),
				1
			)
		}

		if (isSplitView) {
			return {
				mall: outputData.filter((item) => item.name.startsWith('예약') && item.name.length > 3),
				user: outputData.slice(-3),
			}
		}
		return outputData
	})()

	return (
		<div className={classNames('gyeongju-reservation-info-container', { split: isSplitView })}>
			{!isSplitView
				? (
					<>
						<h1>예약내용 확인</h1>
						<ul>
							{filteredReservationData.map((list, idx) => (
								<li key={idx}>
									<div>
										<label>{list.name}</label>
										<span>{list.value}</span>
									</div>
								</li>
							))}
						</ul>
					</>
				)
				: (
					<>
						<h1>예약내용 확인</h1>
						<ul>
							{filteredReservationData.mall.map((mallData, idx) => (
								<li key={idx}>
									<div>
										<label>{mallData.name}</label>
										<span>{mallData.value}</span>
									</div>
								</li>
							))}
						</ul>
						{price && isDetailView
							? (
								<NoticeParagraph>
							- 해당 레스토랑은 <label className="red">No Show</label>로 인해 발생되는 매장의 피해를 예방하고자 예약금
							제도를 운영합니다.
								</NoticeParagraph>
							)
							: (
								<></>
							)}
						<h1>예약자 정보</h1>
						<ul>
							{filteredReservationData.user.map((userData, idx) => (
								<li key={idx}>
									<div>
										<label>{userData.name}</label>
										<span>{userData.value}</span>
									</div>
								</li>
							))}
						</ul>
					</>
				)}
		</div>
	)
}

export default ReservationInfo
