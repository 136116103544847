import { createContext, useContext, useState } from 'react'
import moment from 'moment/moment'
import { getMyVoucherOrders } from '../../lib/voucher'
import { BookingMyPageAPI } from '../../lib/mytable'

const MyPageContext = createContext({
	reservationList: [],
	setReservationList: () => { },
	voucherList: [],
	setVoucherList: () => { },
	reservationInfo: null,
	setReservationInfo: () => { },
	reservationCancelToken: null,
	setReservationCancelToken: () => { },
	getMyList: async () => {}
})

const MyPageContextProvider = (props) => {
	const [reservationList, setReservationList] = useState([])
	const [voucherList, setVoucherList] = useState([])
	const [reservationInfo, setReservationInfo] = useState()
	const [reservationCancelToken, setReservationCancelToken] = useState()

	const getMyList = async (params) => {
		const { apiKey, partnerName, partnerInfo, setIsLoading } = { ...params }

		if (!apiKey || !partnerName)
			return

		setIsLoading?.(true)

		const bookingMyPageAPI = new BookingMyPageAPI(apiKey, null)
		let userId = sessionStorage.getItem('authorization')
		if (partnerName === 'kb')
			userId = `member_id:kb ${partnerInfo.chnlMbrId}`

		await bookingMyPageAPI.getReservationList(userId).then((res) => {
			if (res.result) {
				res.list.forEach((item) => {
					item.date = moment(new Date(item.date_y, item.date_m - 1, item.date_d)).set(
						'minute',
						Math.round(item.date_h_start * 60)
					)
				})
				setReservationList(res.list.reverse())
			}
		})

		const isPartner = partnerName !== 'tablemanager'
		await getMyVoucherOrders(userId, isPartner, ['AVAILABLE'].join(','), sessionStorage.getItem('referral'), { withNote: 'Y', })
			.then((res) => {
				let successList = res.orders.filter((order) => {
					return order.points.some((point) => point.status === 'SUCCESS')
				})
				successList.forEach((item) => {
					item.points = item.points.filter((point) => point.status === 'SUCCESS')
					item.products = item.products.filter((product) =>
						item.points.map((point) => point.externalId).includes(product.externalId)
					)
					item.service.expireMoment = moment(item.points[0].expiryDate)
				})
				setVoucherList(successList)
			})
			.catch((err) => console.log(err))

		setIsLoading?.(false)
	}

	const initialValue = {
		reservationList,
		setReservationList,
		voucherList,
		setVoucherList,
		reservationInfo,
		setReservationInfo,
		reservationCancelToken,
		setReservationCancelToken,
		getMyList
	}

	return <MyPageContext.Provider value={initialValue}>{props.children}</MyPageContext.Provider>
}


const useMyPageContext = () => useContext(MyPageContext)

export { MyPageContextProvider, useMyPageContext }
