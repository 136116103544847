import React from 'react'
import { useState, useEffect } from 'react'
import { ReactComponent as Marker } from '../../../../assets/icon/marker.svg'
import { ReactComponent as Domicile } from '../../../../assets/icon/domicile.svg'
import { ReactComponent as Call } from '../../../../assets/icon/call.svg'
import Map from '../../../Share/Map'
import BookingSubtitle from '../../../Share/Booking/BookingSubtitle/BookingSubtitle'
import { usePartnerContext } from '../../../../context/Common/partner'
import { callPhoneNumberInSkt } from '../../../../utils'
export default function Address(props) {
	const { partnerName } = usePartnerContext()
	const [lat, setLat] = useState()
	const [lng, setLng] = useState()
	const [address, setAddress] = useState()
	useEffect(() => {
		if (props.lat && props.lng && props.address) {
			setLat(props.lat)
			setLng(props.lng)
			setAddress(props.address)
		}
	}, [props.lat, props.lng, props.address])

	if (!address)
		return null

	return (
		<div className="address-container">
			<BookingSubtitle subtitle="주소"></BookingSubtitle>
			<Map lat={lat} lng={lng}></Map>
			<div className="address-box">
				<div className="street-address">
					<div className="text">{address.road} {address.detail}</div>
				</div>
				<div className="domicile-address">
					<div className="text">지번 : {address.address}</div>
				</div>
				<div className="address-phone-container">
					<div className="icon-container">
						<Call className="call-icon" />
					</div>
					<div className="phone b1">
						{partnerName === 'skt'
							? (
								<div className="tel" onClick={() => callPhoneNumberInSkt(props.phone)}>
									<div className="phone-number">{props.phone}</div>
								</div>
							)
							: (
								<a href={`tel:${props.phone}`} className="tel">
									<div className="phone-number">{props.phone}</div>
								</a>
							)}
					</div>
				</div>
			</div>
		</div>
	)
}
