import React, { useEffect, useMemo } from 'react'
import OrderSheetHeader from '../../../Components/Pickup/OrderSheet/OrderSheetHeader'
import OrderSheetFooter from '../../../Components/Pickup/OrderSheet/OrderSheetFooter'
import './OrderSheet.scss'
import { usePickupContext } from '../../../context/Pickup/pickup'
import { PickupAPI } from '../../../lib/api'
import { useParams } from 'react-router-dom'
import Menu from '../../../Components/Share/Pickup/Menu'
import useDynamicComponent from '../../../hooks/useDynamicComponent'
import { PickupMenu } from '../../../Components/Gyeongju'
import { usePartnerContext } from '../../../context/Common/partner'
import { authorizationWithPrefix } from '../../../lib/utils'

export default function OrderSheet() {

	const { orderSheet, mall, setMall } = usePickupContext()
	const { renderComponent } = useDynamicComponent()
	const { apiKey } = usePartnerContext()
	const params = useParams()

	const pickupAPI = useMemo(
		() => new PickupAPI(apiKey, authorizationWithPrefix()),
		[apiKey]
	)

	useEffect(() => {
		const fetchMall = () => pickupAPI.getMall(params.mallUri).then((res) => {
			setMall(res.mall)
		})
		if (params.mallUri)
			fetchMall()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params.mallUri])

	return (
		<>
			{
				renderComponent({
					default: (
						<div>
							<OrderSheetHeader mall={mall}></OrderSheetHeader>
							{orderSheet.length === 0
								? <div className="empty-order-sheet">저런 장바구니가 비었네요</div>
								: null}
							{orderSheet.map((menu, idx) => <Menu type="edit" menu={menu} key={idx} />)}
							<OrderSheetFooter orderSheet={orderSheet}></OrderSheetFooter>
						</div>
					),
					gyeongjuro: (
						<div>
							{orderSheet.length === 0
								? <div className="empty-order-sheet">저런 장바구니가 비었네요</div>
								: null}
							{orderSheet.map((menu, idx) => <PickupMenu type="edit" menu={menu} key={idx} />)}
							<OrderSheetFooter orderSheet={orderSheet}></OrderSheetFooter>
						</div>
					)
				})
			}
		</>
	)
}
