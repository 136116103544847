import React from 'react'
import './VoucherItem.scss'
import { getSaleRate } from '../../../Voucher/utils'
import { useHistory } from 'react-router-dom'
export default function VoucherItem(props) {
	const { voucher } = props
	const history = useHistory()
	function MallImage() {
		const imageStyle = {
			background: `url('${voucher.service.thumbnail}')`,
			backgroundPosition: '50% 50%',
			backgroundSize: 'cover'
		}
		return <div className="mall-image" style={imageStyle}>
		</div>
	}
	if (!voucher)
		return null
	function moveVoucher() {
		sessionStorage.setItem('moveScrollY', window.scrollY)
		history.push(`/voucher/detail/${voucher.brand.id}`)
	}
	return (
		<div className="voucher-home-item-container" onClick={moveVoucher}>
			<MallImage></MallImage>
			<div className="mall-food b3">{voucher.service.category}</div>
			<div className="mall-name t5">{voucher.brand.name}</div>
			<div className="amount b3">{voucher.products[0].amount.toLocaleString()}원</div>
			<div className="sale-amount b2"><span className="sale-percent">{getSaleRate(voucher.products[0].amount, voucher.products[0].salePrice)}%</span>{voucher.products[0].salePrice.toLocaleString()}원</div>
		</div>
	)
}
