import React, { useState } from 'react'
import { useHomeContext } from '../../../../context/Booking/home'
import { usePartnerContext } from '../../../../context/Common/partner'
import './RegionSlider.scss'

export default function RegionSlider() {
	const [mouseDown, setMouseDown] = useState(false)
	const { selectedRegion, setSelectedRegion, regions } = useHomeContext()
	const { partnerName } = usePartnerContext()
	let pos = {
		left: 0,
		x: 0
	}

	function handleMouseDown(e) {
		const ele = document.getElementById('region-slider')
		pos = {
			left: ele.scrollLeft,
			x: e.clientX,
		}
		ele.style.cursor = 'grabbing'
		ele.style.userSelect = 'none'
		document.addEventListener('mousemove', handleMouseMove)
		document.addEventListener('mouseup', handleMouseUp)
	}

	function handleMouseMove(e) {
		if (!mouseDown)
			setMouseDown(true)

		const dx = e.clientX - pos.x
		const dy = e.clientY - pos.y
		const ele = document.getElementById('region-slider')
		ele.scrollTop = pos.top - dy
		ele.scrollLeft = pos.left - dx
	}

	function handleMouseUp(e) {
		const ele = document.getElementById('region-slider')
		ele.style.cursor = 'default'
		ele.style.removeProperty('user-select')
		document.removeEventListener('mousemove', handleMouseMove)
		document.removeEventListener('mouseup', handleMouseUp)
		setTimeout(() => {
			setMouseDown(false)
		}, 100)
	}

	function changeRegion(name, index) {
		setSelectedRegion(name)
		const ele = document.getElementById('region-slider')
		const children = ele.getElementsByClassName('region')
		let left = 0
		for (let i = 0; i < index; i++)
			left += children[i].clientWidth + 19
		ele.scrollLeft = left
		let targetScrollY = 580
		if (partnerName === 'skt')
			targetScrollY += 370
		if (window.scrollY > targetScrollY)
			window.scrollTo(0, targetScrollY)
	}

	if (!regions)
		return <div></div>

	return (
		<div className="region-slider-container" id="region-slider" onMouseDown={handleMouseDown}>
			{regions.map((region, idx) =>
				<div key={idx} onClick={() => changeRegion(region.regions_name, idx)} className={region.regions_name === selectedRegion
					? 'region selected t5'
					: 'region t5'}>{region.regions_name}</div>
			)}
		</div>
	)
}
