import { Fragment, useLayoutEffect } from 'react'
import { useMall, useReservation } from '../../store'

export default function ZustandProvider(props) {
	const { mall, malls, mallUri, setMall } = useMall()
	const { additionalInfos, setReservation } = useReservation()

	useLayoutEffect(() => {
		if (malls)
			sessionStorage.setItem('BookingMalls', JSON.stringify(malls))
	}, [malls])

	useLayoutEffect(() => {
		(async function() {
			if (mall) {
				let tmpObject = JSON.parse(sessionStorage.getItem('mallList')) || {}
				tmpObject[mall.mall_id] = mall
				await sessionStorage.setItem('mallList', JSON.stringify(tmpObject))
				await sessionStorage.setItem('mall', JSON.stringify(mall))
				if (mall.info_additional?.length > 0) {
					mall.info_additional.forEach((additional) => {
						if (!additional.value && additional.enabled) {
							if (additional.kind === 'select' && additional.multiple)
								additional.value = []
							else if (additional.kind === 'select')
								additional.value = additional.validations[0]?.name
							else
								additional.value = ''
						}
					})
					if (!additionalInfos)
						setReservation('additionalInfos', mall.info_additional)
				}
			}
			if (sessionStorage.getItem('mallList') && !mall && mallUri) {
				if (JSON.parse(sessionStorage.getItem('mallList'))[mallUri])
					setMall('mall', JSON.parse(sessionStorage.getItem('mallList'))[mallUri])
			}
		})()
	}, [mall, mallUri])

	return <Fragment>{props.children}</Fragment>
}
