import React from 'react'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
// eslint-disable-next-line camelcase
import { createMuiTheme, unstable_createMuiStrictModeTheme } from '@material-ui/core/styles'
import { loginFromKakao } from '../../lib/kakao'
import { useAccountContext } from '../../context/Common/account'
import { usePartnerContext } from '../../context/Common/partner'
import { ReactComponent as Close } from '../../assets/icon/closeIcon.svg'
import KakaoLoginButton from '../Share/Social/Kakao/LoginButton/KakaoLoginButton'
import NaverLoginButton from '../Share/Social/Naver/NaverLoginButton'
import Drawer from '../Share/Drawer/Drawer'
import './LoginDrawer.scss'

let isMobile = false
if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent))
	isMobile = true

const createTheme = process.env.NODE_ENV === 'production'
	? createMuiTheme
	// eslint-disable-next-line camelcase
	: unstable_createMuiStrictModeTheme
const createdTheme = createTheme({
	custom: {
		drawer: {
			width: (isMobile
				? '100%'
				: '360px'),
			backgroundColor: 'transparent',
			borderRadius: '16px 16px 0 0',
			left: 'auto',
			right: 'auto'
		}
	}
})

export default function LoginDrawer(props) {
	const { isOpen, onClose } = props
	const history = useHistory()
	const { user, setAuthData } = useAccountContext()
	const { partnerName } = usePartnerContext()

	const kakaoLogin = async () => {
		try {
			const kakaoResponse = await loginFromKakao()
			if (kakaoResponse) {
				setAuthData(kakaoResponse.auth)
				history.replace('/voucher/login/auth', { auth: kakaoResponse })
			} else {
				throw Error('카카오 로그인에 실패하였습니다.')
			}
		} catch (e) {
			if (typeof e.message === 'string')
				alert(e.message)
			else
				console.error(e.message)
		}
	}

	const toggleDrawer = () => (event) => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift'))
			return
		onClose()
	}

	if (partnerName !== 'tablemanager' || user)
		return null
	return (
		<MuiThemeProvider theme={createdTheme}>
			<Drawer
				anchor="bottom"
				open={isOpen}
				onClose={toggleDrawer()}
			>
				<div className={
					isMobile
						? 'login-drawer-container mobile'
						: 'login-drawer-container'
				}>
					<Close className="close-button" onClick={toggleDrawer()}></Close>
					<h2 className="visit-welcome-title-first">
						3초만에 끝!
					</h2>
					<h2 className="visit-welcome-title-second">
						초스피드로 로그인<span className="emoji">⚡️</span>
					</h2>
					<p className="visit-welcome-sub-title">
						로그인하고 더예약 회원들만의<br/>
						특별 할인 혜택을 받아보세요!<br/>
						예약안내도 카톡으로 띠링-
					</p>

					<div className="social-login-button-container">
						<KakaoLoginButton key="kakao" onClick={kakaoLogin}></KakaoLoginButton>
						{/* <NaverLoginButton key="naver" label="네이버 로그인"></NaverLoginButton> */}
					</div>
				</div>
			</Drawer>
		</MuiThemeProvider>
	)
}
