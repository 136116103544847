import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { usePickupContext } from '../../../context/Pickup/pickup'
import './MenuItem.scss'

function MenuItem(props) {
	const history = useHistory()
	const params = useParams()
	const { searchOption } = usePickupContext()
	const handleClick = () => history.push(`/pickup/${params.mallUri}/${searchOption.policy}/menu-detail/${props.menu._id}`)

	return (
		<div className="menu-item-container" onClick={handleClick}>
			<div className="description-container">
				<div className="menu-name">{props.menu.name}</div>
				<div className="menu-price">{props.menu.price.toLocaleString()}원</div>
			</div>
			<div className="image-container">
				{props.menu.imageId
					? <img src={`https://images.tblm.co/image/${props.menu.imageId}?w=64&h=64`} alt="메뉴 이미지"/>
					: null}
			</div>
		</div>
	)
}

export default MenuItem
