import { useState } from 'react'
import { createContext, useContext } from 'react'
import AlertModal from '../../Components/Share/AlertModal/AlertModal'
import ConfirmModal from '../../Components/Share/ConfirmModal/ConfirmModal'
import Toast from '../../Components/Share/Toast/Toast'
import PageModal from '../../Components/Share/PageModal/PageModal'
import { useHistory } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Toast as GyeongjuroToast } from '../../Components/Gyeongju'
import { usePartnerContext } from './partner'

const ModalContext = createContext({
	alertOpen: () => {},
	alertClose: () => {},
	confirmOpen: () => {},
	confirmClose: () => {},
	shareOpen: () => {},
	shareClose: () => {},
	confirmCallback: null,
	setConfirmCallback: () => {},
	shareCallback: null,
	setShareCallback: () => {},
	toastOpen: () => {},
	toastClose: () => {},
	pageModalOpen: () => {},
	pageModalClose: () => {}
})

const useModalContext = () => useContext(ModalContext)


const ModalContextProvider = (props) => {
	const { partnerName } = usePartnerContext()
	const history = useHistory()
	const [popAlertModal, setPopAlertModal] = useState(false)
	const [popConfirmModal, setPopConfirmModal] = useState(false)
	const [popShareModal, setPopShareModal] = useState(false)
	const [popupToast, setPopupToast] = useState(false)
	const [pageModal, setPageModal] = useState(false)
	const [notice, setNotice] = useState('')
	const [okayText, setOkayText] = useState('')
	const [header, setHeader] = useState('')
	const [closeText, setCloseText] = useState('')
	const [closePath, setClosePath] = useState('')
	const [contents, setContents] = useState({ title: '', description: '' })
	const [alertCallback, setAlertCallback] = useState(null)
	const [confirmCallback, setConfirmCallback] = useState(null)
	const [shareCallback, setShareCallback] = useState(null)
	const [autoHideDuration, setAutoHideDuration] = useState(3000)
	const initialValue = {
		alertOpen,
		alertClose,
		confirmOpen,
		confirmClose,
		shareOpen,
		shareClose,
		confirmCallback,
		setConfirmCallback,
		shareCallback,
		setShareCallback,
		toastOpen,
		toastClose,
		pageModalOpen,
		pageModalClose
	}

	function alertClose() {
		setPopAlertModal(false)
		alertCallback?.()
		setAlertCallback(null)
		document.body.classList.remove('modal-open')
	}

	// eslint-disable-next-line max-params
	function alertOpen(text, buttonText = '닫기', headerText = '알림', callback = null) {
		setNotice(text)
		setCloseText(buttonText)
		setHeader(headerText)
		setPopAlertModal(true)
		setAlertCallback(callback)
		document.body.classList.add('modal-open')
	}

	function confirmClose(value) {
		setPopConfirmModal(false)
		setConfirmCallback(value)
		document.body.classList.remove('modal-open')
	}

	// eslint-disable-next-line max-params
	function confirmOpen(text, okayButtonText = '확인', cancelButtonText = '닫기', headerText = '안내') {
		setNotice(text)
		setOkayText(okayButtonText)
		setCloseText(cancelButtonText)
		setHeader(headerText)
		setPopConfirmModal(true)
		document.body.classList.add('modal-open')
	}

	function shareClose(value) {
		setPopShareModal(false)
		document.body.classList.remove('modal-open')
		if (value)
			copyMessage()
		setShareCallback(value)
	}

	function copyMessage() {
		const selBox = document.createElement('textarea')
		let value = localStorage.getItem('shareText')
		selBox.style.position = 'fixed'
		selBox.style.left = '0'
		selBox.style.top = '0'
		selBox.style.opacity = '0'
		selBox.value = value
		document.body.appendChild(selBox)
		selBox.focus()

		if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
			selBox.select()
			selBox.setSelectionRange(0, 999999)
		} else {
			selBox.select()
		}

		document.execCommand('copy')
		document.body.removeChild(selBox)
		switch (partnerName) {
		case 'gyeongjuro': GyeongjuroToast('텍스트가 복사되었습니다.')
			break

		default: toast.dark('텍스트가 복사되었습니다.', {
			position: 'bottom-center',
			autoClose: 3000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			progress: undefined,
		})
			break
		}
	}

	// eslint-disable-next-line max-params
	function shareOpen(text, okayButtonText = '확인', cancelButtonText = '닫기', headerText = '공유하기') {
		setNotice(text)
		setOkayText(okayButtonText)
		setCloseText(cancelButtonText)
		setHeader(headerText)
		setPopShareModal(true)
		document.body.classList.add('modal-open')
	}

	function toastClose() {
		setPopupToast(false)
	}

	function toastOpen(text, hideDuration = 3000) {
		setNotice(text)
		setAutoHideDuration(hideDuration)
		setPopupToast(true)
	}

	function pageModalClose(state = '') {
		setPageModal(false)
		if (closePath)
			history.replace(closePath, state)
	}

	function pageModalOpen({
		path,
		content,
		headerText = '문의 접수 완료',
		cancelButtonText = '홈으로 가기'
	}) {
		setClosePath(path)
		setContents(content)
		setHeader(headerText)
		setCloseText(cancelButtonText)
		setPageModal(true)
	}

	return (
		<ModalContext.Provider value={initialValue}>{props.children}
			<AlertModal open={popAlertModal} onClose={alertClose} closeText={closeText} header={header}>{notice}</AlertModal>
			<ConfirmModal open={popConfirmModal} onClose={confirmClose} okayText={okayText} cancelText={closeText} header={header}>{notice}</ConfirmModal>
			<ConfirmModal open={popShareModal} onClose={shareClose} okayText={okayText} cancelText={closeText} header={header}>{notice}</ConfirmModal>
			<Toast open={popupToast} onClose={toastClose} text={notice} autoHideDuration={autoHideDuration}></Toast>
			<PageModal open={pageModal} onClose={pageModalClose} contents={contents} closeText={closeText} header={header}></PageModal>
			<ToastContainer/>
		</ModalContext.Provider>
	)
}

export { ModalContextProvider, useModalContext }
