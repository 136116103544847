/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { usePickupContext } from '../../../context/Pickup/pickup'
import { useMenuOptionContext } from '../../../context/Pickup/menuOption'
import ActionButton from '../../Share/ActionButton'
import './MenuConfirm.scss'
import { useHistory } from 'react-router-dom'

import { ReactComponent as Minus } from '../../../assets/icon/minus.svg'
import { ReactComponent as MinusGray } from '../../../assets/icon/minusGray.svg'

export default function MenuConfirm(props) {
	const { detailMenu, orderSheet, setOrderSheet } = usePickupContext()
	const [ disabled, setDisabled ] = useState(true)
	const [ totalPrice, setTotalPrice ] = useState(0)
	const [ totalValue, setTotalValue ] = useState(0)
	const [ menuTotalPrice, setMenuTotalPrice ] = useState(0)
	const { menuOption } = useMenuOptionContext()
	const history = useHistory()

	useEffect(() => {
		if (detailMenu) {
			let tmpTotalPrice = detailMenu.price
			detailMenu.optionGroups.forEach(optionGroup => {
				let targetOptionGroup = menuOption[optionGroup._id]
				optionGroup.options.forEach(option => {
					let targetOption = menuOption[optionGroup._id][option._id]
					if (optionGroup.type === 'NUMBER' && targetOption.value > 0)
						tmpTotalPrice += targetOption.value * option.price

					if (optionGroup.type === 'MULTI_SELECT' && targetOption.value)
						tmpTotalPrice += option.price

				})
				if (optionGroup.type === 'SELECT')
					tmpTotalPrice += targetOptionGroup[targetOptionGroup.value].price

			})
			setMenuTotalPrice(tmpTotalPrice)
			setTotalPrice(tmpTotalPrice)
		}
	}, [detailMenu])
	useEffect(() => {
		if (detailMenu) {
			if (totalValue < 1)
				setTotalValue(1)

			let tmpTotalPrice = detailMenu.price
			detailMenu.optionGroups.forEach(optionGroup => {
				let targetOptionGroup = menuOption[optionGroup._id]
				optionGroup.options.forEach(option => {
					let targetOption = menuOption[optionGroup._id][option._id]
					if (optionGroup.type === 'NUMBER' && targetOption.value > 0)
						tmpTotalPrice += targetOption.value * option.price

					if (optionGroup.type === 'MULTI_SELECT' && targetOption.value)
						tmpTotalPrice += option.price

				})
				if (optionGroup.type === 'SELECT')
					tmpTotalPrice += targetOptionGroup[targetOptionGroup.value].price

			})
			setMenuTotalPrice(tmpTotalPrice)
			setTotalPrice(tmpTotalPrice * totalValue)
		}
	}, [menuOption])

	useEffect(() => {
		setTotalPrice(menuTotalPrice * totalValue)
		if (totalValue > 0)
			setDisabled(false)
		else
			setDisabled(true)

	}, [totalValue])

	function setValue(number) {
		if (totalValue + number < 1)
			return

		setTotalValue(totalValue + number)
	}

	function addMenu() {
		let menuObject = {
			menuId: detailMenu._id,
			price: detailMenu.price,
			name: detailMenu.name,
			totalPrice: menuTotalPrice,
			uniqueKey: new Date().getTime().toString(),
			count: totalValue,
			optionGroups: [
			]
		}
		detailMenu.optionGroups.forEach(optionGroup => {
			let targetOptionGroup = menuOption[optionGroup._id]
			if (optionGroup.type === 'SELECT') {
				let targetOption = optionGroup.options.find(opt => opt._id === targetOptionGroup.value)
				menuObject.optionGroups.push({
					optionGroupId: optionGroup._id,
					name: optionGroup.name,
					options:  [{
						optionId: targetOptionGroup.value,
						count: 1,
						name: targetOption.name,
						price: targetOption.price
					}]
				})
			}
			if (optionGroup.type === 'MULTI_SELECT') {
				let keyGroup = Object.keys(targetOptionGroup)
				let options = []
				let add = false
				keyGroup.forEach(key => {
					if (targetOptionGroup[key].value) {
						add = true
						let targetOption = optionGroup.options.find(opt => opt._id === key)
						options.push({
							optionId: key,
							count: 1,
							name: targetOption.name,
							price: targetOption.price
						})
					}
				})
				if (add) {
					menuObject.optionGroups.push({
						optionGroupId: optionGroup._id,
						name: optionGroup.name,
						options
					})
				}
			}
			if (optionGroup.type === 'NUMBER') {
				let keyGroup = Object.keys(targetOptionGroup)
				let options = []
				let add = false
				keyGroup.forEach(key => {
					let targetOption = optionGroup.options.find(opt => opt._id === key)
					if (targetOptionGroup[key].value > 0) {
						add = true
						options.push({
							optionId: key,
							count: targetOptionGroup[key].value,
							name: targetOption.name,
							price: targetOption.price
						})
					}
				})
				if (add) {
					menuObject.optionGroups.push({
						optionGroupId: optionGroup._id,
						name: optionGroup.name,
						options
					})
				}
			}
		})
		if (orderSheet && orderSheet.length > 0 )
			setOrderSheet([...orderSheet, menuObject])
		else
			setOrderSheet([menuObject])

		history.goBack()
	}

	return (
		<>
			<div className="menu-confirm-container-placeholder">
			</div>
			<div className="menu-confirm-container">
				<div className="count-container">
					<div className="icon-container">
						<div className="icon" onClick={() => setValue(-1)}>
							{totalValue === 1
								? <MinusGray />
								: <Minus />}
						</div>
						<div className="count">
							{totalValue}
						</div>
						<div className="icon" onClick={() => setValue(1)}>
							<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M0 4C0 1.79086 1.79086 0 4 0H52C54.2091 0 56 1.79086 56 4V52C56 54.2091 54.2091 56 52 56H4C1.79086 56 0 54.2091 0 52V4Z" fill="white"/>
								<rect x="16" y="27" width="24" height="2" rx="1" fill="#0075FF"/>
								<rect x="29" y="16" width="24" height="2" rx="1" transform="rotate(90 29 16)" fill="#0075FF"/>
								<path d="M4 2H52V-2H4V2ZM54 4V52H58V4H54ZM52 54H4V58H52V54ZM2 52V4H-2V52H2ZM4 54C2.89543 54 2 53.1046 2 52H-2C-2 55.3137 0.686294 58 4 58V54ZM54 52C54 53.1046 53.1046 54 52 54V58C55.3137 58 58 55.3137 58 52H54ZM52 2C53.1046 2 54 2.89543 54 4H58C58 0.686291 55.3137 -2 52 -2V2ZM4 -2C0.686291 -2 -2 0.686294 -2 4H2C2 2.89543 2.89543 2 4 2V-2Z" fill="#E6E6E6"/>
							</svg>
						</div>
					</div>
					<div className="price-container">
						{totalPrice.toLocaleString()}원
					</div>
				</div>
				<div className="button-contanier">
					<ActionButton
						onClick={addMenu}
						disabled={disabled}
						variant="contained"
						color="primary"
						fullWidth={true}
						className="action-button"
						label="장바구니 담기">
					</ActionButton>
				</div>
			</div>
		</>
	)
}

