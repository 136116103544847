import React from 'react'
import './ThirdPartyTerms.scss'
import { ReactComponent as Close } from '../../../assets/icon/close.svg'
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'

export default function ThirdPartyTerms(props) {
	const history = useHistory()
	const popstateEvent = (event) => {
		event.preventDefault()
		props.closeTerms()
	}

	const closeTerm = () => {
		history.goBack()
		props.closeTerms()
	}

	useEffect(() => {
		window.addEventListener('popstate', popstateEvent)
		return (() => {
			window.removeEventListener('popstate', popstateEvent)
		})
	}, [])
	return (
		<div className="service-container">
			<div className="header">
				<div>
				</div>
				<div className="close-title">
					개인정보 제 3자 제공동의
				</div>
				<div className="close-button">
					<Close onClick={closeTerm} />
				</div>
			</div>
			<div className="pre-container">
				<div className="service-title">제 1 조 (목적)</div>
				<div className="service-body">
					개인정보를 제공받는 자 : 효성에프엠에스㈜, 금융기관(국민, 신한, 우리, 농협은행 외 16곳), 통신사(SKT, KT, LG U+, CJ헬로비젼), ㈜카카오톡 등.

					자세한 내용 홈페이지 게시(www.efnc.co.kr/제휴사 소개 메뉴 내)

					개인정보를 제공받는 자의 이용 목적 : 자동이체 서비스 제공 및 자동이체 동의 사실 통지, 통신사 부가서비스 가입신청 대행, 알림톡 서비스 제공 등

					개인정보를 제공받는 자의 개인정보 보유 및 이용기간 : 동의일로부터 서비스 해지일까지. 단, 관계 법령에 의거 일정기간동안 보관.

					신청자는 개인정보에 대해 제 3자에게 제공하는 것을 거부할 수 있으나, 거부 시 자동이체 신청 및 통신사 부가서비스 가입신청 대행업무가 처리되지 않습니다.
				</div>
			</div>
		</div>
	)
}
