import { create } from 'zustand'

const initialState = {
	label: undefined,
	link: undefined,
	action: undefined,
	active: false,
}

const useFooter = create((set) => ({
	...initialState,
	setFooter: (type, value) => set({ [type]: value }),
	reset: () => set(initialState),
}))

export default useFooter
