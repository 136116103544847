import React from 'react'
import './Recommend.scss'
import { useState } from 'react'
import RecommendItem from '../RecommendItem/RecommendItem'
import { useHomeContext } from '../../../../context/Booking/home'
import BookingSubtitle from '../../../Share/Booking/BookingSubtitle/BookingSubtitle'
export default function Recommend() {
	const { recommendMalls } = useHomeContext()
	const [mouseDown, setMouseDown] = useState(false)
	let pos = {
		left: 0,
		x: 0
	}
	function handleMouseDown(e) {
		const ele = document.getElementById('drag-recommend')
		pos = {
			left: ele.scrollLeft,
			x: e.clientX,
		}
		ele.style.cursor = 'grabbing'
		ele.style.userSelect = 'none'
		document.addEventListener('mousemove', handleMouseMove)
		document.addEventListener('mouseup', handleMouseUp)
	}
	function handleMouseMove(e) {
		if (!mouseDown)
			setMouseDown(true)

		const dx = e.clientX - pos.x
		const dy = e.clientY - pos.y
		const ele = document.getElementById('drag-recommend')
		ele.scrollTop = pos.top - dy
		ele.scrollLeft = pos.left - dx
	}
	function handleMouseUp(e) {
		const ele = document.getElementById('drag-recommend')
		ele.style.cursor = 'default'
		ele.style.removeProperty('user-select')
		document.removeEventListener('mousemove', handleMouseMove)
		document.removeEventListener('mouseup', handleMouseUp)
		setTimeout(() => {
			setMouseDown(false)
		}, 100)
	}
	if (!recommendMalls || recommendMalls.length === 0)
		return <div></div>

	return (
		<div className="recommend-container">
			<div className="subtitle-container">
				<BookingSubtitle subtitle="추천 레스토랑"></BookingSubtitle>
			</div>
			<div className="slider-container" id="drag-recommend" onMouseDown={handleMouseDown}>
				{recommendMalls.map((mall, idx) =>
					<RecommendItem mall={mall} mouseDown={mouseDown} key={idx}></RecommendItem>
				)}
			</div>
		</div>
	)
}
