import React from 'react'
import { useBookingContext } from '../../../../context/Booking/booking'
import BookingSubtitle from '../../../Share/Booking/BookingSubtitle/BookingSubtitle'

import './MallDescription.scss'
import { useMall } from '../../../../store'
export default function MallDescription() {
	const { mall_description: description } = useMall(state => state.mall)

	return (
		<div className="mall-description-container">
			<BookingSubtitle subtitle="매장안내"></BookingSubtitle>
			<div className="b1 break-line">{description}</div>
		</div>
	)
}
