import React from 'react'
import './ConfirmModal.scss'
import modalConfig from '../../../configs/partnerModal.json'
import { usePartnerContext } from '../../../context/Common/partner'
export default function ConfirmModal(props) {
	const { open, onClose, okayText, cancelText, header } = props
	const { partnerName } = usePartnerContext()
	const containerStyle = modalConfig.modal[partnerName].containerStyle
	const headerStyle = modalConfig.modal[partnerName].headerStyle
	const bodyStyle = modalConfig.modal[partnerName].bodyStyle
	const footerStyle = modalConfig.modal[partnerName].footerStyle
	const okayButtonStyle = modalConfig.modal[partnerName].okayButtonStyle
	const cancelButtonStyle = modalConfig.modal[partnerName].cancelButtonStyle
	return (
		<div className={open
			? 'openModal modal'
			: 'modal'}>
			{ open ?
				<div className="modal-container" style={containerStyle}>
					<div className="modal-header" style={headerStyle}>
						{header}
					</div>
					<div className="modal-body" style={bodyStyle}>
						{props.children}
					</div>
					<div className="modal-footer" style={footerStyle}>
						<button onClick={() => onClose(false)} style={cancelButtonStyle}>{cancelText}</button>
						<button onClick={() => onClose(true)} style={okayButtonStyle}>{okayText}</button>
					</div>
				</div>
				: null}
		</div>
	)
}
