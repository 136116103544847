import './SearchedItem.scss'

import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSearchContext } from '../../../../context/Booking/search'

export default function SearchedItem(props) {
	const history = useHistory()
	const { searchInfo } = useSearchContext()
	function moveMall(uri) {
		history.push(`/booking/mall-detail/${uri}`)
	}

	function makeMallName() {
		let name = searchInfo.searchWord
		let lowerName = name.toLowerCase()
		const obj = { characters: [], name: props.mall.mall_name }
		let lowerMallName = props.mall.mall_name.toLowerCase()
		let splitName = lowerMallName.split(lowerName)
		let index = 0
		splitName.forEach((charcter) => {
			obj.characters.push({
				character: charcter.toUpperCase(),
				isHighlight: false,
			})
			if (index !== splitName.length - 1) {
				obj.characters.splice(1 + index * 2, 0, {
					character: name.toUpperCase(),
					isHighlight: true,
				})
			}
			index++
		})
		return obj.characters
	}
	return (
		<div className="searched-item-container" onClick={() => moveMall(props.mall.mall_uri)}>
			<div className="mall-image" style={
				{
					backgroundImage: `url(${props.mall.thumbnail || props.mall.info_images[0].src})`,
					backgroundPosition: 'center',
					backgroundSize: 'cover',  /* contain */
					backgroundRepeat: 'no-repeat'
				}}>
			</div>
			<div className="mall-right-area">
				<div className="mall-name t5">{makeMallName().map((item, idx) => <span key={item.character + idx} className={item.isHighlight
					? 'highlight'
					: null}>{item.character}</span>)}</div>
				<div className="mall-food b3">{props.mall.mall_food.join('·')}</div>
			</div>
		</div>
	)
}
