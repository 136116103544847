import React from 'react'
import './MenuOptionGroup.scss'

export default function MenuOptionGroup(props) {
	return (
		<div className="option-group-container">
			<div className="option-desc">{props.optionGroup.name} : {props.optionGroup.options.map((option, idx) =>
				<span key={idx}>{option.name} {option.price
					? `(${option.price.toLocaleString()}원)`
					: '' }</span>)}</div>
		</div>
	)
}
