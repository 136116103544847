/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { usePickupContext } from '../../../context/Pickup/pickup'
import { useMenuOptionContext } from '../../../context/Pickup/menuOption'
import './MenuConfirm.scss'
import { useHistory } from 'react-router-dom'

import { ReactComponent as Minus } from '../../../assets/icon/stepperMinusGyeongju.svg'
import { ReactComponent as MinusGray } from '../../../assets/icon/stepperMinusGrayGyeongju.svg'
import { ReactComponent as Plus } from '../../../assets/icon/stepperPlusGyeongju.svg'
import ActionButton from '../ActionButton/ActionButton'

export default function MenuConfirm(props) {
	const { detailMenu, orderSheet, setOrderSheet } = usePickupContext()
	const [disabled, setDisabled] = useState(true)
	const [totalPrice, setTotalPrice] = useState(0)
	const [totalValue, setTotalValue] = useState(0)
	const [menuTotalPrice, setMenuTotalPrice] = useState(0)
	const { menuOption } = useMenuOptionContext()
	const history = useHistory()

	const getLabelText = () => {
		if (totalPrice > 0)
			return `${totalPrice.toLocaleString()}원 담기`
		return '장바구니 담기'
	}

	useEffect(() => {
		if (detailMenu) {
			let tmpTotalPrice = detailMenu.price
			detailMenu.optionGroups.forEach(optionGroup => {
				let targetOptionGroup = menuOption[optionGroup._id]
				optionGroup.options.forEach(option => {
					let targetOption = menuOption[optionGroup._id][option._id]
					if (optionGroup.type === 'NUMBER' && targetOption.value > 0)
						tmpTotalPrice += targetOption.value * option.price

					if (optionGroup.type === 'MULTI_SELECT' && targetOption.value)
						tmpTotalPrice += option.price

				})
				if (optionGroup.type === 'SELECT')
					tmpTotalPrice += targetOptionGroup[targetOptionGroup.value].price

			})
			setMenuTotalPrice(tmpTotalPrice)
			setTotalPrice(tmpTotalPrice)
		}
	}, [detailMenu])
	useEffect(() => {
		if (detailMenu) {
			if (totalValue < 1)
				setTotalValue(1)

			let tmpTotalPrice = detailMenu.price
			detailMenu.optionGroups.forEach(optionGroup => {
				let targetOptionGroup = menuOption[optionGroup._id]
				optionGroup.options.forEach(option => {
					let targetOption = menuOption[optionGroup._id][option._id]
					if (optionGroup.type === 'NUMBER' && targetOption.value > 0)
						tmpTotalPrice += targetOption.value * option.price

					if (optionGroup.type === 'MULTI_SELECT' && targetOption.value)
						tmpTotalPrice += option.price

				})
				if (optionGroup.type === 'SELECT')
					tmpTotalPrice += targetOptionGroup[targetOptionGroup.value].price

			})
			setMenuTotalPrice(tmpTotalPrice)
			setTotalPrice(tmpTotalPrice * totalValue)
		}
	}, [menuOption])

	useEffect(() => {
		setTotalPrice(menuTotalPrice * totalValue)
		if (totalValue > 0)
			setDisabled(false)
		else
			setDisabled(true)

	}, [totalValue])

	function setValue(number) {
		if (totalValue + number < 1)
			return

		setTotalValue(totalValue + number)
	}

	function addMenu() {
		let menuObject = {
			menuId: detailMenu._id,
			price: detailMenu.price,
			name: detailMenu.name,
			totalPrice: menuTotalPrice,
			uniqueKey: new Date().getTime().toString(),
			count: totalValue,
			optionGroups: [
			]
		}
		detailMenu.optionGroups.forEach(optionGroup => {
			let targetOptionGroup = menuOption[optionGroup._id]
			if (optionGroup.type === 'SELECT') {
				let targetOption = optionGroup.options.find(opt => opt._id === targetOptionGroup.value)
				menuObject.optionGroups.push({
					optionGroupId: optionGroup._id,
					name: optionGroup.name,
					options: [{
						optionId: targetOptionGroup.value,
						count: 1,
						name: targetOption.name,
						price: targetOption.price
					}]
				})
			}
			if (optionGroup.type === 'MULTI_SELECT') {
				let keyGroup = Object.keys(targetOptionGroup)
				let options = []
				let add = false
				keyGroup.forEach(key => {
					if (targetOptionGroup[key].value) {
						add = true
						let targetOption = optionGroup.options.find(opt => opt._id === key)
						options.push({
							optionId: key,
							count: 1,
							name: targetOption.name,
							price: targetOption.price
						})
					}
				})
				if (add) {
					menuObject.optionGroups.push({
						optionGroupId: optionGroup._id,
						name: optionGroup.name,
						options
					})
				}
			}
			if (optionGroup.type === 'NUMBER') {
				let keyGroup = Object.keys(targetOptionGroup)
				let options = []
				let add = false
				keyGroup.forEach(key => {
					let targetOption = optionGroup.options.find(opt => opt._id === key)
					if (targetOptionGroup[key].value > 0) {
						add = true
						options.push({
							optionId: key,
							count: targetOptionGroup[key].value,
							name: targetOption.name,
							price: targetOption.price
						})
					}
				})
				if (add) {
					menuObject.optionGroups.push({
						optionGroupId: optionGroup._id,
						name: optionGroup.name,
						options
					})
				}
			}
		})
		if (orderSheet && orderSheet.length > 0)
			setOrderSheet([...orderSheet, menuObject])
		else
			setOrderSheet([menuObject])

		history.goBack()
	}

	return (
		<>
			<div className="menu-confirm-container-placeholder">
			</div>
			<div className="gyeongjuro-menu-confirm-container">
				<div className="count-container">
					<div className="icon-container">
						<div className="icon" onClick={() => setValue(-1)}>
							{totalValue === 1
								? <MinusGray className="minus" />
								: <Minus className="minus" />}
						</div>
						<div className="count">
							{totalValue}
						</div>
						<div className="icon" onClick={() => setValue(1)}>
							<Plus className="plus" />
						</div>
					</div>
					<div className="price-container">
						{totalPrice.toLocaleString()}원
					</div>
				</div>
				<div className="button-contanier">
					<ActionButton
						onClick={addMenu}
						disabled={disabled}
						variant="contained"
						color="primary"
						fullWidth={true}
						className="gyeongjuro-action-button"
						label={getLabelText()}>
					</ActionButton>
				</div>
			</div>
		</>
	)
}

