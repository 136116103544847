import React from 'react'
import { Route, Switch } from 'react-router-dom'
/* Provider */
import { VoucherContextProvider } from '../context/Voucher/voucher'
/* Login */
import LoginAuth from '../pages/Login/LoginAuth'
/* Voucher */
import VoucherMain from '../pages/Voucher/Main/Main'
import MyPage from '../pages/Voucher/MyPage/MyPage'
import CustomerInquiry from '../pages/Voucher/Inquiry/CustomerInquiry/CustomerInquiry'
import PartnershipInquiry from '../pages/Voucher/Inquiry/PartnershipInquiry/PartnershipInquiry'
import BulkPurchaseInquiries from '../pages/Voucher/Inquiry/BulkPurchaseInquiries/BulkPurchaseInquiries'
import LiveCommerceInquiry from '../pages/Voucher/Inquiry/LiveCommerceInquiry/LiveCommerceInquiry'
import VoucherDetail from '../pages/Common/VoucherDetail/VoucherDetail'
import InfoForm from '../pages/Voucher/InfoForm/InfoForm'
import Payment from '../pages/Voucher/Payment/Payment'
import Complete from '../pages/Voucher/Complete/Complete'
import PrivateRoute from './PrivateRoute'
import { HomeContextProvider } from '../context/Voucher/home'

export default () => {
	return (
		<VoucherContextProvider>
			<HomeContextProvider>
				<Route exact={true} path="/voucher/main" component={VoucherMain} />
			</HomeContextProvider>
			<PrivateRoute exact={true} path="/voucher/mypage" component={MyPage} />

			<PrivateRoute exact={true} path="/voucher/inquiry/customer" component={CustomerInquiry} />
			<Route exact={true} path="/voucher/inquiry/partnership" component={PartnershipInquiry} />
			<Route exact={true} path="/voucher/inquiry/bulkpurchase" component={BulkPurchaseInquiries} />
			<Route exact={true} path="/voucher/inquiry/live-commerce" component={LiveCommerceInquiry} />

			<Route exact={true} path="/voucher/login/auth" component={LoginAuth} />
			<Route exact={true} path="/voucher/detail/:brandId" component={VoucherDetail} />
			<Route exact={true} path="/voucher/step/info" component={InfoForm} />
			<Route exact={true} path="/voucher/step/payment" component={Payment} />
			<Route exact={true} path="/voucher/step/complete" component={Complete} />
		</VoucherContextProvider>
	)
}
