import React, { useState } from 'react'
import './PickupPaymentMenuList.scss'
import MenuOptionGroup from '../MenuOptionGroup/MenuOptionGroup'
import { ReactComponent as ExpandButton } from '../../../assets/icon/expandButtonGyeongju.svg'
import classNames from 'classnames'

export default function PickupPaymentMenuList(props) {
	const { orderInfo } = props
	const [expanded, setExpanded] = useState(false)
	return (
		<div className="payment-menu-list-container">
			<div className="payment-menu-header-container">
				<div className="my-menu-subtitle">
					<span>내 메뉴</span>
					<div className="menu-count-badge">{orderInfo.serviceOrder.selectedMenus.length}</div>
				</div>
				<ExpandButton className={classNames('expand-button', { reverse: expanded })} onClick={() => setExpanded(!expanded)} />
			</div>
			<div className={classNames('pickup-menu-container', { open: expanded })}>
				{orderInfo.serviceOrder.selectedMenus.map(menu =>
					<div className="pickup-menu-box">
						<div className="row">
							<div className="menu-name">{menu.name}<span className="menu-count">{menu.count}개</span></div>
						</div>
						{menu.optionGroups.length > 0 ?
							<div className="menu-option-group-container">
								{menu.optionGroups.map((optionGroup, idx) => <MenuOptionGroup optionGroup={optionGroup} key={idx} />)}
							</div>
							: null
						}
						<div className="row">
							<div className="menu-price">{((menu.totalPrice || menu.price) * (menu.count)).toLocaleString()}원</div>
						</div>
					</div>)}
			</div>
			{
				orderInfo.serviceOrder.selectedMenus.reduce((prev, next) => {
					return prev + (next.totalPrice * next.count)
				}, 0) > 0 ?
					<div className="total-payment-menu-contianer">
						<div className="row">
							<div className="label">총 수량</div>
							<div className="value">{orderInfo.serviceOrder.selectedMenus.reduce((prev, next) => {
								return prev + next.count
							}, 0)}</div>
						</div>
						<div className="row">
							<div className="label">총 결제 금액</div>
							<div className="value total-price">{orderInfo.serviceOrder.selectedMenus.reduce((prev, next) => {
								return prev + (next.totalPrice * next.count)
							}, 0).toLocaleString()}원</div>
						</div>
					</div> :
					null
			}
		</div>
	)
}
