import React, { useEffect } from 'react'
import { ReactComponent as Close } from '../../../assets/icon/close.svg'
import { useHistory } from 'react-router-dom'
import './PrivateTerms.scss'

export default function PrivateTerms(props) {
	const history = useHistory()
	const popstateEvent = (event) => {
		event.preventDefault()
		props.closeTerms()
	}

	const closeTerm = () => {
		history.goBack()
		props.closeTerms()
	}

	useEffect(() => {
		window.addEventListener('popstate', popstateEvent)
		return (() => {
			window.removeEventListener('popstate', popstateEvent)
		})
	}, [])

	return (
		<div className="service-container">
			<div className="header">
				<div>
				</div>
				<div className="close-title">
          개인정보 취급방침
				</div>
				<div className="close-button">
					<Close onClick={closeTerm}/>
				</div>
			</div>

			<div class="pre-container">
				<div class="service-contents">
					<p class="service-content">
					테이블매니저(주)(이하 “회사”)에서 운영하는 더예약(the yeyak)서비스(이하 “서비스”)를 이용함에 있어 “회사”와 “이용자”간의 권리, 의무 및 책임사항을 규정함을 목적으로 합니다.
					“회사”가 제공하는 “서비스”는 이용자의 개인정보를 중요시하며 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 “정보통신망법”), 개인정보보호법 등 개인정보 보호 법령을 준수하고 있습니다.
					수집하는 개인정보의 항목, 개인정보의 수집 및 이용목적을 다음과 같이 수립·공개합니다.
					</p>
				</div>

				<div class="service-contents">
					<h5 class="service-title">제 1조 개인정보의 수집 및 이용 목적 </h5>
					<ol class="service-items static-numbering">
						<li>
							<p class="service-content"><span class="item-number">&#9312;</span> 이용자는 회원가입을 하지 않아도 매장 검색 등 서비스에
							자유롭게 접근할 수 있습니다. 이용자가 예약, 결제 등과 같이 개인화 혹은 회원제 서비스를 이용하기 위해 회원가입을 할 경우, 회사는 서비스 이용을 위해 필요한 최소한의 개인정보를
							수집합니다.</p>
						</li>
						<li>
							<p class="service-content"><span class="item-number">&#9313;</span> 회원가입 시점에 회사가 이용자로부터 수집하는 개인정보는
							아래와 같습니다.</p>
							<table class="service-table">
								<thead>
									<tr>
										<th>구분</th>
										<th>수집 목적</th>
										<th>수집하는 정보</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>비회원 서비스</td>
										<td>이용자 식별 및 본인여부 확인, 상담 관리</td>
										<td>이름, 휴대 전화번호, 이메일 주소, 생년월일, 성별</td>
									</tr>
									<tr>
										<td>회원 가입</td>
										<td>회원 가입 의사의 확인, 회원탈퇴 의사의 확인,이용자 식별 및 본인여부 확인, 상담 관리, 맞춤형 회원 서비스 제공, 거점 기반 서비스 제공, 맞춤형 광고 제공, 만족도 및
										설문조사 등</td>
										<td>이름, 휴대 전화번호, 이메일 주소, 생년월일, 성별</td>
									</tr>
									<tr>
										<td>결제 서비스</td>
										<td>결제 카드 등록 및 관리, 결제 처리, 본인 명의 결제수단 인증, 부정 사용자 차단 및 관리</td>
										<td>카드정보 : 카드번호, 카드 비밀번호 앞2자리, 유효기간(월/년도), 생년월일</td>
									</tr>
								</tbody>
							</table>
						</li>
						<li>
							<p class="service-content"><span class="item-number">&#9314;</span> 서비스의 각 제휴 채널 별 이용과정에서 이용자로부터
							수집하는 개인정보는 아래와 같습니다.</p>
							<table class="service-table">
								<thead>
									<tr>
										<th>구분</th>
										<th>수집·이용 목적</th>
										<th>수집 항목</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>더예약</td>
										<td>회원 식별, 회원제 서비스 제공, 상담 관리, 휴대전화번호 인증, 맞춤형 광고 제공</td>
										<td>이름, 휴대 전화번호, 이메일 주소, 생년월일, 성별</td>
									</tr>
									<tr>
										<td>삼성카드 프리미엄다이닝</td>
										<td>회원 식별, 회원제 서비스 제공, 상담 관리, 휴대전화번호 인증</td>
										<td>이름, 휴대 전화번호, 이메일 주소, 예약 내역, 결제 내역, CI</td>
									</tr>
									<tr>
										<td>SKT T멤버십 VIP Pick</td>
										<td>회원 식별, 회원제 서비스 제공, 상담 관리, 휴대전화번호 인증</td>
										<td>이름, 휴대 전화번호, 이메일 주소, 예약 내역, 결제 내역, 멤버십 등급, 멤버십 카드번호, 생년월일</td>
									</tr>
									<tr>
										<td>KB Pay 레스토랑 예약</td>
										<td>회원 식별, 회원제 서비스 제공, 상담 관리, 휴대전화번호 인증</td>
										<td>이름, 휴대 전화번호, 이메일 주소, 예약 내역, 결제 내역, CI</td>
									</tr>
									<tr>
										<td>신한카드</td>
										<td>회원 식별, 회원제 서비스 제공, 상담 관리, 휴대전화번호 인증</td>
										<td>이름, 휴대 전화번호, 이메일 주소, 예약 내역, 결제 내역, CI</td>
									</tr>
									<tr>
										<td>BC카드</td>
										<td>회원 식별, 회원제 서비스 제공, 상담 관리, 휴대전화번호 인증</td>
										<td>이름, 휴대 전화번호, 이메일 주소, 예약 내역, 결제 내역, CI</td>
									</tr>
									<tr>
										<td>카카오</td>
										<td>회원 식별, 회원제 서비스 제공, 상담 관리, 휴대전화번호 인증</td>
										<td>이름, 휴대 전화번호, 이메일 주소, 예약 내역, 결제 내역, CI, 생년월일, 성별, 카카오톡 채널 추가 상태 및 내역</td>
									</tr>
								</tbody>
							</table>
						</li>
						<li>
							<p class="service-content"><span class="item-number">&#9315;</span> 서비스 이용 과정에서 아래와 같은 정보들이 생성되어 수집될
							수 있습니다.</p>
							<ol class="service-items auto-numbering" start="1">
								<li>
									<p class="service-content">최근 접속일, 접속 IP 정보, 쿠키, 예약 로그, 이벤트 로그 등 서비스 이용 기록, 기기 정보</p>
								</li>
							</ol>
						</li>
						<li>
							<p class="service-content"><span class="item-number">&#9316;</span> 회사는 다음과 같은 방법으로 개인정보를 수집합니다.</p>
							<ol class="service-items auto-numbering" start="1">
								<li>
									<p class="service-content">회원가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에 대해 동의를 하고 직접 정보를 입력하는 경우</p>
								</li>
								<li>
									<p class="service-content">고객센터를 통한 상담 과정에서 웹 페이지, 메일, 팩스, 전화 등을 통해 수집되는 경우</p>
								</li>
								<li>
									<p class="service-content">회사와 제휴한 외부 기업 등으로부터 개인정보를 제공받을 수 있으며, 이러한 경우에는 정보통신망법 또는 개인정보보호법에 따라
									제휴사에서 이용자에게 개인정보 제 3자 제공 동의 등을 받은 후에 회사에 제공합니다.</p>
								</li>
								<li>
									<p class="service-content">기기 정보와 같은 생성 정보는 PC 웹, 모바일 웹/앱 이용 과정에서 생성되어 수집될 수 있습니다.</p>
								</li>
							</ol>
						</li>
						<li>
							<p class="service-content"><span class="item-number">&#9317;</span> 회사는 법정 대리인의 동의가 필요한 만 14세 미만 아동의
							회원가입은 받고 있지 않습니다.</p>
						</li>
					</ol>
				</div>
				<div class="service-contents">
					<h5 class="service-title">제 2조 수집한 개인정보의 이용</h5>
					<p class="service-content">회사는 수집한 개인정보를 아래의 목적으로만 이용합니다. 이용자가 제공한 모든 정보는 하기 목적에 필요한 용도 이외로는 사용되지 않으며 이용 목적이
					변경될 시에는 사전 동의를 구할 것입니다.</p>
					<ol class="service-items static-numbering">
						<li>
							<p class="service-content"><span class="item-number">&#9312;</span> 회원 가입 의사의 확인, 연령확인, 이용자 식별, 회원탈퇴
							의사의 확인 등 회원관리를 위하여 개인정보를 이용합니다.</p>
						</li>
						<li>
							<p class="service-content"><span class="item-number">&#9313;</span> 서비스 제공, 서비스 방문 및 이용기록의 분석, 서비스
							이용에 대한 통계, 맞춤화 된 서비스 제공 및 광고 게재 등 신규 서비스 발굴 및 기존 서비스 개선 등을 위하여 개인정보를 이용합니다.</p>
						</li>
						<li>
							<p class="service-content"><span class="item-number">&#9314;</span> 이벤트 참여, 유료 서비스 제공에 따르는 본인 인증, 구매
							및 요금 결제, 상품 및 서비스의 배송을 위하여 개인정보를 이용합니다.</p>
						</li>
						<li>
							<p class="service-content"><span class="item-number">&#9315;</span> 이벤트 정보 및 참여 기회 제공, 광고성 정보 제공 등
							마케팅 및 프로모션 목적으로 개인정보를 이용합니다.</p>
						</li>
						<li>
							<p class="service-content"><span class="item-number">&#9316;</span> 법령 및 이용약관을 위반하는 회원에 대한 이용 제한 조치,
							부정 이용 행위를 포함하여 서비스의 원활한 운영에 지장을 주는 행위에 대한 방지 및 제재, 약관 개정 등의 고지사항 전달, 민원처리 등 이용자 보호 및 서비스 운영을 위하여 개인정보를
							이용합니다.</p>
						</li>
						<li>
							<p class="service-content"><span class="item-number">&#9317;</span> 회사는 보다 나은 서비스의 제공을 위하여 아래와 같은
							빅테이터 분석 및 활용을 위한 목적으로 이용내역정보(서비스 이용기록, 인터넷 접속정보 파일, 접속기기 정보 등)와 개인정보를 이용합니다.</p>
							<ol class="service-items auto-numbering" start="1">
								<li>
									<p class="service-content">회사는 서비스를 제공하기 위하여 개인 식별성이 없는 예약 및 결제 정보 등을 수집합니다. 이와 같은 정보는 향후 고객의 서비스
									개선을 위하여 기존에 수집된 개인정보와 결합되어 분석, 활용될 수 있습니다.</p>
								</li>
								<li>
									<p class="service-content">회사는 고객의 공개된 정보 및 서비스 이용기록(로그)과 메타데이터와 같은 정보를 수집하여 이용자에게 보다 큰 효용을 제공할 수
									있도록 분석 및 활용을 하고 있습니다. 회사는 분석된 정보를 통해 고객의 관심 분야에 맞는 광고를 제공하거나 이벤트 참여 기회를 제공하는 등 마케팅 목적으로 활용합니다.</p>
								</li>
							</ol>
						</li>
					</ol>
				</div>
				<div class="service-contents">
					<h5 class="service-title">제 3조 개인정보의 제공 및 위탁</h5>
					<ol class="service-items static-numbering">
						<li>
							<p class="service-content"><span class="item-number">&#9312;</span> 회사는 원칙적으로 이용자 동의 없이, 개인정보를 제
							3자에게 제공하지 않습니다.</p>
						</li>
						<li>
							<p class="service-content"><span class="item-number">&#9313;</span> 단, 이용자가 외부 제휴사의 서비스를 이용하기 위하여 개인정보 제공에
							직접 동의를 한 경우, 그리고 관련 법령에 의거해 회사에 개인정보 제출 의무가 발생한 경우에 한하여 개인정보를 제공하고 있습니다.</p>
						</li>
						<li>
							<p class="service-content"><span class="item-number">&#9314;</span> 서비스 이용 과정에서 제 3자에게 제공되는 정보 및 제공된
							정보의 이용 목적은 다음과 같습니다.</p>
							<table class="service-table">
								<thead>
									<tr>
										<th>제공 대상자</th>
										<th>제공정보</th>
										<th>제공목적</th>
										<th>보유 및 이용기간</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>(주)비씨카드</td>
										<td>성명, 휴대폰 번호, 예약정보, 결제정보</td>
										<td>“Paybooc” 서비스를 통해 접수된 예약에 한해 중개 및 본인 확인, 할인 혜택 제공</td>
										<td>회원 탈퇴 시 혹은 위탁 계약 종료시까지</td>
									</tr>
									<tr>
										<td>삼성카드 주식회사</td>
										<td>성명, 휴대폰 번호, 예약정보, 결제정보</td>
										<td>“삼성카드 프리미엄다이닝” 서비스를 통해 접수된 예약에 한해 중개 및 본인 확인, 할인 혜택 제공</td>
										<td>회원 탈퇴 시 혹은 위탁 계약 종료시까지</td>
									</tr>
									<tr>
										<td>SK 텔레콤(주)</td>
										<td>성명, 휴대폰 번호, 예약정보, 결제정보</td>
										<td>“T멤버십 VIP Pick” 서비스를 통해 접수된 예약에 한해 중개 및 본인 확인, 할인 혜택 제공</td>
										<td>회원 탈퇴 시 혹은 위탁 계약 종료시까지</td>
									</tr>
									<tr>
										<td>주식회사 KB 국민카드</td>
										<td>예약정보, 결제정보</td>
										<td>“KB Pay 레스토랑 예약” 서비스를 통해 접수된 예약에 한해 중개 및 본인 확인, 할인 혜택 제공</td>
										<td>회원 탈퇴 시 혹은 위탁 계약 종료시까지</td>
									</tr>
									<tr>
										<td>예약 접수한 매장</td>
										<td>성명, 휴대폰 번호, 예약정보, 결제정보</td>
										<td>예약 서비스 제공 및 예약자 본인 확인</td>
										<td>예약 이용 완료 및 본인 확인 회원 탈퇴 시까지</td>
									</tr>
									<tr>
										<td>페이스북</td>
										<td>전화번호, 이메일</td>
										<td>맞춤형 정보 제공 및 광고 제공</td>
										<td>회원탈퇴시까지</td>
									</tr>
									<tr>
										<td>구글</td>
										<td>전화번호, 이메일</td>
										<td>맞춤형 정보 제공 및 광고 제공</td>
										<td>회원탈퇴시까지</td>
									</tr>
									<tr>
										<td>카카오</td>
										<td>이름, 휴대 전화번호, 이메일 주소, 예약 내역, 결제 내역, CI, 생년월일, 성별</td>
										<td>맞춤형 정보 제공 및 광고 제공</td>
										<td>회원탈퇴시까지</td>
									</tr>
									<tr>
										<td>아워홈</td>
										<td>이름, 휴대 전화번호, 이메일 주소, 예약 내역, 결제 내역, CI, 생년월일, 성별</td>
										<td>맞춤형 정보 제공 및 광고 제공</td>
										<td>회원탈퇴시까지</td>
									</tr>
									<tr>
										<td>주식회사 플레이스엠</td>
										<td>이름, 휴대 전화번호, 이메일 주소, 예약 내역, 결제 내역, CI, 생년월일, 성별</td>
										<td>맞춤형 정보 제공 및 광고 제공</td>
										<td>회원탈퇴시까지</td>
									</tr>
								</tbody>
							</table>
						</li>
						<li>
							<p class="service-content"><span class="item-number">&#9315;</span> 회사는 서비스 제공을 위하여 필요한 업무 중 일부를 외부
							업체에 위탁하고 있으며, 위탁 받은 업체가 개인정보보호법에 따라 개인정보를 안전하게 처리하도록 필요한 사항을 규정하고 관리/감독을 하고 있습니다.</p>
							<table class="service-table">
								<thead>
									<tr>
										<th>수탁자</th>
										<th>위탁업무</th>
										<th>보유 및 이용기간</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>(주)시옷, 나이스페이먼츠(주), (주)케이알파트너스, 주식회사 KB 국민카드, 삼성카드 주식회사, (주)비씨카드, 전 카드사 등</td>
										<td>결제 처리 및 결제 도용 방지</td>
										<td>회원 탈퇴 시 혹은 위탁 계약 종료 시까지</td>
									</tr>
									<tr>
										<td>에스케이브로드밴드 주식회사, (주)에스엠티앤티, (주)엠앤와이즈</td>
										<td>문자 서비스 전송 시스템 운영</td>
										<td>회원 탈퇴 시 혹은 위탁 계약 종료 시까지</td>
									</tr>
									<tr>
										<td>SK 텔레콤(주)</td>
										<td>“T멤버십 VIP Pick” 서비스를 통해 접수된 예약 및 예약상품권 구매 이력을 멤버십 혜택 이용 실적에 반영</td>
										<td>회원 탈퇴 시 혹은 위탁 계약 종료 시까지</td>
									</tr>
								</tbody>
							</table>
						</li>
					</ol>
				</div>
				<div class="service-contents">
					<h5 class="service-title">제 4조 개인정보의 파기</h5>
					<ol class="service-items static-numbering">
						<li>
							<p class="service-content"><span class="item-number">&#9312;</span> 회사는 원칙적으로 이용자의 개인정보를 회원 탈퇴 시 지체
							없이 파기합니다. 단, 이용자에게 개인정보 보관 기간에 대해 별도의 동의를 얻은 경우, 또는 법령에서 일정 기간 정보 보관 의무를 부과하는 경우에는 해당 기간 동안 개인정보를 안전하게
							보관합니다.</p>
						</li>
						<li>
							<p class="service-content"><span class="item-number">&#9313;</span> 이용자에게 개인정보 보관 기간에 대해 별도의 동의를 얻은
							경우는 아래와 같습니다.</p>
							<ol class="service-items auto-numbering" start="1">
								<li>
									<p class="service-content">부정 가입 및 징계 기록 등의 부정이용기록</p>
								</li>
								<li>
									<p class="service-content">보존 항목 : 이름, 휴대 전화번호</p>
								</li>
								<li>
									<p class="service-content">보존 근거 : 부정 가입 및 이용 방지, 불법 사용자에 대한 수사기관 수사 협조</p>
								</li>
								<li>
									<p class="service-content">보존 기간 : 수집 시점으로부터 6개월</p>
								</li>
								<li>
									<p class="service-content">‘부정 이용 기록’이란 부정 가입 및 운영 원칙에 위배되는 게시글 작성 등으로 인해 회사로부터 이용 제한 등을 당한 기록입니다.
									</p>
								</li>
							</ol>
						</li>
						<li>
							<p class="service-content"><span class="item-number">&#9314;</span> 전자상거래 등에서의 소비자 보호에 관한 법률,
							전자금융거래법, 통신비밀보호법 등 법령에서 일정 기간 정보의 보관을 규정하는 경우는 아래와 같습니다.</p>
							<table class="service-table">
								<thead>
									<tr>
										<th>법령</th>
										<th>항목</th>
										<th>보관 기간</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td rowSpan="3">전자상거래 등에서의 소비자보호에 관한 법률</td>
										<td>계약 또는 청약철회 등에 관한 기록</td>
										<td>5년</td>
									</tr>
									<tr>
										<td>대금 결제 및 재화 등의 공급에 관한 기록</td>
										<td>5년</td>
									</tr>
									<tr>
										<td>소비자의 불만 또는 분쟁 처리에 관한 기록</td>
										<td>3년</td>
									</tr>
									<tr>
										<td>위치정보의 보호 및 이용 등에 관한 법률</td>
										<td>개인 위치 정보에 관한 기록</td>
										<td>6개월</td>
									</tr>
									<tr>
										<td rowSpan="2">전자금융거래법</td>
										<td>건당 거래 금액 1만원 이하 전자금융 거래에 관한 기록 </td>
										<td>1년</td>
									</tr>
									<tr>
										<td>건당 거래 금액 1만원 초과 전자금융 거래에 관한 기록</td>
										<td>5년</td>
									</tr>
									<tr>
										<td>통신비밀보호법</td>
										<td>서비스 이용 관련 개인정보(로그기록)</td>
										<td>3개월</td>
									</tr>
								</tbody>
							</table>
						</li>
						<li>
							<p class="service-content"><span class="item-number">&#9315;</span> 회원탈퇴, 서비스 종료, 이용자에게 동의 받은 개인정보 보유 기간의
							도래와 같이 개인정보의 수집 및 이용 목적이 달성된 개인정보는 재생이 불가능한 방법으로 파기하고 있습니다. 법령에서 보존 의무를 부과한 정보에 대해서도 해당 기간 경과 후 지체 없이 재생이
							불가능한 방법으로 파기합니다. 전자적 파일 형태의 경우, 복구 및 재생이 되지 않도록 기술적인 방법을 이용하여 안전하게 삭제하며, 출력물 등은 분쇄하거나 소각을 통하여 파기합니다.
							</p>
						</li>
					</ol>
				</div>
				<div class="service-contents">
					<h5 class="service-title">제 5조 이용자 및 법정대리인의 권리와 그 행사방법</h5>
					<ol class="service-items static-numbering">
						<li>
							<p class="service-content"><span class="item-number">&#9312;</span> 이용자는 언제든지 더예약 서비스 &gt; 마이페이지에서
							자신의 개인정보를 조회하거나 수정할 수 있습니다.</p>
						</li>
						<li>
							<p class="service-content"><span class="item-number">&#9313;</span> 이용자는 언제든지 회원탈퇴 등을 통해 개인정보의 수집 및
							이용 동의를 철회할 수 있습니다.</p>
						</li>
						<li>
							<p class="service-content"><span class="item-number">&#9314;</span> 이용자가 개인정보의 오류에 대한 정정 또는 삭제를 요청한
							경우, 정정 또는 삭제를 완료하기 전까지 해당 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제 3자에게 이미 제공한 경우에는 정정 처리결과를 제 3자에게 지체 없이
							통지하여 정정이 이루어지도록 합니다.</p>
						</li>
						<li>
							<p class="service-content"><span class="item-number">&#9315;</span> 이용자는 자신의 개인정보를 최신의 상태로 유지해야 하며,
							이용자의 부정확한 정보 입력으로 발생하는 문제의 책임은 이용자 자신에게 있습니다.</p>
						</li>
						<li>
							<p class="service-content"><span class="item-number">&#9316;</span> 타인의 개인정보를 도용한 회원가입의 경우 이용자 자격을
							상실하거나 관련 개인정보보호 법령에 의해 처벌 받을 수 있습니다.</p>
						</li>
						<li>
							<p class="service-content"><span class="item-number">&#9317;</span> 이용자는 전자우편, 비밀번호 등에 대한 보안을 유지할
							책임이 있으며 제 3자에게 이를 양도하거나 대여할 수 없습니다.</p>
						</li>
					</ol>
				</div>
				<div class="service-contents">
					<h5 class="service-title">제 6조 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항</h5>
					<ol class="service-items static-numbering">
						<li>
							<p class="service-content"><span class="item-number">&#9312;</span> 회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해
							이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.</p>
						</li>
						<li>
							<p class="service-content"><span class="item-number">&#9312;</span> 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의
							컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터 내의 하드디스크에 저장되기도 합니다.</p>
						</li>
						<li>
							<p class="service-content"><span class="item-number">&#9312;</span> 쿠키는 개인을 식별하는 정보를 자동적/능동적으로 수집하지
							않으며, 이용자는 이러한 쿠키의 저장을 거부하거나 삭제할 수 있습니다.</p>
							<ol class="service-items auto-numbering" start="1">
								<li>
									<p class="service-content">쿠키의 사용목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용 형태, 인기 검색어, 보안접속 여부, 이용자의
									취향과 관심분야를 파악 및 각종 이벤트 참여 정도 및 방문 회수 파악 등을 통한 개인 맞춤 서비스 제공을 위해 사용됩니다.</p>
								</li>
								<li>
									<p class="service-content">쿠키의 설치/운영 및 거부 방법 : 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹 브라우저 별 옵션
									선택을 통해 모든 쿠키를 허용 또는 거부하거나, 쿠키가 저장될 때마다 확인을 거치도록 할 수 있습니다. 쿠키 설치 허용여부를 지정하는 방법은 다음과 같습니다.</p>
									<ol class="service-items static-numbering">
										<li>
											<p class="service-content"><span class="item-number">가.</span> Internet Explorer : 도구 메뉴
											&gt; 인터넷 옵션 &gt; 개인정보 탭 &gt; 개인정보처리 수준 설정</p>
										</li>
										<li>
											<p class="service-content"><span class="item-number">나.</span> Chrome : 설정 메뉴 &gt; 고급 설정 표시
											선택 &gt; 개인정보-콘텐츠 설정 &gt; 쿠키 수준 설정</p>
										</li>
										<li>
											<p class="service-content"><span class="item-number">다.</span> Firefox : 옵션 메뉴 &gt; 개인정보
											&gt; 방문 기록-사용자 정의 설정 &gt; 쿠키 수준 설정</p>
										</li>
										<li>
											<p class="service-content"><span class="item-number">라.</span> Safari : 환경설정 메뉴 &gt; 개인정보 탭
											&gt; 쿠키 및 웹 사이트 데이터 수준 설정</p>
										</li>
									</ol>
								</li>
								<li>
									<p class="service-content">쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</p>
								</li>
							</ol>
						</li>
					</ol>
				</div>
				<div class="service-contents">
					<h5 class="service-title">제 7조 개인정보의 기술적, 관리적 보호 대책</h5>
					<ol class="service-items static-numbering">
						<li>
							<p class="service-content"><span class="item-number">&#9312;</span> 회사는 개인 정보의 안정성 확보를 위해 개인정보를 취급함에
							있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적 관리적 대책을 구하고 있습니다.</p>
							<ol class="service-items auto-numbering" start="1">
								<li>
									<p class="service-content">개인정보 암호화</p>
									<ol class="service-items static-numbering">
										<li>
											<p class="service-content"><span class="item-number">가.</span> 법령에서 암호화를 요구하고 있는 비밀번호,
											고유식별정보 등을 추가로 암호화하여 보관하고 있습니다.</p>
										</li>
									</ol>
								</li>
								<li>
									<p class="service-content">해킹 등에 대비한 기술적 대책</p>
									<ol class="service-items static-numbering">
										<li>
											<p class="service-content"><span class="item-number">가.</span> 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가
											유출되거나 훼손되는 것을 막기 위해 외부로부터 접근이 통제된 구역에 시스템을 설치하고 있습니다. 개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신 백신프로그램을
											이용하여 이용자들의 개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며, 암호화 통신 등을 통하여 네트워크 상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.
											그리고 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.</p>
										</li>
									</ol>
								</li>
								<li>
									<p class="service-content">개인정보처리시스템 접근 제한</p>
									<ol class="service-items static-numbering">
										<li>
											<p class="service-content"><span class="item-number">가.</span> 회사는 개인정보를 처리할 수 있도록 체계적으로 구성한
											데이터베이스시스템에 대한 접근 권한의 부여, 변경, 말소 등에 관한 기준을 수립하고 비밀번호의 생성 방법, 변경 주기 등을 규정, 운영하며 기타 개인정보에 대한 접근통제를 위해
											필요한 조치를 다 하고 있습니다.</p>
										</li>
									</ol>
								</li>
								<li>
									<p class="service-content">개인정보 취급 직원의 최소화 및 교육</p>
									<ol class="service-items static-numbering">
										<li>
											<p class="service-content"><span class="item-number">가.</span> 개인정보관련 취급 직원은 담당자에 한정시켜 최소화하고
											새로운 보안기술의 습득 및 개인정보보호 의무에 관해 정기적인 교육을 실시하며 별도의 인증서를 부여하여 접근 권한을 관리하는 등 관리적 대책을 시행하고 있습니다.</p>
										</li>
									</ol>
								</li>
							</ol>
						</li>
					</ol>
				</div>
				<div class="service-contents">
					<h5 class="service-title">제 8조 개인정보 보호책임자 및 담당자 안내</h5>
					<ol class="service-items static-numbering">
						<li>
							<p class="service-content"><span class="item-number">&#9312;</span> 회사는 이용자의 개인정보를 보호하고 개인정보와 관련한
							불만을 처리하기 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.</p>
						</li>
						<li>
							<p class="service-content"><span class="item-number">&#9313;</span> 이용자께서는 회사의 서비스를 이용하시면서 발생한 모든
							개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당자에게 문의하실 수 있습니다. 회사는 이용자의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
							</p>
							<table class="service-table">
								<thead>
									<tr>
										<th>개인정보 보호책임자</th>
										<th>개인정보 민원처리 담당부서</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<p class="pre-line-paragraph">
											성명 : 조강희<br />
											전화번호 : 1544-2017<br />
											이메일 : help@tablemanager.team
											</p>
										</td>
										<td>
											<p class="pre-line-paragraph">
											소속 : 운영팀<br />
											이메일 : help@tablemanager.team
											</p>
										</td>
									</tr>
								</tbody>
							</table>
						</li>
					</ol>
				</div>
				<div class="service-contents">
					<h5 class="service-title">제 9조 이용자의 권익침해에 대한 구제방법</h5>
					<ol class="service-items static-numbering">
						<li>
							<p class="service-content"><span class="item-number">&#9312;</span> 이용자는 아래의 기관에 대해 개인정보 침해에 대한
							피해구제, 상담 등을 문의하실 수 있습니다. 아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면
							문의하여 주시기 바랍니다.</p>
						</li>
						<li>
							<p class="service-content"><span class="item-number">&#9313;</span> 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는
							아래 기관에 문의하시기 바랍니다.</p>
							<table class="service-table">
								<thead>
									<tr>
										<th>문의처</th>
										<th>연락처</th>
										<th>홈페이지</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>개인정보침해신고센터</td>
										<td>(국번없이) 118</td>
										<td>privacy.kisa.or.kr</td>
									</tr>
									<tr>
										<td>전자거래분쟁조정위원회</td>
										<td>1661-5714</td>
										<td>https://www.ecmc.or.kr</td>
									</tr>
									<tr>
										<td>대검찰청 사이버범죄수사단</td>
										<td>(국번없이) 1301</td>
										<td>www.spo.go.kr</td>
									</tr>
									<tr>
										<td>경찰청 사이버안전국</td>
										<td>(국번없이) 182</td>
										<td>www.ctrc.go.kr</td>
									</tr>
								</tbody>
							</table>
						</li>
					</ol>
				</div>
				<div class="service-contents">
					<h5 class="service-title">제 10조 본 개인정보처리방침의 적용 범위</h5>
					<ol class="service-items static-numbering">
						<li>
							<p class="service-content"><span class="item-number">&#9312;</span> 본 개인정보처리방침은 서비스의 웹 사이트 및 어플리케이션에
							적용되며, 다른 브랜드로 제공되는 서비스에 대해서는 별개의 개인정보취급방침이 적용될 수 있습니다.</p>
						</li>
						<li>
							<p class="service-content"><span class="item-number">&#9313;</span> 서비스에 링크되어 있는 다른 회사의 웹 사이트에서
							개인정보를 수집하는 경우, 이용자 동의 하에 개인정보가 제공된 이후에는 본 개인정보처리방침이 적용되지 않습니다.</p>
						</li>
					</ol>
				</div>
				<div class="service-contents">
					<h5 class="service-title">제 11조 개정 전 고지 의무</h5>
					<ol class="service-items static-numbering">
						<li>
							<p class="service-content">
								<span class="item-number">&#9312;</span>
							본 개인정보취급방침의 추가, 삭제 및 수정이 있을 경우 개정 최소 7일 전에 공지사항을 통하여 사전 고지할 것입니다. 다만, 수집하는 개인정보의 항목, 이용목적의 변경 등과 같이 이용자
							권리의 중대한 변경이 발생할 때에는 최소 30일 전에 공지하며, 필요 시 이용자의 동의를 다시 받을 수도 있습니다.<br />
							- 공고일자 : 2021년 6월 4일<br />
							- 시행일자 : 2021년 6월 4일<br />
							- 수정일자 : 2021년 11월 4일<br />
							- 시행일자 : 2021년 11월 12일
							</p>
						</li>
					</ol>
				</div>
			</div>
		</div>
	)
}
