import React, { useEffect, useLayoutEffect, useState } from 'react'
import { usePartnerContext } from '../../../../context/Common/partner'
import { useTimer } from '../../../../store'
import classNames from 'classnames'
import moment from 'moment/moment'
import { useModalContext } from '../../../../context/Common/modal'
import { useHistory, useParams } from 'react-router-dom'
import useDynamicComponent from '../../../../hooks/useDynamicComponent'

export default function Timer(props) {
	const { mini = false } = props
	const history = useHistory()
	const { mallUri } = useParams()
	const { expire, remainTime, setTimer, resetTimer } = useTimer()
	const [isStickyView, setIsStickyView] = useState(mini)
	const { headerExists } = usePartnerContext()
	const { alertOpen } = useModalContext()
	const { renderComponent } = useDynamicComponent()
	let interval
	let isTicking = false

	const expireTimeFollowUp = () => {
		resetTimer()
		alertOpen('예약 유효기간이 만료되었습니다.')
		history.push(`/booking/mall-detail/${mallUri}`)
	}

	const getTime = () => {
		if (!expire)
			return

		const duration = moment.duration(expire.diff(moment()))
		const minutes = duration.minutes(),
			seconds = duration.seconds()

		setTimer('remainTime', `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`)

		if (moment().isAfter(expire))
			expireTimeFollowUp()
	}

	const detectElementPosition = () => {
		if (!isTicking) {
			isTicking = true
			requestAnimationFrame(() => {
				const timerElement = document.querySelector('.timer-container')
				setIsStickyView(timerElement?.getBoundingClientRect().top < 0)
				isTicking = false
			})
		}
	}

	useLayoutEffect(() => {
		if (expire) {
			getTime()
			interval = setInterval(getTime, 1000)
		} else {
			clearInterval(interval)
		}
		return () => clearInterval(interval)
	}, [expire])

	useEffect(() => {
		if (!mini)
			window.addEventListener('scroll', detectElementPosition, { passive: true })

		return () => {
			window.removeEventListener('scroll', detectElementPosition)
			clearInterval(interval)
		}
	}, [])

	return (
		<div className={classNames('timer-container', { 'none-header': headerExists })}>
			{!mini && (
				<div className="timer-box">
					<span className="time t4">{remainTime}</span>
					<p className="text b2">
						원활한 예약을 위해 좌석을 홀딩합니다.
						<br />
						해당 시간 이내에 예약을 완료해주세요.
					</p>
				</div>
			)}
			{isStickyView && (
				<div className="timer-mini b2">
					{expire
						? (
							renderComponent({
								default: <span>{remainTime} 이내에 예약을 완료해주세요.</span>,
								gyeongjuro: (
									<div className='timer-mini-remain-box'>
										<span className='time'>{remainTime}</span>
										<span>이내에 예약을 완료해주세요.</span>
									</div>
								),
							})
						)
						: (
							<span>시간이 만료되었습니다.</span>
						)}
				</div>
			)}
		</div>
	)
}
