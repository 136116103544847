import React from 'react'
import { FormControlLabel, Checkbox } from '@material-ui/core'
import './AgreeCheckbox.scss'
import useDynamicComponent from '../../../hooks/useDynamicComponent'
import { ReactComponent as AgreementArrow } from '../../../assets/icon/agreementArrowGyeongjuro.svg'
const AgreeCheckbox = (props) => {
	const { value, setValue, required, desc, expand, expandFunction, icon, checkedIcon } = props
	const { renderComponent } = useDynamicComponent()
	return (
		<div className="agree-checkbox">
			<FormControlLabel
				control={
					<Checkbox
						color="primary"
						checked={value}
						onChange={setValue}
						icon={icon}
						checkedIcon={checkedIcon}
					/>
				}
				label={(required
					? '(필수) '
					: '(선택) ') + desc}
				className="checkbox-label"
			/>
			{expand
				? renderComponent({
					default: (
						<div onClick={expandFunction} className="expand">더보기</div>
					),
					gyeongjuro: (
						<AgreementArrow onClick={expandFunction} className="agreement-arrow"/>
					)
				})
				: null}
		</div >
	)
}

export default AgreeCheckbox
