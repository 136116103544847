import React, { useEffect, useMemo } from 'react'
import MallPickupDescription from '../../../Components/Pickup/Order/MallPickupDescription'
import OrderMemo from '../../../Components/Pickup/Order/OrderMemo'
import Payment from '../../../Components/Pickup/Order/Payment'
import Agreement from '../../../Components/Pickup/Order/Agreement'
import Seperator from '../../../Components/Share/Seperator'
import UserInfoForm from '../../../Components/Pickup/Order/UserInfoForm'
import PickupUserInfoForm from '../../../Components/Gyeongju/PickupUserInfoForm/PickupUserInfoForm'

import { PaymentContextProvider } from '../../../context/Pickup/payment'
import { usePickupContext } from '../../../context/Pickup/pickup'
import { PickupAPI } from '../../../lib/api'
import { useParams } from 'react-router-dom'
import PaymentButton from '../../../Components/Pickup/Order/PaymentButton'
import useDynamicComponent from '../../../hooks/useDynamicComponent'
import { usePartnerContext } from '../../../context/Common/partner'
import { authorizationWithPrefix } from '../../../lib/utils'

export default function Order() {
	const { mall, setMall, orderSheet } = usePickupContext()
	const { renderComponent } = useDynamicComponent()
	const { apiKey } = usePartnerContext()
	const params = useParams()

	const pickupAPI = useMemo(
		() => new PickupAPI(apiKey, authorizationWithPrefix()),
		[apiKey]
	)

	useEffect(() => {
		const fetchMall = () => pickupAPI.getMall(params.mallUri).then((res) => {
			setMall(res.mall)
		})
		if (params.mallUri)
			fetchMall()
	}, [params.mallUri])

	if (!mall)
		return <div>loading</div>


	return (
		<PaymentContextProvider>
			{
				renderComponent({
					default: (
						<div>
							<MallPickupDescription mall={mall}></MallPickupDescription>
							<Seperator></Seperator>
							<UserInfoForm></UserInfoForm>
							<Seperator></Seperator>
							<OrderMemo></OrderMemo>
							<Seperator></Seperator>
							{
								orderSheet.reduce((prev, next) => {
									return prev + (next.totalPrice * next.count)
								}, 0) > 0 ?
									<>
										<Payment></Payment>
										<Seperator></Seperator>
									</>
									: null
							}
							<Agreement></Agreement>
							<PaymentButton />
						</div>
					),
					gyeongjuro: (
						<div>
							<MallPickupDescription mall={mall}></MallPickupDescription>
							<Seperator></Seperator>
							<PickupUserInfoForm></PickupUserInfoForm>
							<Seperator></Seperator>
							<OrderMemo></OrderMemo>
							<Seperator></Seperator>
							{
								orderSheet.reduce((prev, next) => {
									return prev + (next.totalPrice * next.count)
								}, 0) > 0 ?
									<>
										<Payment></Payment>
										<Seperator></Seperator>
									</>
									: null
							}
							<Agreement></Agreement>
							<PaymentButton />
						</div>
					)
				})
			}

		</PaymentContextProvider>
	)
}
