import { setup } from 'axios-cache-adapter'

const axios = setup({
	baseURL: process.env.REACT_APP_CARROT_API_HOST
})


export class QuestionAPI {
	_get = async (brandId) => {
		return axios.get(`/brands/${brandId}/faq`)
	}
}
