import React from 'react'
import './BookingMenuList.scss'
import BookingSubtitle from '../../../Share/Booking/BookingSubtitle/BookingSubtitle'
import { useHistory, useParams } from 'react-router-dom'
import { useMall } from '../../../../store'
export default function BookingMenuList() {
	const { representativeMenus, menus } = useMall(state => state.mall)
	const { mallUri } = useParams()
	const history = useHistory()

	function moveMenu() {
		history.push(`/booking/mall-detail/${mallUri}/menu`)
	}

	if (!representativeMenus || !menus.length)
		return null

	return (
		<div className="booking-menu-list-container">
			<BookingSubtitle subtitle="메뉴" moveTo={moveMenu} count={menus.length}></BookingSubtitle>
			{
				representativeMenus.length > 0 ?
					<div className="menu-list">
						{representativeMenus.map((menu, idx) =>
							<div className="menu-box" key={idx}>
								<div className="menu-name b2">{menu.name}</div>
								<div className="menu-price b1">{menu.fee.toLocaleString()} 원</div>
							</div>
						)}
					</div> :
					null
			}

		</div>
	)
}
