import { setup } from 'axios-cache-adapter'

// axios 생성
const axios = setup({
	baseURL: process.env.REACT_APP_CARROT_API_HOST,
	headers: { 'x-api-key': 'F1B80501BB7989BB5B15B4934DD268BA88261B8D', }
})

const _call = async ({
	method = 'get',
	uri,
	headers = {},
	expires = 0,
	body = {},
	params = {}
}) => {
	method = method.toLowerCase()
	if (['get', 'delete', 'post', 'patch', 'put', 'options'].indexOf(method) < 0) {
		const message = `method is wrong - method: ${method}`
		throw new Error(message)
	}

	expires = Number(expires)
	if (isNaN(expires)) {
		console.warn('expires is assigned 0 (=default value) because type is not number')
		expires = 0
	}

	const request = axios({
		method,
		data: body,
		url: uri,
		cache: { maxAge: expires },
		headers,
		params
	})

	return await request.then(async (response) => {
		const data = response.data
		if (data.code && data.code !== 200)
			throw data

		return data
	})
}

const getBrands = () => {
	return _call({
		method: 'GET',
		uri: '/brands'
	})
}

const getBrandVoucherProducts = async (brandId, referral) => {
	return await _call({
		method: 'GET',
		uri: `/brands/${brandId}/voucher-products${referral ? `?referral=${referral}` : ''}`,
	})
}

const getVoucherProducts = async (referral) => {
	return await _call({
		method: 'GET',
		uri: `/voucher-products${referral ? `?referral=${referral}` : ''}`
	})
}

const getLiveCommerces = async (status = 'ON_AIR') => {
	return await _call({
		method: 'GET',
		uri: `/live-commerces/${status}`
	})
}

const getCommonMultimedia = async (type) => {
	return await _call({
		method: 'GET',
		uri: `/common/multimedia/${type}`
	})
}

const getFavorites = (userToken) => {
	return _call({
		method: 'GET',
		uri: `/me/favorites`,
		headers: {
			Authorization: `Bearer ${userToken}`
		}
	})
}

const patchFavorite = (userToken, serviceId, body) => {
	return _call({
		method: 'PATCH',
		uri: `/me/favorites/${serviceId}`,
		headers: {
			Authorization: `Bearer ${userToken}`
		},
		body
	})
}

const getMyVoucherOrders = (userToken, isPartner, status, referral, params) => {
	const headers = {}
	if (isPartner) {
		headers['Authorization'] = `partner ${userToken}`
	} else {
		headers['Authorization'] = `Bearer ${userToken}`
	}
	let uri = `/me/voucher-orders?status=${status}`
	if(referral) {
		uri += `&referral=${referral}`
	}
	return _call({
		method: 'GET',
		uri,
		headers,
		params
	})
}

const getMyReservations = (userToken, type) => {
	return _call({
		method: 'GET',
		uri: `/me/reservations?type=${type}`,
		headers: {
			Authorization: `Bearer ${userToken}`
		}
	})
}

const getMyReservation = (reservationId, userToken) => {
	return _call({
		method: 'GET',
		uri: `/me/reservations/${reservationId}`,
		headers: {
			Authorization: `Bearer ${userToken}`
		}
	})
}

const requestQuestions = (body, userToken) => {
	const headers = {}
	if (userToken) {
		headers['Authorization'] = `Bearer ${userToken}`
	}
	return _call({
		method: 'POST',
		uri: `/questions`,
		headers,
		body
	})
}

const getQuestionEmail = (userToken, type) => {
	const headers = {}
	if (userToken) {
		headers['Authorization'] = `Bearer ${userToken}`
	}
	return _call({
		method: 'GET',
		uri: `/questions/${type}/email`,
		headers,
	})
}

const sendOrders = (body, userToken, isPartner, inflowId = '') => {
	const headers = {}
	if (isPartner) {
		headers['Authorization'] = `partner ${userToken}`
	} else {
		headers['Authorization'] = `Bearer ${userToken}`
	}
	if (inflowId)
		headers['x-inflow-id'] = inflowId
	return _call({
		method: 'POST',
		uri: `/voucher-orders`,
		headers,
		body
	})
}

const billingPayment = (body, orderId, userToken, isPartner) => {
	const headers = {}
	if (isPartner) {
		headers['Authorization'] = `partner ${userToken}`
	} else {
		headers['Authorization'] = `Bearer ${userToken}`
	}
	return _call({
		method: 'POST',
		uri: `/voucher-orders/${orderId}/payments`,
		headers,
		body
	})
}

const getVoucherInfo = (orderId) => {
	return _call({
		method: 'GET',
		uri: `/voucher-orders/${orderId}`
	})
}

const getCardList = (userToken) => {
	return _call({
		method: 'GET',
		uri: `/me/subscriptions`,
		headers: {
			Authorization: `Bearer ${userToken}`
		}
	})
}

const deleteSubscriptions = (userSubscriptionId, userToken) => {
	return _call({
		method: 'DELETE',
		uri: `/me/subscriptions/${userSubscriptionId}`,
		headers: {
			Authorization: `Bearer ${userToken}`
		}
	})
}

const getAccountInfo = async (brandId, userId) => {
	return _call({
		method: 'get',
		uri: `/me/brands/${brandId}/voucher-histories`,
		headers: { Authorization: `kakao ${userId}` }
	})
}

const getUserInfo = async (token) => {
	return _call({
		method: 'get',
		uri: '/me/info',
		headers: { Authorization: `Bearer ${token}` }
	})
}

const sendPolicy = (body, userToken) => {
	return _call({
		method: 'put',
		uri: `/user/termsAgreements`,
		headers: {
			Authorization: `Bearer ${userToken}`
		},
		body
	})
}

const getInflowIdbyKakaoInflowId = (inflowId) => {
	return _call({
		method: 'get',
		uri: `/voucher-inflows?id=${inflowId}`,
	})
}

const postRefundRequest = (body, userToken, isPartner) => {
	const headers = {}
	if (isPartner) {
		headers['Authorization'] = `partner ${userToken}`
	} else {
		headers['Authorization'] = `Bearer ${userToken}`
	}
	return _call({
		method: 'POST',
		uri: '/me/voucher-orders/refund',
		headers,
		body
	})
}

export {
	getBrands,
	getBrandVoucherProducts,
	getVoucherProducts,
	getCommonMultimedia,
	getLiveCommerces,
	getFavorites,
	patchFavorite,
	getMyVoucherOrders,
	getMyReservations,
	getMyReservation,
	deleteSubscriptions,
	requestQuestions,
	getQuestionEmail,
	sendOrders,
	billingPayment,
	getVoucherInfo,
	getCardList,
	getAccountInfo,
	getUserInfo,
	sendPolicy,
	getInflowIdbyKakaoInflowId,
	postRefundRequest
}
