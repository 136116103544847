import React, { useEffect, useLayoutEffect } from 'react'
import { useReservation, useValidation } from '../../../store'
import { PAYMENT_KEY } from '../../../store/useReservation'
import { Radio } from '../Radio'

const PAYMENT_LIST = ['kakao', 'card']

const PaymentType = () => {
	const { payment, setReservation } = useReservation()

	useLayoutEffect(() => {
		setReservation('payment', { ...payment, type: PAYMENT_LIST[0] })
	}, [])

	return (
		<div className="payment-container">
			<h1>결제수단</h1>
			<div onClick={(e) => e.target.value && setReservation('payment', { ...payment, type: e.target.value })}>
				{PAYMENT_LIST.map((item, idx) => {
					const isChecked = item === payment.type
					return <Radio key={idx} value={item} name={PAYMENT_KEY[item].name} checked={isChecked} />
				})}
			</div>
		</div>
	)
}

export default PaymentType
