import React from 'react'
import { useMall } from '../../../../store'
export default function SaleNotice() {
	const { saleNotice } = useMall(state => state.mall)
	return (
		<div className="sale-notice-container">
			<div className="title b1">할인 유의사항</div>
			<div className="description b3">{ saleNotice }</div>
		</div>
	)
}
