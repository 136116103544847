import CounterOption from './CounterOption'
import React from 'react'

export default function CounterGroup(props) {
	return (
		<div>
			{props.optionGroup.options.map((option, idx) => <CounterOption key={props.optionGroup._id} option={option} groupId={props.optionGroup._id}></CounterOption>)}
		</div>
	)
}
