import React from 'react'
import VoucherProductList from '../../VoucherProduct/List/VoucherProductList'
import Title from '../../../Share/Title/Title'
import { useHomeContext } from '../../../../context/Voucher/home'

export default function WishList(props) {
	const { myWishList } = useHomeContext()
	const { goVoucherDetailPage, trackingAPI } = props
	if (!myWishList || myWishList.length === 0)
		return null
	return (
		<div className="wish-it-voucher-wrapper">
			<Title text={'찜한 예약상품권 💛'}></Title>
			<div className="wish-it-products">
				<VoucherProductList type="circle" data={myWishList} customFunction={goVoucherDetailPage} favoriteList={myWishList} tracker={trackingAPI}></VoucherProductList>
			</div>
		</div>
	)
}
