import React from 'react'
import Subtitle from '../../Share/Subtitle'
import { usePaymentContext } from '../../../context/Pickup/payment'

export default function OrderMemo() {
	const { paymentInfo, dispatchPaymentInfo } = usePaymentContext()

	function setUserInfo(event, type) {
		dispatchPaymentInfo({ type, value: event.target.value })
	}

	return (
		<div className="order-memo-container">
			<Subtitle subtitle="요청사항 입력"></Subtitle>
			<input type="text" className="input" value={paymentInfo.request} onChange={(e) => setUserInfo(e, 'REQUEST')} placeholder="주문시 요청사항을 입력해주세요."/>
		</div>
	)
}
