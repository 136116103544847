import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import ActionButton from '../../Share/ActionButton'
import { useHistory, useParams } from 'react-router-dom'
import useDynamicComponent from '../../../hooks/useDynamicComponent'
import { ActionButton as GyeongjuroActionButton } from '../../Gyeongju/ActionButton'
export default function OrderSheetFooter(props) {
	const [totalPrice, setTotalPrice] = useState(0)
	const history = useHistory()
	const params = useParams()
	const { renderComponent } = useDynamicComponent()
	useEffect(() => {
		let sum = 0
		props.orderSheet.forEach(menu => {
			sum += menu.totalPrice * menu.count
		})
		setTotalPrice(sum)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		let sum = 0
		props.orderSheet.forEach(menu => {
			sum += menu.totalPrice * menu.count
		})
		setTotalPrice(sum)
	}, [props.orderSheet])

	function goMain() {
		history.goBack()
	}

	function goOrder() {
		history.push(`/pickup/${params.mallUri}/order`)
	}

	return (
		<>
			<div className="order-sheet-footer-container-placeholder"></div>
			{
				renderComponent({
					default: (
						<div className="order-sheet-footer-container">
							<div className="info-container">
								<div className="label">총 주문금액</div>
								<div className="value">{totalPrice.toLocaleString()} 원</div>
							</div>
							<div className="action-container">
								<ActionButton
									onClick={goMain}
									variant="contained"
									color="primary"
									fullWidth={false}
									className="action-button two-button"
									label="메뉴 추가">
								</ActionButton>
								<ActionButton
									onClick={goOrder}
									disabled={props.orderSheet.length === 0}
									variant="contained"
									color="primary"
									fullWidth={false}
									className="action-button"
									label="포장 주문하기">
								</ActionButton>
							</div>
						</div>
					),
					gyeongjuro: (
						<div className="order-sheet-footer-container">
							<div className="info-container">
								<div className="label">총 주문금액</div>
								<div className="value">{totalPrice.toLocaleString()} 원</div>
							</div>
							<div className="action-container">
								<GyeongjuroActionButton
									onClick={goMain}
									variant="contained"
									color="primary"
									fullWidth={false}
									className="gyeongjuro-action-button two-button menu-add-button"
									label="메뉴 추가">
								</GyeongjuroActionButton>
								<GyeongjuroActionButton
									onClick={goOrder}
									disabled={props.orderSheet.length === 0}
									variant="contained"
									color="primary"
									fullWidth={false}
									className="gyeongjuro-action-button two-button"
									label="포장 주문하기">
								</GyeongjuroActionButton>
							</div>
						</div>
					)
				})
			}
		</>
	)
}
