import React, { useMemo } from 'react'
import { useState } from 'react'
import { useVoucherContext } from '../../../../context/Common/voucher'
import BookingSubtitle from '../../../Share/Booking/BookingSubtitle/BookingSubtitle'
import InputHint from '../../../Share/InputHint'
import { Button } from '@material-ui/core'
import './Form.scss'
import { useModalContext } from '../../../../context/Common/modal'
import { AuthAPI } from '../../../../lib/mytable'
import { usePartnerContext } from '../../../../context/Common/partner'
import { ReactComponent as Info } from '../../../../assets/icon/info.svg'
export default function Form() {
	const { userError, phoneNumbersError, emailError, phoneAuthError } = useVoucherContext().validations
	const { voucherInfo, dispatchVoucherInfo } = useVoucherContext()
	const { alertOpen } = useModalContext()
	const { apiKey, partnerName } = usePartnerContext()
	const phoneAuthAPI = useMemo(() => new AuthAPI(apiKey, null), [apiKey])
	const [dirtyName, setDirtyName] = useState(false)
	const [dirtyPhone, setDirtyPhone] = useState(false)
	function sendPhoneAuthCode() {
		if (!phoneNumbersError && !voucherInfo.phoneAuthToken) {
			phoneAuthAPI.requestPhoneCode(voucherInfo.user.phoneNumber).then((res) => {
				if (res.result)
					dispatchVoucherInfo({ type: 'SET_PHONE_AUTH_TOKEN', value: res.token })
				else
					alertOpen('인증과정에 실패하였습니다. 다시 시도해주세요.')
				return
			}).catch((e) => {
				alertOpen(e.message)
			})
		}
	}

	function phoneAuth() {
		if (voucherInfo.auth)
			return
		phoneAuthAPI.checkPhoneCode(voucherInfo.phoneAuthToken, voucherInfo.phoneAuthCode, voucherInfo.user.name, voucherInfo.user.email).then((res) => {
			if (res.result) {
				alertOpen('휴대폰 인증이 완료되었습니다.')
				dispatchVoucherInfo({ type: 'AUTH' })
			} else {
				alertOpen(res.message)
			}
		}).catch((e) => {
			alertOpen(e.message)
		})
	}

	return (
		<div className="voucher-info-form">
			<BookingSubtitle subtitle="구매자 정보"></BookingSubtitle>
			<div className="input-label b3">이름*</div>
			<div className="input-box">
				<input type="text" className={userError && dirtyName
					? 'input b1 error'
					: 'input b1'} placeholder="성명 입력" value={voucherInfo.user.name} onBlur={() => setDirtyName(true)} onChange={(e) => dispatchVoucherInfo({ type: 'SET_USER_NAME', value: e.target.value })} />
				{userError && dirtyName
					? <InputHint text={userError} />
					: null}
			</div>
			<div className="input-label b3">휴대폰 번호*</div>
			{
				voucherInfo.phoneAuth ?
					<div className="input-box">
						<input type="text" className="input b1" disabled={voucherInfo.phoneAuthToken} maxLength="13" inputMode="numeric" onBlur={() => setDirtyPhone(true)} placeholder="010-0000-0000" value={voucherInfo.user.phoneNumber} onChange={(e) => dispatchVoucherInfo({ type: 'CHANGE_PHONE_NUMBER', value: e.target.value })}></input>
						{partnerName === 'shinhan' ?
							<></> :
							<div className="phone-change-button b1" onClick={() => dispatchVoucherInfo({ type: 'CLEAR_AUTH' })}>변경</div>}
					</div> :
					<>
						<div className="input-box">
							<div className="phone-auth-box">
								<input type="text" className={phoneNumbersError && dirtyPhone
									? 'input auth-number b1 error'
									: 'input auth-number b1'} disabled={voucherInfo.phoneAuthToken || partnerName === 'shinhan'} maxLength="13" inputMode="numeric" onBlur={() => setDirtyPhone(true)} placeholder="010-0000-0000" value={voucherInfo.user.phoneNumber} onChange={(e) => dispatchVoucherInfo({ type: 'CHANGE_PHONE_NUMBER', value: e.target.value })}></input>
								<Button variant="contained" color="primary" className="auth-button" onClick={sendPhoneAuthCode}>인증번호 받기</Button>
							</div>
							{phoneNumbersError && dirtyPhone
								? <InputHint text={phoneNumbersError} />
								: null}
						</div>
						<div className="input-box">
							<div className="phone-auth-box">
								<input type="number" className={phoneAuthError && dirtyPhone
									? 'input auth-number b1 error'
									: 'input auth-number b1'} placeholder="인증번호 입력" disabled={!voucherInfo.phoneAuthToken} value={voucherInfo.phoneAuthCode} onChange={(e) => dispatchVoucherInfo({ type: 'SET_PHONE_AUTH_CODE', value: e.target.value })} />
								<Button disabled={!voucherInfo.phoneAuthToken} variant="contained" color="primary" className="auth-button" onClick={phoneAuth}>확인</Button>
							</div>
							{phoneAuthError
								? <InputHint text={phoneAuthError} />
								: null}
						</div>
					</>
			}
			<div className="input-label b3">이메일</div>
			<div className="input-box email">
				<input type="email" className="input b1" placeholder="이메일을 입력해주세요." value={voucherInfo.user.email} onChange={(e) => dispatchVoucherInfo({ type: 'SET_USER_EMAIL', value: e.target.value })} />
				{emailError
					? <InputHint text={emailError} />
					: null}
			</div>
			<div className="b3">이메일 주소 입력하시면 결제 내역을 발송해드립니다.</div>

			{partnerName === 'shinhan' ?
				<div className="shinhan-notice-container">
					<div className="shinhan-notice-box b3">
						<Info className="icon" />
						<div className="notice">신한카드에 현재 등록된 휴대폰 번호로만 인증이 가능합니다.<br /> <br />
							휴대폰번호가 변경된 경우 신한카드 홈페이지 및 고객센터(1544-7000)를 통해 변경 신청이 가능합니다.
						</div>
					</div>
				</div> :
				null}
		</div>
	)
}
