import ReactDOM from 'react-dom'

const Toast = (message, { ...props } = {}) => {
	const { duration = 3000 } = { ...props }
	const root = document.querySelector('div.main-container') || document.querySelector('div.main-container-mobile')
	const randomId = Math.random().toString()

	const element = (
		<div className="gyeongju-toast-container" id={randomId}>
			<label>{message}</label>
		</div>
	)

	const cleanUpToastElement = () => {
		document.getElementsByClassName('gyeongju-toast-container')?.[0]?.parentElement?.remove()
	}

	const removeToastElement = () => {
		const toastElement = document.getElementById(randomId)

		if (toastElement) {
			toastElement.classList.add('close')
			setTimeout(() => toastElement.parentElement.remove(), 200)
		}
	}

	const createToastElement = () => {
		cleanUpToastElement()
		const dummyElement = document.createElement('div')
		dummyElement.style.setProperty('position', 'fixed')
		dummyElement.style.setProperty('z-index', '99')
		root.appendChild(dummyElement)
		ReactDOM.render(element, dummyElement)
		setTimeout(removeToastElement, duration)
	}

	return createToastElement()
}

export default Toast
