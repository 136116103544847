import './MallDetail.scss'

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import Address from '../../../Components/Share/Booking/Address/Address'
import BookingFooter from '../../../Components/Share/Booking/BookingFooter/BookingFooter'
import { ClipLoader } from 'react-spinners'
import ImageCarousel from '../../../Components/Share/ImageCarousel'
import Seperator from '../../../Components/Share/Seperator'
import { useBookingContext } from '../../../context/Booking/booking'
import { usePartnerContext } from '../../../context/Common/partner'
import { useMall, useModify, useProvisionalReservation, useReservation, useValidation } from '../../../store'
import {
	BookingMenuList,
	BusinessHours,
	DepositInfo,
	MallDescription,
	MallTitle,
	Notice,
} from '../../../Components/Booking'
import Facility from '../Facility/Facility'

export default function MallDetail() {
	const { mallUri } = useParams()
	const { setVoucherId } = useBookingContext()
	const { voucherData, resetReservation } = useReservation()
	const { resetAuth } = useProvisionalReservation()
	const { resetModify } = useModify()
	const { mall, fetchMall, setMall } = useMall()
	const { resetValidation } = useValidation()
	const { apiKey } = usePartnerContext()
	const search = useLocation().search
	const voucherId = new URLSearchParams(search).get('voucherId')
	const history = useHistory()

	useEffect(() => {
		if (mallUri && mallUri !== 'undefined' && apiKey) {
			fetchMall(apiKey, mallUri)
			setMall('mallUri', mallUri)
			resetReservation()
			resetAuth()
			resetModify()
			resetValidation()
		}
	}, [mallUri, apiKey])

	useEffect(() => {
		if (voucherId)
			setVoucherId(voucherId)
		else
			setVoucherId(null)
	}, [voucherId])

	function moveReservation() {
		history.push(`/booking/${mallUri}/step/info`)
	}
	if (!mall || mall.mall_id !== mallUri) {
		return (
			<div className="loader-container">
				<ClipLoader></ClipLoader>
			</div>
		)
	}

	return (
		<div className="mall-detail-container">
			<ImageCarousel images={mall.images.slice(0, 5)}></ImageCarousel>
			<MallTitle></MallTitle>
			<Notice></Notice>
			<Seperator></Seperator>
			<Facility></Facility>
			<Address></Address>
			<BusinessHours></BusinessHours>
			<BookingMenuList></BookingMenuList>
			<DepositInfo></DepositInfo>
			<MallDescription></MallDescription>
			<BookingFooter text="예약하기" action={moveReservation} active={true}></BookingFooter>
		</div>
	)
}
