import React, { useMemo } from 'react'
import BookingSubtitle from '../../../Share/Booking/BookingSubtitle/BookingSubtitle'
import BookingAgreeCheckbox from '../../../Share/Booking/BookingAgreeCheckbox/BookingAgreeCheckbox'
import { FormControlLabel, Checkbox } from '@material-ui/core'
import { ReactComponent as AllActiveCheckbox } from '../../../../assets/icon/allActiveCheckbox.svg'
import { ReactComponent as AllDeactiveCheckbox } from '../../../../assets/icon/allDeactiveCheckbox.svg'
import { useFetchPolicies } from '../../../../hooks'
import ModalPortal from '../../../Share/Modal/ModalPortal'
import PolicyModal from '../../../Share/Modal/PolicyModal'
import { useReservation } from '../../../../store'

export default function Agreement() {
	const { agreement, setReservation } = useReservation()
	const { servicePolicies, loading, openServiceTerm, currentPolicy, policiesList } = useFetchPolicies({
		url: '/service/policies',
		dispatchInfo: ({ value }) =>
			setReservation(
				'agreement',
				value.reduce((acc, cur) => ({ ...acc, [cur]: false }), {}),
			),
	})
	const agreements = useMemo(() => Object.keys(agreement || {}), [agreement])

	const handleAllCheckbox = () => {
		const isAllChecked = Object.values(agreement).every((item) => item)
		setReservation(
			'agreement',
			agreements.reduce((acc, cur) => ({ ...acc, [cur]: !isAllChecked }), {}),
		)
	}

	const handleCheckbox = (value) => setReservation('agreement', { ...agreement, [value]: !agreement[value] })

	const showServiceTerm = (type) => {
		window.history.pushState(null, null, '')
		openServiceTerm(type)
	}

	return (
		<div className='payment-agreement-container'>
			<BookingSubtitle subtitle='서비스 이용 및 약관 동의' />
			<FormControlLabel
				control={
					<Checkbox
						color='primary'
						checked={agreements.every((item) => agreement[item])}
						onChange={handleAllCheckbox}
						checkedIcon={<AllActiveCheckbox className='check-icon' />}
						icon={<AllDeactiveCheckbox className='check-icon' />}
						className='all-check'
					/>
				}
				label='전체 동의'
				className='checkbox-label checkbox-all'
			/>
			<div className='separator-line'></div>
			{!loading && policiesList.length
				? policiesList.map((policyType) => {
					const policy = servicePolicies.find((item) => item?.type === policyType)
					return (
						<BookingAgreeCheckbox
							value={agreement[policy.type]}
							setValue={() => handleCheckbox(policy.type)}
							required={policy.required}
							desc={policy.content.KO.name}
							expand={policy.content.KO.contentUrl}
							expandFunction={() => showServiceTerm(policy.type)}
							key={policy.type}
						/>
					)
				})
				: null}
			<ModalPortal>
				<PolicyModal url={currentPolicy} openServiceTerm={openServiceTerm} />
			</ModalPortal>
		</div>
	)
}
