/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useState } from 'react'
import moment from 'moment'

const BookingContext = createContext({
	orderId: null,
	setOrderId: () => { },
	voucherId: null,
	setVoucherId: () => { },
	headerAction: null,
	setHeaderAction: () => {},
	closeModal: false,
	setCloseModal: () => {}
})

const BookingContextProvider = (props) => {
	const [orderId, setOrderId] = useState(null)
	const [voucherId, setVoucherId] = useState(null)
	const [headerAction, setHeaderAction] = useState(null)
	const [closeModal, setCloseModal] = useState(false)

	moment().locale('ko')

	const initialValue = {
		orderId,
		setOrderId,
		voucherId,
		setVoucherId,
		headerAction,
		setHeaderAction,
		closeModal,
		setCloseModal
	}

	return <BookingContext.Provider value={initialValue}>{props.children}</BookingContext.Provider>
}

const useBookingContext = () => useContext(BookingContext)

export { BookingContextProvider, useBookingContext }
