const TYPE_PREFIX = { name: '이름을', phone: '휴대폰 번호를', email: '이메일을' }

const regex = {
	name: new RegExp(/[a-z|A-Z|가-힣]/),
	phone: new RegExp(/^01([0|1|6|7|8|9])-?([0-9]{4})-?([0-9]{4})$/),
	email: new RegExp(
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/
	),
}

export default function CheckUserFormData(type, value) {
	// 빈 값 확인
	if (/^(name|phone)$/.test(type) && !value)
		return `${TYPE_PREFIX[type]} 반드시 입력해주세요.`

	// 상세 Validation
	if (!regex[type].test(value))
		return `${TYPE_PREFIX[type]} 양식에 맞게 입력해주세요.`
	return true
}
