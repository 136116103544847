import { useEffect, useMemo, useState } from 'react'

export default function useOnScreen(ref, rootMargin = '0px') {
	console.log(rootMargin)
	const [isIntersecting, setIntersecting] = useState(false)

	const observer = useMemo(() => new IntersectionObserver(
		([entry]) => setIntersecting(entry.isIntersecting), { rootMargin }
	), [ref])


	useEffect(() => {
		observer.observe(ref.current)
		return () => observer.disconnect()
	}, [])

	return isIntersecting
}
