import React, { useEffect } from 'react'
import { usePickupContext } from '../../../context/Pickup/pickup'
import './CounterOption.scss'
import { useMenuOptionContext } from '../../../context/Pickup/menuOption'
import { ReactComponent as Minus } from '../../../assets/icon/minus.svg'
import { ReactComponent as MinusGray } from '../../../assets/icon/minusGray.svg'
import { ReactComponent as Plus } from '../../../assets/icon/plus.svg'
import { ReactComponent as PlusGray } from '../../../assets/icon/plusGray.svg'

export default function CounterOption(props) {
	const { detailMenu } = usePickupContext()
	const { dispatchMenuOption, menuOption } = useMenuOptionContext()
	const option = menuOption[props.groupId][props.option._id]

	if (detailMenu === null)
		return <div>loading</div>

	function setValue(type) {
		dispatchMenuOption({ type, optionGroupId: props.groupId, optionId: props.option._id })
	}

	return (
		<div className="counter-option-container">
			<div className="option-name">
				{option.name} {option.price
					? `(${option.price.toLocaleString()}원)`
					: null}
			</div>
			<div className="counter-container">
				<div className="icon" onClick={() => setValue('DECREMENT')}>
					{option.min >= option.value || option.value === 0
						? <MinusGray />
						: <Minus />}
				</div>
				<div className="count">
					{option.value}
				</div>
				<div className="icon" onClick={() => setValue('INCREMENT')}>
					{option.max <= option.value
						? <PlusGray />
						: <Plus />}
				</div>
			</div>
		</div>
	)
}
