import moment from 'moment'
import React, { Fragment, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useModalContext } from '../../../../context/Common/modal'
import { sendKakaoMessage } from '../../../../lib/kakao'
import TrackingAPI from '../../../../lib/tracking'
import { isMobile } from '../../../../utils'
import BottomButton from '../../BottomSheet/BottomButton'
import BottomSheet from '../../BottomSheet/BottomSheet'
import './VoucherItem.scss'
import './../../../../constants/voucherType'
import { VOUCHER_TYPE } from './../../../../constants/voucherType'
import Minus from '../../../../assets/icon/system/voucherActiveMinus.png'
import Plus from '../../../../assets/icon/system/voucherActivePlus.png'
import DeactiveMinus from '../../../../assets/icon/system/voucherDeactiveMinus.png'
import DeactivePlus from '../../../../assets/icon/system/voucherDeactivePlus.png'
import { postRefundRequest } from './../../../../../src/lib/voucher'

export default function VoucherItem(props) {
	const { type, data, user, buttonEvent, ticketData, setIsRefund } = props
	const history = useHistory()
	const trackingAPI = useMemo(() => new TrackingAPI(user, null), [user])
	const { alertOpen } = useModalContext()

	const [voucherInfo, setVoucherInfo] = useState()
	const [isOpenReservNote, setIsOpenReservNote] = useState(false)
	const [isOpenRefundSheet, setIsOpenRefundSheet] = useState(false)
	const [isDisabledRefundBtn, setIsDisabledRefundBtn] = useState(!false)
	const [refundCount, setRefundCount] = useState({})
	const [totalRefundPrice, setTotalRefundPrice] = useState(0)
	const [currentReservNoteDepth, setCurrentReservNoteDepth] = useState(0)

	const isVoucherTypeTicket = data.products && data.products[0].voucherType === VOUCHER_TYPE.TICKET

	const getVoucherInfo = () => {
		const productNumber = data.products.map(product => ({
			id: product.id,
			amount: product.amount,
			purchaseCount: product.purchaseCount
		}))
		const text = productNumber.map((product) => {
			let thousandUnitValue = parseInt(product.amount / 1000, 10)
			if (thousandUnitValue % 10 > 0) {
				if (thousandUnitValue > 10)
					return `${parseInt(thousandUnitValue / 10)}만${thousandUnitValue % 10}천원권`
				return `${thousandUnitValue % 10}천원권`
			}
			return `${thousandUnitValue / 10}만원권`
		}).join(' / ')

		return {
			text,
			productNumber,
			totalAmount: data.totalLeftAmount,
			expireDate: new Date(data.points[0].expiryDate)
		}
	}

	useEffect(() => {
		if (type === 'voucher') {
			const voucher = getVoucherInfo()
			setVoucherInfo(voucher)
		}
	}, [])

	const changeDateFormat = (date) => {
		return `${date.getFullYear()}년 ${date.getMonth() + 1}월 ${date.getDate()}일까지`
	}

	const changeReservationDateFormat = (date) => {
		const reservationDate = moment(new Date(date.year, date.month - 1, date.day, date.hour))
		return reservationDate.format('YYYY년 M월 D일 (dd) H시')
	}

	const makeExpirationDateString = (reservation) => {
		return moment(new Date(reservation.year, reservation.month - 1, reservation.day, reservation.hour, 0)).format('YYYY. MM. DD(dd). HH:mm')
	}

	const maskingUserName = (userName) => {
		const splittedName = userName.split('')
		return `${splittedName[0]}*${splittedName.slice(2).join('')}`
	}

	const getImageLink = (imageLink) => {
		const imageLinkItems = imageLink.split('/')
		const fileName = imageLinkItems[imageLinkItems.length - 1].split('.')
		const imageId = fileName[0]
		const extension = fileName[1]
		return `https://mytable-mall-image.s3.ap-northeast-2.amazonaws.com/${imageId}/original.${extension}`
	}

	const shareKakao = () => {
		if (type === 'voucher') {
			if (navigator.share) {
				navigator.share({ url: `${window.location.origin}/voucher/detail/${data.brand.id}` })
			} else {
				sendKakaoMessage('feed', {
					content: {
						title: '',
						imageUrl: getImageLink(data.service.thumbnail),
						imageWidth: 200,
						imageHeight: 100,
						link: {
							mobileWebUrl: `${window.location.origin}/voucher/detail/${data.brand.id}`,
							webUrl: `${window.location.origin}/voucher/detail/${data.brand.id}`,
						},
						description: '친구로부터 공유받은 더예약 예약상품권을 지금 바로 확인해보세요!'
					},
					callback: (() => {
						trackingAPI.share(data.brand.name, 'mypage - 구매링크 공유하기')
					}),
				})
			}
		} else {
			const latLng = [data.mallName, data.locationLat, data.locationLng].join()
			sendKakaoMessage('feed', {
				content: {
					title: '',
					imageUrl: getImageLink('https://images.tblm.co/image/a16fd231-d8be-4043-8481-5d87a3ad328c.png'),
					link: {
						mobileWebUrl: `${window.location.origin}/voucher/main`,
						webUrl: `${window.location.origin}/voucher/main`,
					},
					imageWidth: 200,
					imageHeight: 100,
					description: `매장명: ${data.mallName}\n예약일시: ${makeExpirationDateString(data)}\n예약자: ${maskingUserName(user.name)}\n인원: ${data.peopleCount}명`
				},
				callback: (() => {
					trackingAPI.share(data.mallName, 'mypage - 예약정보 공유하기')
				}),
				buttons: [
					{
						title: '위치보기',
						link: {
							mobileWebUrl: `https://map.kakao.com/link/to/${latLng}`,
							webUrl: `https://map.kakao.com/link/to/${latLng}`
						}
					}
				]
			})
		}
	}

	const openKakaoMap = () => {
		const latLng = [data.mallName, data.locationLat, data.locationLng].join()
		if (isMobile())
			window.location.href = `https://map.kakao.com/link/to/${latLng}`
		else
			window.open(`https://map.kakao.com/link/to/${latLng}`, '_blank')
	}

	const rePurchaseVoucher = (brandId) => {
		trackingAPI.showDetail(data.mallName, 'mypage - 재구매하기')
		history.push(`/voucher/detail/${brandId}`, { openRequestVoucherPopup: voucherInfo.productNumber })
	}

	const clickAgreeReservationNotesHandler = (isAgree) => {
		if (isAgree) {
			const isCurrentNoteLastDepth = currentReservNoteDepth === data.note?.length - 1

			if (isCurrentNoteLastDepth) {
				setIsOpenReservNote(false)
				setCurrentReservNoteDepth(0)
				buttonEvent()
			} else {
				setCurrentReservNoteDepth(prev => prev + 1)
			}
		} else {
			alertOpen('구매 전 동의사항에 모두 동의해야 예약상품권 구매가 가능합니다.', '확인', '다시 예약해주세요.')
			setCurrentReservNoteDepth(0)
			setIsOpenReservNote(false)
		}
	}

	const clickUseVoucherHandler = (voucherType) => {
		// 사용하기 버튼 클릭
		if (voucherType === VOUCHER_TYPE.TICKET)
			setIsOpenReservNote(true)
		else
			buttonEvent()
	}

	const showNonRefundableAlert = (errorKey) => {
		if (errorKey === 'FAILED') {
			// 환불 error = 오류
			alertOpen('일시적인 오류로 환불요청에 실패했습니다.\n다시 시도해주세요.', undefined, '환불이 불가합니다.')
		} else {
			// 환불 error = 환불 불가
			alertOpen('[환불 정책]\n구매 후 14일 이내 : 환불가능\n\n환불 정책에 따라, 선택하신 예약상품권은 환불이 불가합니다.', undefined, '환불이 불가합니다.')
		}
	}

	const clickRefundVoucherHandler = () => {
		const refundableVerification = () => {
			if (isVoucherTypeTicket)
				return data.points?.filter(item => item.externalId === ticketData.externalId)?.some(refundItem => refundItem.refundable)
			return data.points?.some(item => item.refundable)
		}

		return refundableVerification()
			? setIsOpenRefundSheet(true)
			: showNonRefundableAlert(null)
	}

	const refundableVoucherList = (pointTypeExternalId) => {
		const filterItems = (compareId) => data.points.filter(item => compareId === item.externalId && item.refundable)

		if (isVoucherTypeTicket)
			return filterItems(ticketData.externalId)
		return filterItems(pointTypeExternalId)
	}

	const submitRefundRequest = async () => {
		// 환불 요청 버튼 클릭
		let refundIdList = []

		if (isVoucherTypeTicket) {
			refundIdList = refundableVoucherList().slice(0, refundCount[ticketData.externalId]).map(item => item.id)
		} else {
			Object.keys(refundCount).forEach(select => {
				refundableVoucherList(select).slice(0, refundCount[select]).forEach(item => refundIdList.push(item.id))
			})
		}

		await postRefundRequest({
			partnerId: 'MYTABLE',
			brandId: data.brand?.id,
			custompointIds: refundIdList
		}, user.token)
			.then(() => {
				setIsOpenRefundSheet(false)
				alertOpen('예약상품권 환불이 완료되었습니다.\n\n환불 요청 이후 환불 완료 시까지 3~5영업일 정도 소요 될 수 있습니다.', '확인', '예약상품권 환불 완료')
				setIsRefund(true)
			})
			.catch(err => {
				setIsOpenRefundSheet(false)
				showNonRefundableAlert(err.code)
				setIsRefund(false)
			})
	}

	const controlRefundCount = (count, itemObj, maxLength) => {
		if (count === 1) {
			if (refundCount[itemObj.externalId] < maxLength) {
				setRefundCount(prev => ({ ...prev, [itemObj.externalId]: prev[itemObj.externalId] + count }))
				setTotalRefundPrice(prev => prev + itemObj.salePrice)
			}
		} else if (count === -1) {
			if (refundCount[itemObj.externalId] >= 1) {
				setRefundCount(prev => ({ ...prev, [itemObj.externalId]: prev[itemObj.externalId] + count }))
				setTotalRefundPrice(prev => prev - itemObj.salePrice)
			}
		}
	}

	useEffect(() => {
		// init count value
		if (isVoucherTypeTicket) {
			let init = {}
			init[ticketData.externalId] = 0
			setRefundCount(init)
		} else {
			let init = {}
			data.products?.forEach(item => {
				init[item.externalId] = 0
			})
			setRefundCount(init)
		}
		setTotalRefundPrice(0)
	}, [isOpenRefundSheet])

	useEffect(() => {
		setIsDisabledRefundBtn(totalRefundPrice <= 0)
	}, [totalRefundPrice])

	return (
		<Fragment>
			<div className="voucher-item-wrapper">
				<div className="voucher-information-wrapper">
					<img className="thumbnail" src={
						type === 'voucher'
							? data.service.thumbnail
							: data.thumbnail} alt="브랜드 매장 이미지 썸네일"
					></img>
					<div className="voucher-information">
						<span className="voucher-name">{data.brand && data.brand.name}</span>
						<div className="voucher-use-information">
							{
								type === 'voucher' && voucherInfo
									? (<>
										<p>{isVoucherTypeTicket
											? ticketData.name
											: voucherInfo.text}</p>
										<p>{isVoucherTypeTicket
											? `잔여수량 : ${ticketData.leftPurchaseCount}장`
											: `잔여금액 : ${voucherInfo.totalAmount.toLocaleString()}원`}</p>
										<p>유효기간 : {changeDateFormat(voucherInfo.expireDate)}</p>
									</>)
									: (<>
										<p>{changeReservationDateFormat(data)}</p>
										<p>{data.peopleCount}명</p>
									</>)
							}
						</div>
					</div>

					<div className="voucher-controller-wrapper">
						<button
							className={
								type === 'voucher'
									? 'voucher-control-button using'
									: 'voucher-control-button cancel'
							}
							onClick={() => type === 'voucher'
								? clickUseVoucherHandler(ticketData?.voucherType)
								: buttonEvent(data.reservationId)}
						>
							<span className="text">
								{
									type === 'voucher'
										? '사용하기'
										: '예약취소'
								}
							</span>
						</button>
					</div>
				</div>

				<div className="button-group">
					<button className="share" onClick={() => type === 'voucher'
						? clickRefundVoucherHandler()
						: shareKakao()}>
						<span className="text">
							{
								type === 'voucher'
									? '환불하기'
									: '예약정보 공유하기'
							}
						</span>
					</button>
					<button
						className="re-purchase"
						onClick={
							type === 'voucher'
								? (() => rePurchaseVoucher(data.brand.id))
								: openKakaoMap
						}
					>
						<span className="text">
							{
								type === 'voucher'
									? '재구매하기'
									: '위치 보기'
							}
						</span>
					</button>
				</div>
			</div>
			{/* 빕스 예약상품권 - 유의사항 */}
			{isOpenReservNote &&
				<BottomSheet title="예약상품권 사용">
					{
						data.note?.length ?
							<section className='voucher-ticket-notes-content'>
								<h3>{data.note[currentReservNoteDepth][0]}</h3>
								<p>{data.note[currentReservNoteDepth][1]}</p>
							</section>
							: null
					}
					<BottomButton className="button-wrapper ticket-notes-button-wrapper share" cancelFunc={() => clickAgreeReservationNotesHandler(false)} confirmFunc={() => clickAgreeReservationNotesHandler(true)} cancelBtn="동의안함" confirmBtn="동의" />
				</BottomSheet>}
			{/* 빕스 예약상품권 - 환불 */}
			{isOpenRefundSheet &&
				<BottomSheet title="예약상품권 환불">
					<section className="voucher-refund-wrapper">
						<div className="voucher-refund-content">
							{
								<ul>
									<li>
										<h3>환불 가능한 예약상품권 현황</h3>
										{isVoucherTypeTicket
											? (
												<div className="voucher-list-wrapper">
													<dl>
														<dt>{ticketData.name}</dt>
														<dd>{refundableVoucherList().length}장</dd>
													</dl>
												</div>
											)
											: (
												data.products.map((item, idx) =>
													refundableVoucherList(item.externalId).length > 0 &&
														<div className="voucher-list-wrapper" key={idx}>
															<dl>
																<dt>{item.name}</dt>
																<dd>{refundableVoucherList(item.externalId).length}장</dd>
															</dl>
														</div>)
											)}
									</li>
									<li>
										<h3>환불 요청할 예약상품권</h3>
										{isVoucherTypeTicket ?
											(<div className="voucher-list-wrapper">
												<dl className='voucher-item'>
													<dt>{ticketData.name}</dt>
													<dd className='voucher-information'>
														<div className="icon-group">
															<div className="icon-box minus" onClick={() => controlRefundCount(-1, ticketData)}>
																{
																	refundCount[ticketData.externalId] !== 0
																		? <img src={Minus} className="icon active" alt="-" />
																		: <img src={DeactiveMinus} className="icon deactive" alt="-" />
																}
															</div>
															<span className="value">{refundCount[ticketData.externalId]}</span>
															<div className="icon-box plus" onClick={() => controlRefundCount(1, ticketData, refundableVoucherList().length)}>
																{
																	refundCount[ticketData.externalId] < refundableVoucherList().length
																		? <img src={Plus} className="icon active" alt="-" />
																		: <img src={DeactivePlus} className="icon deactive" alt="-" />
																}
															</div>
														</div>
													</dd>
												</dl>
											</div>)
											:
											(data.products.map((countItem, idx) =>
												refundableVoucherList(countItem.externalId).length > 0 &&
												<div className="voucher-list-wrapper" key={idx}>
													<dl className='voucher-item'>
														<dt>{countItem.name}</dt>
														<dd className='voucher-information'>
															<div className="icon-group">
																<div className="icon-box minus" onClick={() => controlRefundCount(-1, countItem)}>
																	{
																		refundCount[countItem.externalId] !== 0
																			? <img src={Minus} className="icon active" alt="마이너스 버튼" />
																			: <img src={DeactiveMinus} className="icon deactive" alt="마이너스 버튼" />
																	}
																</div>
																<span className="value">{refundCount[countItem.externalId]}</span>
																<div className="icon-box plus" onClick={() => controlRefundCount(1, countItem, refundableVoucherList(countItem.externalId).length)}>
																	{
																		refundCount[countItem.externalId] < refundableVoucherList(countItem.externalId).length
																			? <img src={Plus} className="icon active" alt="플러스 버튼" />
																			: <img src={DeactivePlus} className="icon deactive" alt="플러스 버튼" />
																	}
																</div>
															</div>
														</dd>
													</dl>
												</div>)
											)}
									</li>
								</ul>
							}
						</div>
						<div className="voucher-total-purchase-price-wrapper">
							<div className="total-information">
								<span className="total-information-title">환불 예정 금액</span>
								<span className="total-price">
									{totalRefundPrice.toLocaleString()}원
								</span>
							</div>
						</div>
					</section>
					<BottomButton className="button-wrapper ticket-notes-button-wrapper share" cancelFunc={() => setIsOpenRefundSheet(false)} confirmFunc={submitRefundRequest} cancelBtn="취소" confirmBtn="환불요청" isDisabledConfirmBtn={isDisabledRefundBtn} />
				</BottomSheet>}
		</Fragment>
	)
}
