import React, { useState, useMemo, useEffect } from 'react'
import './PickupUserInfoForm.scss'
import { usePaymentContext } from '../../../context/Pickup/payment'
import { AuthAPI } from '../../../lib/mytable'
import { usePartnerContext } from '../../../context/Common/partner'
import { PickupSubtitle } from '../PickupSubtitle'
import { authorizationWithPrefix } from '../../../lib/utils'

export default function PickupUserInfoForm() {

	const { paymentInfo, dispatchPaymentInfo } = usePaymentContext()
	const { apiKey, partnerInfo } = usePartnerContext()

	const [phoneToken, setPhoneToken] = useState(null)
	const [phoneCode, setPhoneCode] = useState('')
	const phoneAuthAPI = useMemo(() => new AuthAPI(apiKey, null), [apiKey])
	const isAuthorized = useMemo(() => paymentInfo.authorization !== null, [paymentInfo])
	function setUserInfo(event, type) {
		dispatchPaymentInfo({ type, value: event.target.value })
	}

	const reset = () => {
		setPhoneToken(null)
		setPhoneCode(null)
		dispatchPaymentInfo({ type: 'SET_AUTHORIZATION', value: null })
	}

	const clickPhoneCodeButton = () => {
		phoneAuthAPI.requestPhoneCode(paymentInfo.user.phone)
			.then((res) => {
				alert('인증번호가 발송되었습니다.')
				setPhoneToken(res.token)
			})
			.catch((err) => alert(err.response.data.message))
	}

	const submitPhoneCode = () => {
		if (phoneToken === null) {
			alert('인증번호 받기를 클릭해주세요.')
		} else if (phoneCode.length !== 6) {
			alert('인증번호를 입력해주세요.')
		} else if (isAuthorized) {
			alert('이미 인증되었습니다.')
		} else {
			phoneAuthAPI.checkPhoneCode(phoneToken, phoneCode, paymentInfo.user.name, paymentInfo.user.email)
				.then((res) => {
					if (res.result) {
						alert('인증에 성공하였습니다.')
						console.log(res.token)
						dispatchPaymentInfo({ type: 'SET_AUTHORIZATION', value: res.token })
					} else {
						alert(res.message)
					}

				})
				.catch((err) => {
					console.log(err)
				})
		}
	}

	useEffect(() => {
		if (partnerInfo) {
			dispatchPaymentInfo({ type: 'USER_NAME', value: partnerInfo.name })
			dispatchPaymentInfo({ type: 'USER_PHONE', value: partnerInfo.phone })
			dispatchPaymentInfo({ type: 'SET_AUTHORIZATION', value: authorizationWithPrefix() })
			setPhoneToken('ALREADY')
		}
	}, [partnerInfo])

	return (
		<div className="pickup-user-info-form-container">
			<PickupSubtitle subtitle="주문자 정보"></PickupSubtitle>
			<div className="required label">이름</div>
			<input type="text" className="input" placeholder="이름을 입력해주세요" value={paymentInfo.user.name} onChange={(e) => setUserInfo(e, 'USER_NAME')} />
			<div className="required label">휴대폰 번호</div>
			<div className='input-group phone-number'>
				<input disabled={isAuthorized || phoneToken} type="tel" className="input" placeholder="010-0000-0000" value={paymentInfo.user.phone} onChange={(e) => setUserInfo(e, 'USER_PHONE')} />
				{
					phoneToken
						? <button className='input-button' onClick={reset}>다시 인증</button>
						: <button disabled={paymentInfo.user.phone.length < 10} className='input-button' onClick={clickPhoneCodeButton}>인증번호 받기</button>
				}

			</div>
			{
				phoneToken && isAuthorized ?
					null :
					(
						<div className='input-group'>
							<input
								type="text"
								maxLength="6"
								inputMode="numeric"
								className="input"
								placeholder="인증번호를 입력해주세요"
								value={phoneCode}
								onChange={(e) => setPhoneCode(e.target.value)}
								disabled={isAuthorized || !phoneToken}
							/>
							<button disabled={isAuthorized || !phoneToken} className='input-button' onClick={submitPhoneCode}>인증번호 확인</button>
						</div>
					)
			}

			<div className="label">이메일</div>
			<input type="email" className="input" placeholder="이메일을 입력해주세요." value={paymentInfo.user.email} onChange={(e) => setUserInfo(e, 'USER_EMAIL')} />
		</div>
	)
}

