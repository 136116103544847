import React from 'react'
import MenuItem from './MenuItem'
import './MenuCategory.scss'
import { PickupSubtitle } from '../../Gyeongju/PickupSubtitle'

export default function MenuCategory(props) {
	return (
		<div className="menu-category-container" id={props.category.categoryId}>
			<PickupSubtitle subtitle={props.category.categoryName}></PickupSubtitle>
			<div className="menu-item-list">
				{props.category.menus.map((menu) => <MenuItem menu={menu} key={menu._id}/>)}
			</div>
		</div>
	)
}
