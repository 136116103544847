import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { usePartnerContext } from '../../../context/Common/partner'
import { useVoucherContext } from '../../../context/Common/voucher'
import { useAccountContext } from '../../../context/Common/account'
import { useVoucherContext as useWrapVoucherContext } from '../../../context/Voucher/voucher'
import ProductInfo from '../../../Components/Voucher/VoucherPayment/ProductInfo/ProductInfo'
import VoucherNotice from '../../../Components/Voucher/VoucherPayment/VoucherNotice/VoucherNotice'
import VoucherAgreement from '../../../Components/Voucher/VoucherPayment/VoucherAgreement/VoucherAgreement'
import Seperator from '../../../Components/Share/Seperator'
import BookingFooter from '../../../Components/Share/Booking/BookingFooter/BookingFooter'
import PaymentType from '../../../Components/Voucher/VoucherPayment/PaymentType/PaymentType'
import { openPaymentModal } from '../../../lib/nicepay'
import { billingPayment, deleteSubscriptions, getCardList, sendOrders, sendPolicy } from '../../../lib/voucher'
import TrackingAPI from '../../../lib/tracking'
import { useModalContext } from '../../../context/Common/modal'
import './Payment.scss'

export default function Payment() {
	const { voucherInfo, validations, paymentInfo, dispatchPaymentInfo, voucherProduct } = useVoucherContext()
	const { referralConfig } = useWrapVoucherContext()
	const { partnerName } = usePartnerContext()
	const [isLoading, setIsLoading] = useState(false)
	const [cardList, setCardList] = useState([])
	const { alertOpen } = useModalContext()
	const { user } = useAccountContext()
	const trackingAPI = useMemo(() => new TrackingAPI(user, null), [user])
	const history = useHistory()

	function initPayment() {
		if (isLoading)
			return

		let merchantId = process.env.REACT_APP_NICEPAY_SIMPLE_MERCHANT_ID
		let merchantKey = process.env.REACT_APP_NICEPAY_SIMPLE_MERCHANT_KEY
		if (paymentInfo.type === 'BILLING_KEY') {
			merchantId = process.env.REACT_APP_NICEPAY_BILLING_MERCHANT_ID
			merchantKey = process.env.REACT_APP_NICEPAY_BILLING_MERCHANT_KEY
		}
		if (partnerName === 'shinhan') {
			merchantId = process.env.REACT_APP_NICEPAY_SHINHAN_MERCHANT_ID
			merchantKey = process.env.REACT_APP_NICEPAY_SHINHAN_MERCHANT_KEY
		}
		let products = voucherInfo.products.filter(item => item.count > 0).map(item => {
			return {
				productId: item.product.id,
				count: item.count
			}
		})
		const isPartner = partnerName !== 'tablemanager'
		const inflowId = sessionStorage.getItem('inflowId')
		let authorization = ''
		if (partnerName === 'tablemanager')
			authorization = user.token
		else
			authorization = sessionStorage.getItem('authorization')

		let sendOrderBody = {}
		if (paymentInfo.type === 'BILLING_KEY')
			sendOrderBody = { orderItems: products }
		else
			sendOrderBody = { orderItems: products, clientCallbackUrl: window.location.origin + '/voucher/step/complete', merchantId }

		trackingAPI.purchase(voucherProduct.brand.name, '결제시도')
		sendOrders(sendOrderBody, authorization, isPartner, inflowId)
			.then(res => {
				if (paymentInfo.type === 'BILLING_KEY') {
					setIsLoading(true)
					const body = {
						card: {
							cardNumber: paymentInfo.billingKeyContext.cardNumbers.join(''),
							password: paymentInfo.billingKeyContext.password,
							expiry: `${paymentInfo.billingKeyContext.expiry.month}/${paymentInfo.billingKeyContext.expiry.year.substr(2, 2)}`,
							identifyNumber: paymentInfo.billingKeyContext.identifyCode,
							subscriptionId: paymentInfo.billingKeyContext.subscriptionId,
						},
						saveCard: voucherInfo.agreement.saveCard
					}
					billingPayment(body, res.orderId, authorization, isPartner)
						.then(billingRes => {
							setIsLoading(false)
							if (billingRes.status === 'SUCCESS')
								history.replace(`/voucher/step/complete?status=success&orderId=${res.orderId}`)
							else
								alertOpen(billingRes.error.message)
						}).catch((err) => {
							setIsLoading(false)
						})
				} else {
					let paymentOption = {
						amount: res.purchasePrice,
						orderId: res.orderId,
						user: voucherInfo.user,
						goodsName: voucherProduct.service.name + ' 예약상품권',
						returnUrl: window.location.origin + '/voucher/step/complete',
						callBackUrl: process.env.REACT_APP_PAYMENT_API_HOST + '/nicepay/v1/payment-callback',
						payType: paymentInfo.type,
						merchantId,
						merchantKey
					}
					if (paymentInfo.type === 'EXCEPTION')
						paymentOption.directOption = referralConfig.payment.directOption
					openPaymentModal(paymentOption)
				}
				trackingAPI.purchase(voucherProduct.brand.name, '결제성공')

				const policyArr = Object.entries(voucherInfo.agreement).reduce((result, [serviceType, agree]) => {
					return serviceType !== 'saveCard'
						? [...result,
							{
								servicePolicyType: serviceType,
								agreement: agree
							}]
						: [...result]
				}, [])

				sendPolicy({
					user: {
						phone: user?.phone,
						email: user?.email
					},
					termsAgreements: policyArr,

					userToken: user?.token
				})
			})
			.catch((error) => {
				setIsLoading(false)
				if (error && error.message) {
					alert(
						error.message !== 'Request failed with status code 500'
							? error.message
							: '결제에 실패하셨습니다.\n다른 결제 수단으로 시도하시거나 잠시 후 다시 시도해주세요.'
					)
					history.goBack()
				}
				trackingAPI.purchase(voucherProduct.brand.name, '결제실패')
			})
	}

	function deleteCard(userSubscriptionId) {
		deleteSubscriptions(userSubscriptionId, user.token)
			.then(() => {
				getUserCardData()
			}).catch((err) => {
				if (err.message)
					alert(err.message)
			})
	}

	function getUserCardData() {
		getCardList(user.token)
			.then((res) => {
				setCardList(res.subscriptions)
			}).catch((err) => {
				console.log(err)
			})
	}

	useEffect(() => {
		if (partnerName === 'tablemanager' && user && user.token)
			getUserCardData()
	}, [user])

	useEffect(() => {
		if (partnerName === 'skt')
			dispatchPaymentInfo({ type: 'CHANGE_PAYMENT_TYPE', value: 'PG' })
	}, [partnerName])

	useEffect(() => {
		if (referralConfig && referralConfig.payment.exception)
			dispatchPaymentInfo({ type: 'CHANGE_PAYMENT_TYPE', value: 'EXCEPTION' })
	}, [referralConfig])

	useEffect(() => {
		let parsedInfo = JSON.parse(sessionStorage.getItem('voucherInfo'))
		if (voucherInfo.amount === 0 || voucherInfo.products.length === 0) {
			if (parsedInfo.amount === 0 && parsedInfo.products.length === 0)
				history.go(-1)
		}
	}, [voucherInfo.amount])

	return (
		<div>
			<ProductInfo></ProductInfo>
			<Seperator></Seperator>
			<VoucherNotice></VoucherNotice>
			<Seperator></Seperator>
			{
				partnerName === 'skt' || referralConfig.payment.exception ?
					null
					: <><PaymentType cardList={cardList} deleteCard={deleteCard}></PaymentType><Seperator></Seperator></>
			}


			<VoucherAgreement></VoucherAgreement>
			<BookingFooter
				text={isLoading
					? ''
					: voucherInfo.amount.toLocaleString() + '원 결제하기'
				}
				active={!validations.agreementError}
				action={initPayment}
			></BookingFooter>
			{
				isLoading
					? <div className="payment-loader-container">
						<ClipLoader></ClipLoader>
					</div>
					: null
			}
		</div>
	)
}

