import React, { useEffect, useCallback } from 'react'
import { useState } from 'react'
import { useMyPageContext } from '../../../../context/Booking/myPage'
import BookingSubtitle from '../../../Share/Booking/BookingSubtitle/BookingSubtitle'
import useDynamicComponent from '../../../../hooks/useDynamicComponent'
import Format from '../../../../utils/Format'

const RESERVATION_STATE = {
	RESERVATION_CANCEL: 0,
	RESERVATION_LAST: 1,
	VISIT_CANCEL: 2,
	VISIT_FINISH: 3,
}

export default function MallInfo() {
	const { reservationInfo } = useMyPageContext()
	const [scrollHeight, setScrollHeight] = useState(0)
	const { renderComponent } = useDynamicComponent()
	const handleScroll = () => {
		const position = window.pageYOffset
		setScrollHeight(position)
	}

	const StateInfo = useCallback(() => {
		const isShowReservationInfo =
			reservationInfo.state === RESERVATION_STATE.RESERVATION_CANCEL && !reservationInfo.isCancel
		let stateClassName = ['state-info']
		let text = ''

		if (reservationInfo.state === RESERVATION_STATE.VISIT_CANCEL) {
			stateClassName.push('cancel')
			text = '방문 취소된 예약입니다.'
		}
		if (
			reservationInfo.state === RESERVATION_STATE.RESERVATION_LAST ||
			reservationInfo.state === RESERVATION_STATE.VISIT_FINISH
		) {
			stateClassName.push('finish')
			text = '방문 완료된 예약입니다.'
		}
		if (reservationInfo.isCancel) {
			stateClassName.push('reserv-cancel')
			text = '예약이 취소되었습니다.'
		}

		if (scrollHeight > 80)
			stateClassName.push('sticky')

		return !isShowReservationInfo && <div className={stateClassName.join(' ')}>{text}</div>
	}, [reservationInfo])

	const detailParty = () => {
		if (reservationInfo.info_people.length <= 1)
			return null

		let detailPartyArray = reservationInfo.info_people.filter((item) => item.count > 0)

		return (
			<>
				(
				{detailPartyArray
					.map((party, idx) => (
						<span>
							{party.name} {party.count}명
						</span>
					))
					.reduce((prev, curr) => [prev, ', ', curr])}
				)
			</>
		)
	}

	useEffect(() => {
		window.addEventListener('scroll', handleScroll, { passive: true })

		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])

	return (
		<div className="my-reservation-info-container">
			<div className="state-info-container">
				<StateInfo></StateInfo>
			</div>
			<div className="container border">
				<h1 className="mall-info-title">{reservationInfo.mall_name}</h1>
				<BookingSubtitle subtitle="예약내역 확인"></BookingSubtitle>
				{renderComponent({
					default: (
						<>
							<div className="reservation-info">
								<div className="label">
									<span>예약일시</span>
								</div>
								<div className="value">{reservationInfo.date.format('YYYY년 M월 D일 (dd) · LT')}</div>
							</div>
							<div className="reservation-info">
								<div className="label">
									<span>예약인원</span>
								</div>
								<div className="value">
									{reservationInfo.info_people.reduce((a, b) => a + b.count, 0)}명 {detailParty()}
								</div>
							</div>
							{reservationInfo.deposit_price > 0
								? (
									<div className="reservation-info">
										<div className="label">
											<span>예약금액</span>
										</div>
										<div className="value">{reservationInfo.deposit_price.toLocaleString()}원</div>
									</div>
								)
								: null}
						</>
					),
					gyeongjuro: (
						<>
							<ul className="info-container">
								<li className="info-list">
									<div className="info-box">
										<label className="label">예약일시</label>
										<span className="value">{reservationInfo.date.format('LL (dd) ∙ LT')}</span>
									</div>
								</li>
								<li className="info-list">
									<div className="info-box">
										<label className="label">예약인원</label>
										<span className="value">
											{reservationInfo.info_people.reduce((a, b) => a + b.count, 0)}명 {detailParty()}
										</span>
									</div>
								</li>
								{reservationInfo.deposit_price > 0
									? (
										<li className="info-list">
											<div className="info-box">
												<label className="label">예약금액</label>
												<span className="value">{reservationInfo.deposit_price.toLocaleString()}원</span>
											</div>
										</li>
									)
									: null}
							</ul>
							{/* {reservationInfo.state === 0 && reservationInfo.deposit_price > 0*/}
							{/*	? (*/}
							<div className="noshow-info">
									- 해당 레스토랑은 <span className="noshow-text">No Show</span>로 인해 발생되는 매장의 피해를 예방하고자
									예약금 제도를 운영합니다.
							</div>
							{/* )*/}
							{/* : null}*/}
						</>
					),
				})}
				{renderComponent({
					gyeongjuro: (
						<>
							<div className="user-info-space" />
							<BookingSubtitle subtitle="예약자 정보"></BookingSubtitle>
							<ul className="info-container">
								<li className="info-list">
									<div className="info-box">
										<label className="label">예약자</label>
										<span className="value">{reservationInfo.user_name}</span>
									</div>
								</li>
								<li className="info-list">
									<div className="info-box">
										<label className="label">연락처</label>
										<span className="value">{Format.blindPhone(Format.phone(reservationInfo.user_phone))}</span>
									</div>
								</li>
								<li className="info-list">
									<div className="info-box">
										<label className="label">요청사항</label>
										<span className="value">{reservationInfo.request || '없음'}</span>
									</div>
								</li>
							</ul>
						</>
					),
				})}
			</div>
		</div>
	)
}
