
import React from 'react'
import './VoucherEmail.scss'

export default function VoucherEmail() {
	return (
		<a className="voucher-email-anchor" href="mailto:voucher@tablemanager.team">
			<button className="email-inquiry-button">
				<div className="email-icon"></div>
				<span className="text">이메일 문의(voucher@tablemanager.team)</span>
			</button>
		</a>
	)
}
