import React from 'react'
import { ReactComponent as Call } from '../../../assets/icon/call.svg'
import { useMall } from '../../../store'
import Map from '../../Share/Map'
import format from '../../../utils/Format'

const Address = (props) => {
	const { location } = { ...props }
	const { mall } = useMall((state) => state)
	const {
		location_lat: lat,
		location_lng: lng,
		location_address: addressObj,
		mall_phone: mallPhone,
	} = (location ?? mall)
	const { address, detail, road } = { ...addressObj }

	return (
		<div className="address-container">
			<h1>주소</h1>
			<Map lat={lat} lng={lng}></Map>
			<address>
				<p className='address-detail'>{`${road} ${detail}`}</p>
				<p className='address-detail'>{`지번 : ${address}`}</p>
				{mallPhone && (
					<div className='call-box'>
						<Call />
						<a href={`tel:${mallPhone}`}>{format.phone(mallPhone)}</a>
					</div>
				)}
			</address>
		</div>
	)
}

export default Address
