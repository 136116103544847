import React, { useEffect, useState } from 'react'
import { ReactComponent as Minus } from '../../../../assets/icon/minusRect.svg'
import { ReactComponent as DeactiveMinus } from '../../../../assets/icon/minusRectGray.svg'
import { ReactComponent as Plus } from '../../../../assets/icon/plusRect.svg'
import { ReactComponent as DeactivePlus } from '../../../../assets/icon/plusRectGray.svg'
import { ReactComponent as GyeongjuroMinus } from '../../../../assets/icon/stepper-minus.svg'
import { ReactComponent as GyeongjuroPlus } from '../../../../assets/icon/stepper-plus.svg'
import { useFooter, useMall, useReservation } from '../../../../store'
import classNames from 'classnames'
import useDynamicComponent from '../../../../hooks/useDynamicComponent'

export default function PartySizeSelector({ isLoaded }) {
	const { totalPartySize, setReservation } = useReservation()
	const { mall } = useMall()
	const { setFooter } = useFooter()
	const { people_max: max, people_min: min } = { ...mall }
	const { renderComponent } = useDynamicComponent()
	const [isActive, setIsActive] = useState({
		left: false,
		right: false,
	})

	const handlePartySize = (target, value) => {
		if (!target)
			return
		setReservation('totalPartySize', totalPartySize + value)
		setReservation('dateTime', undefined)
		setFooter('active', false)
	}

	useEffect(() => {
		if (!totalPartySize && mall) {
			// 매장 정책이 허용되는 경우, 기본값 2로 설정 요청
			if (min <= 2 && max >= 2)
				setReservation('totalPartySize', 2)
			else
				setReservation('totalPartySize', min)
		}
	}, [mall])

	useEffect(() => {
		if (totalPartySize && max && min) {
			isActive.left = totalPartySize > min
			isActive.right = totalPartySize < max
			setIsActive({ ...isActive })
		}
	}, [totalPartySize, max, min])

	return (
		<div className={classNames('party-size-selector-container', { isLoaded })}>
			<span className="people-text t3">인원</span>
			<div className="icon-box">
				<div className="icon minus">
					{renderComponent({
						default:
							totalPartySize > min
								? <Minus onClick={() => handlePartySize(isActive.left, -1)} />
								: <DeactiveMinus />,
						gyeongjuro: (
							<GyeongjuroMinus
								className={isActive.left
									? 'enable'
									: 'disable'}
								onClick={() => handlePartySize(isActive.left, -1)}
							/>
						),
					})}
				</div>
				<span className="people-value t4">
					{renderComponent({ gyeongjuro: totalPartySize, default: `${totalPartySize}명` })}
				</span>
				<div className="icon plus">
					{renderComponent({
						default:
							totalPartySize < max
								? <Plus onClick={() => handlePartySize(isActive.right, +1)} />
								: <DeactivePlus />,
						gyeongjuro: (
							<GyeongjuroPlus
								className={isActive.right
									? 'enable'
									: 'disable'}
								onClick={() => handlePartySize(isActive.right, +1)}
							/>
						),
					})}
				</div>
			</div>
		</div>
	)
}
