import React, { useEffect, useState } from 'react'
import { useMyPageContext } from '../../../context/Booking/myPage'
import { useHistory, useParams } from 'react-router-dom'
import './ExpandReservationList.scss'
import { VOUCHER_TYPE } from '../../../constants/voucherType'
import { ReservationItem, VoucherItem } from '../../../Components/Booking'

export default function ExpandReservationList() {
	const { reservationList, setReservationInfo, voucherList } = useMyPageContext()
	const parmas = useParams()
	const history = useHistory()
	const [list, setList] = useState([])

	useEffect(() => {
		if (reservationList.length === 0 && voucherList.length === 0)
			history.goBack()
		if (parmas.type === 'scheduled')
			setList(reservationList.filter(item => item.state === 0))
		if (parmas.type === 'finished')
			setList(reservationList.filter(item => item.state !== 0))
		if (parmas.type === 'voucher')
			setList(voucherList)
	}, [parmas.type, voucherList])

	useEffect(() => {
		setReservationInfo(null)
	}, [])

	if (parmas.type === 'voucher') {
		return (
			<div className="expand-reservation-list-container">
				{list.map((item, idx) => {
					if (item.products[0].voucherType === VOUCHER_TYPE.TICKET) {
						return item.products.map((ticketItem, ticketIdx) => (
							<VoucherItem voucher={item} ticket={ticketItem} key={`${item.inquiryId}-${ticketIdx}`} />
						))
					}
					return <VoucherItem voucher={item} key={`${parmas.type}-${idx}`}></VoucherItem>
				})}
			</div>
		)
	}
	return (
		<div className="expand-reservation-list-container">
			{list.map((item, idx) => <ReservationItem reservation={item} key={idx}></ReservationItem>)}
		</div>
	)
}
