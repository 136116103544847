import React, { useEffect } from 'react'
import { ReactComponent as Close } from '../../../assets/icon/close.svg'
import './BusinessInfo.scss'
import { useHistory } from 'react-router-dom'

export default function BusinessInfo(props) {
	const history = useHistory()
	const popstateEvent = (event) => {
		event.preventDefault()
		props.closeTerms()
	}

	const closeTerm = () => {
		history.goBack()
		props.closeTerms()
	}

	useEffect(() => {
		window.addEventListener('popstate', popstateEvent)
		return (() => {
			window.removeEventListener('popstate', popstateEvent)
		})
	}, [])

	return (
		<div className="business-information-container">
			<div className="header">
				<div className="close-title">
          사업자정보확인
				</div>
				<div className="close-button">
					<Close onClick={closeTerm}/>
				</div>
			</div>

			<div className="business-information">
				<table className="business-information-table">
					{/* <caption>통신판매사업자(상세) - 통신판매번호, 신고기관명, 상호, 사업자등록번호, 법인여부, 법인등록번호, 대표자명, 전화번호, 전자우편(E-mail), 신고일자, 사업장소재지, 사업장소재지(도로명), 신고현황, 신고기관 대표연락처, 판매방식, 취급품목, 인터넷도메인, 호스트서버소재지 순으로 내용을 제공하고 있습니다.</caption> */}
					<tbody>
						<tr>
							<th scope="row">통신판매번호</th>
							<td>2020-서울성동-00268</td>
							<th scope="row">사업자등록번호</th>
							<td>751-81-00830</td>
						</tr>
						<tr>
							<th scope="row">신고현황</th>
							<td>통신판매업 신고</td>
							<th scope="row">법인여부</th>
							<td>법인</td>
						</tr>
						<tr>
							<th scope="row">상호</th>
							<td colSpan="3">테이블매니저(주)</td>
						</tr>
						<tr>
							<th scope="row">대표자명</th>
							<td>최훈민</td>
							<th scope="row">대표 전화번호</th>
							<td>02-6952-4053</td>
						</tr>
						<tr>
							<th scope="row">판매방식</th>
							<td>인터넷, 카탈로그, 신문잡지
							</td>
							<th scope="row">취급품목</th>
							<td>종합몰, 건강/식품, 상품권</td>
						</tr>
						<tr>
							<th scope="row">전자우편(E-mail)</th>
							<td>hoonmin@tablemanager.io</td>
							<th scope="row">신고일자</th>
							<td>20170720</td>
						</tr>
						<tr>
							<th scope="row">사업장소재지</th>
							<td colSpan="3">서울특별시 성동구 성수동2가 273번지 36호 제이케이타워</td>
						</tr>
						<tr>
							<th scope="row">사업장소재지(도로명)</th>
							<td colSpan="3">서울특별시 성동구 성수이로20길 16, 제이케이타워 901호 (성수동2가)</td>
						</tr>
						<tr>
							<th scope="row">인터넷도메인</th>
							<td colSpan="3">www.tablemanager.io</td>
						</tr>
						<tr>
							<th scope="row">호스트서버소재지</th>
							<td colSpan="3"></td>
						</tr>
						<tr>
							<th scope="row">통신판매업 신고기관명</th>
							<td colSpan="3">서울특별시 성동구청&nbsp;&nbsp;(02-2286-5471)</td>
						</tr>
					</tbody>
				</table>

				<div className="business-box">
					<p className="description">
						본자료는 전자상거래시장에서 소비자가 정확한 사업자 정보를 가지고 안전한 거래를 할 수 있도록 전국 시,군,구에 신고된&nbsp;
						<strong>통신판매업자</strong>의 신원정보를 <em className="em_blue">전자상거래소비자보 호법 제12조4항</em>
						에 따라 제공하는 정보입니다. 사업자 정보에 대한 궁금한 사항이나 사업자의 신원정보가 정보공개 내용과 불일치할 경우에는 사업자 정보검색시 확인되는 해당&nbsp;
						<strong>신고기관(지방자치단체)</strong>에 문의하여 주시기 바랍니다.<br/><br/>
						일부 사업자의 경우, 부가가치세법상 사업자 폐업 신고는 하였으나 전자상거래법상 통신판매업 폐업 신고는 하지 않은 사례가 있을 수 있습니다.
						소비자 피해를 방지하기 위해 부가가치세법상 <strong>사업자 폐업 여부</strong>도 <a href="http://www.hometax.go.kr" target="_blank" title="새창" rel="noreferrer"><strong>국세청 홈택스 페이지</strong>(www.hometax.go.kr)</a>의 사업자등록상태조회 코너를 통해 확인하시기 바랍니다.&nbsp;
						<a href="https://teht.hometax.go.kr/websquare/websquare.html?w2xPath=/ui/ab/a/a/UTEABAAA13.xml" target="_blank" title="새창" rel="noreferrer">[바로가기 링크]</a>
					</p>
				</div>
			</div>
		</div>
	)
}
