import { useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useBookingContext } from '../../../context/Booking/booking'
import { useModalContext } from '../../../context/Common/modal'
import { usePartnerContext } from '../../../context/Common/partner'
import { BookingMallDetailAPI } from '../../../lib/mytable'
import { getOrderId } from '../../../lib/payment'
import { authorizationWithPrefix } from '../../../lib/utils'
import { useReservation } from '../../../store'

export default function PaymentComplete() {
	const history = useHistory()
	const location = useLocation()
	const search = location.search
	const tid = new URLSearchParams(search).get('tid') || new URLSearchParams(search).get('orderId')
	const resultCode = new URLSearchParams(search).get('resultCd')
	const resultMessage = new URLSearchParams(search).get('resultMsg') || new URLSearchParams(search).get('message')
	const status = new URLSearchParams(search).get('status')
	const { apiKey, partnerName, setPartnerInfo } = usePartnerContext()
	const { orderId, setOrderId } = useBookingContext()
	const { alertOpen } = useModalContext()
	const { voucherData } = useReservation()
	let count = 0
	const bookingMallDetailAPI = useMemo(() => new BookingMallDetailAPI(apiKey, authorizationWithPrefix()), [apiKey, voucherData])
	useEffect(() => {
		if (tid && resultCode && resultMessage) {
			if (resultCode === '1001' || resultCode === '0000') {
				getOrderId(tid).then(res => {
					if (res.payments && res.payments.length > 0){
						setOrderId(res.payments[0].orderId)
						setPartnerInfo({
							chnlMbrId : res.payments[0].extraProperty.kbUserId,
							chnlId: res.payments[0].extraProperty.kbChannelId
						})
					}
				})
			}
		}
		if (status) {
			if (status === 'success') {
				setOrderId(tid)
			} else {
				alert(resultMessage)
				history.go(-2)
			}
		}
	}, [tid, resultCode, resultMessage, status])

	useEffect(() => {
		const fetchPaymentResult = () => {
			bookingMallDetailAPI.getPaymentResult(orderId).then(res => {
				if (res.status === 'FAIL') {
					alertOpen(res.message)
					history.go(-4)
					return
				}
				if (res.status === 'WAIT') {
					if (count < 10) {
						count++
						setTimeout(fetchPaymentResult, 1000)
					}
				}
				if (res.status === 'SUCCESS') {
					if (partnerName === 'tablemanager') {
						const tablemanagerHoldings = sessionStorage.getItem('tablemanagerHoldings')
						// eslint-disable-next-line camelcase
						tablemanagerHoldings.reservation_id = res.reservationId
						tablemanagerHoldings.confirmed = true
						sessionStorage.setItem('tablemanagerHoldings', JSON.stringify(tablemanagerHoldings))
					}

					sessionStorage.setItem('authorization', res.authorization)
					history.push(`/booking/step/complete-view/${res.reservationId}`)
				} else {
					localStorage.removeItem('tablemanagerHoldings')
				}

			})
		}
		if (orderId)
			fetchPaymentResult()

	}, [orderId])
	return null
}
