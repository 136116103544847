import React from 'react'
import BookingFooter from '../../../Components/Share/Booking/BookingFooter/BookingFooter'
import { useVoucherContext } from '../../../context/Common/voucher'
import { useHistory } from 'react-router-dom'
import Form from '../../../Components/Voucher/InfoForm/Form/Form'

export default function InfoForm() {
	const { voucherInfo } = useVoucherContext()
	const history = useHistory()

	function movePayment() {
		history.push('/voucher/step/payment')
	}
	return (
		<div>
			<Form></Form>
			<BookingFooter text="다음" action={movePayment} active={voucherInfo.user.name && voucherInfo.user.phoneNumber && voucherInfo.phoneAuth}></BookingFooter>
		</div>
	)
}
