import React from 'react'
import { useSearchContext } from '../../../../context/Booking/search'
import { ReactComponent as Search } from '../../../../assets/icon/searchCircle.svg'
import { ReactComponent as Delete } from '../../../../assets/icon/delete.svg'
import './SearchHistory.scss'

export default function SearchHistory() {
	const { searchInfo, searchHistories, setSearchHistories, search } = useSearchContext()

	function clearHistory() {
		setSearchHistories([])
	}

	function clearHistoryOne(name) {
		setSearchHistories(searchHistories.filter(history => history.name !== name))
	}

	if (!searchInfo.isSearching && !searchInfo.searchCompleted && searchInfo.searchWord === '' && searchHistories.length > 0) {
		return (
			<div className="search-history-container">
				<div className="search-title">
					<span className="t4">최근검색어</span>
					<span className="clear-all b1" onClick={clearHistory}>전체 삭제</span>
				</div>
				{searchHistories.map((history, idx) =>
					<div className="search-data b1" key={idx}>
						<div className="search-icon-container" onClick={() => search(history.name)}>
							<Search className="search-icon"/>
							<span>{history.name}</span>
						</div>
						<div className="clear-icon-container">
							<Delete className="clear-icon" onClick={() => clearHistoryOne(history.name)}/>
						</div>
					</div>
				)}
			</div>
		)
	}
	return null


}
