/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useState, useEffect } from 'react'

const AccountContext = createContext({
	user: null,
	setUser: () => {},
	authData: null,
	setAuthData: () => {},
})

const useAccountContext = () => useContext(AccountContext)

const AccountContextProvider = (props) => {
	const [user, setUser] = useState(null)
	const [authData, setAuthData] = useState(null)

	const initialValue = {
		user,
		setUser,
		authData,
		setAuthData,
	}

	useEffect(() => {
		const checkUser = () => {
			if (user) {
				localStorage.setItem('user', JSON.stringify(user))
			} else {
				const userInfo = localStorage.getItem('user')
				if (userInfo)
					setUser(JSON.parse(userInfo))
			}
		}
		const checkAuth = () => {
			if (authData) {
				localStorage.setItem('oauth', JSON.stringify(authData))
			} else {
				const oauthInfo = localStorage.getItem('oauth')
				if (oauthInfo)
					setAuthData(JSON.parse(oauthInfo))
			}
		}

		checkUser()
		checkAuth()
	}, [])

	return <AccountContext.Provider value={initialValue}>{props.children}</AccountContext.Provider>
}

export { AccountContextProvider, useAccountContext }
