import './AutocompleteItem.scss'

import React from 'react'
import { ReactComponent as Search } from '../../../../assets/icon/searchCircle.svg'
import { useSearchContext } from '../../../../context/Booking/search'

export default function AutocompleteItem(props) {
	const { dispatchSearchInfo, search } = useSearchContext()

	function searchClick() {
		search(props.mall.name)
		dispatchSearchInfo({ type: 'CHANGE_SEARCH_WORD', value: props.mall.name })
		dispatchSearchInfo({ type: 'SEARCH_COMPLETE' })
		dispatchSearchInfo({ type: 'SEARCH_FINISH' })
	}
	return (
		<div className="autocomplete-item-container" onClick={searchClick}>
			<div className="search-icon-container" onClick={searchClick}>
				<div className="search-value b1">
					<Search className="search-icon" />
					{props.mall.characters.map((item, idx) =>
						<span key={item.character + idx} className={item.isHighlight
							? 'highlight'
							: null}>{item.character}</span>)}
				</div>
			</div>
		</div>
	)
}
