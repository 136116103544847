import React from 'react'
import './MenuDescription.scss'

function MenuDescription(props) {
	return (
		<div className="menu-description-container">
			<div className="menu-name">{props.menuName}</div>
			<div className="menu-description">{props.menuDescription}</div>
		</div>
	)
}
export default MenuDescription
