import React from 'react'
import { useVoucherContext } from '../../../../context/Common/voucher'
import BookingSubtitle from '../../../Share/Booking/BookingSubtitle/BookingSubtitle'
import './VoucherNotice.scss'

export default function VoucherNotice() {
	const { voucherInfo } = useVoucherContext()

	if (!voucherInfo)
		return null
	return (
		<div className="voucher-notice-container">
			<BookingSubtitle subtitle="꼭 확인하세요"></BookingSubtitle>

			{
				voucherInfo.products.some(product => product.product.voucherType === 'POINT')
					? <>
						<div className='subheader t4'>유의사항</div>
						<div className="notice-line b1">
							<div className='list-header'>ㆍ</div>
							<span>본 예약상품권은 구매 후 ‘방문 일자‘와 ‘시간’ 등을 지정해 예약을 하고 매장을 방문해야 사용하실 수 있습니다.</span>
						</div>
						<div className="notice-line b1">
							<div className='list-header'>ㆍ</div>
							<span>구매자와 예약자 그리고 사용자의 전화번호가 동일해야 사용 가능합니다.</span>
						</div>
						<div className="notice-line b1">
							<div className='list-header'>ㆍ</div>
							<span>본 예약상품권의 남은 금액은 유효기한 내 예약상품권 사용이 가능한 매장에서 사용하실 수 있습니다.</span>
						</div>
						<div className="notice-line b1">
							<div className='list-header'>ㆍ</div>
							<span>본 예약상품권은 제휴사 사정으로 변경 및 중단될 수 있습니다.</span>
						</div>
						<div className="notice-line b1">
							<div className='list-header'>ㆍ</div>
							<span>예약상품권은 수요예측 AI 모델링을 기반으로 한 예약 서비스로, 매장 상황에 따라 예약이 가능한 시간대가 변동될 수 있습니다.</span>
						</div>
						<div className="seperator-line"></div>
						<div className='subheader t4'>환불규정</div>
						<div className="notice-line b1">
							<div className='list-header'>ㆍ</div>
							<span>구매 후 14일 초과 시 환불이 불가합니다.</span>
						</div>
						<div className="notice-line b1">
							<div className='list-header'>ㆍ</div>
							<span>해당 예약상품권의 사용 이력이 있는 경우 취소 및 현금으로 환불이 불가합니다.</span>
						</div>
						<div className="notice-line b1">
							<div className='list-header'>ㆍ</div>
							<span>본 예약상품권은 환불 불가 규정에 동의하셔야 구매가 가능합니다.</span>
						</div>
						<div className="notice-line b1">
							<div className='list-header'>ㆍ</div>
							<span>환불 및 관련 문의는 아래 테이블매니저 고객센터로 연락 바랍니다.</span>
						</div>
						<div className="seperator-line"></div>
						<div className='subheader t4'>테이블매니저 고객센터</div>
						<div className="notice-line b1">
							<div className='list-header'>ㆍ</div>
							<span>1644-9997</span>
						</div>
						<div className="notice-line b1">
							<div className='list-header'>ㆍ</div>
							<span><a href='mailto:voucher@tablemanager.team?subject=[예약상품권 환불요청]&body=성함:%0D%0A연락처:%0D%0A구매 브랜드:%0D%0A권종(예: 5만원권,10만원권):%0D%0A구매 장 수:%0D%0A환불 장 수 (부분 취소 불가합니다.):%0D%0A환불 사유:'>voucher@tablemanager.team</a></span>
						</div>
						<div className="notice-line b1">
							<div className='list-header'>ㆍ</div>
							<span>평일ㅣ10:00-19:00 (점심시간 12-13시 제외)<br />토, 일요일 및 공휴일 휴무</span>
						</div>

					</>
					: <>
						<div className='subheader t4'>유의사항</div>
						<div className="notice-line b1">
							<div className='list-header'>ㆍ</div>
							<span>본 예약상품권은 구매 후 ‘방문 일자‘와 ‘시간’ 등을 지정해 예약을 하고 매장을 방문해야 사용하실 수 있습니다.</span>
						</div>
						<div className="notice-line b1">
							<div className='list-header'>ㆍ</div>
							<span>구매자와 예약자 그리고 사용자의 전화번호가 동일해야 사용 가능합니다.</span>
						</div>
						<div className="notice-line b1">
							<div className='list-header'>ㆍ</div>
							<span>본 예약상품권은 제휴사 사정으로 변경 및 중단될 수 있습니다.</span>
						</div>
						<div className="seperator-line"></div>
						<div className='subheader t4'>환불규정</div>
						<div className="notice-line b1">
							<div className='list-header'>ㆍ</div>
							<span>구매 후 14일 초과 시 환불이 불가합니다.</span>
						</div>
						<div className="notice-line b1">
							<div className='list-header'>ㆍ</div>
							<span>해당 예약상품권의 사용 이력이 있는 경우 취소 및 현금으로 환불이 불가합니다.</span>
						</div>
						<div className="notice-line b1">
							<div className='list-header'>ㆍ</div>
							<span>본 예약상품권은 환불 불가 규정에 동의하셔야 구매가 가능합니다.</span>
						</div>
						<div className="notice-line b1">
							<div className='list-header'>ㆍ</div>
							<span>환불 및 관련 문의는 아래 테이블매니저 고객센터로 연락 바랍니다.</span>
						</div>
						<div className="seperator-line"></div>
						<div className='subheader t4'>테이블매니저 고객센터</div>
						<div className="notice-line b1">
							<div className='list-header'>ㆍ</div>
							<span>1644-9997</span>
						</div>
						<div className="notice-line b1">
							<div className='list-header'>ㆍ</div>
							<span><a href='mailto:voucher@tablemanager.team?subject=[예약상품권 환불요청]&body=성함:%0D%0A연락처:%0D%0A구매 브랜드:%0D%0A권종(예: 5만원권,10만원권):%0D%0A구매 장 수:%0D%0A환불 장 수 (부분 취소 불가합니다.):%0D%0A환불 사유:'>voucher@tablemanager.team</a></span>
						</div>
						<div className="notice-line b1">
							<div className='list-header'>ㆍ</div>
							<span>평일ㅣ10:00-19:00 (점심시간 12-13시 제외)<br />토, 일요일 및 공휴일 휴무</span>
						</div>
					</>
			}
		</div >
	)
}
