import React, { useLayoutEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { usePartnerContext } from '../../../context/Common/partner'
import { useMall, useReservation } from '../../../store'
import { Calendar, InfoFooter, PartySizeSelector, ThinSeparator, TimeSelector } from '../../../Components/Booking'
import Seperator from '../../../Components/Share/Seperator'
import useDynamicComponent from '../../../hooks/useDynamicComponent'

export default function Info() {
	const { mallUri } = useParams()
	const [isLoaded, setIsLoaded] = useState(false)
	const { mall, fetchMall, setMall } = useMall()
	const { dateTime, getVoucherData } = useReservation()
	const { apiKey } = usePartnerContext()
	const { renderComponent } = useDynamicComponent()

	useLayoutEffect(() => {
		if (mallUri && apiKey && !mall) {
			fetchMall(apiKey, mallUri)
			setMall('mallUri', mallUri)
		}
	}, [mallUri, apiKey, mall])

	useLayoutEffect(() => getVoucherData(), [])

	return (
		<div className="info-page-container">
			{!isLoaded && (
				<div className="loader-container">
					<ClipLoader />
				</div>
			)}
			<PartySizeSelector isLoaded={isLoaded} />
			{isLoaded &&
				renderComponent({
					gyeongjuro: <Seperator />,
					default: <ThinSeparator />,
				})}
			<Calendar isLoaded={isLoaded} setIsLoaded={setIsLoaded} />
			{dateTime && (
				<>
					<ThinSeparator />
					<TimeSelector />
				</>
			)}
			<InfoFooter />
		</div>
	)
}
