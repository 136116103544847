import React, { useEffect } from 'react'
import { useAccountContext } from '../../context/Common/account'
import { useLoginContext } from '../../context/Common/login'
import { useHistory, useLocation } from 'react-router-dom'
import { AuthAPI } from '../../lib/auth'

const NAVER_CLIENT_ID = process.env.REACT_APP_NAVER_CLIENT_ID
declare var naver

// eslint-disable-next-line no-restricted-globals
const windowLocation = location

const LoginAuth = () => {
	const { user, setUser, authData, setAuthData } = useAccountContext()
	const { loginPath, setIsOpenDrawer } = useLoginContext()
	const reactLocation = useLocation()
	const history = useHistory()
	const authAPI = new AuthAPI()

	const kakaoOauth = () => {
		setIsOpenDrawer(false)
		authAPI.oauthLogin('kakao', authData.access_token)
			.then((res) => {
				if (res && res.code === 'SIGN_UP_REQUIRED') {
					authAPI.oauthRegister('kakao', user, authData)
						.then((response) => {
							setUser(response)
							localStorage.setItem('oauth', JSON.stringify(authData))
						}).catch(error => {
							if (error.response.data)
								alert(error.response.data.message)
							history.replace('/voucher/main')
							throw new Error(error.message)
						})
				} else {
					if (res.code === 'SIGN_IN' && res.name) {
						setUser(res)
						localStorage.setItem('user', JSON.stringify(res))
						localStorage.setItem('oauth', JSON.stringify(authData))
					} else {
						alert(res.message)
					}
				}
				if (loginPath)
					history.push(loginPath)
				else
					history.replace('/voucher/main')
				window.location.reload()
			}).catch(() => {
				history.replace('/voucher/main')
				window.location.reload()
			})
	}

	const naverOauth = () => {
		setIsOpenDrawer(false)
		const naverLogin = new naver.LoginWithNaverId(
			{
				clientId: NAVER_CLIENT_ID,
				callbackUrl: `${windowLocation.origin}/voucher/login/auth`,
				isPopup: false,
				callbackHandle: true
			}
		)
		naverLogin.init()
		naverLogin.getLoginStatus(function (status) {
			if (status) {
				// const id			= naverLogin.user.getId()
				const name			= naverLogin.user.getName()
				const gender		= naverLogin.user.getGender()
				const birthyear	= naverLogin.user.getBirthyear()
				const birthday	= naverLogin.user.getBirthday()
				const email		= naverLogin.user.getEmail()
				const phone		= naverLogin.user.getMobile()

				let isRequire = true
				if (name === undefined || name === null || name === '') {
					alert('이름은 필수 정보입니다. 정보제공을 동의해주세요.')
					isRequire = false
				} else if (email === undefined || email === null || email === '') {
					alert('이메일은 필수 정보입니다. 정보제공을 동의해주세요.')
					isRequire = false
				} else if (phone === undefined || phone === null || phone === '') {
					alert('전화번호는 필수 정보입니다. 정보제공을 동의해주세요.')
					isRequire = false
				}

				if (isRequire === false) {
					naverLogin.reprompt() // 필수정보를 얻지 못 했을 때 다시 정보제공 동의 화면으로 이동
					return
				}

				const authInfo = {}
				reactLocation.hash.replace('#', '').split('&').forEach(value => {
					const [ key, token ] = value.split('=')
					authInfo[key] = token
				})
				authInfo.type = 'naver'
				setAuthData(authInfo)
				localStorage.setItem('oauth', JSON.stringify(authInfo))

				// eslint-disable-next-line no-restricted-globals
				location.hash = ''
				authAPI.oauthLogin('naver', authInfo.access_token)
					.then((res) => {
						if (res && res.code === 'SIGN_UP_REQUIRED') {
							authAPI.oauthRegister('naver', user, authInfo)
								.then((response) => {
									setUser(response)
								}).catch(error => {
									if (error.response.data)
										alert(error.response.data.message)
									history.replace('/voucher/main')
									throw new Error(error.message)
								})
						} else {
							if (res.code === 'SIGN_IN') {
								setUser(res)
								localStorage.setItem('user', JSON.stringify(res))
							} else {
								alert(res.message)
							}
						}
						if (loginPath)
							history.push(loginPath)
						else
							history.replace('/voucher/main')
						window.location.reload()
					}).catch(() => {
						history.replace('/voucher/main')
						window.location.reload()
					})
			} else {
				console.log('callback 처리에 실패하였습니다.')
				history.replace(loginPath || '/voucher/main')
			}
		})
	}

	useEffect(() => {
		if (user) {
			if (loginPath)
				history.push(loginPath)
			else
				history.replace('/voucher/main')
			return
		}

		if (authData && authData.type !== 'naver')
			kakaoOauth()
		else
			naverOauth()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authData])

	return (<></>)
}

export default LoginAuth
