import React from 'react'
import CircleImageItem from '../Item/CircleImageItem/CircleImageItem'
import ImageItem from '../Item/ImageItem/ImageItem'
import './VoucherProductList.scss'

export default function VoucherProductList(props) {
	const { data = [], type, customFunction, favoriteList, tracker } = props

	const isFavoriteProduct = (serviceId) => {
		if (favoriteList && favoriteList.length > 0)
			return favoriteList.some(item => item.service.id === serviceId)

		return false
	}

	return (
		<>
			{
				data.map((value, index) => {
					switch (type) {
					case 'circle':
						return <CircleImageItem key={index} badgeType="SERVICE" item={value} customFunction={customFunction}></CircleImageItem>
					case 'card':
						return <ImageItem key={index} voucher={value} wishIt={customFunction} isFavorite={isFavoriteProduct(value.service.id)} tracker={tracker}></ImageItem>
					default:
						return <ImageItem key={index} voucher={value} wishIt={customFunction} isFavorite={isFavoriteProduct(value.service.id)} tracker={tracker}></ImageItem>
					}
				})
			}
		</>
	)
}
