import React, { useMemo } from 'react'
import { ReactComponent as CheckboxOn } from '../../../assets/icon/checkbox-on.svg'
import { ReactComponent as CheckboxOff } from '../../../assets/icon/checkbox-off.svg'
import { ReactComponent as Arrow } from '../../../assets/icon/terms-arrow.svg'
import { useReservation } from '../../../store'
import { useFetchPolicies } from '../../../hooks'
import PolicyModal from '../../Share/Modal/PolicyModal'
import ModalPortal from '../../Share/Modal/ModalPortal'

const Agreement = () => {
	const { agreement, setReservation } = useReservation()
	const { servicePolicies, loading, openServiceTerm, currentPolicy, policiesList } = useFetchPolicies({
		url: '/service/policies',
		dispatchInfo: ({ value }) =>
			setReservation(
				'agreement',
				value.reduce((acc, cur) => ({ ...acc, [cur]: false }), {}),
			),
	})
	const agreements = useMemo(() => Object.keys(agreement || {}), [agreement])
	const allChecked = useMemo(() => agreements.every((item) => agreement[item]), [agreements])

	const handleAllCheckbox = () => {
		const isAllChecked = Object.values(agreement).every((item) => item)
		setReservation(
			'agreement',
			agreements.reduce((acc, cur) => ({ ...acc, [cur]: !isAllChecked }), {}),
		)
	}

	const handleCheckbox = (value) => setReservation('agreement', { ...agreement, [value]: !agreement[value] })

	const showServiceTerm = (e, policy) => {
		e.preventDefault()
		if (policy.content.KO.contentUrl) {
			window.history.pushState(null, null, '')
			openServiceTerm(policy.type)
		}
	}

	if (loading)
		return null

	return (
		<div className='payment-agreement-container'>
			<h1>서비스 이용 및 약관 동의</h1>
			<label className='all-selector'>
				<span>약관 전체동의</span>
				<input name='약관 전체동의' type='checkbox' checked={allChecked} onChange={handleAllCheckbox} />
				{allChecked
					? <CheckboxOn />
					: <CheckboxOff />}
			</label>
			{policiesList.length
				? policiesList.map((policyType, idx) => {
					const policy = servicePolicies.find((item) => item?.type === policyType)
					return (
						<label key={idx}>
							<div onClick={(e) => showServiceTerm(e, policy)}>
								<span>{policy.content.KO.name + (policy.required
									? ' (필수)'
									: '')}</span>
								{policy.content.KO.contentUrl && <Arrow />}
							</div>
							<input
								name={policy.content.KO.name}
								type='checkbox'
								checked={agreement[policy.type]}
								onChange={() => handleCheckbox(policy.type)}
							/>
							{agreement[policy.type]
								? <CheckboxOn />
								: <CheckboxOff />}
						</label>
					)
				})
				: null}
			<ul>
				<li>이용금액 결제 시 승인 가맹점명이 ‘테이블매니저(주)’로 표기됩니다.</li>
			</ul>
			<ModalPortal>
				<PolicyModal url={currentPolicy} openServiceTerm={openServiceTerm} />
			</ModalPortal>
		</div>
	)
}

export default Agreement
