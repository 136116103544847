import React from 'react'
import './HistoryItem.scss'
import { format } from 'date-fns'
export default function HistroryItem(props) {

	function typeText() {
		if (props.history.type === 'USE')
			return '사용'
		if (props.history.type === 'CHARGE')
			return '충전'
		if (props.history.type === 'EXPIRED')
			return '만료'
		return ''
	}
	return (
		<div className="history-item-container">
			<div className="history-top-box">
				<div className="history-type">
					{props.history.mallName} {typeText()}
				</div>
				{props.history.type === 'USE' || props.history.type === 'EXPIRED' ?
					<div className="history-amount">
						-{props.history.amount.toLocaleString()} 원
					</div> :
					<div className="history-amount plus">
						+{props.history.amount.toLocaleString()} 원
					</div>
				}

			</div>
			<div className="history-time">
				{format(new Date(props.history.createdAt), 'yyyy.MM.dd')}
			</div>
		</div>
	)
}
