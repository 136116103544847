import React, { useLayoutEffect } from 'react'
import './Facility.scss'
import { useMall } from '../../../store'
import { useParams } from 'react-router-dom'
import { FacilityItem } from '../../../Components/Booking'

export default function Facility() {
	const { mallUri } = useParams()
	const { mall, setMall } = useMall()

	useLayoutEffect(() => {
		if (mallUri)
			setMall('mallUri', mallUri)
	}, [mallUri])

	if (!mall)
		return null

	return (
		<div className="facility-page-container">
			{mall.facility.map((item, idx) => <FacilityItem name={item.name} key={idx} className="facility-item"></FacilityItem>)}
		</div>
	)
}
