import React, { useLayoutEffect } from 'react'
import { ClipLoader } from 'react-spinners'
import usePathQuery from '../../../hooks/usePathQuery'
import { usePartnerContext } from '../../../context/Common/partner'
import { PartnerPickupAPI } from '../../../lib/partner'
import { useHistory } from 'react-router-dom'

const Incoming = () => {
	const { apiKey, partnerName, setPartnerInfo } = usePartnerContext()
	const { gyeongjuroLogin } = new PartnerPickupAPI(apiKey, null)
	const query = usePathQuery()
	const history = useHistory()

	useLayoutEffect(() => {
		if (apiKey && partnerName === 'gyeongjuro' && query) {
			gyeongjuroLogin(query).then(
				(res) => {
					const response = res.login
					if (response && response.result) {
						sessionStorage.setItem('authorization', response.authorization)
						sessionStorage.setItem('shopId', response.shopId)
						setPartnerInfo({
							name: response.name,
							phone: response.phone,
						})
						if (query?.redirectUrl)
							history.replace(query.redirectUrl)
						else
							history.replace(`/pickup/${response.mallUri}`)
					}
				})
		}
	}, [apiKey, partnerName])

	return (
		<div className="incoming-page-container">
			<div className="loader-container">
				<ClipLoader />
			</div>
		</div>
	)
}

export default Incoming
