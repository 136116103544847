import React, { useLayoutEffect, useState } from 'react'
import { ReactComponent as Back } from '../../../assets/icon/arrow.svg'
import { ReactComponent as Home } from '../../../assets/icon/home-gyeongju.svg'
import { useHistory, useLocation } from 'react-router-dom'
import { Share } from '../Share'
// import LanguageSelector from './LanguageSelector'

const settingInitialValue = {
	title: '',
	showBack: false,
	showShare: false,
	showOnlyBack: false,
}

const Header = () => {
	const history = useHistory()
	const { pathname } = useLocation()
	const [settings, setSettings] = useState({
		title: '',
		showBack: false,
		showShare: false,
		showOnlyBack: false,
	})

	const changeSettings = (changedSettings) => {
		setSettings({ ...settingInitialValue, ...changedSettings })
	}

	const validateTargetPathName = (regex) => regex.test(pathname)

	useLayoutEffect(() => {
		if (validateTargetPathName(/(booking)\/\S+\/(step)\/(info)/g)) {
			changeSettings({
				showBack: true,
				title: '예약하기',
			})
		} else if (validateTargetPathName(/(booking)\/\S+\/(step)\/(category)/g)) {
			changeSettings({
				showBack: true,
				title: '예약하기',
			})
		} else if (validateTargetPathName(/(booking)\/\S+\/(step)\/(detail-info)/g)) {
			changeSettings({
				showBack: true,
				title: '예약하기',
			})
		} else if (validateTargetPathName(/(booking)\/\S+\/(step)\/(payment)/g)) {
			changeSettings({
				showBack: true,
				title: '예약하기',
			})
		} else if (validateTargetPathName(/(booking)\/\S+\/(step)\/(complete)/g)) {
			changeSettings({
				showBack: true,
				showShare: true,
				title: '예약완료',
			})
		} else if (validateTargetPathName(/(booking)\/(step)\/(complete-view)\/[0-9]{1,}$/g)) {
			changeSettings({
				showBack: true,
				showShare: true,
				title: '예약완료',
			})
		} else if (validateTargetPathName(/(booking)\/detail\/[0-9]+/g)) {
			changeSettings({
				showBack: true,
				showShare: true,
				title: '예약내역'
			})
		}
	}, [pathname])

	return (
		<>
			<header className="header-container">
				{settings.showBack
					? <Back onClick={history.goBack} />
					: null}
				<h1>{settings.title}</h1>
				{/* <LanguageSelector /> */}
				{settings.showShare
					? <Share />
					: <Home onClick={() => window.location.assign('https://gjsmarttour.kr')} />}
			</header>
			<div className="header-container-placeholder" />
		</>
	)
}

export default Header
