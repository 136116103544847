import React from 'react'
import { ReactComponent as KakaoLoginButtonIcon } from '../../../../../assets/icon/soical/kakaoLoginIcon.svg'
import './KakaoLoginButton.scss'

const KakaoLoginButton = (props) => {
	const { onClick } = props

	return (
		<div id="kakao-account-button">
			<KakaoLoginButtonIcon className="icon" onClick={onClick} />
		</div>
	)
}

export default KakaoLoginButton
