import { createContext, useContext, useState, useEffect } from 'react'

const PickupContext = createContext({
	setMall: () => {},
	mall: null,
	setDetailMenu: () => {},
	detailMenu: null,
	dispatchMenuResult: () => {},
	menuResult: null,
	orderSheet: [],
	setOrderSheet: () => {},
	searchOption: {
		service: null,
		policy: null,
		orderDateTime: null,
	},
	setSearchOption: () => {},
	selectedId: null,
	setSelectedId: () => {},
})

const PickupContextProvider = (props) => {
	const [mall, setMall] = useState(null)
	const [detailMenu, setDetailMenu] = useState(null)
	const [orderSheet, setOrderSheet] = useState([])
	const [searchOption, setSearchOption] = useState({})
	const [selectedId, setSelectedId] = useState({})

	const initialValue = {
		setMall,
		mall,
		setDetailMenu,
		detailMenu,
		orderSheet,
		setOrderSheet,
		searchOption,
		setSearchOption,
		selectedId,
		setSelectedId,
	}

	useEffect(() => {
		if (orderSheet && mall) {
			let tmpObject = JSON.parse(sessionStorage.getItem(orderSheet)) || {}
			tmpObject[mall.uri] = orderSheet

			sessionStorage.setItem('orderSheet', JSON.stringify(tmpObject))
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderSheet])

	useEffect(() => {
		if (sessionStorage.getItem('orderSheet') && mall && mall.uri)
			setOrderSheet(JSON.parse(sessionStorage.getItem('orderSheet'))[mall.uri])


	}, [mall])
	return <PickupContext.Provider value={initialValue}>{props.children}</PickupContext.Provider>
}


const usePickupContext = () => useContext(PickupContext)

export { PickupContextProvider, usePickupContext }
