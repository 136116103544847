import React from 'react'
import Subtitle from '../../Share/Subtitle'
import Format from '../../../utils/Format'

export default function UserInfo(props) {
	return (
		<div className="user-info-container">
			<Subtitle subtitle="주문자 정보"></Subtitle>
			<div className="row">
				<div className="label">주문자명</div>
				<div className="value">{props.orderInfo.serviceOrder.user.name}</div>
			</div>
			<div className="row">
				<div className="label">연락처</div>
				<div className="value">{Format.phone(props.orderInfo.serviceOrder.user.phone)}</div>
			</div>
			{props.orderInfo.serviceOrder.request
				? <div className="row">
					<div className="label">요청사항</div>
					<div className="value">{props.orderInfo.serviceOrder.request}</div>
				</div>
				: null}

		</div>
	)
}
