import React from 'react'
import { useHistory } from 'react-router-dom'
import './RecommendItem.scss'
export default function RecommendItem(props) {
	const history = useHistory()

	function moveMall(uri) {
		if (!props.mouseDown) {
			sessionStorage.setItem('moveMallId', uri)
			sessionStorage.setItem('moveScrollY', window.scrollY)
			history.push(`/booking/mall-detail/${uri}`)
		}


	}
	return (
		<div className="recommend-item-container" onClick={() => moveMall(props.mall.mall_uri)}>
			<img className="recommend-item-image" src={props.mall.thumbnail || props.mall.info_images[0].src} draggable="false" alt="추천 매장 이미지"/>
			<div>
				<div className="mall-food b3">{props.mall.mall_food.join('·')}</div>
				<div className="mall-name t5">{props.mall.mall_name}</div>
			</div>
		</div>
	)
}
