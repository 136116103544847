import React from 'react'
import './AccountInfo.scss'

export default function AccountInfo(props) {
	return (
		<div className="account-info-container">
			<div className="box">
				<div className="label">이름</div>
				<div className="value">{props.name}</div>
			</div>
			<div className="box">
				<div className="label">번호</div>
				<div className="value">{props.phone}</div>
			</div>
			{props.email
				? <div className="box">
					<div className="label">이메일</div>
					<div className="value">{props.email}</div>
				</div>
				: null}
		</div>
	)
}
