import React from 'react'
import Map from '../../Map'
import BookingSubtitle from '../BookingSubtitle/BookingSubtitle'
import { ReactComponent as Call } from '../../../../assets/icon/call.svg'
import { usePartnerContext } from '../../../../context/Common/partner'
import { callPhoneNumberInSkt } from '../../../../utils'
import { useMall } from '../../../../store'
export default function Address() {
	const { location_lat: lat, location_lng: lng, location_address: address, mall_phone:phone } = useMall(state => state.mall)
	const { partnerName } = usePartnerContext()

	return (
		<div className="address-container">
			<BookingSubtitle subtitle="주소"></BookingSubtitle>
			{typeof kakao === 'undefined' ?
				null :
				<Map lat={lat} lng={lng}></Map>}
			<div className="address-box">
				<div className="street-address">
					<div className="text">{address.road} {address.detail}</div>
				</div>
				<div className="domicile-address">
					<div className="text">지번 : {address.address}</div>
				</div>
				<div className="address-phone-container">
					<div className="icon-container">
						<Call className="call-icon" />
					</div>
					<div className="phone b1">
						{partnerName === 'skt'
							? (
								<div className="tel" onClick={() => callPhoneNumberInSkt(phone)}>
									<div className="phone-number">{phone}</div>
								</div>
							)
							: (
								<a href={`tel:${phone}`} className="tel">
									<div className="phone-number">{phone}</div>
								</a>
							)}
					</div>
				</div>
			</div>
		</div>
	)
}
