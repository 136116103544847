import React from 'react'
import BookingSubtitle from '../../../Share/Booking/BookingSubtitle/BookingSubtitle'
import { useMall, useReservation } from '../../../../store'
import Format from '../../../../utils/Format'
import useDynamicComponent from '../../../../hooks/useDynamicComponent'

export default function ReservationPreview() {
	const { mall } = useMall()
	const { totalPartySize, dateTime, name, phone, request, getPartySizeString } = useReservation()
	const { renderComponent } = useDynamicComponent()

	if (!mall || !dateTime)
		return null

	return (
		<div className="reservation-preview-container">
			<BookingSubtitle subtitle="예약내용 확인"></BookingSubtitle>
			{renderComponent({
				default: (
					<>
						<div className="info-box">
							<div className="label b1">매장명</div>
							<div className="value b1">{mall.mall_name}</div>
						</div>
						<div className="info-box">
							<div className="label b1">예약일시</div>
							<div className="value b1">{dateTime.format('YYYY년 M월 D일 LT')}</div>
						</div>
						<div className="info-box">
							<div className="label b1">예약인원</div>
							<div className="value b1">
								{totalPartySize}명&nbsp;
								<span>({getPartySizeString()})</span>
							</div>
						</div>
						<div className="info-box">
							<div className="label b1">예약자</div>
							<div className="value b1">{name}</div>
						</div>
						<div className="info-box">
							<div className="label b1">연락처</div>
							<div className="value b1">{Format.blindPhone(phone)}</div>
						</div>
						<div className="info-box">
							<div className="label b1">요청사항</div>
							<div className="value b1">{request || '없음'}</div>
						</div>
					</>
				),
				gyeongjuro: (
					<>
						<li className="info-box">
							<div className="label b1">매장명</div>
							<div className="value b1">{mall.mall_name}</div>
						</li>
						<li className="info-box">
							<div className="label b1">예약일시</div>
							<div className="value b1">{dateTime.format('YYYY년 M월 D일 LT')}</div>
						</li>
						<li className="info-box">
							<div className="label b1">예약인원</div>
							<div className="value b1">
								{totalPartySize}명&nbsp;
								<span>({getPartySizeString()})</span>
							</div>
						</li>
						<li className="info-box">
							<div className="label b1">예약자</div>
							<div className="value b1">{name}</div>
						</li>
						<li className="info-box">
							<div className="label b1">연락처</div>
							<div className="value b1">{Format.blindPhone(phone)}</div>
						</li>
						<li className="info-box">
							<div className="label b1">요청사항</div>
							<div className="value b1">{request || '없음'}</div>
						</li>
					</>
				)
			})}
		</div>
	)
}
