import React from 'react'
import { useMyPageContext } from '../../../../context/Booking/myPage'
import { ReactComponent as Arrow } from '../../../../assets/icon/arrowRightGray.svg'
import './VoucherList.scss'
import { useHistory } from 'react-router-dom'
import VoucherItem from '../VoucherItem/VoucherItem'
import { VOUCHER_TYPE } from '../../../../constants/voucherType'
export default function VoucherList() {
	const { voucherList } = useMyPageContext()
	const history = useHistory()
	const voucherLength =
		voucherList.filter((item) => item.products[0].voucherType === VOUCHER_TYPE.TICKET).reduce((acc, cur) => acc + cur.products.length, 0) +
		voucherList.filter((item) => item.products[0].voucherType === VOUCHER_TYPE.POINT).length
	let renderCount = 0

	function expandScheduled() {
		history.push('/booking/list/expand/voucher')
	}

	return (
		<div className="voucher-list-container" id="purchase-list">
			<div className="voucher-list-content">
				<div className="title">
					<span className="t3">예약상품권 목록</span>
					{voucherLength > 5
						? (
							<div className="expand b3" onClick={expandScheduled}>
								<span>전체보기</span>
								<Arrow className="icon" />
							</div>
						)
						: null}
				</div>
				{voucherList.slice(0, 5).map((item, idx) => {
					if (item.products[0].voucherType === VOUCHER_TYPE.TICKET) {
						// Voucher/MyPage.js Line:156 참조
						return item.products.map((ticketItem, ticketIdx) => {
							++renderCount
							return renderCount > 5
								? (
									<React.Fragment key={'not-render-ticket' + ticketIdx} />
								)
								: (
									<VoucherItem voucher={item} ticket={ticketItem} key={`${item.inquiryId}-${ticketIdx}`} />
								)
						})
					}
					++renderCount
					return renderCount > 5
						? <React.Fragment key={'not-render-voucher' + idx} />
						: <VoucherItem voucher={item} key={`${item.itemId}-${idx}`}></VoucherItem>
				})}
			</div>
		</div>
	)
}
