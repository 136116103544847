import { createContext, useContext, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import VoucherSelectionModal from '../../Components/Voucher/MyPage/VoucherSelectionModal/VoucherSelectionModal'

const VoucherContext = createContext({
	voucherProducts: null,
	setVoucherProducts: () => { },
	referralConfig: null,
	setReferralConfig: () => { },
	voucherMainScrollY: null,
	changeVoucherMainScrollY: () => { },
	openVoucherMallSelectionModal: () => { },
	closeVoucherMallSelectionModal: () => { },

})
const useVoucherContext = () => useContext(VoucherContext)

const VoucherContextProvider = (props) => {
	const location = useLocation()
	const [voucherProducts, setVoucherProducts] = useState([])
	const [voucherMalls, setVoucherMalls] = useState([])
	const [voucherMainScrollY, setVoucherMainScrollY] = useState(0)
	const [voucherModal, setVoucherModal] = useState(false)
	const [voucherPointId, setVoucherPointId] = useState('')
	const [voucherOrderItemId, setVoucherOrderItemId] = useState(null)
	const [productId, setProductId] = useState(null)
	const [referralConfig, setReferralConfig] = useState(JSON.parse(sessionStorage.getItem('referralConfig')) || {})
	const initialValue = {
		voucherProducts,
		setVoucherProducts,
		referralConfig,
		setReferralConfig,
		voucherMainScrollY,
		changeVoucherMainScrollY,
		openVoucherMallSelectionModal,
		closeVoucherMallSelectionModal
	}

	function changeVoucherMainScrollY(scrollY) {
		localStorage.setItem('scrollY', scrollY.toString())
		setVoucherMainScrollY(scrollY)
	}

	useEffect(() => {
		if (!voucherMainScrollY && location.pathname !== '/') {
			const scrollY = localStorage.getItem('scrollY')
			changeVoucherMainScrollY(
				scrollY
					? parseInt(scrollY)
					: voucherMainScrollY
			)
		}
	}, [])

	useEffect(() => {
		if (referralConfig)
			sessionStorage.setItem('referralConfig', JSON.stringify(referralConfig))
	}, [referralConfig])

	function closeVoucherMallSelectionModal() {
		setVoucherModal(false)
		document.body.classList.remove('modal-open')
	}

	// eslint-disable-next-line max-params
	function openVoucherMallSelectionModal(pointId, malls, ticketItemId, ticketId) {
		setVoucherModal(true)
		setVoucherMalls(malls)
		setVoucherPointId(pointId)
		setVoucherOrderItemId(ticketItemId)
		setProductId(ticketId)
		document.body.classList.add('modal-open')
	}

	return (
		<VoucherContext.Provider value={initialValue}>
			<VoucherSelectionModal open={voucherModal} onClose={closeVoucherMallSelectionModal} voucherPointId={voucherPointId} malls={voucherMalls} voucherOrderItemId={voucherOrderItemId} productId={productId}></VoucherSelectionModal>
			{props.children}
		</VoucherContext.Provider>
	)
}


export { VoucherContextProvider, useVoucherContext }
