import { useEffect, useMemo, useState } from 'react'
import { usePaymentContext } from '../../../context/Pickup/payment'
import { usePickupContext } from '../../../context/Pickup/pickup'
import { UserAPI } from '../../../lib/api'
import { useHistory } from 'react-router-dom'
import { openPaymentModal } from '../../../lib/nicepay'
import ActionButton from '../../Share/ActionButton'
import { sendPolicy } from '../../../lib/voucher'
import { useAccountContext } from '../../../context/Common/account'
import useDynamicComponent from '../../../hooks/useDynamicComponent'
import { ActionButton as GyeongjuroAcitonButton } from '../../Gyeongju/ActionButton'
import { usePartnerContext } from '../../../context/Common/partner'

const PaymentButton = () => {
	const [orderId, setOrderId] = useState()
	const [amount, setAmount] = useState(0)
	const [isLoading, setIsLoading] = useState(false)
	const [paymentAmount, setPaymentAmount] = useState(0)
	const { paymentInfo } = usePaymentContext()
	const { mall, searchOption, orderSheet, setOrderSheet, setSearchOption } = usePickupContext()
	const { user } = useAccountContext()
	const { renderComponent } = useDynamicComponent()
	const { apiKey } = usePartnerContext()
	const history = useHistory()
	const userAPI = useMemo(() => new UserAPI(paymentInfo.authorization, apiKey), [paymentInfo.authorization, apiKey])
	const requiredAgreements = Object.entries(paymentInfo.agreement).map(([key, value]) => {
		return (
			{
				name: key,
				value: value
			})
	}).filter((item) => {
		const softCheckArr = ['MARKETING', 'LATE_NIGHT_MARKETING', 'KEEP_ACTIVE_EVEN_DORMANT', 'saveCard']
		return !softCheckArr.includes(item.name)
	}).find((x) => x.value === false)

	const paymentValid = () => {
		if (orderSheet.length === 0) {
			alert('메뉴는 한 개 이상 선택해야 합니다.')
			return false
		}
		if (paymentInfo.user.name.length === 0) {
			alert('이름을 입력해주세요.')
			return false
		}
		if (paymentInfo.authorization === null) {
			alert('번호 인증을 진행해주세요.')
			return false
		}
		if (paymentInfo.billingKeyContext.cardNumbers.filter((cn) => cn.length === 0).length > 0) {
			alert('카드번호를 입력해주세요.')
			return false
		}
		if (paymentInfo.billingKeyContext.expiry.year.length !== 4 || paymentInfo.billingKeyContext.expiry.month.length !== 2) {
			alert('유효기간을 선택해주세요.')
			return false
		}
		if (paymentInfo.billingKeyContext.type === 'INDIVIDUAL') {
			if (paymentInfo.billingKeyContext.identifyCode.length !== 6) {
				alert('생년월일을 입력해주세요.')
				return false
			}
			if (paymentInfo.billingKeyContext.password.length !== 2) {
				alert('카드 비밀번호를 입력해주세요.')
				return false
			}
		}
		if (paymentInfo.billingKeyContext.type === 'CORPORATION' && paymentInfo.billingKeyContext.identifyCode.length !== 10) {
			alert('사업자 등록번호를 입력해주세요.')
			return false
		}
		return true
	}

	const action = () => {
		if (paymentAmount > 0 && !paymentValid())
			return

		if (isLoading)
			return

		setIsLoading(true)
		userAPI.createOrder(mall.uri, searchOption.service._id, {
			selectedMenus: orderSheet.map((os) => ({
				menuId: os.menuId,
				count: os.count,
				optionGroups: os.optionGroups.map((og) => ({
					optionGroupId: og.optionGroupId,
					options: og.options.map((opt) => ({
						optionId: opt.optionId,
						count: opt.count
					}))
				}))
			})),
			reserveDatetime: searchOption.orderDateTime,
			request: paymentInfo.request,
			userName: paymentInfo.user.name
		}, paymentInfo.authorization)
			.then((res) => {
				setAmount(res.amount)
				setOrderId(res.orderId)
				const policyArr = Object.entries(paymentInfo.agreement).map(([key, value]) => {
					return {
						servicePolicyType: key,
						agreement: value
					}
				})
				sendPolicy({
					user: {
						phone: paymentInfo.user.phone,
						email: paymentInfo.user.email
					},
					termsAgreements: policyArr,
					userToken: user.token
				})
			})
			.catch((err) => {
				setIsLoading(false)
				console.log(err)
				// alert(err.response.data.message)
			})
	}

	useEffect(() => {
		const returnUrl = `/pickup/${mall.uri}/complete/${orderId}`

		if (orderId && amount === 0) {
			setIsLoading(false)
			setOrderSheet([])
			setSearchOption({ service: null, policy: null, orderDateTime: null })
			history.replace(returnUrl)
			return
		}
		if (orderId === undefined || amount === 0)
			return

		if (paymentInfo.paymentType === 'BILLING_KEY') {
			userAPI.processPayment(orderId, paymentInfo.billingKeyContext, paymentInfo.user.email).then(() => {
				setIsLoading(false)
				setOrderSheet([])
				setSearchOption({ service: null, policy: null, orderDateTime: null })
				history.replace(returnUrl)
			}).catch((err) => {
				alert(err.response.data.message)
				setIsLoading(false)
			})
		}

		if (paymentInfo.paymentType === 'PG') {
			openPaymentModal({
				amount: 1000,
				orderId,
				user: paymentInfo.user,
				returnUrl,
			}).then(console.log)
		}
	}, [orderId, amount])

	useEffect(() => {
		let tmpAmount = orderSheet.reduce((prev, next) => {
			return prev + (next.totalPrice * next.count)
		}, 0)
		setPaymentAmount(tmpAmount)
	}, [orderSheet])

	return (
		<>
			<div className="payment-action-container">
				{
					renderComponent({
						default: (
							<ActionButton
								label={paymentAmount > 0 ?
									`${paymentAmount.toLocaleString()}원 결제하기`
									: '주문하기'}
								onClick={action}
								className="action-button"
								disabled={requiredAgreements
									? true
									: false}
							/>
						),
						gyeongjuro: (
							<GyeongjuroAcitonButton
								label={paymentAmount > 0 ?
									`${paymentAmount.toLocaleString()}원 결제하기`
									: '주문하기'}
								onClick={action}
								className="gyeongjuro-action-button"
								disabled={requiredAgreements
									? true
									: false}
							/>
						)
					})
				}
			</div>
			<div className="payment-action-container-placeholder"></div>
		</>
	)
}

export default PaymentButton
