import React from 'react'
import PrivateTerms from '../../Components/Share/PrivateTerms/PrivateTerms'
import ServiceTerms from '../../Components/Share/ServiceTerms/ServiceTerms'
import { useParams } from 'react-router-dom'
import ThirdPartyTerms from '../../Components/Share/ThirdPartyTerms/ThirdPartyTerms'

export default function Terms() {
	const params = useParams()
	return (
		<div>
			{ params.type === 'private'
				? <PrivateTerms closeTerms={() => null}></PrivateTerms>
				: null }
			{ params.type === 'service'
				? <ServiceTerms closeTerms={() => null}></ServiceTerms>
				: null }
			{ params.type === 'thirdparty'
				? <ThirdPartyTerms closeTerms={() => null} />
				: null
			}
		</div>
	)
}
