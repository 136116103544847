import { format } from 'date-fns'
import { createContext, useContext, useReducer } from 'react'

const initialPaymentContext = {
	paymentType: 'BILLING_KEY', // PG | BILLING_KEY
	billingKeyContext: {
		type: 'INDIVIDUAL', // INDIVIDUAL | CORPORATION
		cardNumbers: [
			'',
			'',
			'',
			'',
		],
		expiry: {
			year: '20' + format(new Date(), 'yy'),
			month: '01'
		},
		identifyCode: '',
		password: ''
	},
	user: {
		name: '',
		phone: '',
		email: ''
	},
	request: '',
	agreement: {
		SERVICE: false,
		PRIVACY: false,
		PRIVACY_FOR_THIRD_PARTY: false,
		MARKETING: false,
		KEEP_ACTIVE_EVEN_DORMANT: false,
		LATE_NIGHT_MARKETING: false
	},
	authorization: null
}

const PaymentContext = createContext({
	paymentInfo: initialPaymentContext,
	dispatchPaymentInfo: () => {},
})

const usePaymentContext = () => useContext(PaymentContext)

const paymentInfoReducer = (state, action) => {
	const { value, index } = action

	switch (action.type) {
	case 'CHANGE_PAYMENT_TYPE':
		return { ...state, paymentType: value }
	case 'CHANGE_CARD_TYPE':
		if (state.paymentType !== 'BILLING_KEY')
			throw new Error('invalid input')
		return { ...state, billingKeyContext: { ...state.billingKeyContext, type: value, identifyCode: '' } }
	case 'CHANGE_CARD_NUMBER': {
		const cardNumbers = state.billingKeyContext.cardNumbers
		cardNumbers[index] = value

		return {
			...state, billingKeyContext: {
				...state.billingKeyContext,
				cardNumbers
			}
		}
	}
	case 'USER_NAME' :
		return { ...state, user: { ...state.user, name: value } }
	case 'USER_PHONE' :
		return { ...state, user: { ...state.user, phone: value } }
	case 'USER_EMAIL' :
		return { ...state, user: { ...state.user, email: value } }
	case 'REQUEST' :
		return { ...state, request: value }
	case 'SELECT_EXPIRY_MONTH':
		return { ...state, billingKeyContext: { ...state.billingKeyContext, expiry: { ...state.billingKeyContext.expiry, month: value } } }
	case 'SELECT_EXPIRY_YEAR':
		return { ...state, billingKeyContext: { ...state.billingKeyContext, expiry: { ...state.billingKeyContext.expiry, year: value } } }
	case 'CHANGE_IDENTIFY_CODE':
		return { ...state, billingKeyContext: { ...state.billingKeyContext, identifyCode: value } }
	case 'CHANGE_PASSWORD':
		return { ...state, billingKeyContext: { ...state.billingKeyContext, password: value } }
	case 'TOGGLE_AGREEMENT':
		const newAgreement = state.agreement
		newAgreement[value] = !state.agreement[value]

		return { ...state, agreement: newAgreement }
	case 'TOGGLE_AGREEMENT_ALL':
		const allAgreement = { ...state.agreement }
		value.forEach(v => {
			allAgreement[v] = !state.agreement[v]
		})
		return { ...state, agreement: allAgreement }
	case 'SET_AUTHORIZATION':
		console.log('SET_AUTHORIZATION', value)
		return { ...state, authorization: value }
	default:
		return state
	}
}

const PaymentContextProvider = (props) => {
	const { children } = props

	const [paymentInfo, dispatchPaymentInfo] = useReducer(paymentInfoReducer, initialPaymentContext)

	const initialValue = {
		paymentInfo,
		dispatchPaymentInfo,
	}

	return <PaymentContext.Provider value={initialValue}>{children}</PaymentContext.Provider>
}

export { PaymentContextProvider, usePaymentContext }
