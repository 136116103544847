import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { usePickupContext } from '../../../context/Pickup/pickup'
import './MenuItem.scss'
import { getImageLink } from '../../../utils'

function MenuItem(props) {
	const history = useHistory()
	const params = useParams()
	const { searchOption } = usePickupContext()
	const handleClick = () => history.push(`/pickup/${params.mallUri}/${searchOption.policy}/menu-detail/${props.menu._id}`)
	return (
		<div className="gyeongjuro-menu-item-container" onClick={handleClick}>
			<div className="image-container">
				{props.menu.imageId
					? <img src={getImageLink(props.menu.imageId, 208, 208)} alt="메뉴 이미지"/>
					: null}
			</div>
			<div className="description-container">
				<div className="menu-name">{props.menu.name}</div>
				<div className="menu-description">{props.menu.description}</div>
				<div className="menu-price">{props.menu.price.toLocaleString()}원</div>
			</div>
		</div>
	)
}

export default MenuItem
