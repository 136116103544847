import React from 'react'
import './EmptyInfo.scss'
export default function EmptyInfo() {
	return (
		<div className="empty-info-container">
			<div className="empty-text t4">
				예약 내역이 없습니다.
			</div>
		</div>
	)
}
