import React from 'react'
import BookingSubtitle from '../../../Share/Booking/BookingSubtitle/BookingSubtitle'
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import PaymentForm from '../PaymentForm/PaymentForm'
import { ReactComponent as ActiveRadio } from '../../../../assets/icon/activeRadio.svg'
import { ReactComponent as DeactiveRadio } from '../../../../assets/icon/deactiveRadio.svg'
import { useReservation } from '../../../../store'

export default function PaymentType() {
	const { payment, setReservation } = useReservation()

	return (
		<div className="payment-container">
			<BookingSubtitle subtitle="결제 수단"></BookingSubtitle>
			<div className="payment-radio-group">
				<RadioGroup
					value={payment.type}
					onChange={(e) => setReservation('payment', { ...payment, type: e.target.value })}
				>
					<div className="radio-box">
						<FormControlLabel
							className="t4"
							name="카드정보 입력"
							value="BILLING_KEY"
							control={
								<Radio
									checkedIcon={<ActiveRadio className="icon active" />}
									icon={<DeactiveRadio className="icon" />}
								/>
							}
							label="카드정보 입력"
						/>
					</div>
					{payment.type === 'BILLING_KEY'
						? <PaymentForm key="paymentForm"></PaymentForm>
						: null}
				</RadioGroup>
			</div>
		</div>
	)
}
