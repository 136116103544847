import React from 'react'
import './PaymentInfo.scss'

export default function PaymentInfo(props) {
	return (
		<div className="payment-info-container">
			<div className="total">
				<div className="label">총 주문금액</div>
				<div className="value">{props.orderInfo.serviceOrder.deposit.amount.toLocaleString()}원</div>
			</div>
			<div className="paid">
				<div className="label">결제금액</div>
				<div className="value">{props.orderInfo.serviceOrder.deposit.amount.toLocaleString()}원</div>
			</div>
			{/* <div className="type">
        <div className="label">결제방식</div>
        <div className="value">신용카드 결제</div>
      </div> */}
		</div>
	)
}
