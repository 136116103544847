import React from 'react'
import { FormControl, InputLabel } from '@material-ui/core'
import Select from '@material-ui/core/NativeSelect'
import './OutlineSelect.scss'

// const useStyles = makeStyles((theme) => ({
// 	label: () => ({
// 		'& .MuiFormLabel-asterisk': {
// 			lineHeight: '23px',
// 			color: 'var(--voucher-accent-color)'
// 		}
// 	}),
// 	select: () => ({ '& .MuiNativeSelect-select': { padding: '14.5px 16px' } }),
// }))

export default function OutlineSelect(props) {
	const {
		name,
		onChange,
		placeholder,
		value = '',
		label = '',
		state = 'default',
		menus = [],
		required = false,
	} = props

	return (
		<div className="native-select-container">
			{
				label
					? <InputLabel className="label" required={required} htmlFor="component-outlined-select">{label}</InputLabel>
					: ''
			}

			<FormControl className={`${state}`} variant="outlined">
				<Select
					native="true"
					variant="outlined"
					inputProps={{
						id: 'component-outlined-select',
						className: value
							? 'selected'
							: 'not-selected',
						name,
					}}
					value={value}
					onChange={onChange}
				>
					<option value="">{placeholder}</option>
					{menus.map((text, index) => <option value={text} key={index}>{text}</option>)}
				</Select>
			</FormControl>
		</div>
	)
}
