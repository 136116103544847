import React, { useMemo } from 'react'
import './Agreement.scss'
import { usePaymentContext } from '../../../context/Pickup/payment'
import AgreeCheckbox from '../../Share/AgreeCheckbox/AgreeCheckbox'
import { useFetchPolicies } from '../../../hooks'
import { usePartnerContext } from '../../../context/Common/partner'
import ModalPortal from '../../Share/Modal/ModalPortal'
import PolicyModal from '../../Share/Modal/PolicyModal'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { ReactComponent as AllActiveCheckbox } from '../../../assets/icon/allActiveCheckbox.svg'
import { ReactComponent as AllDeactiveCheckbox } from '../../../assets/icon/allDeactiveCheckbox.svg'
import { ReactComponent as AgreeIconGyeongju } from '../../../assets/icon/agreeIconGyeongju.svg'
import { ReactComponent as UnAgreeIconGyeongju } from '../../../assets/icon/unAgreeIconGyeongju.svg'
import { ReactComponent as CheckedIcon } from '../../../assets/icon/checkedIconGyeongju.svg'
import { ReactComponent as UnCheckedIcon } from '../../../assets/icon/unCheckedIconGyeongju.svg'
import useDynamicComponent from '../../../hooks/useDynamicComponent'

export default function Agreement() {
	const { paymentInfo, dispatchPaymentInfo } = usePaymentContext()
	const { partnerName } = usePartnerContext()
	const { renderComponent } = useDynamicComponent()
	const requiredPolicies = ['SERVICE', 'PRIVACY', 'PRIVACY_FOR_THIRD_PARTY', 'MARKETING', 'KEEP_ACTIVE_EVEN_DORMANT', 'LATE_NIGHT_MARKETING']
	const { servicePolicies, loading, openServiceTerm, currentPolicy, policiesList } = useFetchPolicies({ url: '/service/policies', requiredPolicies: requiredPolicies })


	const changeAllCheckbox = (() => {
		let agreementNameList = [...policiesList]
		if (getAgreements.find(agreement => agreement === 'saveCard'))
			agreementNameList = agreementNameList.concat('saveCard')
		if (agreementNameList.every(agreementName => paymentInfo.agreement[agreementName]))
			dispatchPaymentInfo({ type: 'TOGGLE_AGREEMENT_ALL', value: agreementNameList })
		else
			dispatchPaymentInfo({ type: 'TOGGLE_AGREEMENT_ALL', value: getAgreements.filter(agreement => !paymentInfo.agreement[agreement]) })
	})

	const getAgreements = useMemo(() => {
		const requiredAgreements = Object.keys(paymentInfo.agreement)
		if (partnerName === 'tablemanager')
			return requiredAgreements
		return requiredAgreements.filter(item => item !== 'saveCard')
	}, [paymentInfo.agreement])

	const showServiceTerm = (type) => {
		window.history.pushState(null, null, '')
		openServiceTerm(type)
	}


	return (
		<div className="agreement-container">
			{
				renderComponent({
					default: (
						<FormControlLabel
							control={
								<Checkbox
									color="primary"
									checked={getAgreements.every(agreement => paymentInfo.agreement[agreement])}
									onChange={changeAllCheckbox}
									className="all-check"
								/>
							}
							label="전체 동의"
							className="checkbox-label checkbox-all"
						/>
					),
					gyeongjuro: (
						<>
							<FormControlLabel
								control={
									<Checkbox
										color="primary"
										checked={getAgreements.every(agreement => paymentInfo.agreement[agreement])}
										onChange={changeAllCheckbox}
										className="all-check"
										icon={<UnCheckedIcon className="icon" />}
										checkedIcon={<CheckedIcon className="icon" />}
									/>
								}
								label="전체 동의"
								className="checkbox-label checkbox-all"
							/>
							<div className="agreement-seperator"></div>
						</>
					)
				})
			}

			{!loading && policiesList.length
				? policiesList.map((policyType) => {
					const policy = servicePolicies.find((item) => item?.type === policyType)
					return renderComponent({
						default: (
							<AgreeCheckbox
								key={policyType}
								value={paymentInfo.agreement[policy.type]}
								setValue={() => dispatchPaymentInfo({ type: 'TOGGLE_AGREEMENT', value: policy.type })}
								required={policy.required}
								desc={policy.content.KO.name}
								expand={policy.content.KO.contentUrl}
								expandFunction={() => showServiceTerm(policy.type)}
							/>),
						gyeongjuro: (
							<AgreeCheckbox
								key={policyType}
								value={paymentInfo.agreement[policy.type]}
								setValue={() => dispatchPaymentInfo({ type: 'TOGGLE_AGREEMENT', value: policy.type })}
								required={policy.required}
								desc={policy.content.KO.name}
								expand={policy.content.KO.contentUrl}
								expandFunction={() => showServiceTerm(policy.type)}
								icon={<UnAgreeIconGyeongju className="icon" />}
								checkedIcon={<AgreeIconGyeongju className="icon" />}
							/>
						)
					})
				})
				: null}
			<ModalPortal>
				<PolicyModal url={currentPolicy} openServiceTerm={openServiceTerm} />
			</ModalPortal>
		</div>
	)
}
