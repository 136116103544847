import React from 'react'
import './MenuPrice.scss'

export default function MenuPrice(props) {
	return (
		<div className="menu-price-container">
			<div className="label">가격</div>
			<div className="value">{props.menuPrice.toLocaleString()}원</div>
		</div>
	)
}
