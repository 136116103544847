import React from 'react'
import classNames from 'classnames'
import './PickupSubtitle.scss'

export default function PickupSubtitle(props) {
	return (
		<div className='pickup-subtitle-container'>
			{props.subtitle}
		</div>
	)
}
