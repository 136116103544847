import React from 'react'
import './HistoryInfo.scss'

export default function HistoryInfo() {
	return (
		<div className="history-info-container">
      최근 3개월 이용내역만 조회 가능합니다.
		</div>
	)
}
