import React from 'react'
import './MallTitle.scss'

export default function MallTitle(props) {

	function makeLocation() {
		let location = props.mall.location
		if (location)
			return location.split(' ')[0] + location.split(' ')[1]

		return ''
	}

	return (
		<div className="title-container">
			<div className="title">
				{props.mall.name}
			</div>
			<div className="description">
				{makeLocation()} | {props.mall.category}
			</div>
		</div>
	)
}

