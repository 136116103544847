import { createContext, useContext, useState, useEffect } from 'react'

const HomeContext = createContext({
	setSelectedRegion: () => {},
	selectedRegion: null,
	banners: null,
	setBanners : () => {},
	malls: null,
	setMalls : () => {},
	regions: null,
	setRegions : () => {},
	recommendMalls: null,
	setRecommendMalls : () => {},
	regionMalls: null,
	brands: null,
	setBrands: () => {}
})

const HomeContextProvider = (props) => {
	const { malls, children, propBanners, propRecommendMalls, propRegions, propBrands } = props
	const [selectedRegion, setSelectedRegion] = useState(sessionStorage.getItem('selectedRegion') || '모든지역')
	const [banners, setBanners] = useState(null)
	const [regions, setRegions] = useState(null)
	const [recommendMalls, setRecommendMalls] = useState(null)
	const [regionMalls, setRegionMalls] = useState([])
	const [brands, setBrands] = useState([])
	const initialValue = {
		regions,
		setRegions,
		recommendMalls,
		setRecommendMalls,
		regionMalls,
		setRegionMalls,
		selectedRegion,
		setSelectedRegion,
		banners,
		setBanners,
		brands,
		setBrands
	}

	useEffect(() => {
		if (malls) {
			if (selectedRegion === '모든지역') {
				setRegionMalls(malls)
			} else {
				let filteredMalls = malls.filter(mall => mall.mall_group['지역_시'] === selectedRegion)
				setRegionMalls(filteredMalls)
			}
		}
		if (selectedRegion)
			sessionStorage.setItem('selectedRegion', selectedRegion)


	}, [selectedRegion, malls])

	useEffect(() => {
		setBanners(propBanners)
		setRegions(propRegions)
		setRecommendMalls(propRecommendMalls)
		setBrands(propBrands)
	}, [propBanners, propRegions, propRecommendMalls, propBrands])

	return <HomeContext.Provider value={initialValue}>{props.children}</HomeContext.Provider>
}


const useHomeContext = () => useContext(HomeContext)

export { HomeContextProvider, useHomeContext }
