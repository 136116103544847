import React from 'react'
import { usePickupContext } from '../../../context/Pickup/pickup'
import './OrderSheetHeader.scss'

export default function OrderSheetHeader(props) {

	const { setOrderSheet } = usePickupContext()

	function clearOrderSheet() {
		setOrderSheet([])
	}

	return (
		<div className="order-sheet-header-container">
			<div className="mall-name">{props.name}</div>
			<div className="clear-button" onClick={clearOrderSheet}>메뉴 전체삭제</div>
		</div>
	)
}
