import { Button } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Seperator from '../../../Components/Share/Seperator'
import { useVoucherContext } from '../../../context/Common/voucher'
import { usePartnerContext } from '../../../context/Common/partner'
import { useAccountContext } from '../../../context/Common/account'
import { getVoucherInfo } from '../../../lib/voucher'
import ProductInfo from '../../../Components/Voucher/Complete/ProductInfo/ProductInfo'
import UserInfo from '../../../Components/Voucher/Complete/UserInfo/UserInfo'
import './Complete.scss'

export default function Complete() {
	const history = useHistory()
	const search = useLocation().search
	const status = new URLSearchParams(search).get('status')
	const orderId = new URLSearchParams(search).get('orderId')
	const message = new URLSearchParams(search).get('message')
	const { setBuyer, setPurchaseList } = useVoucherContext()
	const { partnerName } = usePartnerContext()
	const { user } = useAccountContext()
	window.history.pushState(null, null, window.location.href)
	const popstateEvent = (event) => {
		window.history.go(1)
	}

	useEffect(() => {
		window.addEventListener('popstate', popstateEvent)
		return (() => {
			window.removeEventListener('popstate', popstateEvent)
		})
	}, [])
	useEffect(() => {
		if (status === 'success') {
			getVoucherInfo(orderId).then(res => {
				setBuyer(res.user)
				setPurchaseList(res.products)
			})
		} else {
			alert(message || '잘못된 접근입니다.')
			history.go(-2)
		}
	}, [status, orderId])

	function moveReturn() {
		if (partnerName === 'tablemanager' && !user)
			history.push('/voucher/main')
		else
			history.push('/booking/list')
	}
	if (status !== 'success')
		return null
	return (
		<div className="voucher-complete-container">
			<ProductInfo></ProductInfo>
			<Seperator></Seperator>
			<UserInfo></UserInfo>

			<Button variant="contained" color="primary" className="mypage-button t4" onClick={moveReturn}>예약상품권 구매 목록 보러가기</Button>
		</div>
	)
}
