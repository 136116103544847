import React, { useEffect, useState, useCallback } from 'react'
import { ReactComponent as Close } from '../../../assets/icon/close.svg'

import './potalModal.scss'

function PolicyModal(props) {
	const [modalState, setModalState] = useState(false)
	const [isMobile, setIsMobile] = useState(false)
	const resizeCalc = useCallback(() => {
		if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent))
			setIsMobile(true)
	}, [])

	const popstateEvent = () => {
		props.openServiceTerm('')
		setModalState(false)
	}

	useEffect(() => {
		window.addEventListener('popstate', popstateEvent)
		return (() => {
			window.removeEventListener('popstate', popstateEvent)
		})
	}, [])

	useEffect(() => {
		resizeCalc()
	}, [])

	useEffect(() => {
		if (props.url)
			setModalState(true)
		else
			setModalState(false)
	}, [props])

	const closeModal = () => {
		props.openServiceTerm('')
		setModalState(false)
		window.history.go(-1)
	}

	return (
		modalState ?
			(
				<div className="MyModal">
					<div className='ModalContainer'>
						<Close className='close-button' onClick={closeModal}/>
						{/* <div className='close-button' onClick={(e) => closeModal(e)}>닫기</div> */}
						<iframe id={isMobile
							? 'iframe-mobile'
							: 'iframe-desktop'} className="content" title='tablemanager-policy' src={props.url} width="360px" height="100%" />
	  		    	</div>
				</div>
			)
			: <></>
	)
}

export default PolicyModal
