
import { Fragment } from 'react'
import { isMobile } from '../../../utils'
import './BottomSheet.scss'

const mobile = isMobile()

const BottomSheet = ({ children, className, title }) => {
	return <Fragment>
		<div className={`footer-wrapper ticket-notes-wrapper voucher-bottom-sheet-wrapper ${mobile && 'mobile'} ${className}`}>
			{/* bottom sheet content & bottom button */}
			<div className="voucher-select-popup open">
				<div className="voucher-select-popup-title">
					{title}
				</div>
				{children}
			</div>
		</div>
		<div className='dim-bottom-sheet'></div>
	</Fragment>
}

export default BottomSheet
