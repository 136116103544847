import React from 'react'
import { useSearchContext } from '../../../../context/Booking/search'
import SearchedItem from '../SearchedItem/SearchedItem'
import './SearchedList.scss'
export default function SearchedList() {
	const { searchedMalls, searchInfo } = useSearchContext()
	if (searchedMalls.length === 0 && searchInfo.searchCompleted) {
		return (
			<div className="empty-info-container">
				<div className="empty-text">
					검색 결과가 없습니다.
				</div>
			</div>
		)
	}
	return (
		<div className="searched-list-container">
			{searchedMalls.map((mall, idx) => <SearchedItem mall={mall} key={idx} text={searchInfo.searchWord}></SearchedItem>)}
		</div>
	)
}
