import React from 'react'
import './AccountCard.scss'
export default function AccountCard(props) {
	return (
		<div className="account-card-container">
			<div className="card-name">{props.name}</div>
			<div className="card-left-text">현재 잔액</div>
			<div className="card-left-amount">{props.amount.toLocaleString()}</div>
		</div>
	)
}
