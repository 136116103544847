import React, { useEffect, useMemo, useState } from 'react'

import Menu from '../../../Components/Share/Pickup/Menu'
import PaymentInfo from '../../../Components/Pickup/Complete/PaymentInfo'
import PickupInfo from '../../../Components/Pickup/Complete/PickupInfo'
import Seperator from '../../../Components/Share/Seperator'
import UserInfo from '../../../Components/Pickup/Complete/UserInfo'
import { PickupAPI } from '../../../lib/api'
import { useParams } from 'react-router-dom'
import useDynamicComponent from '../../../hooks/useDynamicComponent'
import PickupPaymentMenuList from '../../../Components/Gyeongju/PickupPaymentMenuList/PickupPaymentMenuList'
import { authorizationWithPrefix } from '../../../lib/utils'
import { usePartnerContext } from '../../../context/Common/partner'

export default function Complete() {
	const params = useParams()
	const [orderInfo, setOrderInfo] = useState(null)
	const { renderComponent } = useDynamicComponent()
	const { apiKey } = usePartnerContext()

	const pickupAPI = useMemo(
		() => new PickupAPI(apiKey, authorizationWithPrefix()),
		[apiKey]
	)

	useEffect(() => {
		pickupAPI.getOrder(params.completeId).then(res => {
			setOrderInfo(res)
		})
	}, [])
	if (!orderInfo)
		return <div>loading...</div>

	return (
		<div>
			<PickupInfo orderInfo={orderInfo}></PickupInfo>
			<Seperator></Seperator>
			{
				renderComponent({
					default: (
						<>
							{orderInfo.serviceOrder.selectedMenus.map(menu => <Menu key={orderInfo.serviceOrder.policyId} policyId={orderInfo.serviceOrder.policyId} menu={menu} />)}
							{
								orderInfo.serviceOrder.selectedMenus.reduce((prev, next) => {
									return prev + (next.totalPrice * next.count)
								}, 0) > 0 ?
									<PaymentInfo orderInfo={orderInfo}></PaymentInfo> :
									null
							}
						</>
					),
					gyeongjuro: (
						<PickupPaymentMenuList orderInfo={orderInfo}></PickupPaymentMenuList>
					)
				})
			}
			<Seperator></Seperator>
			<UserInfo orderInfo={orderInfo}></UserInfo>
		</div>
	)
}
