import React from 'react'
import { isMobile } from '../../../../utils'
import CustomerInquiryBannerImage from '../../../../assets/icon/CustomerInquiryBanner.png'
import { inquiryType } from '../../utils'

export default function CustomerInquiry(props) {
	const { moveInquiryPage } = props
	return (
		<div className="customer-inquiry-wrapper">
			<img
				className={
					isMobile() ?
						'customer-inquiry-banner mobile'
						: 'customer-inquiry-banner'
				} src={CustomerInquiryBannerImage}
				onClick={(() => moveInquiryPage(inquiryType.CUSTOMER))}
				alt="고객의 소리함"></img>
		</div>
	)
}
