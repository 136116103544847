import React from 'react'
import { useBookingContext } from '../../../../context/Booking/booking'
import './MallTitle.scss'
import { useMall } from '../../../../store'

export default function MallTitle() {
	const { mall } = useMall()
	return (
		<div className="mall-title-container">
			<div className="mall-food b3">{mall.foods.join('·')}</div>
			<div className="title">
				<span className="t1">{mall.mall_name}</span>
				{mall.mall_group['할인율']
					? <div className="info-discount b2">{mall.mall_group['할인율']}%</div>
					: null}
			</div>
			{ mall.mall_group['매장설명']
				? <div className="description b1">{mall.mall_group['매장설명']}</div>
				: null}
		</div>

	)
}
