import React from 'react'
import './ImageCarousel.scss'
import { Carousel } from 'react-responsive-carousel'
import '../../assets/Css/carousel.min.css'
import { useHistory } from 'react-router-dom'
export default function ImageCarousel(props) {
	const history = useHistory()
	function handleClick(image) {
		if (image.type === 'LINK')
			window.location.href = image.value
		if (image.type === 'moveGallery')
			history.push(`/booking/mall-detail/${image.value}/gallery`)
		if (image.type === 'popMall')
			history.push(`/booking/mall-detail/${image.value}`)
		if (image.type === 'popEx')
			window.open(image.value, 'Ex')
		if (image.type === 'popEvent')
			history.push(`/${image.value}`)
		else
			return
	}

	return (
		<Carousel className="carousel-container" showStatus={false} showThumbs={false} dynamicHeight={true} showArrows={false} autoPlay={false} interval={10000000000} showIndicators={props.images.length > 1}>
			{props.images.map((image, idx) => <div style={
				{
					height: '190px',
					// backgroundColor: '#FFF',
					backgroundImage: `url(${image.imageUrl})`,
					backgroundPosition: 'center',
					backgroundSize: 'cover',  /* contain */
					backgroundRepeat: 'no-repeat'
				}
			} onClick={() => handleClick(image)} key={idx}></div>)}
		</Carousel>
	)
}
