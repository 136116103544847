import { createContext, useContext, useState, useEffect } from 'react'

const ChangeNameContext = createContext({
	setOrder: () => {},
	order: {},
	setName: () => {},
	name: null,
})

const ChangeNameContextProvider = (props) => {
	const [order, setOrder] = useState(null)
	const [name, setName] = useState('')

	const initialValue = {
		setOrder,
		order,
		setName,
		name
	}

	return <ChangeNameContext.Provider value={initialValue}>{props.children}</ChangeNameContext.Provider>
}


const useChangeNameContext = () => useContext(ChangeNameContext)

export { ChangeNameContextProvider, useChangeNameContext }
