import { debounce, throttle } from 'lodash';
import { useCallback } from 'react';
import moment from 'moment'

const useDebounce = (delay = 1000) => {
  return useCallback(debounce((func, ...args) => {
    func(...args);
  }, delay), [delay]);
}

const useThrottle = (delay = 1000) => {
  return useCallback(throttle((func, ...args) => {
    func(...args);
  }, delay), [delay]);
}

const durationIsValid = (iso8601DurationString) => {
  if (iso8601DurationString === 'P0D') return true
  return moment.duration(iso8601DurationString).toISOString() !== 'P0D'
}

const durationParser = (duration) => {
  let descTokens = []
  if (duration.years() > 0) descTokens.push(`${duration.years()}년`)
  if (duration.months() > 0) descTokens.push(`${duration.months()}개월`)
  if (duration.days() > 0) descTokens.push(`${duration.days()}일`)

  return descTokens.join(' ')
}

const authorizationWithPrefix = () => {
  const authorization = sessionStorage.getItem('authorization')

  if (!authorization)
    return null

  let prefix = ''
  if (localStorage.getItem('partnerName') === 'tablemanager')
    prefix = 'Bearer'
  return `${prefix} ${authorization}`.trim()
}

export { useDebounce, useThrottle, durationIsValid, durationParser, authorizationWithPrefix };
