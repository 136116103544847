import React, { useLayoutEffect } from 'react'
import { useMemo } from 'react'
import { BookingMallDetailAPI } from '../../../../lib/mytable'
import { usePartnerContext } from '../../../../context/Common/partner'
import moment from 'moment'
import { useState } from 'react'
import { authorizationWithPrefix } from '../../../../lib/utils'
import { useMall, useReservation } from '../../../../store'
import DateSelector from './DateSelector'
import useCalendar from '../../../../hooks/useCalendar'
import classNames from 'classnames'
import { useParams } from 'react-router-dom'

export default function Calendar({ isLoaded, setIsLoaded }) {
	const { mall } = useMall()
	const { mallUri } = useParams()
	const { totalPartySize, dateTime, setReservation, voucherData } = useReservation()
	const { apiKey } = usePartnerContext()
	const [viewDate, setViewDate] = useState(dateTime ?? moment())
	const [calendar, setCalendar] = useState()
	const { getPrev, getNow, getNext } = useCalendar(viewDate.toDate())
	const prev = getPrev(),
		now = getNow(),
		next = getNext()
	const bookingMallDetailAPI = useMemo(
		() => new BookingMallDetailAPI(apiKey, authorizationWithPrefix()),
		[apiKey, voucherData]
	)

	useLayoutEffect(() => {
		if (!mallUri || !totalPartySize)
			return

		bookingMallDetailAPI.getDates(mallUri, totalPartySize, voucherData?.voucherOrderItemId).then((res) => {
			setIsLoaded(true)
			if (res.result && res.days.length === 0) {
				if (totalPartySize <= mall.people_min) {
					setReservation('totalPartySize', totalPartySize + 1)
					return
				}
				setCalendar(undefined)
				return
			}
			setCalendar(res.days)
		})
	}, [totalPartySize, mallUri, voucherData?.voucherOrderItemId])

	const renderCalendarWeeks = () => {
		const handleDate = (value) => {
			const { itemDate, isSelectedDate, isSelectable } = { ...value }
			if (isSelectedDate || !isSelectable)
				return
			setReservation('dateTime', itemDate)
		}

		const allDays = [
			prev.map((item, idx) => (
				<div key={'prev-days-' + idx} className="hide">
					{item}
				</div>
			)),
			now.map((item, idx) => {
				const itemDate = moment(viewDate).date(item) // 렌더링할 날짜
				const value = {
					itemDate,
					isSelectedDate: itemDate.isSame(dateTime || new Date(0), 'day'),
					isSelectable: calendar?.find((ele) => itemDate.format('YYYYMMDD') === ele.date)?.selectable,
				}
				return (
					<div
						key={'now-days-' + idx}
						className={classNames('b1', {
							selected: value.isSelectedDate,
							deactive: !value.isSelectable,
						})}
						onClick={() => handleDate(value)}
					>
						{item}
					</div>
				)
			}),
			next.map((item, idx) => (
				<div key={'next-days-' + idx} className="hide">
					{item}
				</div>
			)),
		].flat()

		let weeks = []
		while (allDays.length > 0)
			weeks.push(allDays.splice(0, 7))

		return weeks
	}

	const notificationForNothing = () => {
		if (calendar) {
			return (
				<span className="no-item-noti">
					해당 월에는 선택하신 인원 조건에 맞는
					<br />
					예약 가능 일자가 없습니다.
				</span>
			)
		}
		return (
			<span className="no-item-noti">
				선택하신 인원 조건에 맞는
				<br />
				예약 가능 일자가 없습니다.
			</span>
		)
	}

	return (
		<div className={classNames('calendar-container', { isLoaded })}>
			<DateSelector state={{ viewDate, setViewDate, calendar }} />
			<div className="picker-container">
				{calendar?.filter((day) => day.date.includes(viewDate.format('YYYYMM'))).find((month) => month.selectable)
					? (
						<>
							<div className='week-list'>
								<div className="text b1">일</div>
								<div className="text b1">월</div>
								<div className="text b1">화</div>
								<div className="text b1">수</div>
								<div className="text b1">목</div>
								<div className="text b1">금</div>
								<div className="text b1">토</div>
							</div>
							{renderCalendarWeeks().map((weeks, idx) => (
								<div key={`week-${idx}`} className='week-list'>{weeks.map((days) => days)}</div>
							))}
						</>
					)
					: (
						notificationForNothing()
					)}
			</div>
		</div>
	)
}
