import React, { useLayoutEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useMall } from '../../../store'

export default function Auth() {
	const { mallUri } = useParams()
	const { setMall } = useMall()

	useLayoutEffect(() => {
		if (mallUri)
			setMall('mallUri', mallUri)
	}, [mallUri])

	return (
		<div>

		</div>
	)
}
