import React from 'react'
import { useBookingContext } from '../../../../context/Booking/booking'
import './BusinessHours.scss'
import BookingSubtitle from '../../../Share/Booking/BookingSubtitle/BookingSubtitle'
import { useMall } from '../../../../store'
export default function BusinessHours() {
	const { info_opentime: businessHours } = useMall(state => state.mall)
	return (
		<div className="business-hours-container">
			<BookingSubtitle subtitle="영업시간"></BookingSubtitle>
			{businessHours.map((item, idx) =>
				<div className="business-hours-item b1" key={idx}>
					<span className="weekday">{ item.week }</span>
					{item.type === '휴무'
						? <span>휴무</span> :
						<span>
							{ item.date_start.substr(1, 1) === ':'
								? '0' + item.date_start.padEnd(4, '0')
								: item.date_start.padEnd(5, '0') }
            ~{ item.date_end.substr(1, 1) === ':'
								? '0' + item.date_end.padEnd(4, '0')
								: item.date_end.padEnd(5, '0') }
						</span> }
					{item.description
						? <span className="description"> ({item.description})</span>
						: null }
				</div>
			)}
		</div>
	)
}

