
import React from 'react'
import './BrandItem.scss'

export default function Brand(props) {
	const { imageUrl, brandName } = props

	return (
		<div className="brand-introduce-view">
			<img className="brand-logo-image" src={imageUrl} alt={brandName}></img>
			<span className="brand-name">{brandName}</span>
		</div>
	)
}
