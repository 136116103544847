import React from 'react'
import { SearchContextProvider } from '../../../context/Booking/search'
import './Search.scss'
import { useMall } from '../../../store'
import { AutocompleteList, SearchBar, SearchedList, SearchHistory } from '../../../Components/Booking'

export default function Search() {
	const { malls } = useMall()
	return (
		<SearchContextProvider malls={malls}>
			<div className="search-page-container">
				<SearchBar></SearchBar>
			</div>
			<AutocompleteList></AutocompleteList>
			<SearchedList></SearchedList>
			<SearchHistory></SearchHistory>
		</SearchContextProvider>

	)
}
