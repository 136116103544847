import React from 'react'
import { useVoucherContext } from '../../../../context/Common/voucher'
import BookingSubtitle from '../../../Share/Booking/BookingSubtitle/BookingSubtitle'
import './UserInfo.scss'

export default function UserInfo() {
	const { buyer } = useVoucherContext()
	return (
		<div className="voucher-user-info-container">
			<BookingSubtitle subtitle="구매자 정보"></BookingSubtitle>
			<div className="info-box">
				<div className="label b1">구매자</div>
				<div className="value b1">{buyer.name}</div>
			</div>
			<div className="info-box">
				<div className="label b1">연락처</div>
				<div className="value b1">{buyer.phone}</div>
			</div>
			{buyer.email
				? <div className="info-box">
					<div className="label b1">이메일</div>
					<div className="value b1">{buyer.email}</div>
				</div>
				: null}

		</div>
	)
}
