import React from 'react'
import MenuInfo from './MenuInfo'
import MenuAction from './MenuAction'
import MenuOptionGroup from './MenuOptionGroup'
import './Menu.scss'
export default function Menu(props) {
	return (
		<div className="menu-container">
			<MenuInfo menuName={props.menu.name} menuPrice={props.menu.totalPrice || props.menu.price}></MenuInfo>
			{props.menu.optionGroups.map((optionGroup, idx) => <MenuOptionGroup optionGroup={optionGroup} key={idx}/>)}
			{props.type === 'edit'
				? <MenuAction count={props.menu.count} uniqueKey={props.menu.uniqueKey}></MenuAction>
				: <div className="count">{props.menu.count}개</div>}
		</div>
	)
}
