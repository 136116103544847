import React from 'react'
import './MenuImage.scss'
import { getImageLink } from '../../../utils'

export default function MenuImage(props) {

	return (
		<div className="menu-image-container">
			<img src={getImageLink(props.imageId)} alt="menuImage"/>
		</div>
	)
}
