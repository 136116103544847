import React, { useState } from 'react'
import { useHomeContext } from '../../../../context/Booking/home'
import LocationItem from '../LocationItem/LocationItem'
import RegionSlider from '../RegionSlider/RegionSlider'
import './Location.scss'
import BookingSubtitle from '../../../Share/Booking/BookingSubtitle/BookingSubtitle'

export default function Location() {
	const { regionMalls } = useHomeContext()
	const [sticky, setSticky] = useState(true)
	if (!regionMalls)
		return <div></div>

	return (
		<div className="location-container">
			<div className="subtitle-container">
				<BookingSubtitle subtitle="지역별 레스토랑"></BookingSubtitle>
			</div>
			{sticky
				? <div className="slide-placehoder"></div>
				: null}
			<RegionSlider></RegionSlider>
			{regionMalls.map((mall, idx) =>
				<LocationItem mall={mall} key={idx}></LocationItem>)}
		</div>
	)
}
