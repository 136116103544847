import React from 'react'
import './Event.scss'
import { useHistory } from 'react-router-dom'

export default function Event() {
	const history = useHistory()
	function clickImage(e) {
		let image = document.getElementById('skt-event-img')
		let imageHeight = image.height
		if (e.pageY > imageHeight * 0.24 && e.pageY < imageHeight * 0.285)
			history.push('/voucher/detail/ontheborder')
		if (e.pageY > imageHeight * 0.29 && e.pageY < imageHeight * 0.335)
			history.push('/voucher/detail/tgif')
		if (e.pageY > imageHeight * 0.34 && e.pageY < imageHeight * 0.385)
			history.push('/voucher/detail/agra')
	}
	return (
		<div>
			<img src="https://images.tblm.co/image/0d05d53e-001a-40d0-a2f3-bea8430fe307.png" usemap="#image-map" id="skt-event-img" onClick={(e) => clickImage(e)} alt="" />

			<map name="image-map">
				<area target="" alt="" title="" href="/voucher/detail/ontheborder" coords="1328,2977,115,2519" shape="rect" />
				<area target="" alt="" title="" href="/voucher/detail/tgif" coords="115,3022,1329,3476,167,3022,75,3050,192,3121" shape="rect" />
				<area target="" alt="" title="" href="/voucher/detail/agra" coords="109,3524,1330,3973" shape="rect" />
			</map>
		</div>
	)
}
