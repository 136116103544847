import React, { useEffect } from 'react'
import './ReservationItem.scss'
import moment from 'moment'
import 'moment/locale/ko'
import { useHistory } from 'react-router-dom'
export default function ReservationItem(props) {
	moment().locale('ko')
	const today = moment().startOf('day')
	const history = useHistory()
	const { reservation } = props

	function selectReservationItem() {
		history.push(`/booking/detail/${reservation.reservation_id}`)
	}

	function MallImage() {
		const imageStyle = {
			background: `url('${reservation.info_images[0]?.src}')`,
			backgroundPosition: '50% 50%',
			backgroundSize: 'cover'
		}
		return <div className="mall-image" style={imageStyle}>
			{reservation.state === 2 ?
				<div className="dim">방문취소</div>
				: null}
			{reservation.state === 3 || reservation.state === 1 ?
				<div className="dim">방문완료</div>
				: null}
			{reservation.state === 0 ?
				<div className="badge b3">
					{reservation.date.diff(today, 'days') > 0 ?
						'D-' + reservation.date.diff(today, 'days')
						: 'D-DAY'}
				</div>
				: null}
		</div>
	}

	if (!reservation)
		return null

	return (
		<div className="reservation-item-container">
			<div className="mall-container" onClick={selectReservationItem}>
				<div>
					<MallImage></MallImage>
				</div>
				<div className="mall-right-area">
					<div className="mall-name t5">
						{reservation.mall_name}</div>
					<div className="mall-info date-info b2">
						{reservation.date.format('YYYY.MM.DD(dd)·LT')} · {reservation.count_people}명
					</div>
				</div>
			</div>
		</div>
	)

}
