import React, { useCallback, useLayoutEffect } from 'react'
import { usei18next, useMall } from '../../../store'

/**
 * @deprecated 경주로ON 다국어 지원 예정으로 아직 사용하지 않습니다.
 */
const LanguageSelector = () => {
	const { language, setLanguage } = usei18next((state) => state)
	const { languages_enabled: languages } = useMall((state) => state)

	const initialLanguage = useCallback(() => {
		if (!languages)
			return

		const browserSettings = localStorage.getItem('language')

		if (browserSettings) {
			setLanguage(browserSettings)
			return
		}

		const matchBrowserLanguage = languages.find((item) => navigator.language.includes(item.code))

		if (!matchBrowserLanguage)
			setLanguage('en')
		else
			setLanguage(matchBrowserLanguage.code)
	}, [languages])

	const saveLanguageToStorage = () => {
		if (language)
			localStorage.setItem('language', language)
	}

	useLayoutEffect(() => initialLanguage(), [initialLanguage])
	useLayoutEffect(() => saveLanguageToStorage(), [language])

	return <div>대충 지구모양짤</div>
}

export default LanguageSelector
