import React from 'react'
import './FacilityItem.scss'
import Corkage from '../../../../assets/icon/facility/corkage.png'
import Kidszone from '../../../../assets/icon/facility/kidszone.png'
import Babyseat from '../../../../assets/icon/facility/babyseat.png'
import Kidsmenu from '../../../../assets/icon/facility/kidsmenu.png'
import Sommelier from '../../../../assets/icon/facility/sommelier.png'
import Parking from '../../../../assets/icon/facility/parking.png'
import Valetparking from '../../../../assets/icon/facility/valetparking.png'
import Wifi from '../../../../assets/icon/facility/wifi.png'
import Pet from '../../../../assets/icon/facility/pet.png'
import Dis from '../../../../assets/icon/facility/dis.png'
import Private from '../../../../assets/icon/facility/private.png'
import Rooftop from '../../../../assets/icon/facility/rooftop.png'
import Bbq from '../../../../assets/icon/facility/bbq.png'
import Nursingroom from '../../../../assets/icon/facility/nursingroom.png'
import Smoking from '../../../../assets/icon/facility/smoking.png'
import Waiting from '../../../../assets/icon/facility/waiting.png'
import Blank from '../../../../assets/icon/facility/blank.png'
export default function FacilityItem(props) {
	const mapper = {
		'Valet': '발렛파킹',
		'Wifi': '와이파이',
		'Private Room': '프라이빗 룸',
	}

	const mappedName = mapper[props.name] || props.name

	const facilityList = {
		'코르키지 (유료)': {
			name: '코르키지 (유료)',
			renderImage: Corkage,
			returnName: '콜키지(유료)'
		},
		'코르키지 (무료)': {
			name: '코르키지 (무료)',
			renderImage: Corkage,
			returnName: '콜키지(무료)'
		},
		'놀이방': {
			name: '놀이방',
			renderImage: Kidszone,
			returnName: '놀이방'
		},
		'베이비 시트': {
			name: '베이비 시트',
			renderImage: Babyseat,
			returnName: '베이비시트'
		},
		'아동용 메뉴': {
			name: '아동용 메뉴',
			renderImage: Kidsmenu,
			returnName: '아동 메뉴'
		},
		'소믈리에': {
			name: '소믈리에',
			renderImage: Sommelier,
			returnName: '소믈리에'
		},
		'기계식 주차장': {
			name: '기계식 주차장',
			renderImage: Parking,
			returnName: '주차공간'
		},
		'주차공간': {
			name: '주차공간',
			renderImage: Parking,
			returnName: '주차공간'
		},
		'발렛파킹': {
			name: '발렛파킹',
			renderImage: Valetparking,
			returnName: '발렛파킹'
		},
		'와이파이': {
			name: '와이파이',
			renderImage: Wifi,
			returnName: '와이파이'
		},
		'반려견 동반 가능': {
			name: '반려견 동반 가능',
			renderImage: Pet,
			returnName: '반려동물'
		},
		'장애인 편의시설': {
			name: '장애인 편의시설',
			renderImage: Dis,
			returnName: '장애인시설'
		},
		'프라이빗 룸': {
			name: '프라이빗 룸',
			renderImage: Private,
			returnName: '프라이빗룸'
		},
		'루프탑': {
			name: '루프탑',
			renderImage: Rooftop,
			returnName: '루프탑'
		},
		'루프탑 BBQ': {
			name: '루프탑 BBQ',
			renderImage: Bbq,
			returnName: '바베큐'
		},
		'수유실': {
			name: '수유실',
			renderImage: Nursingroom,
			returnName: '수유실'
		},
		'흡연실': {
			name: '흡연실',
			renderImage: Smoking,
			returnName: '흡연실'
		},
		'대기공간': {
			name: '대기공간',
			renderImage: Waiting,
			returnName: '대기공간'
		},
	}

	const facility = facilityList[mappedName] || {
		name: props.name,
		renderImage: Blank,
		returnName: props.name
	}

	return (
		<div className="facility-item-container">
		 <div className="facility-image">
				<img src={facility.renderImage} alt='facility' />
		 </div>
		 <div className="b2">{facility.returnName}</div>
		</div>
	)
}
