import React, { useEffect, useState } from 'react'
import { useHomeContext } from '../../../../context/Voucher/home'
import Title from '../../../Share/Title/Title'
import VoucherProductList from '../../VoucherProduct/List/VoucherProductList'

export default function SellingVoucher(props) {
	const { getVouchers, myWishList } = useHomeContext()
	const { wishIt, trackingAPI } = props
	const [sellingVouchers, setSellingVouchers] = useState([])
	useEffect(() => {
		if (getVouchers('sold') && getVouchers('sold').length > 0 && sellingVouchers.length === 0) {
			let soldVoucher = getVouchers('sold')
			let targetBrands = ['pfchang', 'drrobbin', 'ontheborder', 'thedininglab', 'oppachicken', 'fuzzynable', 'cpk']
			if (sessionStorage.getItem('referral') === 'shinhan')
				targetBrands = ['ontheborder', 'tgif', 'agra', 'thedininglab']
			let tmpVoucher = soldVoucher.filter(voucher => !targetBrands.includes(voucher.brand.id))
			targetBrands.forEach(brand => {
				let findVoucher = soldVoucher.find(voucher => voucher.brand.id === brand)
				if (findVoucher)
					tmpVoucher.push(findVoucher)
			})
			setSellingVouchers(tmpVoucher)
		}
	}, [getVouchers('sold')])
	return (
		<div className="selling-voucher voucher-products">
			<Title text={'예약상품권 컬렉션'}></Title>
			<VoucherProductList type="card" data={sellingVouchers} customFunction={wishIt} favoriteList={myWishList} tracker={trackingAPI}></VoucherProductList>
		</div>
	)
}
