import React, { useEffect } from 'react'
import addSelectedImage from '../../../../assets/icon/add_selected.png'
import CheckboxImage from '../../../../assets/icon/checkbox.png'
import { useVoucherContext } from '../../../../context/Common/voucher'
import { ReactComponent as Close } from '../../../../assets/icon/close.svg'
import './MyTableCardList.scss'

export default function MyTableCardList(props) {
	const { cardList, addCard, deleteCard } = props
	const { paymentInfo, dispatchPaymentInfo } = useVoucherContext()

	const selectCard = (event, card) => {
		if (!event.target.closest('.delete-icon-wrapper'))
			dispatchPaymentInfo({ type: 'CHANGE_MEMBER_CARD_ID', value: card.subscriptionId })
	}

	const addCardEvent = () => {
		addCard()
		if (!paymentInfo.billingKeyContext.subscriptionId && cardList && cardList.length > 0)
			selectFirstCard()
	}

	const deleteCardItem = (event, userSubscriptionId) => {
		if (event.target.closest('.delete-icon-wrapper')) {
			dispatchPaymentInfo({ type: 'CHANGE_MEMBER_CARD_ID', value: '' })
			deleteCard(userSubscriptionId)
		}
	}

	const selectFirstCard = () => {
		const firstCard = cardList[0]
		dispatchPaymentInfo({ type: 'CHANGE_MEMBER_CARD_ID', value: firstCard.subscriptionId })
	}

	useEffect(() => {
		if (cardList && cardList.length > 0)
			selectFirstCard()
	}, [])

	return (
		<div className="card-list-container">
			<div className="pay-card add-card-button" onClick={addCardEvent}>
				<img className="add-card-icon" src={addSelectedImage} alt="+" />
				<span className="card-text">카드 추가</span>
			</div>
			{
				cardList.map((card, index) => (
					<div
						key={index}
						className={
							paymentInfo.billingKeyContext.subscriptionId === card.subscriptionId
								? 'pay-card selected-card'
								: 'pay-card unselected-card'
						} onClick={((e) => selectCard(e, card))}
					>
						<button className="delete-icon-wrapper" onClick={((event) => deleteCardItem(event, card.userSubscriptionId))}>
							<Close className="delete-icon" />
						</button>
						{
							paymentInfo.billingKeyContext.subscriptionId === card.subscriptionId ?
								<img className="checkbox-image" src={CheckboxImage} alt="selected" />
								: <></>
						}
						<span className="card-text card-kind">{card.cardName}</span>
						<span className="card-text">{card.cardNumber}</span>
					</div>
				))
			}
		</div>
	)
}
