import React from 'react'
import BookingSubtitle from '../../../Share/Booking/BookingSubtitle/BookingSubtitle'
import { useMall, useReservation } from '../../../../store'
import useDynamicComponent from '../../../../hooks/useDynamicComponent'
export default function ReservationInfo() {
	const { totalPartySize, dateTime, getLocaleDeposit, getPartySizeString, getTotalAmount } = useReservation()
	const { mall } = useMall()
	const { renderComponent } = useDynamicComponent()

	if (!mall || !dateTime)
		return null

	return (
		<div className="reservation-info-container">
			<div className="complete-box t4">예약이 완료되었습니다.</div>
			<div className="mall-name t2">{mall.mall_name}</div>
			<BookingSubtitle subtitle="예약내용 확인"></BookingSubtitle>
			{renderComponent({
				default: (
					<>
						<div className="info-box">
							<div className="label b1">예약일시</div>
							<div className="value b1">{dateTime.format('YYYY년 M월 D일 LT')}</div>
						</div>
						<div className="info-box">
							<div className="label b1">예약인원</div>
							<div className="value b1">
								{totalPartySize}명&nbsp;
								<span>({getPartySizeString()})</span>
							</div>
						</div>
						{getTotalAmount() > 0
							? (
								<div className="info-box">
									<div className="label b1">예약금액</div>
									<div className="value b1">{getLocaleDeposit()}</div>
								</div>
							)
							: null}
					</>
				),
				gyeongjuro: (
					<ul className="info-container">
						<li className="info-list">
							<div className="info-box">
								<label className="label">예약일시</label>
								<span className="value">{dateTime.format('LL (dd) ∙ LT')}</span>
							</div>
						</li>
						<li className="info-list">
							<div className="info-box">
								<label className="label">예약인원</label>
								<span className="value">
									{totalPartySize}명 ({getPartySizeString()})
								</span>
							</div>
						</li>
						{getTotalAmount() > 0
							? (
								<li className="info-list">
									<div className="info-box">
										<label className="label">예약금액</label>
										<span className="value">{getLocaleDeposit()}</span>
									</div>
								</li>
							)
							: null}
					</ul>
				),
			})}
		</div>
	)
}
