import { createContext, useContext, useState, useEffect } from 'react'
import LoginDrawer from '../../Components/LoginDrawer/LoginDrawer'

const LoginContext = createContext({
	isOpenDrawer: null,
	setIsOpenDrawer: () => {},
	loginPath: null,
	setLoginPath: () => {},
	uriReferral: null,
	setUriReferral: () => {},
})

const useLoginContext = () => useContext(LoginContext)
const LoginContextProvider = (props) => {
	const [isOpenDrawer, setIsOpenDrawer] = useState(false)
	const [loginPath, setLoginPath] = useState('')
	const [uriReferral, setUriReferral] = useState('')
	const initialValue = {
		isOpenDrawer,
		setIsOpenDrawer,
		uriReferral,
		setUriReferral,
		loginPath,
		setLoginPath
	}

	useEffect(() => {
		const referral = sessionStorage.getItem('referral')
		if (referral)
			setUriReferral(referral)
	}, [])

	return (
		<LoginContext.Provider value={initialValue}>
			<LoginDrawer isOpen={isOpenDrawer} onClose={(() => setIsOpenDrawer(false))}></LoginDrawer>
			{props.children}
		</LoginContext.Provider>
	)
}

export { LoginContextProvider, useLoginContext }
