import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import { Radio as RadioBox } from '../../../Gyeongju'
import BookingSubtitle from '../../../Share/Booking/BookingSubtitle/BookingSubtitle'
import { ReactComponent as ActiveRadio } from '../../../../assets/icon/activeRadio.svg'
import { ReactComponent as DeactiveRadio } from '../../../../assets/icon/deactiveRadio.svg'
import { BookingMallDetailAPI } from '../../../../lib/mytable'
import { authorizationWithPrefix } from '../../../../lib/utils'
import { usePartnerContext } from '../../../../context/Common/partner'
import { useProvisionalReservation, useReservation, useTimer } from '../../../../store'
import { useHistory, useParams } from 'react-router-dom'
import { useModalContext } from '../../../../context/Common/modal'
import moment from 'moment'
import useDynamicComponent from '../../../../hooks/useDynamicComponent'

export default function CategorySelector() {
	const history = useHistory()
	const { mallUri } = useParams()
	const { apiKey } = usePartnerContext()
	const { alertOpen } = useModalContext()
	const { voucherData, totalPartySize, dateTime, setReservation, categoryId, resetCategory } = useReservation()
	const { cancelReservation, setProvisionalReservation } = useProvisionalReservation()
	const { setTimer } = useTimer()
	const { renderComponent } = useDynamicComponent()
	const bookingMallDetailAPI = useMemo(
		() => new BookingMallDetailAPI(apiKey, authorizationWithPrefix()),
		[apiKey, voucherData]
	)
	const [categories, setCategories] = useState()

	const fetchCategories = () => {
		bookingMallDetailAPI
			.getCategories(mallUri, totalPartySize, dateTime)
			.then((res) => {
				const reservableList = res.list.filter((item) => item.reservable)

				if (reservableList.length) {
					// 첫번째 좌석을 기본값으로 설정
					setReservation('categoryId', reservableList[0].category_id)
					setCategories(reservableList)
				} else {
					throw new Error()
				}
			})
			.catch(() => {
				alertOpen('현재 예약 가능한 좌석이 없습니다. 다른 날짜를 선택해주세요.', undefined, undefined, () => {
					history.go(-2)
				})
			})
	}

	const makeProvisionalReservation = async () => {
		await cancelReservation(apiKey, mallUri)
		bookingMallDetailAPI
			.makeProvisionalReservation(mallUri, totalPartySize, dateTime, categoryId)
			.then((res) => {
				if (!res.result)
					throw new Error(res.message)
				setProvisionalReservation('reservationToken', res.token)
				setReservation('deposit', res.price)
				setTimer('expire', moment(res.date_expire))
			})
			.catch((e) => {
				resetCategory()
				alertOpen(e.message)
			})
	}

	useLayoutEffect(() => {
		if (mallUri)
			fetchCategories()
	}, [mallUri])

	useEffect(() => {
		if (categoryId)
			makeProvisionalReservation()
	}, [categoryId])

	if (!categories)
		return null

	return (
		<div className="category-selector-container">
			<BookingSubtitle subtitle="예약 좌석 테이블" />
			{renderComponent({
				default: (
					<RadioGroup value={categoryId}>
						{categories.map((category, idx) => (
							<div className="radio-box" key={idx}>
								<FormControlLabel
									name={category.name + category.category_id}
									value={category.category_id}
									control={
										<Radio
											checkedIcon={<ActiveRadio className="icon active" />}
											icon={<DeactiveRadio className="icon" />}
										/>
									}
									label={category.name}
									className="radio-label"
									onChange={() => setReservation('categoryId', category.category_id)}
								/>
							</div>
						))}
					</RadioGroup>
				),
				gyeongjuro: (
					<div className="gyeongju-radio-box">
						{categories.map((category, idx) => (
							<RadioBox
								key={idx}
								name={category.name}
								value={category.category_id}
								func={() => setReservation('categoryId', category.category_id)}
								checked={category.category_id === categoryId}
							/>
						))}
					</div>
				),
			})}
		</div>
	)
}
