import React, { useState } from 'react'
import './NavigationModal.scss'
import modalConfig from '../../../configs/partnerModal.json'
import { usePartnerContext } from '../../../context/Common/partner'

const CloseConfirmModal = (props) => {
	const { onClick } = props
	const { partnerName } = usePartnerContext()
	const containerStyle = modalConfig.modal[partnerName].containerStyle
	const headerStyle = modalConfig.modal[partnerName].headerStyle
	const bodyStyle = modalConfig.modal[partnerName].bodyStyle
	const footerStyle = modalConfig.modal[partnerName].footerStyle
	const okayButtonStyle = modalConfig.modal[partnerName].okayButtonStyle
	const cancelButtonStyle = modalConfig.modal[partnerName].cancelButtonStyle

	return (
		<div className="modal openModal">
			<div className="modal-container" style={containerStyle}>
				<div className="modal-header" style={headerStyle}>
					예약 취소하기
				</div>
				<div className="modal-body" style={bodyStyle}>
					진행 중인 예약이 취소됩니다. 취소하시겠습니까?
				</div>
				<div className="modal-footer" style={footerStyle}>
					<button onClick={() => onClick()} style={cancelButtonStyle}>
						아니오
					</button>
					<button onClick={() => onClick(true)} style={okayButtonStyle}>
						네
					</button>
				</div>
			</div>
		</div>
	)
}

export default CloseConfirmModal
