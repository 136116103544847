const Format = {
	phone: (value) => {
		if (!value)
			return null
		return value?.replace(/[^0-9]/g, '')?.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/g, '$1-$2-$3')
	},

	blindPhone: (value) => {
		if (!value)
			return null
		const phoneArr = value.split('-')
		phoneArr[1] = '*'.repeat(phoneArr[1].length) // 휴대폰 번호의 중간 네자리를 특수기호로 변경
		return phoneArr.join('-')
	}
}

export default Format
