import React, { useEffect, useRef } from 'react'
import './MenuCategory.scss'
import MenuItem from '../MenuItem/MenuItem'
import useOnScreen from '../../../hooks/useOnScreen'
import { usePickupContext } from '../../../context/Pickup/pickup'

export default function MenuCategory(props) {
	const categoryRef = useRef()
	const isVisible = useOnScreen(categoryRef, '-96px 0px -164px')
	const { setSelectedId } = usePickupContext()
	useEffect(() => {
		if (isVisible)
			setSelectedId(props.category.categoryId)
	}, [isVisible])
	return (
		<div className="gyeongjuro-menu-category-container">
			<div className="category-name" id={props.category.categoryId} ref={categoryRef}>{props.category.categoryName}</div>
			{props.category.menus.map((menu) => <MenuItem menu={menu} key={menu._id} />)}
		</div>
	)
}
