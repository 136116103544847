import { usePartnerContext } from '../context/Common/partner'

/**
 * @typedef IRenderProp
 * @type {{ default?: JSX.Element, [key: string]: JSX.Element }}
 */

const useDynamicComponent = () => {
	const { partnerName } = usePartnerContext()

	/**
	 * @param {IRenderProp} settings
	 * @returns {JSX.Element}
	 */
	const renderComponent = (settings) => {
		if (settings[partnerName])
			return settings[partnerName]
		return settings.default || null
	}

	return { renderComponent }
}

export default useDynamicComponent
