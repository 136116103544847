import { createContext, useContext, useState, useEffect } from 'react'

const HomeContext = createContext({
	mainBannerImages: null,
	setMainBannerImages: () => { },
	vouchers: null,
	setVouchers: () => { },
	nowLiveProducts: null,
	setNowLiveProducts: () => { },
	missLiveProducts: null,
	setMissLiveProducts: () => { },
	myWishList: null,
	setMyWishList: () => { },
	myNotificationList: null,
	setMyNotificationList: () => { },
	reviewList: null,
	setReviewList: () => { },
	brands: null,
	setBrands: () => { },
})

const HomeContextProvider = (props) => {
	const [mainBannerImages, setMainBannerImages] = useState([])
	const [vouchers, setVouchers] = useState([])
	const [nowLiveProducts, setNowLiveProducts] = useState([])
	const [missLiveProducts, setMissLiveProducts] = useState([])
	const [myWishList, setMyWishList] = useState([])
	const [myNotificationList, setMyNotificationList] = useState([])
	const [reviewList, setReviewList] = useState([])
	const [brands, setBrands] = useState([])

	const isSoldVoucher = (voucher) => {
		const today = new Date()
		const startDate = new Date(voucher.service.startDate)
		return today >= startDate
	}

	const getVouchers = (type) => {
		let voucherList = []
		if (type === 'sold')
			voucherList = vouchers.filter((voucher) => isSoldVoucher(voucher))
		if (type === 'pre')
			voucherList = vouchers.filter((voucher) => !isSoldVoucher(voucher))
		return voucherList
	}

	const initialValue = {
		mainBannerImages,
		setMainBannerImages,
		vouchers,
		setVouchers,
		nowLiveProducts,
		setNowLiveProducts,
		missLiveProducts,
		setMissLiveProducts,
		myWishList,
		setMyWishList,
		myNotificationList,
		setMyNotificationList,
		reviewList,
		setReviewList,
		brands,
		setBrands,
		getVouchers
	}

	return <HomeContext.Provider value={initialValue}>{props.children}</HomeContext.Provider>
}


const useHomeContext = () => useContext(HomeContext)

export { HomeContextProvider, useHomeContext }
