import { createContext, useContext, useState, useEffect } from 'react'

const PartnerContext = createContext({
	partnerId: null,
	apiKey: null,
	partnerName: localStorage.getItem('partnerName'),
	setPartnerName: () => {},
	partnerInfo: {},
	setPartnerInfo: () => {},
	headerExists: {}
})

const partnerDict = {
	tablemanager: {
		partnerId: 1,
		apiKey: 'F1B80501BB7989BB5B15B4934DD268BA88261B8D',
		partnerName: 'tablemanager'
	},

	bccard: {
		partnerId: 3,
		apiKey: '28C33B08747B01BC0DCC8ED7419F25B77B96D927',
		partnerName: 'bccard'
	},

	samsung: {
		partnerId: 6,
		apiKey: '1CF83811C6EEC4AFC9DA7D289F8797EC9C562FBA',
		partnerName: 'samsung'
	},

	kakao: {
		partnerId: 7,
		apiKey: '415AB40AE9B7CC4E66D6769CB2C08106E8293B48',
		partnerName: 'kakao'
	},
	urbandelight: {
		partnerId: 8,
		apiKey: 'E4AE38658D6A8AAF365D203C7DF1CAC5C263744B',
		partnerName: 'urbandelight'
	},
	bcvip: {
		partnerId: 9,
		apiKey: '944B88D06011C7C4CC9AD9252C1846CDD1E62B21',
		partnerName: 'BCVIP'
	},
	mfg: {
		partnerId: 11,
		apiKey: '3169EDC68CD21A9A9B134227CAB3765572F5A49D',
		partnerName: 'mfg',
		partnerInfo: {
			name:null,
			phone:null
		},
	},
	kb: {
		partnerId: 13,
		apiKey: 'AD3945F3B12DC52A6BDD7BE98DFE5A4D598ED2BD',
		partnerName: 'kb',
		partnerInfo: {
			chnlMbrId: null,
			chnlId: null
		}
	},
	skt: {
		partnerId: 14,
		apiKey: '9832857FDDF8CF68C5AC3738228DFE2DA45A9AA1',
		partnerName: 'skt',
		partnerInfo: {
			ValidDate: null,
			BpcpServiceId: null,
			MbrCardNum10: null,
			TMSSvrUseYN: null,
			MbrGrCd: null,
			BirthDt: null,
			UserId: null,
			CurTime: null,
			UserSeq: null,
			CnntInfo: null,
			UserNm: null,
			SvcNum: null
		},
	},
	kt: {
		partnerId: 15,
		apiKey: '2BC068BEEA5282F93C5231C2D1BC7F77113E2586',
		partnerName: 'kt'
	},
	shinhan: {
		partnerId: 18,
		apiKey: '4EC2A0FE1EAE352403D3ADBD7B40AC2C5516FB60',
		partnerName: 'shinhan'
	},
	gyeongjuro: {
		partnerId: 20,
		apiKey: 'SOL003N593X7SH9CL3KTMD7QXXW2XD6FDR6FRRQW',
		partnerName: 'gyeongjuro'
	}
}

const PartnerContextProvider = (props) => {
	const [partnerId, setPartnerId] = useState(null)
	const [apiKey, setApiKey] = useState(null)
	const [partnerName, setPartnerName] = useState(localStorage.getItem('partnerName') || 'tablemanager')
	const [partnerInfo, setPartnerInfo] = useState(JSON.parse(sessionStorage.getItem('partnerInfo')) || null)
	const [headerExists, setHeaderExists] = useState(true)
	const initialValue = {
		partnerId,
		setPartnerId,
		apiKey,
		setApiKey,
		partnerName,
		setPartnerName,
		partnerInfo,
		setPartnerInfo,
		headerExists,
		setHeaderExists
	}

	useEffect(() => {
		let partnerType
		if (partnerName)
			partnerType = partnerDict[partnerName]
		else
			partnerType = partnerDict.kb

		setPartnerId(partnerType.partnerId)
		setApiKey(partnerType.apiKey)
		if (partnerName === 'skt')
			setHeaderExists(false)
		else
			setHeaderExists(true)
	}, [partnerName])

	useEffect(() => {
		if (partnerInfo)
			sessionStorage.setItem('partnerInfo', JSON.stringify(partnerInfo))

	}, [partnerInfo])
	return <PartnerContext.Provider value={initialValue}>{props.children}</PartnerContext.Provider>
}


const usePartnerContext = () => useContext(PartnerContext)

export { PartnerContextProvider, usePartnerContext }

