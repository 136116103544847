import React from 'react'
import './QuestionList.scss'
import { ReactComponent as Cursor } from '../../../assets/icon/cursor.svg'

export default function QuestionList(props) {
	const { item, itemIndex } = props
	return (
		<details className='collapse'>
			<summary className='collapse-title'>
				<div className='question-title'>
					<p className='question'>{itemIndex + 1}. {item.question}</p>
					<Cursor className='cursor' />
				</div>
			</summary>
			<p className='description' dangerouslySetInnerHTML={{ __html: item.answer }}>
			</p>
		</details>
	)
}


