import { ReactComponent as ShareIcon } from '../../../assets/icon/share-header.svg'
import { useModalContext } from '../../../context/Common/modal'

const Share = () => {
	const { shareOpen } = useModalContext()

	const openSharePopup = () => shareOpen('예약 내역을 다른 사람에게 공유하시겠습니까?', '텍스트 복사', '닫기', '공유하기')

	return <ShareIcon onClick={openSharePopup} />
}

export default Share
