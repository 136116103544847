import { setup } from 'axios-cache-adapter'

// axios 생성
let axios = setup({
	baseURL: process.env.REACT_APP_PAYMENT_API_HOST,
  headers: {
    'x-api-key': 'AD3945F3B12DC52A6BDD7BE98DFE5A4D598ED2BD',
  }
})

//
// API caller
//
const _call = async ({
	method = 'get',
	uri,
	headers = {},
	expires = 0,
	body = {},
}) => {
	method = method.toLowerCase()
	if (
		['get', 'delete', 'post', 'patch', 'put', 'options'].indexOf(method) < 0
	) {
		const message = `method is wrong - method: ${method}`
		throw new Error(message)
	}

	expires = Number(expires)
	if (isNaN(expires)) {
		console.warn(
			'expires is assigned 0 (=default value) because type is not number'
		)
		expires = 0
	}

	const request = axios({
		method,
		data: body,
		url: uri,
		cache: { maxAge: expires },
	})

	return await request.then(async (response) => {
		const data = response.data
		if (data.code && data.code !== 200 && response.status !== 200)
			throw data

		return data
	})
}

const getOrderId = async (transactionId) => {
	return _call({
		method: 'GET',
		uri: `/kbpay/v1/payments?paymentId=${transactionId}`,
	})
}


export {
	getOrderId
}
