import React from 'react'

export default function InputHint(props) {
	const { text } = props
	return (
		<div className="input-hint-container b3">
			<span className="hint-text">
				{text}
			</span>
		</div>
	)
}
