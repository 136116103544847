import { setup } from 'axios-cache-adapter'

// axios 생성
let axios = setup({
	baseURL: process.env.REACT_APP_MYTABLE_LEMON_API_HOST,
	headers: {
		'x-api-key': 'AD3945F3B12DC52A6BDD7BE98DFE5A4D598ED2BD',
	}
})

//
// API caller
//
const _call = async ({
	method = 'get',
	uri,
	headers = {},
	expires = 0,
	body = {},
}) => {
	method = method.toLowerCase()
	if (
		['get', 'delete', 'post', 'patch', 'put', 'options'].indexOf(method) < 0
	) {
		const message = `method is wrong - method: ${method}`
		throw new Error(message)
	}

	expires = Number(expires)
	if (isNaN(expires)) {
		console.warn(
			'expires is assigned 0 (=default value) because type is not number'
		)
		expires = 0
	}

	const request = axios({
		method,
		data: body,
		url: uri,
		cache: { maxAge: expires },
		headers: headers ? headers : axios.headers
	})

	return await request.then(async (response) => {
		const data = response.data
		if (data.code && data.code !== 200 && response.status !== 200)
			throw data

		return data
	})
}

class PartnerAPI {
	constructor(apiKey, authorization) {
		this.apiKey = apiKey;
		this.authorization = authorization;
		if (this.apiKey) {
			axios = setup({
				baseURL: process.env.REACT_APP_MYTABLE_LEMON_API_HOST,
				headers: {
					'x-api-key': this.apiKey,
					'authorization': this.authorization
				}
			});
		} else {
			//axios = null;
		}
	}

	kbLogin = async (kbUserId) => {
		return _call({
			method: 'POST',
			uri: `partners/kb/login`,
			body: {
				kbUserId
			},
		})
	}

	sktLogin = async (tParam) => {
		return _call({
			method: 'POST',
			uri: `partners/skt/login`,
			body: {
				tParam
			},
		})
	}

  shinhanLogin = async (tParam) => {
		return _call({
			method: 'POST',
			uri: `partners/shinhan/login`,
			body: {
				tParam
			}
		})
	}
	
	gyeongjuroLogin = async (body) => {
		return _call({
			method: 'POST',
			uri: 'partners/gyju/login',
			body,
		})
	}

	partnerLogin = async (tParam) => {
		return _call({
			method: 'POST',
			uri: `partners/login`,
			body: {
				tParam
			}
		})
	}
}

class PartnerPickupAPI {
	constructor(apiKey, authorization) {
		this.apiKey = apiKey;
		this.authorization = authorization;
		if (this.apiKey) {
			axios = setup({
				baseURL: process.env.REACT_APP_PICKUP_API_HOST,
				headers: {
					'x-api-key': this.apiKey,
					'authorization': this.authorization
				}
			});
		} else {
			//axios = null;
		}
	}

	gyeongjuroLogin = async (body) => {
		return _call({
			method: 'POST',
			uri: `partners/gyju/login`,
			body
		})
	}
}

export {
  PartnerAPI,
	PartnerPickupAPI
}
