import React, { useState, useMemo } from 'react'
import Subtitle from '../../Share/Subtitle'
import './UserInfoForm.scss'
import { usePaymentContext } from '../../../context/Pickup/payment'
import { AuthAPI } from '../../../lib/mytable'
import { usePartnerContext } from '../../../context/Common/partner'

export default function UserInfoForm() {

	const { paymentInfo, dispatchPaymentInfo } = usePaymentContext()
	const { apiKey } = usePartnerContext()

	const [phoneToken, setPhoneToken] = useState(null)
	const [phoneCode, setPhoneCode] = useState('')
	const phoneAuthAPI = useMemo(() => new AuthAPI(apiKey, null), [apiKey])
	const isAuthorized = useMemo(() => paymentInfo.authorization !== null, [paymentInfo])
	function setUserInfo(event, type) {
		dispatchPaymentInfo({ type, value: event.target.value })
	}

	const clickPhoneCodeButton = () => {
		phoneAuthAPI.requestPhoneCode(paymentInfo.user.phone)
			.then((res) => {
				alert('인증번호가 발송되었습니다.')
				setPhoneToken(res.token)
			})
			.catch((err) => alert(err.response.data.message))
	}

	const submitPhoneCode = () => {
		if (phoneToken === null) {
			alert('인증번호 받기를 클릭해주세요.')
		} else if (phoneCode.length !== 6) {
			alert('인증번호를 입력해주세요.')
		} else if (isAuthorized) {
			alert('이미 인증되었습니다.')
		} else {
			phoneAuthAPI.checkPhoneCode(phoneToken, phoneCode, paymentInfo.user.name, paymentInfo.user.email)
				.then((res) => {
					if (res.result) {
						alert('인증에 성공하였습니다.')
						console.log('123124124', res.token)
						dispatchPaymentInfo({ type: 'SET_AUTHORIZATION', value: res.token })
					} else {
						alert(res.message)
					}

				})
				.catch((err) => {
					console.log(err)
				})
		}
	}

	return (
		<div className="user-info-form-container">
			<Subtitle subtitle="주문자 정보"></Subtitle>
			<div className="required label">이름</div>
			<input type="text" className="input" placeholder="성함을 적어주세요" value={paymentInfo.user.name} onChange={(e) => setUserInfo(e, 'USER_NAME')}/>
			<div className="required label">연락처</div>
			<div className='input-group'>
				<input disabled={isAuthorized} type="tel" className="input" placeholder="숫자만 입력해주세요" value={paymentInfo.user.phone} onChange={(e) => setUserInfo(e, 'USER_PHONE')}/>
				<button disabled={isAuthorized} className='input-button' onClick={clickPhoneCodeButton}>인증번호 받기</button>
			</div>
			<div className="required label">인증번호</div>
			<div className='input-group'>
				<input
					type="text"
					maxLength="6"
					inputMode="numeric"
					className="input"
					placeholder="인증번호를 입력해주세요"
					value={phoneCode}
					onChange={(e) => setPhoneCode(e.target.value)}
					disabled={isAuthorized}
				/>
				<button disabled={isAuthorized} className='input-button' onClick={submitPhoneCode}>확인</button>
			</div>
			<div className="label">이메일주소</div>
			<input type="email" className="input" placeholder="예) abc@naver.com" value={paymentInfo.user.email} onChange={(e) => setUserInfo(e, 'USER_EMAIL')}/>
		</div>
	)
}

