import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react'
import { FormControl } from '@material-ui/core'
import BookingSubtitle from '../../../Share/Booking/BookingSubtitle/BookingSubtitle'
import { useMall, useReservation, useValidation } from '../../../../store'

export default function MenuSelector() {
	const { totalPartySize, menus, setReservation } = useReservation()
	const { policyMenus } = useMall()
	const { setValidation } = useValidation()
	const [requiredMenuSelect, setRequiredMenuSelect] = useState(false)

	const setMenus = (value, menuId) => {
		let selectedMenus = [...menus]
		let targetMenuIndex = selectedMenus.findIndex((bMenu) => bMenu.menu_id === menuId)
		if (targetMenuIndex > -1) {
			selectedMenus[targetMenuIndex].count = Number(value)
		} else {
			selectedMenus.push({
				// eslint-disable-next-line camelcase
				menu_id: menuId,
				count: Number(value),
			})
		}
		let count = 0
		let menuPrice = 0
		policyMenus.forEach((pMenu) => {
			selectedMenus.forEach((bMenu) => {
				if (pMenu.menu_id === bMenu.menu_id) {
					menuPrice += bMenu.count * pMenu.deposit_price
					count += pMenu.population * bMenu.count
				}
			})
		})
		if (requiredMenuSelect)
			setValidation('menus', count === totalPartySize)
		setReservation('menuPrice', menuPrice)
		setReservation('menus', selectedMenus)
	}

	const makeMenuOption = (menuId) => {
		let targetMenu = policyMenus.find((pMenu) => pMenu.menu_id === menuId)
		let numbers = []
		if (targetMenu.population_use) {
			let count = 0
			policyMenus.forEach((pMenu) => {
				menus.forEach((bMenu) => {
					if (pMenu.menu_id === bMenu.menu_id && pMenu.menu_id !== menuId)
						count += pMenu.population * bMenu.count
				})
			})
			numbers = [...Array(Math.floor((totalPartySize - count) / targetMenu.population) + 1).keys()]
		} else {
			numbers = [...Array(totalPartySize + 1).keys()]
		}
		return numbers
	}

	const getMenuCount = (menuId) => {
		let targetMenu = menus?.find((bMenu) => bMenu.menu_id === menuId)
		if (targetMenu)
			return targetMenu.count
		return 0
	}

	const sendParentEvent = () => {
		window.parent.postMessage({ func: 'hideKeyPad' }, '*')
	}

	useEffect(() => {
		if (policyMenus.some((menu) => menu.population_use)) {
			setRequiredMenuSelect(true)
			let count = 0
			policyMenus.forEach((pMenu) => {
				menus.forEach((bMenu) => {
					if (pMenu.menu_id === bMenu.menu_id)
						count = pMenu.population * bMenu.count
				})
			})
			setValidation('menus', count === totalPartySize)
		} else {
			setValidation('menus', true)
		}
	}, [policyMenus])

	useLayoutEffect(() => {
		if ((!menus.length && policyMenus.length > 0) || menus.length !== policyMenus.length) {
			const newMenus = []
			policyMenus.forEach((menu) => {
				newMenus.push({
					// eslint-disable-next-line camelcase
					menu_id: menu.menu_id,
					count: 0,
				})
			})
			setReservation('menus', newMenus)
		}
	}, [policyMenus])

	return (
		<div className="menu-selector-container">
			<BookingSubtitle subtitle="메뉴" firebrick={policyMenus.some((menu) => menu.enabled && menu.population_use)} />
			{policyMenus
				.filter((menu) => menu.enabled)
				.map((menu, idx) => (
					<Fragment key={idx}>
						{(!menu.population_use || (menu.population_use && menu.population <= totalPartySize)) && (
							<div className="menu-container">
								<div className="label-container">
									<div className="name b1">
										{menu.name} {menu.population_use && <span>({menu.population}인분)</span>}
									</div>
									<div className="b3">{menu.desc}</div>
									<div className="price b3">{menu.deposit_price.toLocaleString()}원</div>
								</div>
								<div className="select-container b1">
									<FormControl>
										<select
											placeholder="0개"
											onChange={(e) => setMenus(e.target.value, menu.menu_id)}
											value={getMenuCount(menu.menu_id)}
											className="select-menu-box"
											native="true"
											onBlur={sendParentEvent}
										>
											{makeMenuOption(menu.menu_id).map((n) => (
												<option value={n} key={n}>
													{n}개
												</option>
											))}
										</select>
									</FormControl>
								</div>
							</div>
						)}
					</Fragment>
				))}
		</div>
	)
}
