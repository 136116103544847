import React, { useCallback, useEffect, useLayoutEffect, useMemo } from 'react'
import { FormControl } from '@material-ui/core'
import BookingSubtitle from '../../../Share/Booking/BookingSubtitle/BookingSubtitle'
import { useMall, useReservation, useValidation } from '../../../../store'
export default function DetailPartySize() {
	const { info_people: detailPeople } = useMall((state) => state.mall)
	const { totalPartySize, detailPartySize, setReservation } = useReservation()
	const { setValidation } = useValidation()
	const hasDetailPartySize = useMemo(() => Object.keys(detailPartySize).length > 0, [detailPartySize])

	const initialPeopleItem = () => {
		if (hasDetailPartySize)
			return

		const filteredDetailPeople = detailPeople?.filter((item) => item.enabled)
		if (filteredDetailPeople?.length > 0) {
			detailPeople?.forEach((item) => {
				detailPartySize[item.name] = 0
			})
			setReservation('detailPartySize', detailPartySize)
		} else {
			setValidation('detailPartySize', true)
		}
	}

	const calculateOptionRange = useCallback(
		(type) => {
			const { [type]: curItem, ...diffItem } = { ...detailPartySize }
			return Array.from(
				{ length: totalPartySize - Object.values(diffItem).reduce((acc, cur) => acc + cur, 0) },
				(v, i) => i + 1
			).map((ele, index) => <option key={index}>{ele + '명'}</option>)
		},
		[totalPartySize, detailPartySize]
	)

	const sendParentEvent = () => {
		window.parent.postMessage({ func: 'hideKeyPad' }, '*')
	}

	useLayoutEffect(() => initialPeopleItem(), [detailPeople])

	useEffect(() => {
		if (!hasDetailPartySize)
			return
		setValidation('detailPartySize', Object.values(detailPartySize).reduce((acc, cur) => acc + cur) === totalPartySize)
	}, [totalPartySize, detailPartySize])

	if (detailPeople?.filter((item) => item.enabled)?.length === 0)
		return null

	return (
		<div className="detail-party-size-container">
			<BookingSubtitle subtitle="인원 상세" firebrick={true} />
			{detailPeople.map((people, idx) => {
				const peopleTitle = people.name_display || people.name
				return (
					<div className="people-container" key={idx}>
						<div className="label-container">
							<div className="name b1">{peopleTitle}</div>
							{people.notice && (
								<div className="description">
									<div className="b3">{people.notice}</div>
								</div>
							)}
						</div>
						<div className="select-container">
							<FormControl>
								<select
									placeholder="0명"
									onChange={(e) =>
										setReservation('detailPartySize', {
											...detailPartySize,
											[people.name]: parseInt(e.target.value),
										})
									}
									value={detailPartySize[people.name] + '명'}
									className="select-people-box b1"
									native="true"
									id={'select-people-box' + idx}
									onBlur={sendParentEvent}
								>
									<option>0명</option>
									{calculateOptionRange(people.name)}
								</select>
							</FormControl>
						</div>
					</div>
				)
			})}
		</div>
	)
}
