import { create } from 'zustand'
import moment from 'moment/moment'
import Format from '../utils/Format'

export const PAYMENT_KEY = {
	kakao: {
		name: '카카오페이',
		nicepay: 'KAKAO',
	},
	card: {
		name: '일반 카드 결제',
		nicepay: 'PG',
	},
}

const userInitialState = {
	name: undefined,
	phone: undefined,
	email: undefined,
	menus: [],
	menuPrice: 0,
	additionalInfos: undefined,
	request: undefined,
	payment: {
		type: 'BILLING_KEY', // PG | BILLING_KEY | KAKAO | NAVER
		billingKeyContext: {
			type: 'INDIVIDUAL', // INDIVIDUAL | CORPORATION
			cardNumbers: new Array(4).fill(''),
			expiry: {
				year: moment().format('yy'),
				month: '01',
			},
			password: '',
			identifyCode: '',
		},
	},
}

const categoryInitialState = {
	categoryId: undefined,
	deposit: 0,
}

const useReservation = create((set, get) => ({
	// State
	...userInitialState,
	...categoryInitialState,
	totalPartySize: 0,
	detailPartySize: {},
	dateTime: undefined,
	agreement: undefined,
	voucherData: undefined,
	reservationId: undefined,

	// Getter
	getLocaleDeposit: () => ((get().deposit + get().menuPrice).toLocaleString('ko-KR') + '원'),
	getTotalAmount: () => (parseInt(get().deposit) + parseInt(get().menuPrice)),
	getVoucherData: () => {
		const voucherSessionData = sessionStorage.getItem('voucherData')
		const regexAllBooking = /((booking)\/\S+\/(step)|(booking\/mall-detail))/g
		if (voucherSessionData && regexAllBooking.test(window.location.pathname))
			set({ voucherData: JSON.parse(voucherSessionData) })
	},
	getHoldingData: () => {
		const tablemanagerHoldings = JSON.parse(sessionStorage.getItem('tablemanagerHoldings'))
		const {
			name,
			totalPartySize,
			detailPartySize,
			dateTime,
			phone,
			email,
			menus,
			menuPrice,
			deposit,
			additionalInfos,
			request,
		} = { ...tablemanagerHoldings }
		Object.entries({
			name,
			totalPartySize,
			detailPartySize,
			dateTime: moment(dateTime),
			email,
			menus,
			menuPrice,
			deposit,
			additionalInfos,
			request,
		}).forEach(([key, value]) => set({ [key]: value }))
		set({ phone: Format.phone(phone) })
	},
	getPartySizeString: () => {
		return Object.entries(get().detailPartySize)
			.filter(([, partySize]) => partySize)
			.map(([partyName, partySize]) => `${partyName} ${partySize}명`)
			.join(', ')
	},

	// Setter
	setReservation: (type, value) => set({ [type]: value }),

	// Reset
	resetUser: () => set(userInitialState),
	resetDetailPartySize: () =>
		set((state) => {
			Object.keys(state.detailPartySize).forEach((key) => {
				state.detailPartySize[key] = 0
			})
			return { detailPartySize: { ...state.detailPartySize } }
		}),
	resetCategory: () => set(categoryInitialState),
	resetReservation: () =>
		set((state) => {
			state.resetUser()
			state.resetCategory()
			return {
				totalPartySize: 0,
				detailPartySize: {},
				dateTime: undefined,
				agreement: undefined,
				reservationId: undefined,
			}
		}),
}))

export default useReservation
