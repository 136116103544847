import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { PartnerAPI } from '../../lib/partner'
import { usePartnerContext } from '../../context/Common/partner'

export default function CallBack() {
	const location = useLocation()
	const search = useLocation().search
	const history = useHistory()
	const [items, setItems] = useState([])
	const loginParam = sessionStorage.getItem('tParam') || new URLSearchParams(search).get('tParam')
	const toRedirect = new URLSearchParams(search).get('to')
	const { setPartnerInfo, apiKey, partnerName } = usePartnerContext()
	const partnerAPI = new PartnerAPI(apiKey, null)

	async function decrypt () {
		if (window.location.origin.includes('skt') || localStorage.getItem('partnerName') === 'skt') {
			history.replace(`/booking/main?tParam=${loginParam}`)
		} else if ((window.location.origin.includes('shinhanplay') || localStorage.getItem('partnerName') === 'shinhan') && loginParam) {
			try {
				sessionStorage.setItem('tParam', loginParam)
				await partnerAPI.shinhanLogin(loginParam).then(res => {
					sessionStorage.setItem('authorization', res.authorization)
					setPartnerInfo({
						name: res.name,
						phone: res.phone
					})
					sessionStorage.setItem('referral', 'shinhanplay')
					if (toRedirect && toRedirect === 'mypage')
						history.replace('/booking/main?redirect=mypage')
					else
						history.replace('/booking/main')
				})
			} catch (e) {
				alert('정상적인 값이 입력되지 않았습니다.')
			}
		} else {
			try {
				await partnerAPI.partnerLogin(loginParam).then(res => {
					sessionStorage.setItem('authorization', res.authorization)
					setPartnerInfo({
						name: res.name,
						phone: res.phone,
						email: res.email
					})
					if (toRedirect && toRedirect === 'mypage')
						history.replace('/booking/main?redirect=mypage')
					else
						history.replace('/booking/main')
				})
			} catch (e) {
				alert('정상적인 값이 입력되지 않았습니다.')
			}
		}
	}

	useEffect(() => {
		if (apiKey)
			decrypt()
		else if (!loginParam)
			history.replace('/voucher/main')
	}, [loginParam, apiKey])

	return (
		<div>
			{items.map((item, idx) => <div key={item + idx}>{item.split('=').map((s, idx2) => <span key={s + idx2}>{s}{idx2 === 0
				? '='
				: null}</span>)}</div>)}
		</div>
	)
}
