import React from 'react'
import { useChangeNameContext } from '../../../../context/User/changeName'
import './ChangeForm.scss'
import { Button } from '@material-ui/core'

export default function ChangeForm(props) {
	const { name, setName } = useChangeNameContext()
	return (
		<div className="change-form-container">
			<div className="title">변경할 이름</div>
			<div className="input-box">
				<input placeholder="변경할 이름을 입력해주세요" type="text" value={name} onInput={(e) => setName(e.target.value)} className="input-form" />
			</div>
			<div className="notice-list">
				<div className="notice">· 입력된 이름은 매장 예약 정보로 사용됩니다.</div>
				<div className="notice">· 실명이 아닐 경우 서비스 이용이 제한될 수 있습니다.</div>
			</div>
			<div className="footer-container">
				<Button variant="contained" color="primary" className="change-button" onClick={props.patchName}>변경하기</Button>
			</div>
		</div>
	)
}
