import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useMall, useProvisionalReservation, useReservation } from '../../../store'
import { usePartnerContext } from '../../../context/Common/partner'
import classNames from 'classnames'

export default function BookingInfoView(props) {
	const { mini } = props
	const { mallUri } = useParams()
	const { totalPartySize } = useReservation()
	const { mall } = useMall()
	const { dateTime } = useReservation()
	const { cancelReservation } = useProvisionalReservation()
	const { apiKey } = usePartnerContext()
	const history = useHistory()

	const goBack = async () => {
		await cancelReservation(apiKey, mallUri)
		history.replace(`/booking/${mallUri}/step/info`)
	}

	if (!mall || !dateTime)
		return null

	return (
		<div className={classNames('booking-info-view-container', { mini })}>
			<label className="mall-name t2">{mall.mall_name}</label>
			{!mini
				? (
					<div className="detail-info-box b1" onClick={goBack}>
						<div>
							<label>인원</label>
							<span>총 {totalPartySize}명</span>
						</div>
						<div>
							<label>날짜</label>
							<span>{dateTime.format('M월 D일')}</span>
						</div>
						<div>
							<label>시간</label>
							<span>{dateTime.format('LT')}</span>
						</div>
					</div>
				)
				: (
					<span className="detail-info-text">{dateTime.format('YYYY.M.D (dd) ∙ LT') + ` ∙ ${totalPartySize}명`}</span>
				)}
		</div>
	)
}
