import React from 'react'
import { ReactComponent as Search } from '../../../../assets/icon/search.svg'
import './FindRestaurantButton.scss'
import { useHistory } from 'react-router-dom'
import { useSearchContext } from '../../../../context/Booking/search'

export default function FindRestaurantButton() {
	const history = useHistory()

	function goSearch() {
		history.push('/booking/search')
	}

	return (
		<div className="find-restaurant-container" onClick={goSearch}>
			<div className="find-restaurant-button b1">
        어떤 레스토랑을 찾으세요?<Search />
			</div>
		</div>
	)
}
