import React from 'react'
import './VoucherInfo.scss'
export default function VoucherInfo(props) {
	return (
		<div className="voucher-info-container">
			<div className="voucher-name">{props.name} 티켓</div>
			<div className="voucher-box">
				<div className="voucher-amount">{props.amount.toLocaleString()}원</div>
				<div className="voucher-amount-text">현재 잔액</div>
			</div>
		</div>
	)
}
