import React from 'react'
import { FormControl, InputLabel, OutlinedInput } from '@material-ui/core'
import './OutlineInput.scss'

export default function OutlineInput(props) {
	const {
		name,
		onBlur,
		onChange,
		placeholder,
		value = '',
		label = '',
		type = 'text',
		state = 'default',
		multilineMax = 500,
		multiline = false,
		required = false,
	} = props

	return (
		<div className="outline-input-container">
			{
				label
					? <InputLabel className={'label'} required={required} htmlFor="component-outlined">{label}</InputLabel>
					: ''
			}

			<FormControl className={`${state}`}>
				<OutlinedInput
					id="component-outlined"
					type={type}
					name={name}
					value={value}
					onBlur={onBlur}
					onChange={onChange}
					placeholder={placeholder}
					multiline={multiline}
					inputProps={
						multiline
							? { maxLength: multilineMax }
							: {}
					}
				/>
			</FormControl>
		</div>
	)
}
