import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { ReactComponent as Arrow } from '../../../../assets/icon/arrowRightGray.svg'
import { useAccountContext } from '../../../../context/Common/account'
import './VoucherSelectionModal.scss'

export default function VoucherSelectionModal(props) {
	const history = useHistory()
	const { open, onClose, voucherPointId, malls, voucherOrderItemId, productId } = props
	const { user } = useAccountContext()

	const closeEvent = () => {
		history.goBack()
		onClose()
	}
	const modalClose = (event) => {
		if (event.target.className === 'voucher-selection-modal')
			closeEvent()
	}

	const goVoucherDetail = (mallUri) => {
		onClose()
		const voucherData = {
			theyeyakUserToken: sessionStorage.getItem('authorization') || user.token,
			voucherPointId,
			voucherOrderItemId,
			productId,
		}
		sessionStorage.setItem('voucherData', JSON.stringify(voucherData))
		history.push(`/booking/mall-detail/${mallUri}`)
	}

	const popstateEvent = (event) => {
		event.preventDefault()
		onClose()
	}

	useEffect(() => {
		window.addEventListener('popstate', popstateEvent)
		return (() => {
			window.removeEventListener('popstate', popstateEvent)
		})
	}, [])

	useEffect(() => {
		if (open)
			history.push(`${history.location.pathname}#findMall`)
	}, [open])

	return (
		<>
			{
				open
					? (
						<div className="voucher-selection-modal" onClick={modalClose}>
							<div className="modal-container">
								<div className="modal-header">
									방문 장소 선택
								</div>

								<div className="modal-body">
									{
										malls?.map((mall, index) => (
											<div key={index} className="mall-list-item" onClick={(() => goVoucherDetail(mall.uri))}>
												<span className="mall-name">{mall.name}</span>
												<div className="move-icon-wrapper">
													<Arrow className="right-arrow"></Arrow>
												</div>
											</div>
										))
									}
								</div>
								<div className="modal-footer" onClick={closeEvent}>
									<button className="close-button">닫기</button>
								</div>
							</div>
						</div>
					)
					: <></>
			}
		</>
	)
}
