import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useMyPageContext } from '../../../context/Booking/myPage'
import { usePartnerContext } from '../../../context/Common/partner'
import { BookingMyPageAPI } from '../../../lib/mytable'
import moment from 'moment'
import BookingFooter from '../../../Components/Share/Booking/BookingFooter/BookingFooter'
import { useModalContext } from '../../../context/Common/modal'
import { Address, MallInfo, MyPageDepositNotice, RefundModal, UserInfo } from '../../../Components/Booking'
import useDynamicComponent from '../../../hooks/useDynamicComponent'
import { Address as AddressGyeongjuro } from '../../../Components/Gyeongju/Address'

export default function DetailReservationInfo() {
	const { reservationInfo, setReservationInfo, reservationCancelToken, setReservationCancelToken } = useMyPageContext()
	const { alertOpen, confirmOpen, confirmCallback, setConfirmCallback } = useModalContext()
	const params = useParams()
	const { apiKey, partnerInfo, partnerName } = usePartnerContext()
	const bookingMyPageAPI = useMemo(() => new BookingMyPageAPI(apiKey, null), [apiKey])
	const history = useHistory()
	const [popRefundModal, setPopRefundModal] = useState(false)
	const [depositInfo, setDepositInfo] = useState()
	const { renderComponent } = useDynamicComponent()

	const fetchReservationInfo = () => {
		let authorization = sessionStorage.getItem('authorization')
		let userId = authorization
		if (partnerName === 'kb')
			userId = `member_id:kb ${partnerInfo.chnlMbrId}`
		if (partnerName === 'tablemanager')
			userId = `Bearer ${authorization}`

		bookingMyPageAPI.getReservation(params.reservationId, userId).then(res => {
			if (res.result) {
				res.date = moment(new Date(res.date_y, res.date_m - 1, res.date_d)).set('minute', Math.round(res.date_h_start * 60))
				setReservationInfo(res)
			}
		})
	}

	useEffect(() => {
		if ((params.reservationId && !reservationInfo) && apiKey)
			fetchReservationInfo()
	}, [apiKey, params.reservationId])

	useEffect(() => {
		if (typeof confirmCallback === 'boolean') {
			closeConfirm(confirmCallback)
			setConfirmCallback(null)
		}
	}, [confirmCallback])

	useEffect(() => {
		if (reservationInfo) {
			const date = reservationInfo.date.format('YYYY년 MM월 DD일')
			const time = reservationInfo.date.format('LT')
			const people = reservationInfo.info_people.reduce((a, b) => a + b.count, 0)
			let peopleDetail = ''
			if (reservationInfo.info_people.length > 0) {
				peopleDetail = reservationInfo.info_people
					.filter(item => item.count > 0).map(
						(item) => `${item.name_display || item.name} ${item.count}명`
					)
					.join(',')
			}
			const mallPhone = reservationInfo.mall_phone
			const mallAddress = reservationInfo.location_address.road
			const mallName = reservationInfo.mall_name
			const userName = reservationInfo.user_name
			let partnerType = '테이블매니저'
			if (partnerName === 'skt')
				partnerType = 'SKT VIP'
			if (partnerName === 'kb')
				partnerType = 'KB Pay'
			if (partnerName === 'mfg')
				partnerType = '매드포갈릭'
			if (partnerName === 'gyeongjuro')
				partnerType = '경주로ON'
			const desc = `[${partnerType}}/예약완료]

▶매장명 : ${mallName}
▶이용예정일 : ${date} ${time}
▶매장연락처 : ${mallPhone}
▶주소 : ${mallAddress}
▶예약자 : ${userName}님
▶예약인원 : ${people}명 (${peopleDetail})`
			localStorage.setItem('shareText', desc)
		}
	}, [reservationInfo])

	const scrollTopCancelReservation = () => {
		if (window.scrollY > 0)
			window.scrollTo(0, 0)
	}

	function clickFooter() {
		if (reservationInfo.state === 0 && !reservationInfo.isCancel) {
			let authorization = sessionStorage.getItem('authorization')
			let userId = authorization
			if (partnerName === 'kb')
				userId = `member_id:kb ${partnerInfo.chnlMbrId}`
			if (partnerName === 'tablemanager')
				userId = `Bearer ${authorization}`
			bookingMyPageAPI.callReservationCancel(reservationInfo.reservation_id, userId).then(res => {
				setReservationCancelToken(res.token)
				if (res.deposit_price > 0) {
					setDepositInfo({
						depositPrice: res.deposit_price,
						refundPercent: res.refund_percent,
						refundPrice: res.refund_price
					})
					setPopRefundModal(true)
					document.body.classList.add('modal-open')
					return
				}
				confirmOpen('예약을 취소하겠습니까?', '예약 취소', '닫기', '예약 취소하기')
			})
			return
		}
		history.goBack()
	}
	function closeConfirm(res) {
		if (res)
			cancelReservation()
	}
	function closeRefund(res) {
		if (res)
			cancelReservation()
		setPopRefundModal(false)
		document.body.classList.remove('modal-open')
	}
	function cancelReservation() {
		let authorization = sessionStorage.getItem('authorization')
		let userId = authorization
		if (partnerName === 'kb')
			userId = `member_id:kb ${partnerInfo.chnlMbrId}`
		if (partnerName === 'tablemanager')
			userId = `Bearer ${authorization}`
		bookingMyPageAPI.cancelReservation(reservationInfo.reservation_id, reservationCancelToken, userId).then(res => {
			if (res.result) {
				alertOpen('예약이 취소되었습니다.', '닫기')
				setReservationInfo({ ...reservationInfo, isCancel: true })

				scrollTopCancelReservation()
			}
		})
	}

	if (!reservationInfo)
		return null

	return (
		<div className="detail-reservation-info-container">
			<MallInfo></MallInfo>
			<UserInfo></UserInfo>
			{renderComponent({
				default: <Address lat={reservationInfo.location_lat} lng={reservationInfo.location_lng} address={reservationInfo.location_address} phone={reservationInfo.mall_phone}></Address>,
				gyeongjuro: <AddressGyeongjuro location={reservationInfo} />
			})}
			{reservationInfo.deposit_price > 0
				? <MyPageDepositNotice></MyPageDepositNotice>
				: <div className="deposit-placeholder"></div>}
			<BookingFooter text={reservationInfo.state === 0 && !reservationInfo.isCancel
				? '예약취소'
				: '닫기'} action={clickFooter} active={true}></BookingFooter>
			<RefundModal open={popRefundModal} onClose={closeRefund} depositInfo={depositInfo}></RefundModal>
		</div>
	)
}
