import './CheckboxGroup.scss'

import { Checkbox, FormControlLabel } from '@material-ui/core'

import React from 'react'
import { useMenuOptionContext } from '../../../context/Pickup/menuOption'
import { usePickupContext } from '../../../context/Pickup/pickup'

export default function CheckboxGroup(props) {
	const { detailMenu } = usePickupContext()
	const { dispatchMenuOption, menuOption } = useMenuOptionContext()
	const optionGroup = menuOption[props.optionGroup._id]
	if (detailMenu === null || !optionGroup)
		return <div>loading</div>

	function changeCheckbox(event, type, optionId) {
		dispatchMenuOption({ type, optionGroupId: props.optionGroup._id, optionId: optionId })
	}
	return (
		<div className="checkbox-container">
			{props.optionGroup.options.map(option =>
				<div className="checkbox-box" key={option.name}>
					<FormControlLabel value={option.value} control={<Checkbox color="primary"/>} label={option.name} onChange={(e) => changeCheckbox(e, 'MULTI_SELECT', option._id)}/>
					<div> {option.price
						? `(${option.price.toLocaleString()}원)`
						: null}</div>
				</div>
			)}
		</div>
	)
}
