import { create } from 'zustand'

const initialState = {
	expire: null,
	remainTime: '--:--'
}

const useTimer = create((set) => ({
	...initialState,

	// Setter
	setTimer: (type, value) => set({ [type]: value }),

	// Reset
	resetTimer: () => set(initialState)
}))

export default useTimer
