import classNames from 'classnames'
import React, { useState } from 'react'

const Input = (props) => {
	const { title, required, description, error, className, onFocus, onBlur, children, ...rest } = props
	const useDescription = error || description
	const [focus, setFocus] = useState(false)

	return (
		<div className={classNames('gyeongju-input-component', className)}>
			<label>
				{required && <mark>*</mark>}
				{title}
			</label>
			<input
				{...rest}
				onFocus={(e) => {
					setFocus(true)
					onFocus && onFocus(e)
				}}
				onBlur={(e) => {
					setFocus(false)
					onBlur && onBlur(e)
				}}
			/>
			{useDescription && (
				<span
					className={classNames('gyeongju-input-component-description', {
						error,
						hideError: error && focus,
					})}
				>
					{useDescription}
				</span>
			)}
			{children}
		</div>
	)
}

export default Input
