import React from 'react'
import './MenuInfo.scss'

export default function MenuInfo(props) {
	return (
		<div className="menu-info-container">
			<div className="menu-name">{props.menuName}</div>
			<div className="menu-price">{props.menuPrice.toLocaleString()}원</div>
		</div>
	)
}
