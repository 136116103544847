import { setup } from 'axios-cache-adapter'

declare var dataLayer
declare var fbq
const axios = setup({
	baseURL: process.env.REACT_APP_PICKUP_API_HOST,
	headers: { 'x-api-key': 'F1B80501BB7989BB5B15B4934DD268BA88261B8D', }
})

class TrackingAPI {
	constructor(user) {
		this.user = user
	}

	like = async (mallInfo, enabled) => {
		dataLayer.push({
			event: 'like',
			shop: mallInfo,
			name: this.user?.name,
			email: this.user?.email,
			phone: this.user?.phone,
			enabled
		})
		fbq('trackCustom', 'like', {
			shop: mallInfo,
			name: this.user?.name,
			email: this.user?.email,
			phone: this.user?.phone
		});
	}
	
	mypage = async (description) => {
		dataLayer.push({
			event: 'mypage',
			name: this.user?.name,
			email: this.user?.email,
			phone: this.user?.phone,
			description
		})
		fbq('trackCustom', 'mypage', {
			name: this.user?.name,
			email: this.user?.email,
			phone: this.user?.phone
		});
	}

	purchase = async (mallInfo, description) => {
		dataLayer.push({
			event: 'purchase',
			shop: mallInfo,
			name: this.user?.name,
			email: this.user?.email,
			phone: this.user?.phone,
			description
		})
		fbq('trackCustom', 'purchase', {
			shop: mallInfo,
			name: this.user?.name,
			email: this.user?.email,
			phone: this.user?.phone
		});
	}

	share = async (mallName, type) => {
		dataLayer.push({
			event: 'share',
			shop: mallName,
			name: this.user?.name,
			email: this.user?.email,
			phone: this.user?.phone,
			type
		})
		fbq('trackCustom', 'mypage', {
			name: this.user?.name,
			email: this.user?.email,
			phone: this.user?.phone
		});
	}

	showDetail =(mallName, description) => {
		dataLayer.push({
			event: 'moreInfo',
			shop: mallName,
			name:  this.user?.name ,
			email: this.user?.email ,
			phone: this.user?.phone ,
			description
		})
		fbq('trackCustom', 'moreInfo', {
			shop: mallName,
			name:  this.user?.name ,
			email: this.user?.email ,
			phone: this.user?.phone ,
			description
		});
	}
}

export default TrackingAPI
