import React from 'react'
import { useBookingContext } from '../../../../context/Booking/booking'
import './DepositInfo.scss'
import BookingSubtitle from '../../../Share/Booking/BookingSubtitle/BookingSubtitle'
import { useMall } from '../../../../store'
export default function DepositInfo() {
	const { deposit_refunds: refunds, deposit_exists: depositExists } = useMall(state => state.mall)

	if (!refunds || refunds.length === 0 || !depositExists)
		return null

	return (
		<div className="deposit-info-container">
			<BookingSubtitle subtitle="예약금"></BookingSubtitle>
			<div className="noshow-text b1"><span className="warn">No Show</span> (예약을 하고 나타나지 않는 행위) 방지를 위해 예약금 제도를 운영하는 매장입니다. 예약금은 예약 일시 및 인원에 따라 결정되며, 정확한 금액은 예약 단계에서 확인할 수 있습니다.
			</div>
		</div>
	)
}
