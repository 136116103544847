import React from 'react'
import ActionButton from '../ActionButton/ActionButton'
import { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import './MenuList.scss'
import { usePickupContext } from '../../../context/Pickup/pickup'
import MenuCategory from '../MenuCategory/MenuCategory'

export default function MenuList(props) {
	const history = useHistory()
	const [disabled, setDisabled] = useState(true)
	const { orderSheet } = usePickupContext()
	const params = useParams()
	function onClick() {
		history.push(`/pickup/${params.mallUri}/order-sheet`)
	}

	useEffect(() => {
		if (orderSheet && orderSheet.length > 0)
			setDisabled(false)
		else
			setDisabled(true)


	}, [orderSheet])


	return (
		<div className="gyeongjuro-menu-list-container">
			{props.categories.map((category, idx) => <MenuCategory category={category} key={idx}/>)}
			<div className="button-container">
				<ActionButton
					onClick={onClick}
					disabled={disabled}
					variant="contained"
					color="primary"
					fullWidth={true}
					className="gyeongjuro-action-button"
					label="장바구니">
				</ActionButton>
			</div>
		</div>
	)
}
