import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Seperator from '../../../Components/Share/Seperator'
import MyTableFooter from '../../../Components/Voucher/Footer/MyTableFooter/MyTableFooter'
import ReservationItem from '../../../Components/Voucher/MyPage/ReservationItem/ReservationItem'
import VoucherItem from '../../../Components/Voucher/MyPage/VoucherItem/VoucherItem'
import { useAccountContext } from '../../../context/Common/account'
import { useModalContext } from '../../../context/Common/modal'
import { useVoucherContext } from '../../../context/Voucher/voucher'
import { logoutKakao } from '../../../lib/kakao'
import { oldCancelReservation, reqeustOldCancelReservation } from '../../../lib/mytable'
import { getMyReservations, getMyVoucherOrders } from '../../../lib/voucher'
import './MyPage.scss'
import './../../../constants/voucherType'
import { VOUCHER_TYPE } from '../../../constants/voucherType'

const seperatorStyle = { margin: 0 }
const reservationType = {
	AVAILABLE: 'AVAILABLE',
	PAST: 'PAST',
	CANCEL: 'CANCEL'
}

export default function MyPage(props) {
	const history = useHistory()
	const { user, setUser } = useAccountContext()
	const { toastOpen } = useModalContext()
	const { openVoucherMallSelectionModal } = useVoucherContext()
	const [voucherItems, setVoucherItems] = useState(null)
	const [reservationItems, setReservationItems] = useState(null)
	const [isRefund, setIsRefund] = useState(false)

	const setVoucherOrders = () => {
		getMyVoucherOrders(user.token, false, ['AVAILABLE'].join(','), undefined, { withNote: 'Y' }).then((res) => {
			setVoucherItems(res.orders)
		}).catch(err => console.log(err))
	}

	const setReservations = () => {
		getMyReservations(user.token, 'VOUCHER')
			.then((res) => {
				const reservationData = {
					AVAILABLE: [],
					PAST: [],
					CANCEL: [],
				}
				res.reservations.forEach(data => {
					switch (data.state) {
					case reservationType.AVAILABLE:
						reservationData[reservationType.AVAILABLE] = reservationData[reservationType.AVAILABLE].concat(data)
						break
					case reservationType.PAST:
						reservationData[reservationType.PAST] = reservationData[reservationType.PAST].concat(data)
						break
					case reservationType.CANCEL:
						reservationData[reservationType.CANCEL] = reservationData[reservationType.CANCEL].concat(data)
						break
					default:
						reservationData[reservationType.AVAILABLE] = reservationData[reservationType.AVAILABLE].concat(data)
					}
				})
				setReservationItems(reservationData)
			}).catch(err => console.log(err))
	}

	const logout = () => {
		logoutKakao()
		setUser(null)
		localStorage.removeItem('user')
		toastOpen('로그아웃되었습니다.', 2000)
		history.push('/voucher/main')
	}

	const cancelReservation = (reservationId) => {
		reqeustOldCancelReservation(reservationId, user.token)
			.then(res => {
				if (res.token) {
					oldCancelReservation(reservationId, res.token, user.token)
						.then(() => {
							setReservations()
						})
				}
			})
	}

	const getQueryParams = () => history.location.search
		.replace('?', '')
		.split('&')
		.reduce((r, e) => {
			const key = e.split('=')[0]
			const value = decodeURIComponent(e.split('=')[1])
			return {
				...r,
				[key]: value
			}
		}, {})

	useEffect(() => {
		if (user) {
			setVoucherOrders()
			setReservations()
		}
	}, [user])

	useEffect(() => {
		if (isRefund)
			setVoucherOrders()

	}, [isRefund])

	const filterTicketVoucherTypeItems = voucherItems?.filter(item => item.products[0].voucherType === VOUCHER_TYPE.TICKET)

	useEffect(() => {
		if (history.location.state) {
			if (voucherItems && history.location.state.move === 'purchaseList')
				document.querySelector('#purchase-list').scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' })
			if (reservationItems && reservationItems[reservationType.AVAILABLE] && history.location.state.move === 'reservationList')
				document.querySelector('#reservation-list').scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' })
		}
		const params = getQueryParams()
		if (params.inquiryId && voucherItems && voucherItems.length > 0) {
			const selectedVoucher = voucherItems.find(voucher => voucher.inquiryId === params.inquiryId)
			const sameSelectedProductItem = selectedVoucher?.products?.find(item => item.externalId === selectedVoucher?.points[0].externalId)

			let pointId = ''
			if (selectedVoucher?.points[0].amount > 0)
				pointId = selectedVoucher?.points[0].id
			openVoucherMallSelectionModal(pointId, selectedVoucher?.brand.malls, sameSelectedProductItem.itemId, sameSelectedProductItem.id)
		}
	}, [voucherItems, reservationItems])


	const totalVoucherLengthIncludeTicket = () => {
		// 빕스 예약상품권 - 일괄차감권 개수를 포함하여 예약상품권 목록 개수 노출
		const onlyTicketVoucherLength = filterTicketVoucherTypeItems?.reduce((acc, cur) => Number(acc) + (cur.products.length - 1), [0])

		return voucherItems ?
			(onlyTicketVoucherLength + voucherItems.length)
			: 0
	}

	return (
		<div className="my-page-container">
			<div className="user-info-wrapper">
				<p className="user-info-view">
					{user && user.name}님<br />
					반갑습니다 😄
				</p>
				<button className="logout-button" onClick={logout}>로그아웃</button>
			</div>

			<Seperator style={seperatorStyle}></Seperator>

			<div id="purchase-list" className="voucher-products">
				<h3 className="voucher-products-title">
					예약상품권 목록 ({totalVoucherLengthIncludeTicket()})
				</h3>

				{
					voucherItems?.length > 0 ?
						voucherItems.map(item => {
							if (item.products[0].voucherType === VOUCHER_TYPE.TICKET) {
								// 빕스 예약상품권(일괄차감권) - orders로 리스트업이 아닌, orders > products로 리스트업 합니다.
								// ex. 런치 1매/디너 1매 구매 -> 런치 order 1개, 디너 order 1개 총 2개의 리스트
								// ex. 런치 2매 -> order 1개 및 개수: 2장
								return item.products.map(ticketItem => {
									return <VoucherItem setIsRefund={setIsRefund} key={ticketItem.itemId} type='voucher' data={item} user={user} ticketData={ticketItem} buttonEvent={() =>
										openVoucherMallSelectionModal(item.inquiryId, item.brand.malls, ticketItem.itemId, ticketItem.id)
									} />

								})
							}
							return <VoucherItem setIsRefund={setIsRefund} key={item.inquiryId} type="voucher" data={item} user={user} buttonEvent={(() => openVoucherMallSelectionModal(item.inquiryId, item.brand.malls, item.products[0]?.itemId, item.products[0]?.id))} />
						})
						: (
							<p className="none-items">
							보유하신 예약상품권이 없습니다<br />
							더예약을 통해 특별혜택을 받아보세요!
							</p>
						)
				}
			</div>

			<Seperator style={seperatorStyle}></Seperator>

			<div id="reservation-list" className="voucher-products">
				<h3 className="voucher-products-title">
					예정된 예약 ({
						reservationItems
							? reservationItems[reservationType.AVAILABLE].length
							: 0
					})
				</h3>

				{
					reservationItems && reservationItems[reservationType.AVAILABLE].length > 0 ?
						reservationItems[reservationType.AVAILABLE].map((item, index) => <VoucherItem setIsRefund={setIsRefund} key={index} type="reservation" data={item} user={user} buttonEvent={cancelReservation}></VoucherItem>)
						: (
							<p className="none-items">
								예정된 예약이 없습니다
							</p>
						)
				}
			</div>

			<Seperator style={seperatorStyle}></Seperator>

			<div className="voucher-products">
				<h3 className="voucher-products-title">
					지난 예약 ({
						reservationItems
							? reservationItems[reservationType.PAST].length
							: 0
					})
				</h3>

				{
					reservationItems && reservationItems[reservationType.PAST].length > 0 ?
						reservationItems[reservationType.PAST].map((item, index) => <ReservationItem key={index} reservation={item}></ReservationItem>)
						: (
							<p className="none-items">
							지난 예약이 없습니다
							</p>
						)
				}
			</div>

			<Seperator style={seperatorStyle}></Seperator>

			<div className="voucher-products">
				<h3 className="voucher-products-title">
					취소된 예약 ({
						reservationItems
							? reservationItems[reservationType.CANCEL].length
							: 0
					})
				</h3>

				{
					reservationItems && reservationItems[reservationType.CANCEL].length > 0 ?
						reservationItems[reservationType.CANCEL].map((item, index) => <ReservationItem key={index} reservation={item}></ReservationItem>)
						: (
							<p className="none-items">
							취소된 예약이 없습니다
							</p>
						)
				}
			</div>

			<Seperator style={seperatorStyle}></Seperator>

			<MyTableFooter></MyTableFooter>
		</div>
	)
}
