import { useEffect, useState } from 'react'
import axios from 'axios'
import { usePartnerContext } from '../context/Common/partner'
import { carrotApiHost } from '../constants/index'


const useFetchPolicies = ({ url, dispatchInfo, requiredPolicies = [] }) => {
	const [servicePolicies, setServicePolicies] = useState([])
	const [loading, setLoading] = useState(true)
	const [currentPolicy, setCurrentPolicy ] = useState('')
	const { partnerName } = usePartnerContext()
	const [policiesList, setPoliciesList] = useState([])

	const openServiceTerm = (type) => {
		const serviceUrl = servicePolicies.find(item => item.type === type)?.content.KO.contentUrl
		setCurrentPolicy(serviceUrl)
		return serviceUrl
	}

	const getServicePolcies = () => {

		async function fetchAndSetUser() {
			setLoading(true)
			let policyList = []
			try {
				if (!requiredPolicies.length) {
					if (partnerName === 'skt') {
						policyList = ['SERVICE', 'PRIVACY_FOR_SKT', 'PRIVACY_FOR_SKT_THIRD_PARTY', 'VOUCHER_CANCEL_PENALTY', 'MARKETING', 'LATE_NIGHT_MARKETING', 'KEEP_ACTIVE_EVEN_DORMANT']
					} else if (partnerName === 'tablemanager'){
						policyList = ['SERVICE', 'PRIVACY_FOR_PAYMENT', 'PRIVACY_FOR_PAYMENT_THIRD_PARTY', 'VOUCHER_CANCEL_PENALTY', 'MARKETING', 'LATE_NIGHT_MARKETING', 'KEEP_ACTIVE_EVEN_DORMANT']
						dispatchInfo({ type: 'CHANGE_AGREEMENT_LIST', value: [...policyList, 'saveCard'] })
						return
					} else if (partnerName === 'kb') {
						policyList = ['SERVICE', 'PRIVACY_FOR_PAYMENT', 'PRIVACY_FOR_KB_PAY', 'VOUCHER_CANCEL_PENALTY', 'MARKETING', 'LATE_NIGHT_MARKETING', 'KEEP_ACTIVE_EVEN_DORMANT']
					} else if (partnerName === 'mfg') {
						policyList = ['SERVICE', 'PRIVACY_FOR_PAYMENT', 'PRIVACY_FOR_PAYMENT_THIRD_PARTY', 'VOUCHER_CANCEL_PENALTY', 'MARKETING', 'LATE_NIGHT_MARKETING', 'KEEP_ACTIVE_EVEN_DORMANT']
					} else if (partnerName === 'samsung') {
						policyList = ['SERVICE', 'PRIVACY_FOR_SAMSUNG_CARD', 'PRIVACY_FOR_PAYMENT_THIRD_PARTY', 'VOUCHER_CANCEL_PENALTY', 'MARKETING', 'LATE_NIGHT_MARKETING', 'KEEP_ACTIVE_EVEN_DORMANT']
					} else if (partnerName === 'shinhan') {
						policyList = ['SERVICE', 'PRIVACY_FOR_PAYMENT', 'PRIVACY_FOR_PAYMENT_THIRD_PARTY', 'VOUCHER_CANCEL_PENALTY', 'MARKETING', 'LATE_NIGHT_MARKETING', 'KEEP_ACTIVE_EVEN_DORMANT']
					} else if (partnerName === 'gyeongjuro') {
						policyList = ['SERVICE', 'PRIVACY_FOR_PAYMENT', 'PRIVACY_FOR_PAYMENT_THIRD_PARTY']
					}
					dispatchInfo({ type: 'CHANGE_AGREEMENT_LIST', value: policyList })
				} else {
					policyList = requiredPolicies
				}
			} finally {
				setPoliciesList(policyList)
				try {
					const { data: response } = await axios.get(carrotApiHost + url, { params:{ servicePolicyType: policyList } })
					setServicePolicies(response.servicePolicies)
				} catch (err) {
					alert('약관 정보를 불러오는데 실패하였습니다.')
				} finally {
					setLoading(false)
				}
			}
		}

		fetchAndSetUser()
	}

	useEffect(() => {
		getServicePolcies()
	}, [])

	return { servicePolicies, loading, openServiceTerm, currentPolicy, policiesList }
}

export { useFetchPolicies }
