import React, { useEffect, useState } from 'react'
import { FormControl, InputLabel, Select } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import { useModalContext } from '../../../../context/Common/modal'
import { useReservation } from '../../../../store'
const range = (a, b) => {
	const list = []
	for (let i = a; i <= b; i += 1)
		list.push(i)

	return list
}

export default function PaymentForm() {
	const { payment, setReservation } = useReservation()
	const [monthSelectOpen, setMonthSelelctOpen] = useState(false)
	const [yearSelectOpen, setYearSelelctOpen] = useState(false)
	const { alertOpen } = useModalContext()
	const { type: cardType } = payment.billingKeyContext
	const toggleMonthSelectView = () => {
		setMonthSelelctOpen(!monthSelectOpen)
	}

	const toggleYearSelectView = () => {
		setYearSelelctOpen(!yearSelectOpen)
	}

	const comportTyping = (e) => {
		const nextSibling = e.target.nextElementSibling
		const currentValue = e.target.value

		if (nextSibling != null && currentValue.length >= 4)
			nextSibling.focus()
	}

	const changeCardNumber = (idx, val) => {
		payment.billingKeyContext.cardNumbers[idx] = val
		setReservation('payment', {
			...payment,
			billingKeyContext: { ...payment.billingKeyContext, cardNumbers: payment.billingKeyContext.cardNumbers },
		})
	}

	const changeExpiryDate = (type, val) => {
		setReservation('payment', {
			...payment,
			billingKeyContext: { ...payment.billingKeyContext, expiry: { ...payment.billingKeyContext.expiry, [type]: val } },
		})
	}

	const changeBillingKeyState = (type, val) => {
		setReservation('payment', { ...payment, billingKeyContext: { ...payment.billingKeyContext, [type]: val } })
	}

	useEffect(() => {
		if (payment.billingKeyContext.type === 'CORPORATION') {
			alertOpen(
				'법인카드는 카드 앞면 서명란에 법인명만 표기되어 있는 카드입니다. 법인카드로 결제를 진행하시려면 확인을 눌러주세요.',
				'확인'
			)
		}
	}, [payment.billingKeyContext.type])

	return (
		<div className="payment-form-container">
			<div className="tab-list">
				<div
					className={`tab-button ${cardType === 'INDIVIDUAL'
						? 'selected'
						: ''}`}
					onClick={() => changeBillingKeyState('type', 'INDIVIDUAL')}
				>
					개인카드
				</div>
				<div
					className={`tab-button ${cardType === 'CORPORATION'
						? 'selected'
						: ''}`}
					onClick={() => changeBillingKeyState('type', 'CORPORATION')}
				>
					법인카드
				</div>
			</div>

			<div className="payment-form">
				<div className="card-box">
					<input
						type="text"
						maxLength="4"
						inputMode="numeric"
						onInput={comportTyping}
						placeholder="1234"
						value={payment.billingKeyContext.cardNumbers[0]}
						onChange={(e) => changeCardNumber(0, e.target.value)}
						className="card-number"
					></input>
					<input
						type="text"
						maxLength="4"
						inputMode="numeric"
						onInput={comportTyping}
						placeholder="****"
						value={payment.billingKeyContext.cardNumbers[1]}
						onChange={(e) => changeCardNumber(1, e.target.value)}
						className="card-number text-security"
					></input>
					<input
						type="text"
						maxLength="4"
						inputMode="numeric"
						onInput={comportTyping}
						placeholder="****"
						value={payment.billingKeyContext.cardNumbers[2]}
						onChange={(e) => changeCardNumber(2, e.target.value)}
						className="card-number text-security"
					></input>
					<input
						type="text"
						maxLength="4"
						inputMode="numeric"
						onInput={comportTyping}
						placeholder="1234"
						value={payment.billingKeyContext.cardNumbers[3]}
						onChange={(e) => changeCardNumber(3, e.target.value)}
						className="card-number"
					></input>
				</div>
				<div className="expire-box">
					<div className="month-select-box">
						<FormControl className="select-form">
							<InputLabel>MM</InputLabel>
							<Select
								onOpen={toggleMonthSelectView}
								onClose={toggleMonthSelectView}
								open={monthSelectOpen}
								className="month-select"
								placeholder={'MM'}
								onChange={(e) => changeExpiryDate('month', e.target.value)}
								value={payment.billingKeyContext.expiry.month}
							>
								{range(1, 12).map((n) => (
									<MenuItem value={n.toString().padStart(2, '0')} key={n}>
										{n.toString().padStart(2, '0')}{' '}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>
					<div className="year-select-box">
						<FormControl className="select-form">
							<InputLabel>YY</InputLabel>
							<Select
								onOpen={toggleYearSelectView}
								onClose={toggleYearSelectView}
								open={yearSelectOpen}
								placeholder={'YY'}
								onChange={(e) => changeExpiryDate('year', e.target.value)}
								value={payment.billingKeyContext.expiry.year}
								className="year-select"
							>
								{range(2021, 2030).map((n) => (
									<MenuItem value={n.toString()} key={n}>
										{n.toString()}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>
				</div>
				<div className="input-box">
					<input
						type="text"
						maxLength={cardType === 'INDIVIDUAL'
							? 6
							: 10}
						inputMode="numeric"
						placeholder={
							cardType === 'INDIVIDUAL'
								? '명의자 생년월일 (예 : 900101)'
								: '사업자 등록번호 (예 7518100830)'
						}
						value={payment.billingKeyContext.identifyCode}
						className="birthday"
						onChange={(e) => changeBillingKeyState('identifyCode', e.target.value)}
					></input>
				</div>
				{payment.billingKeyContext.type === 'INDIVIDUAL'
					? (
						<div className="input-box">
							<input
								type="password"
								maxLength="2"
								inputMode="numeric"
								placeholder="비밀번호 앞 2자리 숫자"
								value={payment.billingKeyContext.password}
								className="password"
								onChange={(e) => changeBillingKeyState('password', e.target.value)}
							></input>
						</div>
					)
					: null}
			</div>
		</div>
	)
}
