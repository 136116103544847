import { create } from 'zustand'
import { BookingMallDetailAPI } from '../lib/mytable'
import { authorizationWithPrefix } from '../lib/utils'

const initialState = {
	authToken: undefined,
	authCode: undefined,
}

const useProvisionalReservation = create((set, get) => ({
	...initialState,
	reservationToken: undefined,

	// Setter
	setProvisionalReservation: (type, value) => set({ [type]: value }),

	// Reset
	resetAuth: () => set(initialState),

	// Util
	cancelReservation: async (apiKey, mallUri) => {
		if (get().reservationToken) {
			await new BookingMallDetailAPI(apiKey, authorizationWithPrefix())
				.deleteProvisionalReservation(mallUri, get().reservationToken)
				.then(() => set({ reservationToken: undefined }))
				.catch((e) => console.error(e))
		}
	},
}))

export default useProvisionalReservation
