import React, { useEffect } from 'react'
import { useMyPageContext } from '../../../../context/Booking/myPage'
import { ReactComponent as Arrow } from '../../../../assets/icon/arrowRightGray.svg'
import './ReservationList.scss'
import ReservationItem from '../ReservationItem/ReservationItem'
import { useHistory } from 'react-router-dom'

const reservationType = {
	AVAILABLE: 'AVAILABLE',
	PAST: 'PAST',
	CANCEL: 'CANCEL'
}

export default function ReservationList() {
	const { reservationList } = useMyPageContext()
	const history = useHistory()
	function expandScheduled() {
		history.push('/booking/list/expand/scheduled')
	}
	function expandFinished() {
		history.push('/booking/list/expand/finished')
	}

	return (
		<div className="reservation-list-container" id="reservation-list">
			{reservationList.filter(item => item.state === 0).length > 0 ?
				<div>
					<div className="title">
        		<span className="t3">방문예정</span>
						{reservationList.filter(item => item.state === 0).length >= 5 ?
							<div className="expand b3" onClick={expandScheduled}><span>전체보기</span><Arrow className="icon"/></div>
							: null}
					</div>
					{reservationList.filter(item => item.state === 0).slice(0, 5).map((item, idx) => <ReservationItem reservation={item} key={idx}></ReservationItem>)}
				</div>
				: null}
			{reservationList.filter(item => item.state !== 0).length > 0 ?
				<div>
					<div className="title">
        		<span className="t3">방문완료/취소</span>
						{reservationList.filter(item => item.state !== 0).length >= 5 ?
							<div className="expand b3" onClick={expandFinished}><span>전체보기</span><Arrow className="icon"/></div>
							: null}
					</div>
					{reservationList.filter(item => item.state !== 0).slice(0, 5).map((item, idx) => <ReservationItem reservation={item} key={idx}></ReservationItem>)}
				</div>
				: null}
		</div>
	)
}
