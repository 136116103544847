import { format } from 'date-fns'
import { createContext, useContext, useEffect, useReducer, useState } from 'react'

const initialSearchContext = {
	searchWord: '',
	isSearching: false,
	searchCompleted: false
}

const SearchContext = createContext({
	searchInfo: initialSearchContext,
	dispatchSearchInfo: () => {},
	autocompletedMalls: null,
	setAutoCompletedMalls: () => {},
	searchedMalls: null,
	setSearchedMalls: () => {},
	searchHistories: null,
	setSearchHistories: () => {},
	search: () => {}
})

const useSearchContext = () => useContext(SearchContext)

const searchInfoReducer = (state, action) => {
	const { value } = action

	switch (action.type) {
	case 'CHANGE_SEARCH_WORD':
		return { ...state, searchWord: value }
	case 'SEARCHING':
		return { ...state, isSearching: true }
	case 'SEARCH_FINISH':
		return { ...state, isSearching: false }
	case 'SEARCH_COMPLETE':
		return { ...state, searchCompleted: true }
	case 'SEARCH_INITALIZE':
		return { ...state, searchCompleted: false }
	default:
		return state
	}
}

const SearchContextProvider = (props) => {
	const { malls, children } = props

	const [searchInfo, dispatchSearchInfo] = useReducer(searchInfoReducer, initialSearchContext)
	const [autoCompletedMalls, setAutoCompletedMalls] = useState([])
	const [searchedMalls, setSearchedMalls] = useState([])
	const [searchHistories, setSearchHistories] = useState([])

	useEffect(() => {
		if (localStorage.getItem('searchHistories'))
			setSearchHistories(JSON.parse(localStorage.getItem('searchHistories')).slice(0, 5) || [])

	}, [])

	useEffect(() => {
		localStorage.setItem('searchHistories', JSON.stringify(searchHistories))
	}, [searchHistories])

	const search = (name) => {
		let searchText = name
		let searchMalls = []

		dispatchSearchInfo({ type :'SEARCH_COMPLETE' })
		dispatchSearchInfo({ type :'SEARCH_FINISH' })
		if (searchText.length === 0) {
			setSearchedMalls([])
		} else {
			searchMalls = malls.filter((item) => item.mall_name.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
			let copySearchHistories = JSON.parse(JSON.stringify(searchHistories))
			setSearchedMalls(searchMalls)
			const index = copySearchHistories.findIndex((value) => value.name === searchText)

			if (index > -1)
				copySearchHistories.splice(index, 1)

			copySearchHistories.unshift({
				name: searchText.toUpperCase(),
				date: format(new Date(), 'MM.dd'),
			})
			copySearchHistories = copySearchHistories.slice(0, 5)
			setSearchHistories(copySearchHistories)
			localStorage.setItem('searchHistories', JSON.stringify(copySearchHistories))
		}
		setAutoCompletedMalls([])
	}
	const initialValue = {
		searchInfo,
		dispatchSearchInfo,
		autoCompletedMalls,
		setAutoCompletedMalls,
		searchedMalls,
		setSearchedMalls,
		searchHistories,
		setSearchHistories,
		search
	}

	return <SearchContext.Provider value={initialValue}>{children}</SearchContext.Provider>
}

export { SearchContextProvider, useSearchContext }
