import React from 'react'
import './MenuShortcut.scss'

export default function MenuShortcut(props) {
	return (
		<div className="shortcut-container">
			{props.categories.map((category) => <a href={`#${category.categoryId}`} key={category.categoryId}><div className="shortcut">{category.categoryName}</div></a>)}
		</div>
	)
}
