import { ReactComponent as EllipseOn } from '../../../assets/icon/ellipse-on.svg'
import { ReactComponent as EllipseOff } from '../../../assets/icon/ellipse-off.svg'

const Radio = (props) => {
	const { name, value, checked = false, func, labelClassName = 'default-label' } = { ...props }

	return (
		<label className="gyeongju-radio-component">
			<input value={value} type="radio" checked={checked} onChange={(e) => func && func(e)} {...props} />
			{checked
				? <EllipseOn />
				: <EllipseOff />}
			<span className={labelClassName}>{name || value}</span>
		</label>
	)
}

export default Radio
