import React from 'react'
import { useHomeContext } from '../../../../context/Voucher/home'
import Title from '../../../Share/Title/Title'

export default function ReviewList() {
	const { reviewList } = useHomeContext()
	if (!reviewList || reviewList.length === 0)
		return null
	return (
		<div className="real-review-wrapper">
			<Title text={'생생한 리얼후기 💬'}></Title>
			<div className="real-review-images">
				{reviewList.map((review, index) => <div className="real-review-image-wrapper" key={index}><img className="real-review-image" key={index} src={review.imageLink} alt={review.id}></img></div>)}
			</div>
		</div>
	)
}
