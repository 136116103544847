import moment from 'moment'
import { useReducer } from 'react'
import { createContext, useContext, useState, useEffect } from 'react'

const initialVoucherInfoContext = {
	amount: 0,
	products: [],
	user: {
		name: '',
		phoneNumber: '',
		email: ''
	},
	phoneAuthCode: null,
	phoneAuthToken: null,
	phoneAuth: null,
	agreement: {
		SERVICE: false,
		PRIVACY_FOR_PAYMENT: false,
		PRIVACY_FOR_PAYMENT_THIRD_PARTY: false,
		VOUCHER_CANCEL_PENALTY: false,
		saveCard: false,
	}
}

const initialPaymentInfoContext = {
	type: 'BILLING_KEY', // PG | BILLING_KEY | KAKAO | NAVER
	billingKeyContext: {
		type: 'INDIVIDUAL', // INDIVIDUAL | CORPORATION
		cardNumbers: [
			'',
			'',
			'',
			'',
		],
		expiry: {
			year: moment().format('yy'),
			month: '01'
		},
		password: '',
		identifyCode: '',
		subscriptionId: ''
	}
}

const initialValidationsContext = {
	userError: null,
	emailError: null,
	phoneNumbersError: null,
	phoneAuthError: null,
	agreementError: null,
}

const VoucherContext = createContext({
	voucherInfo: initialVoucherInfoContext,
	dispatchVoucherInfo: () => { },
	paymentInfo: initialPaymentInfoContext,
	dispatchPaymentInfo: () => { },
	validations: initialValidationsContext,
	dispatchValidations: () => { },
	voucherProduct: null,
	setVoucherProduct: () => { },
	buyer: null,
	setBuyer: () => { },
	purchaseList: null,
	setPurchaseList: () => { },
	voucherMalls: null,
	setVoucherMalls: () => { },
	showPaymentForm: null,
	setShowPaymentForm: () => { },
	voucherId: null,
	setVoucherId: () => { }
})

const voucherInfoReducer = (state, action) => {
	const { value, index } = action
	let changedState
	switch (action.type) {
	case 'SET_STATE': {
		changedState = { ...state, ...value }
		break
	}
	case 'SET_AMOUNT':
		changedState = { ...state, amount: value }
		break
	case 'CHANGE_PHONE_NUMBER':
		return { ...state, user: { ...state.user, phoneNumber: value } }
	case 'CLEAR_AUTH':
		changedState = { ...state, phoneAuthToken: null, phoneAuthCode: null, phoneAuth: null, user: { ...state.user, phoneNumber: value } }
		break
	case 'SET_PHONE_AUTH_TOKEN':
		changedState = { ...state, phoneAuthToken: value }
		break
	case 'SET_PHONE_AUTH_CODE':
		changedState = { ...state, phoneAuthCode: value }
		break
	case 'AUTH':
		changedState = { ...state, phoneAuth: true }
		break
	case 'SET_USER_NAME':
		changedState = { ...state, user: { ...state.user, name: value } }
		break
	case 'SET_USER_EMAIL':
		changedState = { ...state, user: { ...state.user, email: value } }
		break
	case 'SET_PRODUCTS':
		changedState = { ...state, products: value }
		break
	case 'TOGGLE_AGREEMENT':
		const newAgreement = { ...state.agreement }
		newAgreement[value] = !state.agreement[value]
		changedState = { ...state, agreement: newAgreement }
		break
	case 'TOGGLE_AGREEMENT_ALL':
		const allAgreement = Object.assign({}, state.agreement)
		value.forEach(v => {
			allAgreement[v] = !state.agreement[v]
		})
		changedState = { ...state, agreement: allAgreement }
		break
	case 'CHANGE_AGREEMENT_LIST' :
		const changeAgree = value.reduce((acc, cur) => {
			acc[cur] = false
			return acc
		}, {})
		changedState = { ...state, agreement: changeAgree }
		break
	case 'CLEAR':
		changedState = initialVoucherInfoContext
		sessionStorage.setItem('voucherInfo', JSON.stringify(initialVoucherInfoContext))
		break
	default:
		changedState = state
		break
	}

	if (changedState)
		sessionStorage.setItem('voucherInfo', JSON.stringify(changedState))
	return changedState
}

const paymentInfoReducer = (state, action) => {
	const { value, index } = action

	switch (action.type) {
	case 'CHANGE_PAYMENT_TYPE':
		return { ...state, type: value }
	case 'CHANGE_CARD_TYPE':
		if (state.type !== 'BILLING_KEY')
			throw new Error('invalid input')
		return { ...state, billingKeyContext: { ...state.billingKeyContext, type: value, identifyCode: '' } }
	case 'CHANGE_CARD_NUMBER': {
		const cardNumbers = state.billingKeyContext.cardNumbers
		cardNumbers[index] = value

		return {
			...state, billingKeyContext: {
				...state.billingKeyContext,
				cardNumbers
			}
		}
	}
	case 'SELECT_EXPIRY_MONTH':
		return { ...state, billingKeyContext: { ...state.billingKeyContext, expiry: { ...state.billingKeyContext.expiry, month: value } } }
	case 'SELECT_EXPIRY_YEAR':
		return { ...state, billingKeyContext: { ...state.billingKeyContext, expiry: { ...state.billingKeyContext.expiry, year: value } } }
	case 'CHANGE_IDENTIFY_CODE':
		return { ...state, billingKeyContext: { ...state.billingKeyContext, identifyCode: value } }
	case 'CHANGE_PASSWORD':
		return { ...state, billingKeyContext: { ...state.billingKeyContext, password: value } }
	case 'CHANGE_MEMBER_CARD_ID':
		return { ...state, billingKeyContext: { ...state.billingKeyContext, subscriptionId: value } }
	default:
		return state
	}
}

const validationsReducer = (state, action) => {
	switch (action.type) {
	case 'USER_NAME_NULL':
		return { ...state, userError: '이름을 반드시 입력해주세요.' }
	case 'USER_NAME_SPECIAL_CHARACTERS':
		return { ...state, userError: '띄어쓰기나 특수문자는 입력하실 수 없습니다.' }
	case 'USER_NAME_COMPLETE':
		return { ...state, userError: null }
	case 'PHONE_NUMBERS_NULL':
		return { ...state, phoneNumbersError: '휴대폰 번호를 반드시 입력해주세요.' }
	case 'PHONE_NUMBERS_INVALID':
		return { ...state, phoneNumbersError: '휴대폰 번호를 양식에 맞게 입력해주세요.' }
	case 'PHONE_NUMBERS_COMPLETE':
		return { ...state, phoneNumbersError: null }
	case 'PHONE_AUTH_ERROR':
		return { ...state, phoneAuthError: '잘못된 인증번호를 입력했습니다.' }
	case 'PHONE_AUTH_INVALID':
		return { ...state, phoneAuthError: '휴대폰 인증을 진행해주세요.' }
	case 'PHONE_AUTH_COMPLETE':
		return { ...state, phoneAuthError: null }
	case 'EMAIL_INVALID':
		return { ...state, emailError: '이메일을 양식에 맞게 입력해주세요.' }
	case 'EMAIL_COMPLETE':
		return { ...state, emailError: null }
	case 'AGREEMENT_INVALID':
		return { ...state, agreementError: '서비스 이용 및 약관 결제 동의를 체크해주세요.' }
	case 'AGREEMENT_COMPLETE':
		return { ...state, agreementError: null }
	default:
		return state
	}
}

const VoucherContextProvider = (props) => {
	const [voucherInfo, dispatchVoucherInfo] = useReducer(voucherInfoReducer, initialVoucherInfoContext)
	const [paymentInfo, dispatchPaymentInfo] = useReducer(paymentInfoReducer, initialPaymentInfoContext)
	const [validations, dispatchValidations] = useReducer(validationsReducer, initialValidationsContext)
	const [voucherProduct, setVoucherProduct] = useState(JSON.parse(sessionStorage.getItem('voucherProduct')))
	const [purchaseList, setPurchaseList] = useState([])
	const [voucherMalls, setVoucherMalls] = useState([])
	const [buyer, setBuyer] = useState({})
	const [showPaymentForm, setShowPaymentForm] = useState(true)
	const [voucherId, setVoucherId] = useState(null)
	const initialValue = {
		voucherInfo,
		dispatchVoucherInfo,
		paymentInfo,
		dispatchPaymentInfo,
		validations,
		dispatchValidations,
		voucherProduct,
		setVoucherProduct,
		purchaseList,
		setPurchaseList,
		buyer,
		setBuyer,
		voucherMalls,
		setVoucherMalls,
		showPaymentForm,
		setShowPaymentForm,
		voucherId,
		setVoucherId
	}
	const [initcontrol, setInitcontrol] = useState(false)

	if (!initcontrol) {
		const getStorageVoucherInfo = JSON.parse(sessionStorage.getItem('voucherInfo'))
		if (getStorageVoucherInfo?.amount)
			dispatchVoucherInfo({ type: 'SET_STATE', value: getStorageVoucherInfo })

		setInitcontrol(true)
	}

	useEffect(() => {
		if (sessionStorage.getItem('voucherInfo')) {
			let sessionVoucherInfo = JSON.parse(sessionStorage.getItem('voucherInfo'))
			dispatchVoucherInfo({ type: 'SET_STATE', value: sessionVoucherInfo })
		}
	}, [])

	useEffect(() => {
		if (voucherInfo.user.name) {
			if ((/\s/g).test(voucherInfo.user.name) || (/[!@#\$%\^\&*\)\(+=._-]/g).test(voucherInfo.user.name)) {
				dispatchValidations({ type: 'USER_NAME_SPECIAL_CHARACTERS' })
				return
			}
			dispatchValidations({ type: 'USER_NAME_COMPLETE' })
			return
		}
		dispatchValidations({ type: 'USER_NAME_NULL' })
	}, [voucherInfo.user.name])

	useEffect(() => {
		if (voucherInfo.user.phoneNumber === '') {
			dispatchValidations({ type: 'PHONE_NUMBERS_NULL' })
			return
		}
		if (!voucherInfo.user.phoneNumber)
			return

		let str = voucherInfo.user.phoneNumber
		str = str.replace(/[^0-9]/g, '')
		let tmp = ''
		if (str.length < 4)
			tmp = str
		else if (str.length < 7)
			tmp = `${str.substr(0, 3)}-${str.substr(3)}`
		else if (str.length < 11)
			tmp = `${str.substr(0, 3)}-${str.substr(3, 3)}-${str.substr(6)}`
		else
			tmp = `${str.substr(0, 3)}-${str.substr(3, 4)}-${str.substr(7)}`
		if (tmp !== voucherInfo.user.phoneNumber) {
			dispatchVoucherInfo({ type: 'CHANGE_PHONE_NUMBER', value: tmp })
			return
		}
		if (!/^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}/.test(tmp)) {
			dispatchValidations({ type: 'PHONE_NUMBERS_INVALID' })
			return
		}
		dispatchValidations({ type: 'PHONE_NUMBERS_COMPLETE' })

	}, [voucherInfo.user.phoneNumber])

	useEffect(() => {
		if (voucherInfo.phoneAuth) {
			dispatchValidations({ type: 'PHONE_AUTH_COMPLETE' })
			return
		}
		dispatchValidations({ type: 'PHONE_AUTH_INVALID' })
	}, [voucherInfo.phoneAuthCode, voucherInfo.phoneAuthToken, voucherInfo.phoneAuth])

	useEffect(() => {
		if (voucherInfo.user.email === '' || !voucherInfo.user.email) {
			dispatchValidations({ type: 'EMAIL_COMPLETE' })
			return
		}
		if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(voucherInfo.user.email)) {
			dispatchValidations({ type: 'EMAIL_INVALID' })
			return
		}
		dispatchValidations({ type: 'EMAIL_COMPLETE' })
		return

	}, [voucherInfo.user.email])

	const requiredAgreements = Object.entries(voucherInfo.agreement).map(([key, value]) => {
		return (
			{
				name: key,
				value: value
			})
	}).filter((item) => {
		const softCheckArr = ['MARKETING', 'LATE_NIGHT_MARKETING', 'KEEP_ACTIVE_EVEN_DORMANT', 'saveCard']
		return !softCheckArr.includes(item.name)
	})

	useEffect(() => {
		if (requiredAgreements.every(item => item.value === true)) {
			dispatchValidations({ type: 'AGREEMENT_COMPLETE' })
			return
		}
		dispatchValidations({ type: 'AGREEMENT_INVALID' })
	}, [voucherInfo])

	useEffect(() => {
		if (voucherProduct)
			sessionStorage.setItem('voucherProduct', JSON.stringify(voucherProduct))
	}, [voucherProduct])
	return <VoucherContext.Provider value={initialValue}>{props.children}</VoucherContext.Provider>
}

const useVoucherContext = () => useContext(VoucherContext)

export { VoucherContextProvider, useVoucherContext }
