import { setup } from 'axios-cache-adapter'

// axios 생성
let axios = setup({
	baseURL: process.env.REACT_APP_CARROT_API_HOST,
	headers: {
		'x-api-key': '1CF83811C6EEC4AFC9DA7D289F8797EC9C562FBA',
	}
})

//
// API caller
//
const _call = async ({
	method = 'get',
	url,
	headers = {},
	expires = 0,
	body = {},
}) => {
	method = method.toLowerCase()
	if (
		['get', 'delete', 'post', 'patch', 'put', 'options'].indexOf(method) < 0
	) {
		const message = `method is wrong - method: ${method}`
		throw new Error(message)
	}

	expires = Number(expires)
	if (isNaN(expires)) {
		console.warn(
			'expires is assigned 0 (=default value) because type is not number'
		)
		expires = 0
	}

	const config = {
		method,
		data: body,
		url,
		cache: { maxAge: expires },
	}
	if (headers)
		config['headers'] = headers

	const request = axios(config)
	const { data } = await request
	if (!data.code)
		throw data

	return data
}

class AuthAPI {
	authLogin = ({ email, password }) => {
		return _call({
			method: 'post',
			url: `auth/login`,
			body: {
				email,
				password
			}
		})
	}

	authRegister = (values) => {
		return _call({
			method: 'post',
			url: `auth/register`,
			body: values
		})
	}

	oauthLogin = (provider, accessToken) => {
		return _call({
			method: 'post',
			url: `oauth/${provider}/login`,
			body: {
				accessToken
			}
		})
	}

	oauthRegister = (provider, userValues, { access_token, refresh_token }) => {
		return _call({
			method: 'post',
			url: `oauth/${provider}/register`,
			body: {
				...userValues,
				accessToken: access_token,
				refreshToken: refresh_token
			}
		})
	}

	otpPhoneVerify = (phone) => {
		return _call({
			method: 'post',
			url: 'auth/otp',
			body: { phone }
		})
	}

	checkOtpPhoneValid = (phoneToken, code) => {
		return _call({
			method: 'post',
			url: 'auth/check-otp',
			body: {
				phoneToken,
				code
			}
		})
	}

	checkDuplicate = ({ phone, email }) => {
		return _call({
			method: 'post',
			url: 'auth/check-duplicate',
			body: {
				phone,
				email
			}
		})
	}
}

export {
	AuthAPI
}
