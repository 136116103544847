import styled from '@emotion/styled'

export const NoticeParagraph = styled.p `
	margin: 12px 0 0;
	${({ theme }) => theme.font.small_2};

	& > label.red {
		color: #f83f60;
	}
`
