import React, { useCallback, useLayoutEffect, useMemo } from 'react'
import Address from '../../../Components/Share/Booking/Address/Address'
import { Address as AddressGyeongjuro } from '../../../Components/Gyeongju'
import { useHistory, useParams } from 'react-router-dom'
import RefundPolicy from '../../../Components/Share/Booking/RefundPolicy/RefundPolicy'
import { usePartnerContext } from '../../../context/Common/partner'
import { BookingMyPageAPI } from '../../../lib/mytable'
import BookingFooter from '../../../Components/Share/Booking/BookingFooter/BookingFooter'
import moment from 'moment'
import BookingSubtitle from '../../../Components/Share/Booking/BookingSubtitle/BookingSubtitle'
import { authorizationWithPrefix } from '../../../lib/utils'
import { useMall, useReservation } from '../../../store'
import { ReservationInfo, UserInfo } from '../../../Components/Booking'
import useDynamicComponent from '../../../hooks/useDynamicComponent'
import Format from '../../../utils/Format'

export default function Complete() {
	const { mall, fetchMall, setMall } = useMall()
	const { name, dateTime, totalPartySize, detailPartySize, setReservation, voucherData, reservationId, getHoldingData, getTotalAmount } = useReservation()
	const { renderComponent } = useDynamicComponent()
	const params = useParams()
	const { apiKey, partnerName } = usePartnerContext()
	const bookingMyPageAPI = useMemo(() => new BookingMyPageAPI(apiKey, authorizationWithPrefix()), [apiKey, voucherData])
	const history = useHistory()
	window.history.pushState(null, null, window.location.href)

	const loadReservation = useCallback(async () => {
		if (reservationId && mall) {
			let authorization = sessionStorage.getItem('authorization')
			if (!authorization) {
				await getHoldingData()
				const date = dateTime.format('YYYY년 MM월 DD일')
				const time = dateTime.format('LT')
				const mallPhone = mall.mall_phone
				const mallAddress = mall.location_address.road
				const mallName = mall.mall_name
				const partySizeString = Object.entries(detailPartySize)
					.filter((ele) => ele[1] > 0)
					.map((item) => `${item[0]} ${item[1]}명`)
					.join(', ')
				let partnerType = '테이블매니저'
				if (partnerName === 'skt')
					partnerType = 'SKT VIP'
				if (partnerName === 'kb')
					partnerType = 'KB Pay'
				if (partnerName === 'mfg')
					partnerType = '매드포갈릭'
				if (partnerName === 'gyeongjuro')
					partnerType = '경주로ON'
				const desc = `[${partnerType}/예약완료]

		▶매장명 : ${mallName}
		▶이용예정일 : ${date} ${time}
		▶매장연락처 : ${mallPhone}
		▶주소 : ${mallAddress}
		▶예약자 : ${name}님
		▶예약인원 : ${totalPartySize}명 (${partySizeString})`
				localStorage.setItem('shareText', desc)
			} else {
				const res = await bookingMyPageAPI.getReservation(reservationId, authorization)
				if (res) {
					if (!mall || !params.mallUri) {
						res.date = moment(new Date(res.date_y, res.date_m - 1, res.date_d)).set(
							'minute',
							Math.round(res.date_h_start * 60)
						)
					}
					const data = {
						totalPartySize: res.info_people.reduce((a, b) => a + b.count, 0),
						detailPartySize: Object.assign({}, ...res.info_people.map((item) => ({ [item.name]: item.count }))),
						dateTime: moment(res.date),
						deposit: res.deposit_price,
						menus: res.info_menu,
						name: res.user_name,
						phone: Format.phone(res.user_phone),
						email: res.user_email,
						additionalInfos: res.info_additional || [],
						request: res.request,
					}
					Object.entries({ ...data }).forEach(([key, value]) => setReservation(key, value))

					const date = res.date.format('YYYY년 MM월 DD일')
					const time = res.date.format('LT')
					const people = res.info_people.reduce((a, b) => a + b.count, 0)
					let peopleDetail = ''
					if (res.info_people.length > 0) {
						peopleDetail = res.info_people
							.filter((item) => item.count > 0)
							.map((item) => `${item.name_display || item.name} ${item.count}명`)
							.join(',')
					}
					const mallPhone = res.mall_phone
					const mallAddress = res.location_address.road
					const mallName = res.mall_name
					const userName = res.user_name
					let partnerType = '테이블매니저'
					if (partnerName === 'skt')
						partnerType = 'SKT VIP'
					if (partnerName === 'kb')
						partnerType = 'KB Pay'
					if (partnerName === 'mfg')
						partnerType = '매드포갈릭'
					if (partnerName === 'gyeongjuro')
						partnerType = '경주로ON'
					const desc = `[${partnerType}/예약완료]

		▶매장명 : ${mallName}
		▶이용예정일 : ${date} ${time}
		▶매장연락처 : ${mallPhone}
		▶주소 : ${mallAddress}
		▶예약자 : ${userName}님
		▶예약인원 : ${people}명 (${peopleDetail})`
					localStorage.setItem('shareText', desc)
				}
			}
		} else if (reservationId && !mall) {
			setMall('mall', JSON.parse(sessionStorage.getItem('mall')))
		}
	}, [reservationId, voucherData, mall])

	const popstateEvent = () => {
		window.history.go(1)
		if (partnerName === 'gyeongjuro')
			window.location.assign('https://gjsmarttour.kr')
	}

	const goHome = () => {
		if (sessionStorage.getItem('voucherData')) {
			sessionStorage.removeItem('voucherData')
			history.replace('/booking/list')
		} else if (partnerName === 'gyeongjuro') {
			window.location.assign('https://gjsmarttour.kr')
		} else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
			history.replace('/booking/main')
		} else {
			window.removeEventListener('popstate', popstateEvent)
			history.go(parseInt('-' + (history.length - 1)))
		}
	}

	useLayoutEffect(() => {
		window.addEventListener('popstate', popstateEvent)
		return () => {
			window.removeEventListener('popstate', popstateEvent)
		}
	}, [])

	useLayoutEffect(() => {
		if (params.reservationId)
			setReservation('reservationId', params.reservationId)
	}, [params.reservationId])

	useLayoutEffect(() => {
		if (!mall && params.mallUri && apiKey)
			fetchMall(apiKey, params.mallUri)
	}, [apiKey, mall, params.mallUri, voucherData])

	useLayoutEffect(() => {
		loadReservation()
	}, [loadReservation])

	if (!mall || !name)
		return null

	return (
		<div className="complete-page-container">
			<ReservationInfo></ReservationInfo>
			<UserInfo></UserInfo>
			{renderComponent({
				default: <Address />,
				gyeongjuro: <AddressGyeongjuro />
			})}
			{getTotalAmount()
				? (
					<div className="refund-policy-container">
						<BookingSubtitle subtitle="취소 수수료 안내"></BookingSubtitle>
						<RefundPolicy policies={mall.deposit_refunds}></RefundPolicy>
					</div>
				)
				: (
					<div className="refund-policy-container"></div>
				)}

			<BookingFooter text="닫기" action={goHome} active={true}></BookingFooter>
		</div>
	)
}
