import React, { useEffect, useLayoutEffect, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { usePartnerContext } from '../../../context/Common/partner'
import { BookingMallDetailAPI } from '../../../lib/mytable'
import BookingFooter from '../../../Components/Share/Booking/BookingFooter/BookingFooter'
import Seperator from '../../../Components/Share/Seperator'
import { PartnerAPI } from '../../../lib/partner'
import { authorizationWithPrefix } from '../../../lib/utils'
import { useMall, useProvisionalReservation, useReservation, useTimer, useValidation } from '../../../store'
import moment from 'moment'
import { BookingInfoView, CategorySelector, DepositNotice } from '../../../Components/Booking'
import Format from '../../../utils/Format'

export default function Category() {
	const { mallUri } = useParams()
	const { resetDetailPartySize, dateTime, voucherData, setReservation, categoryId, deposit } =
		useReservation()
	const { cancelReservation, reservationToken } = useProvisionalReservation()
	const { setValidation } = useValidation()
	const { setTimer } = useTimer()
	const { setMall } = useMall()
	const { apiKey, partnerInfo, partnerName } = usePartnerContext()
	const bookingMallDetailAPI = useMemo(
		() => new BookingMallDetailAPI(apiKey, authorizationWithPrefix()),
		[apiKey, voucherData]
	)
	const partnerAPI = new PartnerAPI(apiKey, null)
	const history = useHistory()

	const startProvisionalReservation = async () => {
		resetDetailPartySize()
		sessionStorage.removeItem('selectedDate')
		if (reservationToken) {
			await bookingMallDetailAPI.pretendProvisionalReservation(reservationToken).then((res) => {
				setTimer('expire', moment(res.date_expire))
				sessionStorage.removeItem('visitedBookingPayment')
				history.replace(`/booking/${mallUri}/step/detail-info?token=${reservationToken}`)
			})
		}
	}

	const backwardHandler = async () => {
		await cancelReservation(apiKey, mallUri)
		history.goBack()
	}

	useEffect(() => {
		if (partnerName === 'kb') {
			partnerAPI.kbLogin(partnerInfo.chnlMbrId).then((res) => {
				const { result, user } = { ...res }
				if (result) {
					sessionStorage.setItem('authorization', res.authorization)
					Object.entries(user).forEach(([key, val]) => setReservation(key, val))
					setValidation('phoneAuth', true)
				}
			})
		} else if (/^(skt|mfg|shinhan|gyeongjuro)$/g.test(partnerName)) {
			if (partnerInfo?.name) {
				setReservation('name', partnerInfo.name)
				setValidation('name', true)
			}
			if (partnerInfo?.phone) {
				setReservation('phone', Format.phone(partnerInfo.phone))
				setValidation('phone', true)
				setValidation('phoneAuth', true)
			}
		}
	}, [])

	useLayoutEffect(() => {
		if (mallUri)
			setMall('mallUri', mallUri)
	}, [mallUri])

	useLayoutEffect(() => {
		sessionStorage.setItem('selectedDate', JSON.stringify(dateTime))
		window.history.pushState(null, null, window.location.href)
		window.addEventListener('popstate', backwardHandler)
		return () => window.removeEventListener('popstate', backwardHandler)
	}, [])

	return (
		<div>
			<BookingInfoView />
			<CategorySelector />
			<Seperator />
			{deposit > 0 && <DepositNotice />}
			<BookingFooter text="다음" action={startProvisionalReservation} active={categoryId} />
		</div>
	)
}
