import React from 'react'
import './ChatbotInfo.scss'
export default function ChatbotInfo() {
	return (
		<div className="chatbot-info-container">
			<span className="notice-icon">※</span>
			<div className="chatbot-text">
        예약상품권은 챗봇으로 예약 후 방문시에만 사용할 수 있습니다.
			</div>
		</div>
	)
}
