import { Checkbox, FormControlLabel, ListItemText, Radio, RadioGroup, Select } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import React, { useEffect } from 'react'
import BookingSubtitle from '../../../Share/Booking/BookingSubtitle/BookingSubtitle'
import { ReactComponent as Close } from '../../../../assets/icon/close.svg'
import { ReactComponent as ActiveRadio } from '../../../../assets/icon/activeRadio.svg'
import { ReactComponent as DeactiveRadio } from '../../../../assets/icon/deactiveRadio.svg'
import { useReservation, useValidation } from '../../../../store'
export default function AdditionalInfoForm() {
	const { additionalInfos, setReservation } = useReservation()
	const { setValidation } = useValidation()

	const setAdditionalInfo = (value, name) => {
		let tmpAdditionalInfos = [...additionalInfos]
		let targetAdditional = tmpAdditionalInfos.find((additional) => additional.name === name)
		targetAdditional.value = value
		setReservation('additionalInfos', tmpAdditionalInfos)
	}

	const sendParentEvent = () => {
		window.parent.postMessage({ func: 'hideKeyPad' }, '*')
	}

	useEffect(() => {
		if (additionalInfos && typeof additionalInfos === 'object') {
			additionalInfos.forEach((item) => {
				if (item.required && item.enabled && !item.value)
					setValidation('additionalInfo', `${item.name}을 입력해주세요.`)
			})
			const everyAdditionalInfoClear = additionalInfos.every((item) => {
				if (!item.required || !item.enabled)
					return true
				if (item.required && item.value) {
					if (item.multiple && item.value.length)
						return true
					return true
				}
				return false
			})
			if (everyAdditionalInfoClear)
				setValidation('additionalInfo', true)
		}
	}, [JSON.stringify(additionalInfos)])

	if (!additionalInfos)
		return null

	return (
		<div className="additional-info-form-container">
			{additionalInfos
				.filter((additional) => additional.enabled)
				.map((additional, idx) => (
					<div className="additional-item" key={idx}>
						<BookingSubtitle subtitle={additional.name} firebrick={additional.required} />
						{additional.kind === 'select' && !additional.multiple && (
							<div>
								<select
									value={additional.value}
									className="additional-select"
									onChange={(event) => setAdditionalInfo(event.target.value, additional.name)}
									onBlur={sendParentEvent}
								>
									{additional.validations.map((validation, index) => (
										<option value={validation.name} key={index}>
											{validation.name}
										</option>
									))}
								</select>
							</div>
						)}
						{additional.kind === 'select' && additional.multiple && (
							<div>
								<Select
									value={additional.value}
									className="additional-select b1"
									renderValue={(selected) => selected.join(', ')}
									multiple
									onChange={(event) => setAdditionalInfo(event.target.value, additional.name)}
								>
									{additional.validations.map((validation, index) => (
										<MenuItem value={validation.name} key={index}>
											<Checkbox checked={additional.value.indexOf(validation.name) > -1} />
											<ListItemText primary={validation.name} />
										</MenuItem>
									))}
								</Select>
							</div>
						)}
						{additional.kind === 'text' && (
							<div className="text-additional-info">
								<input
									type="text"
									placeholder={additional.notice
										? additional.notice
										: null}
									value={additional.value}
									className="input b1"
									onChange={(event) => setAdditionalInfo(event.target.value, additional.name)}
									onBlur={sendParentEvent}
								/>
								<div className="clear-button">
									<Close className="icon" />
								</div>
							</div>
						)}
						{additional.kind === 'toggle' && (
							<div>
								<div>
									<RadioGroup value={additional.value} row>
										<div className="radio-box">
											<FormControlLabel
												name={additional.name}
												value={false}
												control={
													<Radio
														checkedIcon={<ActiveRadio className="icon active" />}
														icon={<DeactiveRadio className="icon" />}
														onChange={(event) => setAdditionalInfo(false, additional.name)}
													/>
												}
												label="없음"
											/>
										</div>
										<div className="radio-box">
											<FormControlLabel
												name={additional.name}
												value={true}
												control={
													<Radio
														checkedIcon={<ActiveRadio className="icon active" />}
														icon={<DeactiveRadio className="icon" />}
														onChange={(event) => setAdditionalInfo(true, additional.name)}
													/>
												}
												label="있음"
											/>
										</div>
									</RadioGroup>
								</div>
								{additional.notice && <div className="b3">{additional.notice}</div>}
							</div>
						)}
					</div>
				))}
		</div>
	)
}
