import moment from 'moment'
import { useMemo } from 'react'

export default function useCalendar(date) {
	const fullDate = useMemo(() => moment(date), [date])
	const lastDateOfPrevMonth = new Date(fullDate.year(), fullDate.month(), 0)
	const lastDateOfCurrentMonth = new Date(fullDate.year(), fullDate.month() + 1, 0)
	const lastDateNumOfPrevMonth = lastDateOfPrevMonth.getDate()
	const lastDayNumOfPrevMonth = lastDateOfPrevMonth.getDay()
	const lastDateNumOfCurrentMonth = lastDateOfCurrentMonth.getDate()
	const lastDayNumOfCurrentMonth = lastDateOfCurrentMonth.getDay()

	const getAll = () => {
		const now = getNow()
		const prev = getPrev()
		const next = getNext()

		return prev.concat(now, next)
	}

	const getNow = () => {
		return Array.from(Array(lastDateNumOfCurrentMonth + 1).keys()).slice(1)
	}

	const getPrev = () => {
		const dates = []

		for (let i = 0; i <= lastDayNumOfPrevMonth; i++)
			dates.unshift(lastDateNumOfPrevMonth - i)

		return dates
	}

	const getNext = () => {
		const dates = []

		for (let i = 1; i < 7 - lastDayNumOfCurrentMonth; i++)
			dates.push(i)

		return dates
	}

	return { getAll, getNow, getPrev, getNext }
}
