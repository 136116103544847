/* eslint-disable react-hooks/exhaustive-deps */
/* global kakao*/
import React from 'react'
import { useEffect } from 'react'
import './Map.scss'
export default function Map(props) {
	const { lat, lng } = props
	let map = null
	useEffect(() => {

		if (lat && lng && !map && typeof kakao !== 'undefined') {
			kakao.maps.load(() => {

				let container = document.getElementById('map-container')
				const options = {
					center: new kakao.maps.LatLng(lat, lng),
					level: 3,
					draggable: false,
					disableDoubleClickZoom: true,
					disableDoubleClick: true
				}

				map = new window.kakao.maps.Map(
					container,
					options
				)

				let marker = new kakao.maps.Marker({
					map: map,
					position: new kakao.maps.LatLng(lat, lng)
				})
				marker.setMap(map)
			})
		}

	}, [lat, lng])

	return (
		<div id="map-container">
		</div>
	)
}
