import React from 'react'
import { useAccountContext } from '../../../../context/Common/account'
import { ReactComponent as RightArrow } from '../../../../assets/icon/voucherRightArrow.svg'
import moment from 'moment'
import './ReservationItem.scss'

export default function ReservationItem(props) {
	const { reservation } = props
	const { user } = useAccountContext()

	const goDetailPage = (reservationId) => {
		window.open(`${process.env.REACT_APP_MYTABLE_HOST}/mypage/detail/${reservationId}?theyeyakUserToken=${user.token}`, '_blank')
	}

	const changeReservationDateFormat = (date) => {
		const reservationDate = moment(new Date(date.year, date.month - 1, date.day, date.hour))
		return reservationDate.format('YYYY년 M월 D일 (dd) H시')
	}

	return (
		<div className="reservation-item-wrapper" onClick={(() => goDetailPage(reservation.reservationId))}>
			<div className="reservation-information-wrapper">
				<div className="reservation-thumbnail-wrapper">
					<img
						className={
							reservation.state === 'CANCEL'
								? 'thumbnail cancel'
								: 'thumbnail'
						}
						src={reservation.thumbnail} alt="">
					</img>
					{
						reservation.state === 'CANCEL'
							? <span className="cancel-mark">취소</span>
							: <></>
					}
				</div>
				<div className="reservation-information">
					<span className="reservation-name">{reservation.brand && reservation.brand.name}</span>
					<p className="reservation-date">{changeReservationDateFormat(reservation)}</p>
					<p className="reservation-people-count">{reservation.peopleCount}명</p>
				</div>
				<div className="arrow-button">
					<RightArrow className="right-arrow"></RightArrow>
				</div>
			</div>
		</div>
	)
}
