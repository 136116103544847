import React from 'react'
import { useVoucherContext } from '../../../../context/Common/voucher'
import BookingSubtitle from '../../../Share/Booking/BookingSubtitle/BookingSubtitle'
import './ProductInfo.scss'

export default function ProductInfo() {
	const { voucherInfo } = useVoucherContext()

	return (
		<div className="product-info-container">
			<BookingSubtitle subtitle="구매 상품"></BookingSubtitle>
			<div className="info-box">
				<div className="label b1">구매상품</div>
				<div className="value b1">{
					voucherInfo.products.filter(item => item.count > 0).map((item, idx) =>
						(<span key={idx}>{item.product.name} {item.count}장</span>)
					)
				}</div>
			</div>
			<div className="info-box">
				<div className="label b1">구매금액</div>
				<div className="value b1">{voucherInfo.products.filter(item => item.count > 0).reduce((a, b) => a + b.product.salePrice * b.count, 0).toLocaleString()}원</div>
			</div>
		</div>
	)
}
