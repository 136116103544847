import React from 'react'
import HistroryItem from './HistoryItem'

export default function HistroyList(props) {
	return (
		<div>
			{props.histories.map((histroy, idx) => <HistroryItem history={histroy} key={idx}></HistroryItem>)}
		</div>
	)
}
