import React, { useEffect, useState } from 'react'
import { useMyPageContext } from '../../../context/Booking/myPage'
import { useHistory } from 'react-router-dom'
import { usePartnerContext } from '../../../context/Common/partner'
import { useAccountContext } from '../../../context/Common/account'
import Seperator from '../../../Components/Share/Seperator'
import './MyPage.scss'
import { EmptyInfo, ReservationList, UserInfo, VoucherList } from '../../../Components/Booking'

const reservationType = {
	AVAILABLE: 'AVAILABLE',
	PAST: 'PAST',
	CANCEL: 'CANCEL'
}

export default function MyPage() {
	const { apiKey, partnerInfo, partnerName, headerExists } = usePartnerContext()
	const {
		reservationList,
		setReservationInfo,
		voucherList,
		getMyList
	} = useMyPageContext()
	const { user } = useAccountContext()
	const history = useHistory()
	const [loaded, setLoaded] = useState(true)

	useEffect(() => {
		setReservationInfo(null)
		sessionStorage.removeItem('voucherData')
		if (!sessionStorage.getItem('authorization'))
			sessionStorage.setItem('authorization', user.token)
	}, [])

	useEffect(() => getMyList({ apiKey, partnerName, partnerInfo, setIsLoading: setLoaded }), [partnerName, apiKey])

	useEffect(() => {
		if (history.location.state) {
			if (voucherList && history.location.state.move === 'purchaseList')
				document.querySelector('#purchase-list')?.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' })
			if (reservationList && reservationList[reservationType.AVAILABLE] && history.location.state.move === 'reservationList')
				document.querySelector('#reservation-list')?.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' })
		}
	}, [voucherList, reservationList])

	if (loaded)
		return null

	return (
		<div className={`mypage-container ${headerExists
			? 'header-exists'
			: ''}`}>
			{partnerName === 'tablemanager' && (
				<>
					<UserInfo />
					<Seperator style={{ margin: 0 }}></Seperator>
				</>
			)}
			{voucherList.length > 0
				? <VoucherList></VoucherList>
				: null}
			{reservationList.length > 0
				? <ReservationList></ReservationList>
				: null}
			{voucherList.length === 0 && reservationList.length === 0 ?
				<EmptyInfo></EmptyInfo>
				: null}
		</div>
	)
}
