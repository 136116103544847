import React, { useLayoutEffect } from 'react'
import './Gallery.scss'
import { useMall } from '../../../store'
import { useParams } from 'react-router-dom'

export default function Gallery() {
	const { mallUri } = useParams()
	const { mall, setMall } = useMall()

	useLayoutEffect(() => {
		if (mallUri)
			setMall('mallUri', mallUri)
	}, [mallUri])

	if (!mall)
		return null
	return (
		<div className="gallery-page-container">
			{mall.images.map((item, idx) => <div style={
				{
					height: '50vw',
					width: '100vw',
					backgroundImage: `url(${item.imageUrl})`,
					backgroundPosition: 'center',
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
					display: 'inline-block',
				}
			} alt='매장 이미지' key={idx} />)}
		</div>
	)
}
