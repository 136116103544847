import React from 'react'
import Complete from '../../../Components/User/ChangeName/Complete/Complete'

export default function CompletePage() {
	return (
		<div>
			<Complete></Complete>
		</div>
	)
}
