import React, { useState } from 'react'
import { useVoucherContext } from '../../../context/Common/voucher'
import { useHistory, useLocation } from 'react-router-dom'
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import { ReactComponent as ActiveRadio } from '../../../assets/icon/activeRadio.svg'
import { ReactComponent as DeactiveRadio } from '../../../assets/icon/deactiveRadio.svg'
import './SelectVoucherMall.scss'

export default function SelectVoucherMall() {
	const { voucherMalls, voucherId } = useVoucherContext()
	const [selectedMallId, setSelectedMallId] = useState(null)
	const history = useHistory()
	const location = useLocation()

	function moveMall() {
		let selectedMall = voucherMalls.find(mall => mall.id === selectedMallId)
		if (selectedMall)
			history.replace(`/booking/mall-detail/${selectedMall.uri}?voucherId=${voucherId}`)
	}

	const closeSelectVoucherMall = () => {
		location.hash ?
			history.push('/booking/list')
			: history.goBack()
	}

	return (
		<div className="select-voucher-mall-container">
			<RadioGroup value={selectedMallId} onChange={(e) => setSelectedMallId(Number(e.target.value))} className="voucher-radio-group">
				{voucherMalls.map((mall, idx) =>
					<div className="radio-box" key={idx}>
						<FormControlLabel name={mall.id} value={mall.id}
							control={<Radio checkedIcon={<ActiveRadio className="icon active"/>} icon={<DeactiveRadio className="icon"/>} />} label={mall.name} className="radio-label b1"/>
					</div>
				)}
			</RadioGroup>
			<div className="footer-button-container">
				<div className="footer-button t4" onClick={closeSelectVoucherMall}>닫기</div>
				<div className={selectedMallId
					? 'selected footer-button t4'
					: 'disabled footer-button t4'} onClick={moveMall}>선택</div>
			</div>
		</div>
	)
}
