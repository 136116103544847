import React from 'react'
import './MenuOption.scss'
import RadioboxGroup from './RadioboxGroup'
import CheckboxGroup from './CheckboxGroup'
import CounterGroup from './CounterGroup'
import { RadioboxGroup as GyeongjuroRadioboxGroup } from '../../Gyeongju/RadioboxGroup'
import { CheckboxGroup as GyeongjuroCheckboxGroup } from '../../Gyeongju/CheckboxGroup'
import { CounterGroup as GyeongjuroCounterGroup } from '../../Gyeongju/CounterGroup'
import useDynamicComponent from '../../../hooks/useDynamicComponent'

export default function MenuOption(props) {
	const { renderComponent } = useDynamicComponent()
	if (props.optionGroup.type === 'NUMBER') {
		return renderComponent({
			default: (
				<div className="menu-option-container">
					<div className="menu-option-name">{props.optionGroup.name} {props.optionGroup.isRequired
						? <Required />
						: null}</div>
					<CounterGroup optionGroup={props.optionGroup}></CounterGroup>
				</div>
			),
			gyeongjuro: (
				<div className="gyeongjuro-menu-option-container">
					<div className="menu-option-name">{props.optionGroup.name} {props.optionGroup.isRequired
						? <Required />
						: null}</div>
					<GyeongjuroCounterGroup optionGroup={props.optionGroup}></GyeongjuroCounterGroup>
				</div>
			)
		})
	}
	if (props.optionGroup.type === 'MULTI_SELECT') {
		return renderComponent({
			default: (
				<div className="menu-option-container">
					<div className="menu-option-name">{props.optionGroup.name} {props.optionGroup.isRequired
						? <Required />
						: null}</div>
					<CheckboxGroup optionGroup={props.optionGroup}></CheckboxGroup>
				</div>
			),
			gyeongjuro: (
				<div className="gyeongjuro-menu-option-container">
					<div className="menu-option-name">{props.optionGroup.name} {props.optionGroup.isRequired
						? <Required />
						: null}</div>
					<GyeongjuroCheckboxGroup optionGroup={props.optionGroup}></GyeongjuroCheckboxGroup>
				</div>
			)
		}

		)
	}
	if (props.optionGroup.type === 'SELECT') {
		return renderComponent({
			default: (
				<div className="menu-option-container">
					<div className="menu-option-name">{props.optionGroup.name} {props.optionGroup.isRequired
						? <Required />
						: null}</div>
					<RadioboxGroup optionGroup={props.optionGroup}></RadioboxGroup>
				</div>
			),
			gyeongjuro: (
				<div className="gyeongjuro-menu-option-container">
					<div className="menu-option-name">{props.optionGroup.name} {props.optionGroup.isRequired
						? <Required />
						: null}</div>
					<GyeongjuroRadioboxGroup optionGroup={props.optionGroup}></GyeongjuroRadioboxGroup>
				</div>
			)
		}
		)
	}
}
const Required = () => (
	<span className="required">
		(필수)
	</span>
)
