import React from 'react'
import { useHomeContext } from '../../../../context/Voucher/home'
import Title from '../../../Share/Title/Title'
import MissedLive from '../../Home/MissedLive/MissedLive'

export default function MissingLive() {
	const { missLiveProducts } = useHomeContext()
	if (!missLiveProducts || missLiveProducts.length === 0)
		return null
	return (
		<div className="missed-lives-wrapper">
			<Title text={'아쉽게 놓친 라이브 😅'}></Title>
			<div className="missed-lives">
				{missLiveProducts.map((product, index) => <MissedLive key={index} product={product} />)}
			</div>
		</div>
	)
}
