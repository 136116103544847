import React, { useLayoutEffect } from 'react'
import { ClipLoader } from 'react-spinners'
import usePathQuery from '../../../hooks/usePathQuery'
import { usePartnerContext } from '../../../context/Common/partner'
import { PartnerAPI } from '../../../lib/partner'
import { useHistory } from 'react-router-dom'

const Incoming = () => {
	const { apiKey, partnerName, setPartnerInfo } = usePartnerContext()
	const { gyeongjuroLogin } = new PartnerAPI(apiKey, null)
	const query = usePathQuery()
	const history = useHistory()

	useLayoutEffect(() => {
		if (apiKey && partnerName === 'gyeongjuro' && query) {
			/**
			 * @typedef LoginResponse
			 * @prop { string } authorization
			 * @prop { string } mallUri
			 * @prop { string } name
			 * @prop { string } phone
			 * @prop { boolean } result
			 * @prop { string } shopId
			 */
			gyeongjuroLogin(query).then(
			/** @param {LoginResponse} res */
				(res) => {
					if (res.result) {
						sessionStorage.setItem('authorization', res.authorization)
						sessionStorage.setItem('shopId', res.shopId)
						setPartnerInfo({
							name: res.name,
							phone: res.phone,
						})
						if (query?.redirectUrl)
							history.replace(query.redirectUrl)
						else
							history.replace(`/booking/${res.mallUri}/step/info`)
					}
				})
		}
	}, [apiKey, partnerName])

	return (
		<div className="incoming-page-container">
			<div className="loader-container">
				<ClipLoader />
			</div>
		</div>
	)
}

export default Incoming
