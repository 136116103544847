import React from 'react'
import MallItem from '../../../Share/Booking/MallItem/MallItem'
import './LocationItem.scss'

export default function LocationItem(props) {
	return (
		<div className="location-item-container">
			<MallItem mall={props.mall}></MallItem>
		</div>
	)
}
