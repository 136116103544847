import React, { useState, useEffect } from 'react'
import { useVoucherContext } from '../../../../context/Common/voucher'
import BookingSubtitle from '../../../Share/Booking/BookingSubtitle/BookingSubtitle'
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import PaymentForm from '../PaymentForm/PaymentForm'
import MyTableCardList from '../MyTableCardList/MyTableCardList'
import { ReactComponent as ActiveRadio } from '../../../../assets/icon/activeRadio.svg'
import { ReactComponent as DeactiveRadio } from '../../../../assets/icon/deactiveRadio.svg'
import './PaymentType.scss'

export default function PaymentType(props) {
	const { cardList, deleteCard } = props
	const { paymentInfo, dispatchPaymentInfo, showPaymentForm, setShowPaymentForm } = useVoucherContext()

	const changeShowPaymentForm = () => {
		if (!showPaymentForm)
			dispatchPaymentInfo({ type: 'CHANGE_MEMBER_CARD_ID', value: '' })
		setShowPaymentForm(!showPaymentForm)
	}

	useEffect(() => {
		if (cardList && cardList.length > 0)
			setShowPaymentForm(false)
		else
			setShowPaymentForm(true)
	}, [cardList])

	return (
		<div className="payment-container">
			<BookingSubtitle subtitle="결제 수단"></BookingSubtitle>
			<div className="payment-radio-group">
				<RadioGroup value={paymentInfo.type} onChange={(e) => dispatchPaymentInfo({ type: 'CHANGE_PAYMENT_TYPE', value: e.target.value })}>
					<div className="radio-box">
						<FormControlLabel className="t4" name='카드정보 입력' value="BILLING_KEY" control={<Radio checkedIcon={<ActiveRadio className="icon active" />} icon={<DeactiveRadio className="icon" />} />} label='카드정보 입력' />
					</div>
					{
						paymentInfo.type === 'BILLING_KEY'
							? [
								(
									cardList && cardList.length > 0
										? <MyTableCardList key="MyTableCardList" cardList={cardList} deleteCard={deleteCard} isOpen addCard={changeShowPaymentForm}></MyTableCardList>
										: null
								),
								(
									showPaymentForm
										? <PaymentForm key="paymentForm"></PaymentForm>
										: null
								)
							]
							: null
					}
					<div className="radio-box">
						<FormControlLabel className="t4" name='신용 / 체크카드' value="PG" control={<Radio checkedIcon={<ActiveRadio className="icon active" />} icon={<DeactiveRadio className="icon" />} />} label='신용 / 체크카드' />
					</div>
					<div className="radio-box">
						<FormControlLabel className="t4" name='카카오페이' value="KAKAO" control={<Radio checkedIcon={<ActiveRadio className="icon active" />} icon={<DeactiveRadio className="icon" />} />} label='카카오페이' />
					</div>
					{/* <div className="radio-box">
						<FormControlLabel className="t4" name='네이버페이' value="NAVER" control={<Radio checkedIcon={<ActiveRadio className="icon active" />} icon={<DeactiveRadio className="icon" />} />} label='네이버페이' />
					</div> */}
				</RadioGroup>
			</div>
		</div>
	)
}
