import React from 'react'
import { ReactComponent as Info } from '../../../../assets/icon/info.svg'
import { useReservation } from '../../../../store'
import useDynamicComponent from '../../../../hooks/useDynamicComponent'
export default function DepositNotice() {
	const { getLocaleDeposit } = useReservation()
	const { renderComponent } = useDynamicComponent()
	return (
		<div className="deposit-notice-container">
			<div className="deposit-container">
				<div className="title t3">
					총 예약금 <span className="money t3">{getLocaleDeposit()}</span>
				</div>
			</div>
			<div className="separator-line"></div>
			<div className="noshow-container">
				{renderComponent({
					default: (
						<div className="noshow-box b3">
							<Info className="icon" />
							<div className="notice">
								매장 정책에 따라 방문 시 예약금을 환불해드리거나 예약금을 제외한 금액이 결제됩니다.
								<br /> <br />
								해당 레스토랑은 <span className="noshow-text">No Show</span>로 인해 발생되는 매장의 피해를 예방하고자
								예약금 제도를 운영합니다.
							</div>
						</div>
					),
					gyeongjuro: (
						<ul>
							<li>
								해당 레스토랑은 <span className="noshow-text">No Show</span>로 인해 발생되는 매장의 피해를 예방하고자 예약금
								제도를 운영합니다.
							</li>
							<li>추후 매장 방문 시 예약금을 제외한 금액이 결제됩니다.</li>
						</ul>
					),
				})}
			</div>
		</div>
	)
}
