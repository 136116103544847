import React from 'react'
import { useHomeContext } from '../../../../context/Voucher/home'
import Title from '../../../Share/Title/Title'
import NowLive from '../../Home/NowLive/NowLive'

export default function LiveProduct() {
	const { nowLiveProducts } = useHomeContext()
	if (!nowLiveProducts || nowLiveProducts.length === 0)
		return null
	return (
		<div className="voucher-products">
			<Title text={'NOW 라이브 🔴'}></Title>
			{nowLiveProducts.map((data, index) => <NowLive key={index} data={data}></NowLive>)}
		</div>
	)
}
