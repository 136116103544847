import './BottomSheet.scss'

const BottomButton = (props) => {
	const { className, cancelBtn, confirmBtn, cancelFunc, confirmFunc, isDisabledConfirmBtn } = props

	return <div className={className}>
		<button
			className="cancel-button"
			onClick={cancelFunc}
		>
			<span className="cancel-button-text">{cancelBtn}</span>
		</button>
		<button
			className="buy-button"
			onClick={confirmFunc}
			disabled={isDisabledConfirmBtn}
		>
			<span className="buy-button-text">{confirmBtn}</span>
		</button>
	</div>
}

export default BottomButton
