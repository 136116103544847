import React from 'react'
import { useMyPageContext } from '../../../../context/Booking/myPage'
import BookingSubtitle from '../../../Share/Booking/BookingSubtitle/BookingSubtitle'
import RefundPolicy from '../../../Share/Booking/RefundPolicy/RefundPolicy'
import './MyPageDepositNotice.scss'
export default function MyPageDepositNotice() {
	const { deposit_refunds: refundPolicy } = useMyPageContext().reservationInfo
	return (
		<div className="mypage-deposit-notice-container">
			<BookingSubtitle subtitle="취소 수수료 안내"></BookingSubtitle>
			<div className="description">
					예약 후 노쇼로 인한 다른 회원의 이용 제한을 최소화하고 합리적인 예약문화를 정착하고자 취소 수수료 부과 제도를 통한 예약 시스템을 운영하고 있습니다.
			</div>
			<RefundPolicy policies={refundPolicy}></RefundPolicy>
		</div>
	)
}
