import { createContext, useContext, useReducer } from 'react'

const MenuOptionContext = createContext({
	dispatchMenuOption: () => { },
	getOptionValue: () => { },
	menuOption: {},
	menu: {},
})

const menuOptionReducer = (menuOption, action) => {
	const { optionGroupId, optionId } = action

	const exValue = menuOption[optionGroupId][optionId].value
	const optionGroup = menuOption[optionGroupId]
	const option = menuOption[optionGroupId][optionId]
	let returnObject
	switch (action.type) {
	case 'INCREMENT':
		console.log(option)
		if (option.max < exValue + 1) {
			returnObject = { ...menuOption }
			break
		}
		returnObject = { ...menuOption, [optionGroupId]: { ...optionGroup, [optionId]: { ...option, value: exValue + 1 } } }
		break
	case 'DECREMENT':
		if (exValue + -1 < 0 || option.min > exValue + -1) {
			returnObject = { ...menuOption }
			break
		}
		returnObject = { ...menuOption, [optionGroupId]: { ...optionGroup, [optionId]: { ...option, value: exValue + -1 } } }
		break
	case 'SELECT':
		returnObject = { ...menuOption, [optionGroupId]: { ...optionGroup, value: optionId } }
		break
	case 'MULTI_SELECT':
		returnObject = { ...menuOption, [optionGroupId]: { ...optionGroup, [optionId]: { ...option, value: !exValue } } }
		break
	default:
		throw new Error('Unexpected type')
	}
	return returnObject
}

const MenuOptionContextProvider = (props) => {
	const { menu, children } = props
	const initialMenuOption = {}
	menu.optionGroups.forEach((og) => {
		initialMenuOption[og._id] = { value: og.options[0]._id }
		og.options.forEach((opt) => {
			if (og.type === 'MULTI_SELECT') {
				initialMenuOption[og._id][opt._id] = {
					value: false,
					name: opt.name,
					price: opt.price,
				}
			} else if (og.type === 'NUMBER') {
				initialMenuOption[og._id][opt._id] = {
					value: 0,
					name: opt.name,
					price: opt.price,
					min: opt.min || 0,
					max: opt.max || 99,
				}
			} else {
				initialMenuOption[og._id][opt._id] = {
					value: 0,
					name: opt.name,
					price: opt.price,
				}
			}
		})
	})

	const [menuOption, dispatchMenuOption] = useReducer(menuOptionReducer, initialMenuOption)
	const getOptionValue = (optionGroupId, optionId) => {
		return menuOption[optionGroupId][optionId]
	}

	const initialValue = {
		menu,
		menuOption,
		dispatchMenuOption,
		getOptionValue
	}

	return <MenuOptionContext.Provider value={initialValue}>{children}</MenuOptionContext.Provider>
}

const useMenuOptionContext = () => useContext(MenuOptionContext)

export { MenuOptionContextProvider, useMenuOptionContext }
