import React from 'react'
import { useHomeContext } from '../../../../context/Booking/home'
import ImageCarousel from '../../../Share/ImageCarousel'

export default function Banner() {
	const { banners } = useHomeContext()
	if (!banners)
		return <div></div>

	return (
		<ImageCarousel images={banners}></ImageCarousel>
	)
}
