import React from 'react'
import useDynamicComponent from '../../../../hooks/useDynamicComponent'
import { usePartnerContext } from '../../../../context/Common/partner'
export default function RefundPolicy(props) {
	const { policies } = props
	const { partnerName } = usePartnerContext()
	const { renderComponent } = useDynamicComponent()

	function replaceRefundPolicy(item, valueType) {
		let dateType = '일'
		let percent = ''
		let value = ''
		switch (item.date_type) {
		case 0:
			dateType = '일'
			break
		case 1:
			dateType = '월'
			break
		case 2:
			dateType = '노쇼'
			break
		default:
			dateType = '일'
			break
		}
		if (item.refundable) {
			switch (item.percent) {
			case 0:
				percent = '환불 불가'
				break
			default:
				percent = item.percent + '% 환불'
				break
			}
		} else {
			percent = '환불 불가'
		}
		if (dateType === '노쇼')
			value = 'No Show'
		else if (item.date_value === 0)
			value = '당일'
		else
			value = item.date_value + dateType + ' 전'

		if (valueType)
			return value

		switch (partnerName) {
		case 'gyeongjuro':
			const percentValue = parseInt(percent)
			if (isNaN(percentValue))
				return percent

			if ((100 - percentValue) === 0)
				return '없음'
			return `결제 금액의 ${100 - percentValue}%`

		default:
			return percent
		}
	}

	return (
		<>
			{renderComponent({ gyeongjuro: <hr className='refund-policy-border-line' /> })}
			<table className="refund-policy-table b3">
				<thead>
					<tr>
						<th>취소 시점 (이용일 기준)</th>
						<th>취소 수수료</th>
					</tr>
				</thead>
				<tbody>
					{policies.map((item, idx) =>
						<tr key={idx}>
							<td>{replaceRefundPolicy(item, true)}</td>
							<td>{replaceRefundPolicy(item, false)}</td>
						</tr>
					)}
				</tbody>
			</table>
		</>
	)
}
