import React, { useEffect, useLayoutEffect, useState } from 'react'
import './Menu.scss'
import { useMall } from '../../../store'
import { useParams } from 'react-router-dom'

export default function Menu() {
	const { mallUri } = useParams()
	const { mall, setMall } = useMall()
	const [allMenus, setAllMenus] = useState([])

	useEffect(() => {
		let menus = []
		if (mall) {
			mall.info_menu.forEach((category) => {
				if (category.menus && category.menus.length > 0)
					menus = menus.concat(category.menus)

			})
			setAllMenus(menus)
		}
	}, [mall])

	useLayoutEffect(() => {
		if (mallUri)
			setMall('mallUri', mallUri)
	}, [mallUri])

	if (!mall || !mall.info_menu)
		return null

	return (
		<div className="menu-page-container">
			{allMenus.map((menu, idx) => <div className="menu-box" key={idx}>
				<div className="name b2">{menu.name}</div>
				<div className="price b1">{menu.fee.toLocaleString()} 원</div>
			</div>)}
		</div >
	)
}
