import React from 'react'
import BrandItem from '../../../Share/BrandItem/BrandItem'
import './TheyeyakWithBrands.scss'

export default function TheyeyakWithBrands(props) {
	const { brands } = props

	return (
		<div className="theyeyak-with-brands-wrapper">
			<h2 className="theyeyak-with-brands-title">더예약과 함께하는 브랜드 🤝</h2>

			<div className="theyeyak-brands-list">
				{
					brands.filter(brand => !brand.id.includes('test')).map((brand, index) => <BrandItem key={index} imageUrl={brand.thumbnail} brandName={brand.name}></BrandItem>)
				}
			</div>
		</div>
	)
}
