import React from 'react'
import './MallItem.scss'
import { useHistory } from 'react-router-dom'

export default function MallItem(props) {
	const history = useHistory()
	function moveMall(uri) {
		sessionStorage.setItem('moveMallId', uri)
		sessionStorage.setItem('moveScrollY', window.scrollY)
		history.push(`/booking/mall-detail/${uri}`)
	}
	return (
		<div className="mall-item-container" onClick={() => moveMall(props.mall.mall_uri)} id={'mall-item-' + props.mall.mall_uri}>
			<div className="mall-image" style={
				{
					backgroundImage : `url(${props.mall.thumbnail || props.mall.info_images[0].src})`,
					backgroundPosition: 'center',
					backgroundSize: 'cover',  /* contain */
					backgroundRepeat: 'no-repeat'
				}}>
				{props.mall.mall_group['할인율'] > 0
					? <div className="mall-discount b3">{props.mall.mall_group['할인율']}%</div>
					: null}
			</div>
			<div className="mall-right-area">
				<div className="mall-info">
					<span className="mall-food b3">{ props.mall.mall_food.join('·') }</span>
				</div>
				<div className="mall-name t5">{props.mall.mall_name}</div>
				<div className="mall-description b3">{ props.mall.mall_group['매장설명'] }</div>
			</div>
		</div>
	)
}
