const getSaleRate = (amount, salePrice) => {
	return Math.floor((100 - (salePrice * 100 / amount)) * 10) / 10
}

const favoriteType = {
	NOTIFICATION: 'NOTIFICATION',
	LIKE: 'LIKE'
}

const inquiryType = {
	CUSTOMER: 'customer',
	PARTNERSHIP: 'partnership',
	BULKPURCHASE: 'bulkpurchase',
	LIVE_COMMERCE: 'live-commerce',
	INTRODUCE: 'introduce',
	THEYEYAK_BLOG: 'theyeyakBlog'
}

export { favoriteType, inquiryType, getSaleRate }
