import React, { useEffect, useReducer, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import OutlineInput from '../../../../Components/Share/Input/OutlineInput/OutlineInput'
import OutlineSelect from '../../../../Components/Share/OutlineSelect/OutlineSelect'
import { getQuestionEmail, requestQuestions } from '../../../../lib/voucher'
import { useModalContext } from '../../../../context/Common/modal'
import { isMobile } from '../../../../utils'
import './PartnershipInquiry.scss'
import { useAccountContext } from '../../../../context/Common/account'

const selectReferralList = [
	'인터넷 검색', '네이버 블로그', '페이스북', '인스타그램', '배너광고', '지인추천', '기타'
]
const reducer = (state, action) => ({ ...state, [action.name]: action.value })
const useInput = (initialState) => {
	const [state, dispatch] = useReducer(reducer, initialState)
	const onChange = (e) => {
		dispatch(e.target)
	}

	const setValue = (name, value) => {
		dispatch({ name, value })
	}

	return [state, onChange, setValue]
}

export default function PartnershipInquiry() {
	const history = useHistory()
	const [inquiryForm, onChange, setValue] = useInput({
		type: 'PARTNER',
		mallName: '',
		phone: '',
		email: '',
		region: '',
		referral: '',
		content: '',
	})
	const [isValid, setIsValid] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const { user } = useAccountContext()
	const { pageModalOpen } = useModalContext()

	useEffect(() => {
		if (user) {
			getQuestionEmail(user.token, 'PARTNER').then(res => {
				if (res.email)
					setValue('email', res.email)

			}).catch((err) => {
				console.log(err)
				setIsLoading(false)
			})
		}
	}, [user])

	const validationInquiryForm = () => {
		if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(inquiryForm.email))
			return false
		return Object.values(inquiryForm).every(value => value)
	}

	const requestInquiry = () => {
		setIsLoading(true)
		requestQuestions(inquiryForm)
			.then((res) => {
				setIsLoading(false)
				pageModalOpen({
					path: '/voucher/main',
					content: {
						title: (
							<>
								고객님의 문의가<br/>
								더예약 팀에게 전달되었습니다! 💌
							</>
						),
						description: <>
							더예약에 관심을 갖고 문의 남겨 주셔서<br/>
							진심으로 감사드립니다!<br/>
							더예약 전문 컨설턴트가 최대한 빠른 시일내에<br/>
							연락 드리도록 하겠습니다 :)<br/>
							<br/>
							감사합니다.
						</>
					}
				})
			}).catch((err) => {
				console.log(err)
				setIsLoading(false)
			})
	}

	useEffect(() => {
		const valid = validationInquiryForm()
		setIsValid(valid)
	}, [inquiryForm])

	return (
		<div className={
			isMobile() ?
				'partnership-inquiry-container mobile'
				: 'partnership-inquiry-container'
		}>
			<div className="partnership-inquiry-wrapper">
				<div className="partnership-inquiry-description">
					<h2 className="partnership-inquiry-description-title">
						지금 바로 무료로<br/>
						상담 받아보세요 💡️
					</h2>
					<p className="partnership-inquiry-paragraph">
						부담없이 무료 상담 받으세요.<br/>
						상담 신청시 전문 컨설턴트가 직접 방문하여<br/>
						매장별 맞춤 상담 해드립니다.️
					</p>
				</div>

				<div className="partnership-inquiry-form">
					<OutlineInput label="매장명" name="mallName" value={inquiryForm.mallName} placeholder="매장명을 입력해주세요" onChange={onChange} required={true}></OutlineInput>
					<OutlineInput type="tel" label="전화번호" name="phone" value={inquiryForm.phone} placeholder="‘-’ 없이 숫자만 입력해주세요" onChange={onChange} required={true}></OutlineInput>
					<OutlineInput type="email" label="이메일" name="email" value={inquiryForm.email} placeholder="theyeyak@example.com" onChange={onChange} required={true}></OutlineInput>
					<OutlineInput label="지역" name="region" value={inquiryForm.region} placeholder="지역명을 입력해주세요" onChange={onChange} required={true}></OutlineInput>
					<OutlineSelect
						label="어떻게 알게 되셨나요?"
						name="referral"
						value={inquiryForm.referral}
						onChange={onChange}
						menus={selectReferralList}
						placeholder="선택해주세요"
						required={true}
					>
					</OutlineSelect>
					<OutlineInput label="문의내용" name="content" value={inquiryForm.content} placeholder={'상담받고 싶으신 내용을 입력해주세요\n(최대 500자 이내)'} onChange={onChange} multiline={true} multilineMax={500} required={true}></OutlineInput>
					<p className="inquiry-content-count">{inquiryForm.content.length}</p>
				</div>
			</div>

			<div
				className={
					isValid
						? 'button-area valid'
						: 'button-area invalid'
				}
				onClick={
					isValid
						? requestInquiry
						: (() => {})
				}
			>
				{
					isLoading
						? <div className={'loader-container'}>
							<ClipLoader></ClipLoader>
						</div>
						: null
				}
				<button className="inquiry-button">{
					isLoading
						? ''
						: '문의하기'
				}</button>
			</div>
		</div>
	)
}
