import React from 'react'
import Subtitle from '../../Subtitle'
import { ReactComponent as Arrow } from '../../../../assets/icon/arrowRightGray.svg'
export default function BookingSubtitle(props) {
	const { subtitle, count, moveTo, firebrick, mini } = props
	return (
		<div className="booking-subtitle-container">
			<div className="left t3">
				<Subtitle subtitle={subtitle} firebrick={firebrick} mini={mini}></Subtitle>
				{count
					? <div className="count b1">{count}</div>
					: null}
			</div>
			{moveTo
				? <div className="move b3" onClick={moveTo}>전체보기 <Arrow className="icon"/></div>
				: null}
		</div>
	)
}
