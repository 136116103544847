import React from 'react'
import './MissedLive.scss'

export default function MissedLive(props) {
	const { product } = props

	const moveVideoLink = () => {
		window.open(product.liveCommerce.videoLink, '_blank')
	}

	return (
		<div className="missed-live-wrapper" onClick={moveVideoLink}>
			<img className="image" src={product.liveCommerce.thumbnail} alt=""></img>

			<div className="brand-information">
				<div className="brand-category">{product.service.category}</div>

				<span className="brand-name">{product.service.name}</span>
				<span className="brand-description">{product.liveCommerce.description}</span>
			</div>
		</div>
	)
}
