import React, { useState, useEffect } from 'react'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
// eslint-disable-next-line camelcase
import { createMuiTheme, unstable_createMuiStrictModeTheme } from '@material-ui/core/styles'
import { useAccountContext } from '../../../context/Common/account'
import { useLoginContext } from '../../../context/Common/login'
import { useGnbDrawerContext } from '../../../context/Voucher/gnbDrawer'
import { ReactComponent as Close } from '../../../assets/icon/closeIcon.svg'
import { isMobile } from '../../../utils'
import { usePartnerContext } from '../../../context/Common/partner'
import Drawer from '../../Share/Drawer/Drawer'
import './GNBDrawer.scss'

const createTheme = process.env.NODE_ENV === 'production'
	? createMuiTheme
	// eslint-disable-next-line camelcase
	: unstable_createMuiStrictModeTheme
const getGnbContainerStyle = () => {
	return isMobile() ?
		{
			width: '55.27%',
			height: '100vh',
			left: '0',
			right: 'auto'
		} :
		{
			width: '199px',
			height: '100vh',
			left: `${(window.innerWidth - 360) / 2}px`,
			right: 'auto'
		}
}

const myListPages = [
	{ label: '구매내역', type: 'purchaseList' },
	{ label: '예약내역', type: 'reservationList' }
]

const inquiryPages = [
	{ label: '대량 구매 문의', type: 'bulkpurchase' },
	{ label: '제휴 및 입점 문의', type: 'partnership' },
	{ label: '라이브커머스 문의', type: 'liveCommerce' },
]

const theyeyakPages = [
	// { label: '더예약 소개', type: 'introduce' },
	{ label: '더예약 블로그 구경하기', type: 'theyeyakBlog' },
	{ label: '전화문의', type: 'phone' },
]

const urlTypes = {
	customer: { path: '/voucher/inquiry/customer', state: {} },
	partnership: { path: '/voucher/inquiry/partnership', state: {} },
	bulkpurchase: { path: '/voucher/inquiry/bulkpurchase', state: {} },
	liveCommerce: { path: '/voucher/inquiry/live-commerce', state: {} },
	purchaseList: { path: '/booking/list', state: { move: 'purchaseList' } },
	reservationList: { path: '/booking/list', state: { move: 'reservationList' } },
	introduce: { path: '/voucher/main', state: {} },
	theyeyakBlog: { url: 'https://blog.naver.com/mytable_bytm', state: {} },
}

export default function GNBDrawer() {
	const history = useHistory()
	const { isOpenGNBDrawer, setIsOpenGNBDrawer } = useGnbDrawerContext()
	const { partnerName } = usePartnerContext()
	const { user } = useAccountContext()
	const { setIsOpenDrawer } = useLoginContext()
	const [inquiries, setInquiries] = useState(inquiryPages)
	const [gnbStyle, setGnbStyle] = useState(createTheme({ custom: { drawer: getGnbContainerStyle() } }))

	const movePage = (type) => {
		setIsOpenGNBDrawer(false)
		if (type === 'customer') {
			if (!user)
				openLoginDrawer(true)
			else
				history.push('/voucher/inquiry/customer')
			return
		}

		if (type === 'phone') {
			const anchorEl = document.createElement('a')
			anchorEl.href = 'tel:1544-8262'
			anchorEl.click()
			return
		}

		const urlParameter = urlTypes[type]
		if (urlParameter.path)
			history.push(urlParameter.path, urlParameter.state)
		else
			window.open(urlParameter.url, '_target')
	}

	const toggleDrawer = (open) => (event) => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift'))
			return
		setIsOpenGNBDrawer(open)
	}

	const openLoginDrawer = () => {
		setIsOpenGNBDrawer(false)
		setIsOpenDrawer(true)
	}

	const isAccentMenu = (type) => {
		const urlParameter = urlTypes[type]
		if (urlParameter && history.location.pathname === urlParameter.path)
			return true
		return false
	}

	const setGnbTheme = () => {
		return setGnbStyle(createTheme({ custom: { drawer: getGnbContainerStyle() } }))
	}

	useEffect(() => {
		if (user && !inquiries.find(inquiry => inquiry.type === 'customer'))
			setInquiries([{ label: '고객의 소리함', type: 'customer' }, ...inquiries])
	}, [user])

	useEffect(() => {
		if (!isMobile()) {
			window.addEventListener('resize', () => {
				return setGnbTheme()
			})
			return () => window.removeEventListener('resize', () => {
				return setGnbTheme()
			})
		}
		return (() => {})
	}, [window.innerWidth])

	if (partnerName !== 'tablemanager')
		return null
	return (
		<MuiThemeProvider theme={gnbStyle}>
			<Drawer
				anchor="left"
				open={isOpenGNBDrawer}
				onClose={toggleDrawer(false)}
			>
				<div className="gnb-drawer-container">
					<div className="gnb-controller">
						<Close className="close-button" onClick={toggleDrawer(false)}></Close>
					</div>

					<div className="user-wrapper">
						{
							user
								? (
									<p className="user-viewer">
										{user.name}님<br/>
										반갑습니다 😄
									</p>
								)
								: (
									<div className="login-wrapper">
										<p className="description">더예약 회원에게만 드리는 특별혜택을 받으세요!🌟</p>
										<button className="login-button" type="button" onClick={openLoginDrawer}>로그인 / 회원가입</button>
									</div>
								)
						}
					</div>

					{
						user ?
							<div className="item-wrapper">
								{myListPages.map((value, index) => <div key={index} className={`item-list ${isAccentMenu(value.type)}`} onClick={(() => movePage(value.type))}>{value.label}</div>)}
							</div>
							: <></>
					}

					<div className="item-wrapper">
						{inquiries.map((value, index) => <div key={index} className={`item-list ${isAccentMenu(value.type)}`} onClick={(() => movePage(value.type))}>{value.label}</div>)}
					</div>

					<div className="item-wrapper">
						{theyeyakPages.map((value, index) => <div key={index} className={`item-list ${isAccentMenu(value.type)}`} onClick={(() => movePage(value.type))}>{value.label}</div>)}
					</div>
				</div>
			</Drawer>
		</MuiThemeProvider>
	)
}
