import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import ImageCard from '../../../../Share/ImageCard/ImageCard'
import Button from '../../../../Share/Button/Button'
import BasicBadge from '../../../../Share/Badge/Basic/BasicBadge'
import { ReactComponent as LikeActive } from '../../../../../assets/icon/icon-like-active.svg'
import { ReactComponent as LikeInactive } from '../../../../../assets/icon/icon-like-inactive.svg'
import { favoriteType, getSaleRate } from '../../../utils'
import moment from 'moment'
import 'moment/locale/ko'
import './ImageItem.scss'

const buttonStyles = {
	common: {
		button: {
			width: '100%',
			minWidth: '328px',
			height: '44px',
			borderRadius: '3px',
			border: 'solid 1px #e3e5ec',
			backgroundColor: '#ffffff'
		},
		label: {
			height: '14px',
			fontFamily: 'AppleSanDolGothicNeo',
			fontSize: '12px',
			fontWeight: 'normal',
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: 'normal',
			letterSpacing: 'normal',
			textAlign: 'center',
			color: '#011753',
			margin: '0',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		}
	},
	isLike: {
		button: {
			width: '100%',
			minWidth: '328px',
			height: '44px',
			borderRadius: '3px',
			border: 'solid 1px var(--voucher-primary-color)',
			backgroundColor: 'var(--voucher-primary-color)'
		},
		label: {
			height: '14px',
			fontFamily: 'AppleSanDolGothicNeo',
			fontSize: '12px',
			fontWeight: 'normal',
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: 'normal',
			letterSpacing: 'normal',
			textAlign: 'center',
			color: '#ffffff',
			margin: '0',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		}
	}
}

export default function ImageItem(props) {
	const history = useHistory()
	const { voucher, wishIt, isFavorite, tracker } = props
	const [simpleInfo, setSimpleInfo] = useState()
	const [amountInfo, setAmountInfo] = useState({})
	const [isExpectVoucher, setIsExpectVoucher] = useState(false)
	const [buttonLabel, setButtonLabel] = useState('')
	const [iconType, setIconType] = useState('')
	const [buttonStyle, setButtonStyle] = useState({})
	const [voucherServiceBadges, setVoucherServiceBadges] = useState([])
	const search = history.location.search
	const kakaoInflowId = new URLSearchParams(search).get('kakaoInflowId')
	const inflowId = new URLSearchParams(search).get('inflowId')

	const makeSimpleInfo = () => {
		return [voucher.service.availableRegion, voucher.service.category].join(' ・ ')
	}

	const getAmountInfo = () => {
		if (!voucher.products || voucher.products.length === 0) {
			return {
				amount: 0,
				salePrice: 0,
				rate: 0
			}
		}
		const minProduct = voucher.products.reduce((prev, next) => {
			return (next.amount < prev.amount)
				? next
				: prev
		})
		return {
			amount: minProduct.amount,
			salePrice: minProduct.salePrice,
			rate: getSaleRate(minProduct.amount, minProduct.salePrice)
		}
	}

	const checkIsExpectVoucher = () => {
		return new Date() < new Date(voucher.service.startDate)
	}

	const moveDetail = (brandId) => {
		tracker.showDetail(voucher.brand.name, 'main')
		if (kakaoInflowId)
			return history.push(`/voucher/detail/${brandId}?kakaoInflowId=${kakaoInflowId}`)
		if (inflowId)
			return history.push(`/voucher/detail/${brandId}?inflowId=${inflowId}`)
		return history.push(`/voucher/detail/${brandId}`)
	}

	const createLabel = (status) => {
		if (status) {
			if (isFavorite)
				return '알림받기 완료!'
			return '카톡으로 알림받기'
		}
		return '예약상품권 더 알아보기'
	}

	useEffect(() => {
		setSimpleInfo(makeSimpleInfo())
		setAmountInfo(getAmountInfo())
		const isSold = checkIsExpectVoucher()
		setIsExpectVoucher(isSold)
		setButtonLabel(createLabel(isSold))
		if (isSold) {
			if (isFavorite) {
				setIconType('isAlert')
				setButtonStyle(buttonStyles.isLike)
			} else {
				setIconType('notAlert')
				setButtonStyle(buttonStyles.common)
			}
		} else {
			setButtonStyle(buttonStyles.common)
		}
	}, [voucher.service])

	useEffect(() => {
		if (voucher.badges && voucher.badges.length > 0)
			setVoucherServiceBadges(voucher.badges.filter(item => item.content && item.type === 'SERVICE'))

	}, [voucher.badges])

	const showVoucherInfoElements = (state) => {
		if (state && voucher.favoriteStatistics) {
			if (voucher.favoriteStatistics.type === 'NOTIFICATION' && voucher.favoriteStatistics.count > 0) {
				return (
					<div className="voucher-alert-count-wrapper">
						<span className="count">{voucher.favoriteStatistics.count.toLocaleString()}명</span>이 알림 신청중!
					</div>
				)
			}
			return (<></>)
		}
		return (
			<div className="voucher-price-info">
				{
					amountInfo.salePrice ?
						<p className="sale-product-alert-text">
							예약베네핏 <s className="stroke-amount">{amountInfo.amount
								? amountInfo.amount.toLocaleString()
								: 0}원</s>
						</p> :
						<></>
				}
				<p className="voucher-price">
					{
						amountInfo.salePrice ?
							<span className="sale-price">{amountInfo.rate}%</span> :
							<></>
					}
					<span className="amount">
						{
							amountInfo.salePrice
								? amountInfo.salePrice.toLocaleString()
								: 0
						}원 {
							voucher.products.length > 1
								? '~'
								: ''
						}
					</span>
				</p>
			</div>
		)
	}

	const clickEvent = (event) => {
		const wishItWrapperElement = event.target.closest('.wish-it-wrapper')
		if (wishItWrapperElement)
			return (() => {})

		if (isExpectVoucher)
			return wishIt(favoriteType.NOTIFICATION, voucher.service.id, voucher.service.name)
		return moveDetail(voucher.brand.id)
	}

	const getBadgeStyle = (badge) => {
		return {
			label: badge.content,
			labelStyle: {
				fontFamily: 'AppleSanDolGothicNeo',
				fontSize: '10px',
				fontWeight: 'bold',
				fontStretch: 'normal',
				fontStyle: 'normal',
				lineHeight: '12px',
				letterSpacing: 'normal',
				textAlign: 'left',
				padding: '3px 5px',
				color: badge.fontColor
			},
			style: {
				display: 'inline-block',
				borderRadius: '3px',
				backgroundColor: badge.backgroundColor
			}
		}
	}

	return (
		<div
			className={
				!isExpectVoucher
					? 'voucher-product-item clickable'
					: 'voucher-product-item'
			}
			onClick={
				!isExpectVoucher
					? clickEvent
					: (()=>{})
			}
		>
			<ImageCard badgeType="THUMBNAIL" imageCardType="sold" imageUrl={voucher.service.thumbnail} badges={voucher.badges}></ImageCard>

			<div className="vocuher-information">
				<div className="badge-group">
					{
						voucherServiceBadges && voucherServiceBadges.length > 0 ?
							voucherServiceBadges.map((badge, index) => (<BasicBadge key={index} type="only" badge={badge} customStyle={getBadgeStyle(badge)}></BasicBadge>))
							: <></>
					}
				</div>
				<span className="voucher-category">{simpleInfo}</span>
				<h2 className="mall-name">{voucher.service.name}</h2>
				<p className="voucher-product-description">
					{voucher.service.description}
				</p>

				{
					!isExpectVoucher ?
						<div className="wish-it-wrapper">
							{
								isFavorite
									? <LikeActive className="like-icon" onClick={(() => wishIt(favoriteType.LIKE, voucher.service.id, voucher.service.name))}></LikeActive>
									: <LikeInactive className="like-icon" onClick={(() => wishIt(favoriteType.LIKE, voucher.service.id, voucher.service.name))}></LikeInactive>
							}
							{
								voucher.favoriteStatistics && voucher.favoriteStatistics.count > 0 ?
									<p className={
										isFavorite
											? 'like-count-text my-wish'
											: 'like-count-text'
									}
									>
										{ voucher.favoriteStatistics.count.toLocaleString() }명
									</p>
									: <></>
							}
						</div>
						: <></>
				}
				{showVoucherInfoElements(isExpectVoucher)}

				<Button
					isIcon={isExpectVoucher}
					iconType={iconType}
					label={buttonLabel}
					style={buttonStyle}
					clickEvent={clickEvent}
				>
				</Button>
			</div>
		</div>
	)
}
