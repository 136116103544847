import React, { useEffect, useReducer, useState } from 'react'
import { ClipLoader } from 'react-spinners'
import OutlineInput from '../../../../Components/Share/Input/OutlineInput/OutlineInput'
import OutlineSelect from '../../../../Components/Share/OutlineSelect/OutlineSelect'
import { getQuestionEmail, requestQuestions } from '../../../../lib/voucher'
import { useAccountContext } from '../../../../context/Common/account'
import { useModalContext } from '../../../../context/Common/modal'
import { isMobile } from '../../../../utils'
import './CustomerInquiry.scss'

const selectInquiryTypes = ['예약/취소/결제 문의', '매장 관련 문의', '더예약 서비스 칭찬', '시스템 오류 제보', '서비스 불편/제안', '더예약 제휴 문의', '기타 문의']
const reducer = (state, action) => ({ ...state, [action.name]: action.value })
const useInput = (initialState) => {
	const [state, dispatch] = useReducer(reducer, initialState)
	const onChange = (e) => {
		dispatch(e.target)
	}

	const setValue = (name, value) => {
		dispatch({ name, value })
	}

	return [state, onChange, setValue]
}

export default function CustomerInquiry() {
	const [inquiryForm, onChange, setValue] = useInput({
		type: 'VOC',
		vocType: '',
		email: '',
		content: '',
	})
	const [isValid, setIsValid] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const { user } = useAccountContext()
	const { pageModalOpen } = useModalContext()

	const requestInquiry = () => {
		setIsLoading(true)
		requestQuestions(inquiryForm, user.token)
			.then((res) => {
				setIsLoading(false)
				pageModalOpen({
					path: '/voucher/main',
					content: {
						title: (
							<>
								고객님의 문의가<br/>
								더예약 팀에게 전달되었습니다! 💌
							</>
						),
						description: <>
							더예약에 관심을 갖고 문의 남겨 주셔서<br/>
							진심으로 감사드립니다!<br/>
							고객님의 소중한 의견을 항상 귀담아 듣고<br/>
							계속해서 성장하는 더예약이 되겠습니다 :)<br/>
							<br/>
							감사합니다.
						</>
					}
				})
			}).catch((err) => {
				console.log(err)
				setIsLoading(false)
			})
	}

	const validationInquiryForm = () => {
		if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(inquiryForm.email))
			return false
		return Object.values(inquiryForm).every(value => value)
	}

	useEffect(() => {
		const valid = validationInquiryForm()
		setIsValid(valid)
	}, [inquiryForm])

	useEffect(() => {
		if (user) {
			getQuestionEmail(user.token, 'VOC').then(res => {
				if (res.email)
					setValue('email', res.email)

			}).catch((err) => {
				console.log(err)
				setIsLoading(false)
			})
		}
	}, [user])

	const getButtonAreaClassName = () => {
		let classNames = ['button-area']
		if (isMobile())
			classNames = classNames.concat('mobile')
		if (isValid)
			classNames = classNames.concat('valid')
		else
			classNames = classNames.concat('invalid')
		return classNames.join(' ')
	}

	return (
		<div className="customer-inquiry-container">
			<div className="customer-inquiry-wrapper">
				<div className="customer-inquiry-description">
					<h2 className="customer-inquiry-description-title">
						고객님과 함께<br/>
						성장하는 더예약 📮
					</h2>
					<p className="customer-inquiry-paragraph">
						문의사항을 편하게 말씀해주세요!<br/>
						고객님의 의견을 귀담아 듣겠습니다.
					</p>
				</div>

				<div className="customer-inquiry-form">
					<OutlineInput type="email" label="이메일" name="email" value={inquiryForm.email} placeholder="theyeyak@example.com" onChange={onChange} required={true}></OutlineInput>
					<OutlineSelect
						label="문의유형"
						name="vocType"
						value={inquiryForm.vocType}
						onChange={onChange}
						menus={selectInquiryTypes}
						placeholder="선택해주세요"
						required={true}
					>
					</OutlineSelect>
					<OutlineInput label="문의내용" name="content" value={inquiryForm.content} placeholder={'상담받고 싶으신 내용을 입력해주세요\n(최대 500자 이내)'} onChange={onChange} multiline={true} required={true}></OutlineInput>
					<p className="inquiry-content-count">{inquiryForm.content.length}</p>
				</div>
			</div>

			<div
				className={getButtonAreaClassName()}
				onClick={
					isValid
						? requestInquiry
						: (() => {})
				}
			>
				{
					isLoading
						? <div className={'loader-container'}>
							<ClipLoader></ClipLoader>
						</div>
						: null
				}
				<button className="inquiry-button">{
					isLoading
						? ''
						: '문의하기'
				}</button>
			</div>
		</div>
	)
}
