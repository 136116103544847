/* eslint-disable camelcase */
import './Payment.scss'
import { BookingMallDetailAPI, BookingMyPageAPI } from '../../../lib/mytable'
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import BookingFooter from '../../../Components/Share/Booking/BookingFooter/BookingFooter'
import { ClipLoader } from 'react-spinners'
import DepositNotice from '../../../Components/Share/Booking/DepositNotice/DepositNotice'
import ReferralDict from '../../../../src/configs/referralDict.json'
import Seperator from '../../../Components/Share/Seperator'
import _ from 'lodash'
import { openPaymentModal } from '../../../lib/nicepay'
import { sendPolicy } from '../../../lib/voucher'
import { useAccountContext } from '../../../context/Common/account'
import { useBookingContext } from '../../../context/Booking/booking'
import { useModalContext } from '../../../context/Common/modal'
import { usePartnerContext } from '../../../context/Common/partner'
import { authorizationWithPrefix } from '../../../lib/utils'
import CloseConfirmModal from '../../../Components/Share/NavigationModal/CloseConfirmModal'
import { useMall, useProvisionalReservation, useReservation, useTimer, useValidation } from '../../../store'
import Timer from '../../../Components/Share/Booking/Timer/Timer'
import { Agreement, PaymentType, ReservationPreview, SaleNotice } from '../../../Components/Booking'
import { PaymentType as PaymentTypeGyeongjuro, Agreement as AgreementGyeongjuro } from '../../../Components/Gyeongju'
import useDynamicComponent from '../../../hooks/useDynamicComponent'
import { isMobile } from '../../../utils'
import { PAYMENT_KEY } from '../../../store/useReservation'
import { gyeongjuroShopBaseUrl } from '../../../constants'

export default function Payment() {
	const { voucherId, closeModal, setCloseModal, headerAction } = useBookingContext()
	const { mall, setMall } = useMall()
	const {
		totalPartySize,
		detailPartySize,
		dateTime,
		voucherData,
		name,
		phone,
		email,
		menus,
		additionalInfos,
		request,
		menuPrice,
		agreement,
		payment,
		getTotalAmount,
	} = useReservation()
	const { cancelReservation, reservationToken, authCode, authToken } = useProvisionalReservation()
	const { expire, resetTimer } = useTimer()
	const { agreement: agreementValidation, setValidation } = useValidation()
	const { apiKey, partnerInfo, partnerName } = usePartnerContext()
	const [isLoading, setIsLoading] = useState(false)
	const { alertOpen } = useModalContext()
	const { mallUri } = useParams()
	const history = useHistory()
	const { user } = useAccountContext()
	const { renderComponent } = useDynamicComponent()
	const bookingMallDetailAPI = useMemo(
		() => new BookingMallDetailAPI(apiKey, authorizationWithPrefix()),
		[apiKey, voucherData],
	)
	const bookingMyPageAPI = useMemo(() => new BookingMyPageAPI(apiKey, authorizationWithPrefix()), [apiKey, voucherData])

	const createOrder = async (isPaymentOrder) => {
		if (isLoading)
			return
		setIsLoading(true)
		let authorization = sessionStorage.getItem('authorization')
		let object = {
			phone_token: authToken,
			phone_code: authCode,
			reservation_token: reservationToken,
			name,
			email,
			info_people: JSON.stringify({ ...detailPartySize }),
			info_additional: JSON.stringify(additionalInfos),
			info_menu: menus?.length > 0
				? JSON.stringify(menus)
				: null,
			request,
			agree_savecard: false,
		}
		if (partnerName === 'kb') {
			let loginResult = await bookingMyPageAPI.kblogin({
				kbUserId: partnerInfo.chnlMbrId,
				name,
				email,
				phoneCode: authCode,
				phoneToken: authToken,
			})
			authorization = loginResult.authorization
			sessionStorage.setItem('authorization', loginResult.authorization)
			object = {
				...object,
				phone_token: null,
				phone_code: null,
			}
		}

		if (voucherId)
			object = Object.assign(object, { voucherId: voucherId, voucherOrderItemId: voucherData?.voucherOrderItemId })

		if (partnerName === 'tablemanager' && getTotalAmount() > 0) {
			const { type, cardNumbers, expiry, identifyCode, password } = payment.billingKeyContext
			const body = {
				payment_cardtype: type,
				payment_card1: cardNumbers[0],
				payment_card2: cardNumbers[1],
				payment_card3: cardNumbers[2],
				payment_card4: cardNumbers[3],
				payment_month: String(expiry.month).slice(-2).padStart(2, '0'),
				payment_year: String(expiry.year).slice(-2).padStart(2, '0'),
				payment_birthday: identifyCode,
				payment_password: password,
				payment_email: object.email,
				agree_savecard: false,
			}
			Object.assign(object, body)
		}

		bookingMallDetailAPI
			.createOrder(object, authorization, isPaymentOrder)
			.then((res) => {
				setIsLoading(false)
				if (res.result) {
					resetTimer()
					let policyArr = Object.entries(agreement).map(([serviceType, agree]) => {
						return {
							servicePolicyType: serviceType,
							agreement: agree,
						}
					})
					if (partnerName === 'tablemanager' || partnerName === 'gyeongjuro') {
						policyArr = policyArr.filter(({ servicePolicyType }) => servicePolicyType !== 'saveCard')
						sessionStorage.setItem(
							'tablemanagerHoldings',
							JSON.stringify({
								totalPartySize,
								detailPartySize,
								dateTime,
								deposit: object.depositAmount,
								menus: object.info_menu,
								menuPrice,
								name: object.name,
								phone,
								email: object.email,
								additionalInfos: object.info_additional || [],
								request: object.request,
							}),
						)
					}
					sendPolicy({
						user: {
							phone,
							email,
						},
						termsAgreements: policyArr,
						userToken: user?.token,
					})
					history.push(`/booking/step/complete-view/${res.reservationId}`)
				} else {
					alertOpen(res.message)
				}
			})
			.catch((e) => {
				console.log(e)
				setIsLoading(false)
				// alertOpen(e)
			})
	}

	const initPayment = async () => {
		let merchantId = process.env.REACT_APP_NICEPAY_SIMPLE_MERCHANT_ID
		let merchantKey = process.env.REACT_APP_NICEPAY_SIMPLE_MERCHANT_KEY
		if (partnerName === 'tablemanager') {
			merchantId = process.env.REACT_APP_NICEPAY_BILLING_MERCHANT_ID
			merchantKey = process.env.REACT_APP_NICEPAY_BILLING_MERCHANT_KEY
		}
		if (partnerName === 'shinhan') {
			merchantId = process.env.REACT_APP_NICEPAY_SHINHAN_MERCHANT_ID
			merchantKey = process.env.REACT_APP_NICEPAY_SHINHAN_MERCHANT_KEY
		}
		let loginResult = {}
		if (partnerName === 'kb') {
			loginResult = await bookingMyPageAPI.kblogin({
				kbUserId: partnerInfo.chnlMbrId,
				name,
				email,
				phoneCode: authCode,
				phoneToken: authToken,
			})
			sessionStorage.setItem('authorization', loginResult.authorization)
		}
		let merchantUid = (new Date().getTime() + reservationToken + phone).substr(0, 40)
		let sendObject = {
			phone_token: authToken,
			phone_code: authCode,
			reservation_token: reservationToken,
			name,
			email,
			info_people: JSON.stringify({ ...detailPartySize }),
			info_additional: JSON.stringify(additionalInfos),
			info_menu: JSON.stringify(menus),
			request,
			agree_savecard: false,
			merchant_uid: merchantUid,
			depositAmount: getTotalAmount(),
			authorization: sessionStorage.getItem('authorization'),
		}
		if (partnerName === 'tablemanager') {
			createOrder('isPaymentOrder')
			return
		}
		if (partnerName === 'kb') {
			sendObject = {
				...sendObject,
				kbUserId: partnerInfo.chnlMbrId,
				kbChannelId: partnerInfo.chnlId,
				phone_token: null,
				phone_code: null,
			}
		}
		if (partnerName !== 'kb') {
			sendObject = {
				...sendObject,
				merchantId: merchantId,
				clientCallbackUrl: window.location.origin + '/booking/step/complete',
			}
		}
		bookingMallDetailAPI
			.sendProvisionalPayment(sendObject)
			.then((res) => {
				if (res.result) {
					if (partnerName === 'kb') {
						const iframe = document.createElement('iframe')
						iframe.style.display = 'none'
						iframe.src = `kb-selfpg://pay?tid=${res.transactionId}`
						iframe.addEventListener('load', function onload() {
							iframe.removeEventListener('load', onload)
							document.body.removeChild(iframe)
						})
						document.body.appendChild(iframe)
					} else if (partnerName === 'shinhan') {
						openPaymentModal({
							amount: getTotalAmount(),
							orderId: merchantUid,
							user: { name, phone, email },
							goodsName: mall.mall_name + '예약금',
							returnUrl: window.location.origin + '/booking/step/complete',
							callBackUrl: process.env.REACT_APP_PAYMENT_API_HOST + '/nicepay/v1/payment-callback',
							merchantId,
							merchantKey,
							payType: ReferralDict.shinhan.payment.exception && 'EXCEPTION',
							directOption: { ...ReferralDict.shinhan.payment.directOption },
						})
					} else if (partnerName === 'gyeongjuro') {
						openPaymentModal({
							amount: getTotalAmount(),
							orderId: merchantUid,
							user: { name, phone, email },
							goodsName: mall.mall_name + '예약금',
							returnUrl: window.location.origin + '/booking/step/complete',
							callBackUrl: process.env.REACT_APP_PAYMENT_API_HOST + '/nicepay/v1/payment-callback',
							merchantId,
							merchantKey,
							payType: isMobile()
								? PAYMENT_KEY[payment.type]?.nicepay
								: 'PG',
						})
					} else {
						openPaymentModal({
							amount: getTotalAmount(),
							orderId: merchantUid,
							user: { name, phone, email },
							goodsName: mall.mall_name + '예약금',
							returnUrl: window.location.origin + '/booking/step/complete',
							callBackUrl: process.env.REACT_APP_PAYMENT_API_HOST + '/nicepay/v1/payment-callback',
							merchantId,
							merchantKey,
						})
					}
				}
			})
			.catch((err) => console.error(err))
	}

	const handleGoHome = async (confirm) => {
		setCloseModal(false)
		if (!confirm)
			return
		await cancelReservation(apiKey, mallUri)
		if (sessionStorage.getItem('referral') === 'shinhanplay') {
			history.replace('/booking/main')
		} else {
			// 예약상품권을 통한 진입 구분
			if (voucherData) {
				history.replace('/voucher/main')
			} else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
				history.replace('/booking/main')
			} else {
				for (let i = history.length; i > 0; i--)
					history.go(-i)
			}

		}
	}

	const backToStartPage = () => {
		if (partnerName === 'gyeongjuro') {
			const shopId = sessionStorage.getItem('shopId')
			window.location.assign(gyeongjuroShopBaseUrl + shopId)
		} else {
			history.push(`/booking/mall-detail/${mallUri}`)
		}
	}

	useLayoutEffect(() => {
		if (mallUri)
			setMall('mallUri', mallUri)
	}, [mallUri])

	useLayoutEffect(() => {
		if (!partnerName)
			return
		const getProvisionalReservationInfo = () =>
			bookingMallDetailAPI.getProvisionalReservationInfo(reservationToken).then((res) => {
				if (!res.result) {
					if (expire)
						alertOpen('예약 유효기간이 만료되었습니다.')
					backToStartPage()
				}
			})

		if (reservationToken)
			getProvisionalReservationInfo()
		else
			backToStartPage()
	}, [partnerName])

	useEffect(() => {
		if (mall) {
			if (mall.mall_group && mall.mall_group['할인유의사항'])
				mall.saleNotice = mall.mall_group['할인유의사항']
		}

	}, [mall, voucherData])

	useEffect(() => {
		if (!agreement)
			return
		setValidation(
			'agreement',
			Object.entries(agreement)
				.filter(([key]) => !/(MARKETING)|(LATE_NIGHT_MARKETING)|(KEEP_ACTIVE_EVEN_DORMANT)|(saveCard)/.test(key))
				.every(([, val]) => val),
		)
	}, [agreement])

	if (!mall)
		return null

	return (
		<div className='payment-page-container'>
			<div className='placeholder' />
			<Timer mini />
			<ReservationPreview />
			<DepositNotice />
			{mall.saleNotice && <SaleNotice />}
			<Seperator />
			{getTotalAmount() > 0
				?
				<>
					{
						renderComponent({
							tablemanager: (
								<PaymentType />
							),
							gyeongjuro: (
								<PaymentTypeGyeongjuro />
							),
						})
					}
					<Seperator />
				</>
				: null}
			{renderComponent({
				default: <Agreement />,
				gyeongjuro: <AgreementGyeongjuro />,
			})}
			{getTotalAmount() > 0
				? (
					<BookingFooter
						text={getTotalAmount().toLocaleString() + '원 결제하기'}
						active={agreementValidation}
						action={_.throttle(initPayment, 2000)}
					/>
				)
				: (
					<BookingFooter text='예약하기' active={agreementValidation} action={createOrder} />
				)}
			{isLoading
				? (
					<div className={'loader-container'}>
						<ClipLoader></ClipLoader>
					</div>
				)
				: null}
			{headerAction === 'home' && closeModal && <CloseConfirmModal onClick={(confirm) => handleGoHome(confirm)} />}
		</div>
	)
}
