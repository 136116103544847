import { setup } from 'axios-cache-adapter'

// axios 생성
let axios = setup({
	baseURL: process.env.REACT_APP_PICKUP_API_HOST,
	headers: { 'x-api-key': 'F1B80501BB7989BB5B15B4934DD268BA88261B8D', }
})

class PickupAPI {
	constructor(apiKey, authorization) {
		this.apiKey = apiKey;
		this.authorization = authorization;
		console.log(this.authorization, authorization)
		if (this.apiKey) {
			axios = setup({
				baseURL: process.env.REACT_APP_PICKUP_API_HOST,
				headers: {
					'x-api-key': this.apiKey,
					'authorization': this.authorization
				}
			});
		}
	}
	getMall = async (mallUri = 'gaon') => {
		return _call({
			method: 'get',
			uri: `malls/${mallUri}`,
			expires: 60 * 1000,
		})
	}

	getService = async (mallUri) => {
		return _call({
			method: 'get',
			uri: `malls/${mallUri}/services?type=takeout`,
			expires: 60 * 1000,
		})
	}

	getSchedules = async (mallUri, serviceId) => {
		return _call({
			method: 'get',
			uri: `malls/${mallUri}/services/${serviceId}/schedules`,
			expires: 60 * 1000,
		})
	}

	getMenus = async (policyId) => {
		return _call({
			method: 'get',
			uri: `policies/${policyId}/menus`,
			expires: 60 * 1000,
		})
	}

	//
	// 메뉴 정보 얻어보기
	//
	getMenu = async (policyId, menuId) => {
		return _call({
			method: 'get',
			uri: `policies/${policyId}/menus/${menuId}`,
			expires: 60 * 1000,
		})
	}

	getOrder = async (orderId) => {
		return _call({
			method: 'get',
			uri: `/orders/${orderId}`
		})
	}

	confirmOrder = async (orderId) => {
		return _call({
			method: 'post',
			uri: `/orders/${orderId}/confirm`
		})
	}
}

class UserAPI {
	constructor(authorization, apiKey) {
		this.authorization = authorization
		this.apiKey = apiKey
		axios = setup({
			baseURL: process.env.REACT_APP_PICKUP_API_HOST,
			headers: {
				'x-api-key': this.apiKey,
				'authorization': this.authorization
			}
		});
	}

	createOrder = async (mallUri, serviceId, orderContext) => {
		return _call({
			method: 'post',
			uri: `/malls/${mallUri}/services/${serviceId}/orders`,
			body: orderContext,
		})
	}

	processPayment = async (orderId, billingKeyContext, noticeEmail) => {
		const { expiry, cardNumbers } = billingKeyContext
		const expiryDate = [expiry.month, expiry.year.slice(2, 4)].join('/')

		const cardnumber = cardNumbers.join('')
		const certificateNumber = billingKeyContext.identifyCode
		const password = billingKeyContext.password

		return _call({
			method: 'POST',
			uri: `orders/${orderId}/payments`,
			body: {
				type: 'card',
				billingKeyContext: {
					cardnumber,
					expiryDate,
					certificateNumber,
					password,
				},
				noticeEmail
			}
		})
	}
}

const getAccountInfo = async (mallUri, token) => {
	return _call({
		method: 'get',
		uri: `/me/malls/${mallUri}/voucher-histories`,
		headers: { Authorization: token }
	})
}

//
//
//

//
// API caller
//
const _call = async ({
	method = 'get',
	uri,
	headers = {},
	expires = 0,
	body = {}
}) => {
	method = method.toLowerCase()
	if (['get', 'delete', 'post', 'patch', 'put', 'options'].indexOf(method) < 0) {
		const message = `method is wrong - method: ${method}`
		throw new Error(message)
	}

	expires = Number(expires)
	if (isNaN(expires)) {
		console.warn('expires is assigned 0 (=default value) because type is not number')
		expires = 0
	}

	const request = axios({
		method,
		data: body,
		url: uri,
		cache: { maxAge: expires },
		headers
	})

	return await request.then(async (response) => {
		const data = response.data
		if (data.code && data.code !== 200)
			throw data

		return data
	})
}


export {
	PickupAPI,
	UserAPI,
	getAccountInfo
}
