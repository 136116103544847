const isMobile = () => {
	return /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
}

const isWebView = () => {
	var standalone = window.navigator.standalone,
		userAgent = window.navigator.userAgent.toLowerCase(),
		safari = /safari/.test(userAgent),
		ios = /iphone|ipod|ipad/.test(userAgent)

	if (ios) {
		if (!standalone && safari) {
			// browser
		} else if (standalone && !safari) {
			// standalone
		} else if (!standalone && !safari) {
			// uiwebview
		}
	} else {
		// not iOS
	}
}

const callPhoneNumberInSkt = (phoneNumber) => {
	window.parent.postMessage(`tel|${phoneNumber}`, '*')
}

const getImageLink = (imageId, width, height) => {
	let paramString = ''
	if (width && height)
		paramString = `?w=${width}&h=${height}`

	if (imageId)
		return `${process.env.REACT_APP_IMAGE_SERVER}/image/${imageId}${paramString}`
	return null
}

const generatePriceString = (number) => {
	if (typeof number !== 'number')
		return '0'
	if (number === 0)
		return '0'

	if (number < 0)
		return number.toLocaleString()

	if (number > 0)
		return `+ ${number.toLocaleString()}`

	return '0'
}

export { isMobile, callPhoneNumberInSkt, getImageLink, generatePriceString }
