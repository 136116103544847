import React from 'react'
import { FormControlLabel, Checkbox } from '@material-ui/core'
import './BookingAgreeCheckbox.scss'
import { ReactComponent as Arrow } from '../../../../assets/icon/rightArrow.svg'
import { ReactComponent as ActiveCheckbox } from '../../../../assets/icon/activeCheckbox.svg'
import { ReactComponent as DeactiveCheckbox } from '../../../../assets/icon/deactiveCheckbox.svg'
export default function BookingAgreeCheckbox(props) {
	const { setValue, required, desc, expand, expandFunction, value } = props
	return (
		<div className="booking-agree-checkbox" key={desc}>
			<FormControlLabel
				control={
					<Checkbox
						color="primary"
						checked={value}
						onChange={setValue}
						checkedIcon={<ActiveCheckbox className="check-icon"/>}
						icon={<DeactiveCheckbox className="check-icon"/>}
					/>
				}
				label={<>
					<span className="required b1">{
						required
							? '(필수)'
							: '(선택)'
					}</span> <span className="b1">{desc}</span>
				</>}
				className="checkbox-label"
			/>
			{expand
				? <div onClick={expandFunction} className="expand"><Arrow className="expand-icon" /></div>
				: null}
		</div>
	)
}
