
import React from 'react'
import './Telephone.scss'

export default function Telephone() {
	return (
		<a className="telephone-anchor" href="tel:1644-9997">
			<button className="telephone-inquiry-button">
				<div className="telephone-icon"></div>
				<span className="text">전화문의 ( 1644-9997 )</span>
			</button>
		</a>
	)
}
