import { createContext, useContext, useState } from 'react'
import GNBDrawer from '../../Components/Voucher/GNBDrawer/GNBDrawer'

const GNBContext = createContext({
	isOpenGNBDrawer: false,
	setIsOpenGNBDrawer: () => {},
})

const GNBDrawerContextProvider = (props) => {
	const [isOpenGNBDrawer, setIsOpenGNBDrawer] = useState(false)
	const initialValue = {
		isOpenGNBDrawer,
		setIsOpenGNBDrawer,
	}

	return (
		<GNBContext.Provider value={initialValue}>
			<GNBDrawer></GNBDrawer>
			{props.children}
		</GNBContext.Provider>
	)
}

const useGnbDrawerContext = () => useContext(GNBContext)

export { GNBDrawerContextProvider, useGnbDrawerContext }
