import React from 'react'
import Subtitle from '../../Share/Subtitle'

export default function MallPickupDescription(props) {
	return (
		<div className="mall-pickup-description-container">
			<Subtitle subtitle="픽업 정보"></Subtitle>
			<div className="mall-name row">
				<div className="label">매장 이름</div>
				<div className="value">{props.mall.name}</div>
			</div>
			<div className="pickup-location row">
				<div className="label">픽업 장소</div>
				<div className="value">{props.mall.location}</div>
			</div>
			<div className="tel row">
				<div className="label">연락처</div>
				<div className="value">{props.mall.phone}</div>
			</div>
		</div>
	)
}
