import React from 'react'
import './MenuShortcut.scss'
import classNames from 'classnames'
import { usePickupContext } from '../../../context/Pickup/pickup'

export default function MenuShortcut(props) {
	const { selectedId } = usePickupContext()
	const goMenuCategory = (id) => {
		let targetElement = document.getElementById(id)
		window.scrollTo({ top: targetElement.offsetTop - 109, })
	}
	return (
		<div className="gyeongjuro-shortcut-container">
			{props.categories.map((category) => <div className={classNames('shortcut', { selected: selectedId === category.categoryId })} onClick={() => goMenuCategory(category.categoryId)}>{category.categoryName}</div>)}
		</div>
	)
}
