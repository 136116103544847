import React from 'react'
import './BasicBadge.scss'

const badgeConfig = {
	only: {
		label: '더예약 Only',
		labelStyle: {
			fontFamily: 'AppleSanDolGothicNeo',
			fontSize: '12px',
			fontWeight: 'bold',
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: 'normal',
			letterSpacing: 'normal',
			color: '#ffffff'
		},
		style: {
			position: 'absolute',
			top: 0,
			left: '16px',
			width: '91px',
			height: '24px',
			textAlign: 'center',
			lineHeight: '21px',
			backgroundColor: 'var(--voucher-primary-color)',
			borderRadius: '0 0 5px 5px'
		}
	},
	recommend: {
		label: '더예약 추천',
		labelStyle: {
			fontFamily: 'AppleSanDolGothicNeo',
			fontSize: '12px',
			fontWeight: 'bold',
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: 'normal',
			letterSpacing: 'normal',
			color: '#ffffff'
		},
		style: {
			position: 'absolute',
			top: 0,
			left: '16px',
			width: '91px',
			height: '24px',
			textAlign: 'center',
			lineHeight: '21px',
			backgroundColor: 'var(--voucher-accent-color)',
			borderRadius: '0 0 5px 5px'
		}
	},
	mdRecommend: {
		label: 'MD 추천',
		labelStyle: {
			fontFamily: 'AppleSanDolGothicNeo',
			fontSize: '12px',
			fontWeight: 'bold',
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: 'normal',
			letterSpacing: 'normal',
			color: '#ffffff'
		},
		style: {
			position: 'absolute',
			top: 0,
			left: '16px',
			width: '91px',
			height: '24px',
			textAlign: 'center',
			lineHeight: '21px',
			backgroundColor: '#3e3e3e',
			borderRadius: '0 0 5px 5px'
		}
	}
}

export default function BasicBadge(props) {
	const { type: badgeType, badge, customStyle = null } = props
	const badgeInfo = customStyle || {
		label: badge.content,
		labelStyle: {
			fontFamily: 'AppleSanDolGothicNeo',
			fontSize: '12px',
			fontWeight: 'bold',
			fontStretch: 'normal',
			fontStyle: 'normal',
			lineHeight: 'normal',
			letterSpacing: 'normal',
			color: badge.fontColor
		},
		style: {
			position: 'absolute',
			top: 0,
			left: '16px',
			width: '91px',
			height: '24px',
			textAlign: 'center',
			lineHeight: '21px',
			backgroundColor: badge.backgroundColor,
			borderRadius: '0 0 5px 5px'
		}
	}

	return (
		<div className='badge' style={badgeInfo.style}>
			<span style={badgeInfo.labelStyle}>{ badgeInfo.label }</span>
		</div>
	)
}
