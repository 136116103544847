import React, { useEffect, useMemo, useState } from 'react'
import MenuDescription from '../../../Components/Pickup/MenuDetail/MenuDescription'
import MenuPrice from '../../../Components/Pickup/MenuDetail/MenuPrice'
import MenuOption from '../../../Components/Pickup/MenuDetail/MenuOption'
import MenuConfirm from '../../../Components/Pickup/MenuDetail/MenuConfirm'
import { MenuDescription as GyeongjuroMenuDescription } from '../../../Components/Gyeongju/MenuDescription'
import { MenuPrice as GyeongjuroMenuPrice } from '../../../Components/Gyeongju/MenuPrice'
import { MenuConfirm as GyeongjuroMenuConfirm } from '../../../Components/Gyeongju/MenuConfirm'

import { useParams } from 'react-router-dom'
import { PickupAPI } from '../../../lib/api'
import { usePickupContext } from '../../../context/Pickup/pickup'
import { MenuOptionContextProvider } from '../../../context/Pickup/menuOption'
import MenuImage from '../../../Components/Pickup/MenuDetail/MenuImage'
import { usePartnerContext } from '../../../context/Common/partner'
import useDynamicComponent from '../../../hooks/useDynamicComponent'
import { authorizationWithPrefix } from '../../../lib/utils'

function MenuDetail() {
	const params = useParams()

	const [setMenuTotalPrice] = useState(0)
	const [totalValue, setTotalValue] = useState(1)
	const { detailMenu, setDetailMenu } = usePickupContext()
	const { apiKey } = usePartnerContext()
	const { renderComponent } = useDynamicComponent()

	const pickupAPI = useMemo(
		() => new PickupAPI(apiKey, authorizationWithPrefix()),
		[apiKey]
	)

	useEffect(() => {
		pickupAPI.getMenu(params.policyId, params.menuId).then((res) => {
			const { menu } = res
			if (menu.optionGroups instanceof Array) {
				menu.optionGroups.forEach(optionGroup => {
					optionGroup.options.forEach(option => {
						if (optionGroup.type === 'NUMBER')
							option.value = 0
						else
							option.value = false

					})
					if (optionGroup.type === 'SELECT')
						optionGroup.value = optionGroup.options[0]._id

				})
			}
			setDetailMenu(menu)
		})
	}, [params.policyId, params.menuId, setDetailMenu])

	useEffect(() => {
		if (totalValue < 1)
			setTotalValue(1)

	}, [totalValue])

	if (!detailMenu) {
		return <div>
			loading
		</div>
	}

	return (
		<MenuOptionContextProvider menu={detailMenu}>
			<div>
				{
					renderComponent({
						default: (
							<>
								{detailMenu.imageId
									? <MenuImage imageId={detailMenu.imageId}></MenuImage>
									: null}
								<MenuDescription menuName={detailMenu.name} menuDescription={detailMenu.description}></MenuDescription>
								<MenuPrice menuPrice={detailMenu.price}></MenuPrice>
								{detailMenu.optionGroups.map((optionGroup, idx) => <MenuOption optionGroup={optionGroup} key={idx} />)}
								<MenuConfirm totalValue={totalValue} setTotalValue={setTotalValue} setMenuTotalPrice={setMenuTotalPrice} detailMenu={detailMenu}></MenuConfirm>
							</>
						),
						gyeongjuro: (
							<>
								{detailMenu.imageId
									? <MenuImage imageId={detailMenu.imageId}></MenuImage>
									: null}
								<GyeongjuroMenuDescription menuName={detailMenu.name} menuDescription={detailMenu.description}></GyeongjuroMenuDescription>
								<GyeongjuroMenuPrice menuPrice={detailMenu.price}></GyeongjuroMenuPrice>
								{detailMenu.optionGroups.map((optionGroup, idx) => <MenuOption optionGroup={optionGroup} key={idx} />)}
								<GyeongjuroMenuConfirm totalValue={totalValue} setTotalValue={setTotalValue} setMenuTotalPrice={setMenuTotalPrice} detailMenu={detailMenu}></GyeongjuroMenuConfirm>
							</>
						)
					}
					)
				}
			</div>
		</MenuOptionContextProvider>
	)
}

export default MenuDetail
