import React from 'react'
import './AlertModal.scss'
import modalConfig from '../../../configs/partnerModal.json'
import { usePartnerContext } from '../../../context/Common/partner'
export default function AlertModal(props) {
	const { open, onClose, closeText, header } = props
	const { partnerName } = usePartnerContext()
	const containerStyle = modalConfig.modal[partnerName].containerStyle
	const headerStyle = modalConfig.modal[partnerName].headerStyle
	const bodyStyle = modalConfig.modal[partnerName].bodyStyle
	const footerStyle = modalConfig.modal[partnerName].footerStyle
	const buttonStyle = modalConfig.modal[partnerName].buttonStyle
	return (
		<div className={open
			? 'openModal modal'
			: 'modal'}>
			{ open ?
				<div className="modal-container" style={containerStyle}>
					<div className="modal-header" style={headerStyle}>
						{header}
					</div>
					<div className="modal-body" style={bodyStyle}>
						{props.children}
					</div>
					<div className="modal-footer" style={footerStyle}>
						<button variant="contained" color="primary" onClick={onClose} style={buttonStyle}>{closeText}</button>
					</div>
				</div>
				: null}
		</div>
	)
}
