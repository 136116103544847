import React from 'react'

export default function Seperator(props) {
	const { style, type = 'default' } = props
	return (
		<div
			className={
				type === 'default'
					? 'seperator-container'
					: 'seperator-container-inline'
			}
			style={style}
		>
		</div>
	)
}
