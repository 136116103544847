import React, { useEffect, useState } from 'react'
import VoucherInfo from '../../../Components/Account/VoucherInfo'
import AccountInfo from '../../../Components/Account/AccountInfo'
import ChatbotInfo from '../../../Components/Account/ChatbotInfo'
import { useLocation } from 'react-router-dom'
import { getAccountInfo } from '../../../lib/voucher'
import { ClipLoader } from 'react-spinners'

export default function DetailAccount() {
	const search = useLocation().search
	const userId = new URLSearchParams(search).get('appUserId')
	const brandId = new URLSearchParams(search).get('brandId')
	const [user, setUser] = useState(null)
	const [brand, setBrand] = useState(null)
	const [leftAmount, setLeftAmount] = useState(0)
	const [nonUser, setNonUser] = useState(false)
	useEffect(() => {
		document.title = '예약상품권 이용내역'
	}, [])
	useEffect(() => {
		const fetchAccount = () => getAccountInfo(brandId, userId).then(res => {
			setUser(res.user)
			setBrand(res.brand)
			setLeftAmount(res.leftAmount)
		}).catch((err) => {
			if (err.response && err.response.status === 401)
				setNonUser(true)
		})
		if (userId && brandId)
			fetchAccount()

	}, [userId, brandId])

	if (!user || !brand) {
		return (
			<div className="loader-container">
				<ClipLoader></ClipLoader>
			</div>
		)
	}
	if (nonUser) {
		return (
			<div className="non-user">
        캐시 정보가 없습니다.<br />
        자세한 내용은 더예약으로 문의주세요.
			</div>
		)
	}
	return (
		<div>
			<VoucherInfo name={brand.name} amount={leftAmount}></VoucherInfo>
			<AccountInfo name={user.name} phone={user.phone} email={user.email}></AccountInfo>
			<ChatbotInfo></ChatbotInfo>
		</div>
	)
}
