import React from 'react'
export default function BookingFooter(props) {
	const { text, action, active } = props
	return (
		<div className={active
			? 'booking-footer-container t3'
			: 'booking-footer-container disabled t3'} onClick={active
			? action
			: null}>
			{text}
		</div>
	)
}
