import React from 'react'
import { Route } from 'react-router-dom'

/* Voucher */
import BookingHome from '../pages/Booking/Home/Home'
import MallDetail from '../pages/Booking/MallDetail/MallDetail'
import Search from '../pages/Booking/Search/Search'
import Category from '../pages/Booking/Category/Category'
import Auth from '../pages/Booking/Auth/Auth'
import DetailInfo from '../pages/Booking/DetailInfo/DetailInfo'
import Payment from '../pages/Booking/Payment/Payment'
import Info from '../pages/Booking/Info/Info'
import PaymentComplete from '../pages/Booking/PaymentComplete/PaymentComplete'
import Complete from '../pages/Booking/Complete/Complete'
import Facility from '../pages/Booking/Facility/Facility'
import Menu from '../pages/Booking/Menu/Menu'
import MyPage from '../pages/Booking/MyPage/MyPage'
import DetailReservationInfo from '../pages/Booking/DetailReservationInfo/DetailReservationInfo'
import { MyPageContextProvider } from '../context/Booking/myPage'
import ExpandReservationList from '../pages/Booking/ExpandReservationList/ExpandReservationList'
import Gallery from '../pages/Booking/Gallery/Gallery'
import SelectVoucherMall from '../pages/Booking/SelectVoucherMall/SelectVoucherMall'
import Event from '../pages/Booking/Event/Event'
import BookingPrivateRoute from './BookingPrivateRoute'
import Incoming from '../pages/Booking/Incoming/Incoming'

export default () => {
	return (
		<>
			<Route exact={true} path="/booking/main" component={BookingHome} />
			<Route exact={true} path="/booking/search" component={Search} />
			<MyPageContextProvider>
				<Route exact={true} path="/booking/list/expand/:type" component={ExpandReservationList} />
				<BookingPrivateRoute exact={true} path="/booking/list" component={MyPage} />
				<Route exact={true} path="/booking/voucher/malls" component={SelectVoucherMall} />
				<Route exact={true} path="/booking/detail/:reservationId" component={DetailReservationInfo} />
			</MyPageContextProvider>
			<Route exact={true} path="/booking/mall-detail/:mallUri" component={MallDetail} />
			<Route exact={true} path="/booking/mall-detail/:mallUri/gallery" component={Gallery} />
			<Route exact={true} path="/booking/mall-detail/:mallUri/facility" component={Facility} />
			<Route exact={true} path="/booking/mall-detail/:mallUri/menu" component={Menu} />
			<Route exact={true} path="/booking/step/complete" component={PaymentComplete} />
			<Route exact={true} path="/booking/step/complete-view/:reservationId" component={Complete} />
			<Route exact={true} path="/booking/step/incoming" component={Incoming} />
			<Route exact={true} path="/booking/:mallUri/step/info" component={Info} />
			<Route exact={true} path="/booking/:mallUri/step/category" component={Category} />
			<Route exact={true} path="/booking/:mallUri/step/detail-info" component={DetailInfo} />
			<Route exact={true} path="/booking/:mallUri/step/auth" component={Auth} />
			<Route exact={true} path="/booking/:mallUri/step/payment" component={Payment} />
			<Route exact={true} path="/booking/:mallUri/step/complete" component={Complete} />
			<Route exact={true} path="/booking/skt/event" component={Event} />
		</>
	)
}
