import React, { useEffect } from 'react'
import { Route, BrowserRouter as Router, withRouter, useLocation } from 'react-router-dom'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import CallBack from '../pages/Common/CallBack'
import colorSet from '../configs/colorSet.json'
import DetailAccount from '../pages/Account/DetailAccount/DetailAccount'
import VoucherHistory from '../pages/Account/VoucherHistory/VoucherHistory'
import FaqPage from '../pages/Faq/FaqPage/FaqPage'
import ChangeName from '../pages/User/ChangeName/ChangeName'
import CompletePage from '../pages/User/CompletePage/CompletePage'

/* Provider */
import ComposeProviders from '../context/utils/compose'
import { PartnerContextProvider } from '../context/Common/partner'
import { AccountContextProvider } from '../context/Common/account'
import { LoginContextProvider } from '../context/Common/login'
import { GNBDrawerContextProvider } from '../context/Voucher/gnbDrawer'
import { VoucherContextProvider } from '../context/Common/voucher'
import { ModalContextProvider } from '../context/Common/modal'
import { ChangeNameContextProvider } from '../context/User/changeName'
import { BookingContextProvider } from '../context/Booking/booking'
import ZustandProvider from '../Components/Share/ZustandProvider'
/* Common */
import GlobalHeader from '../Components/Share/Header/Header'
import { Header as GyeongjuroHeader } from '../Components/Gyeongju'
/* Pickup */
import PickupRoutes from './pickup.route'
/* Terms */
import Terms from '../pages/Terms/Terms'
/* Voucher */
import VoucherRoutes from './voucher.route'
/* Booking */
import BookingRoutes from './booking.route'

import useDynamicComponent from '../hooks/useDynamicComponent'

/* kb */
// const theme = createMuiTheme({
// 	palette: {
// 		primary: { main: '#ffcc00' },
// 		secondary: { main: '#f44336' },
// 	},
// })
const theme = createMuiTheme({
	palette: {
		primary: { main: colorSet[localStorage.getItem('partnerName')]['--primary-color'] },
		secondary: { main: colorSet[localStorage.getItem('partnerName')]['--secondary-color'] },
	},
})

function _ScrollToTop(props) {
	const { pathname } = useLocation()
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [pathname])
	return props.children
}

const ScrollToTop = withRouter(_ScrollToTop)
export default () => {
	const providerComponents = [PartnerContextProvider, AccountContextProvider, BookingContextProvider, VoucherContextProvider, ModalContextProvider, LoginContextProvider, GNBDrawerContextProvider, ZustandProvider]
	const { renderComponent } = useDynamicComponent()

	return (
		<ThemeProvider theme={theme}>
			<Router>
				<ComposeProviders components={providerComponents}>
					<ScrollToTop>
						{renderComponent({ gyeongjuro: <GyeongjuroHeader />, default: <GlobalHeader /> })}
						<Route exact={true} path="/" component={CallBack} />
						<Route exact={true} path="/terms/:type" component={Terms} />
						<Route
							exact={true}
							path="/webview/voucher-histories"
							component={VoucherHistory}
						/>
						<Route
							exact={true}
							path="/webview/detail-account"
							component={DetailAccount}
						/>
						<Route exact={true}
							path="/webview/brands/:brandId/faq"
							component={FaqPage}
						/>
						<ChangeNameContextProvider>
							<Route
								exact={true}
								path="/user/change-name/step/complete"
								component={CompletePage}
							/>
							<Route
								exact={true}
								path="/user/change-name/:id"
								component={ChangeName}
							/>
						</ChangeNameContextProvider>
						<PickupRoutes></PickupRoutes>
						<VoucherRoutes />
						<BookingRoutes />
					</ScrollToTop>
				</ComposeProviders>
			</Router>
		</ThemeProvider>
	)
}
