import React from 'react'
import BookingSubtitle from '../BookingSubtitle/BookingSubtitle'
import RefundPolicy from '../RefundPolicy/RefundPolicy'
import { useMall, useReservation } from '../../../../store'
import useDynamicComponent from '../../../../hooks/useDynamicComponent'
import Seperator from '../../Seperator'
export default function DepositNotice() {
	const { deposit_refunds: refundPolicy } = useMall((state) => state.mall)
	const { getLocaleDeposit, getTotalAmount } = useReservation()
	const { renderComponent } = useDynamicComponent()

	return getTotalAmount() > 0
		? (
			<div className="deposit-notice-container share-component">
				<div className="notice-box">
					<BookingSubtitle subtitle="취소 수수료 안내"></BookingSubtitle>
					<div className="description b1">
						{renderComponent({
							default:
							'예약 후 노쇼로 인한 다른 회원의 이용 제한을 최소화하고 합리적인 예약문화를 정착하고자 취소 수수료 부과 제도를 통한 예약 시스템을 운영하고 있습니다.',
							gyeongjuro:
							'예약 후 No Show로 인한 다른 회원의 이용 제한을 최소화하고 합리적인 예약문화를 정착하고자 취소 수수료 부과 제도를 통한 예약 시스템을 운영하고 있습니다.',
						})}
					</div>
					<RefundPolicy policies={refundPolicy}></RefundPolicy>
				</div>
				{renderComponent({ gyeongjuro: <Seperator /> })}
				<div className="notice-box">
					<BookingSubtitle subtitle="예약금"></BookingSubtitle>
					{renderComponent({
						gyeongjuro: (
							<div className="total-deposit">
								<label className='text'>총 예약금</label>
								<span className='value'>{getLocaleDeposit()}</span>
							</div>
						),
					})}
					<div className="description b1">
						<span className="noshow">No Show</span>
						{renderComponent({
							default:
							'(예약을 하고 나타나지 않는 행위) 방지를 위해 예약금 제도를 운영하는 매장입니다. 예약금은 예약 일시 및 인원에 따라 결정되며, 정확한 금액은 예약 단계에서 확인할 수 있습니다.',
							gyeongjuro:
							' (예약을 하고 나타나지 않는 행위) 방지를 위해 예약금 제도를 운영하는 매장입니다. 예약금은 예약 일시 및 인원에 따라 결정되며, 정확한 금액은 예약 단계에서 확인할 수 있습니다.',
						})}
					</div>
				</div>
			</div>
		)
		: (
			<></>
		)
}
