import React from 'react'
import './Tab.scss'

export default function Tab({ mallUri, serviceTypes }) {

	function goMytable() {
		window.location.href = `https://www.mytable.info/m/${mallUri}`
	}

	return (
		<div className="tab-container">
			{serviceTypes.includes('booking')
				? <div className="tab" onClick={goMytable}>예약</div>
				: null}
			{serviceTypes.includes('takeout')
				? <div className="tab selected">픽업</div>
				: null}
			{serviceTypes.includes('delivery')
				? <div className="tab">배달</div>
				: null}
		</div>
	)
}
