import React from 'react'
import { ReactComponent as CircleCheck } from '../../../../assets/icon/circleChecked.svg'
import './Complete.scss'

export default function Complete() {
	return (
		<div className="complete-container">
			<CircleCheck className="icon" />
			<div>변경이 완료되었습니다.</div>
		</div>
	)
}
