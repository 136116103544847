import React, { useState } from 'react'
import { Select, FormControl, InputLabel } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import './BillingForm.scss'
import { usePaymentContext } from '../../../context/Pickup/payment'

const range = (a, b) => {
	const list = []
	for (let i = a; i <= b; i += 1)
		list.push(i)


	return list
}

const BillingForm = () => {
	const { dispatchPaymentInfo, paymentInfo } = usePaymentContext()
	const { type: cardType } = paymentInfo.billingKeyContext


	const [monthSelectOpen, setMonthSelelctOpen] = useState(false)
	const [yearSelectOpen, setYearSelelctOpen] = useState(false)

	const toggleMonthSelectView = () => {
		setMonthSelelctOpen(!monthSelectOpen)
	}

	const toggleYearSelectView = () => {
		setYearSelelctOpen(!yearSelectOpen)
	}

	const comportTyping = (e) => {
		const nextSibling = e.target.nextElementSibling
		const currentValue = e.target.value

		if (nextSibling != null && currentValue.length >= 4)
			nextSibling.focus()

	}

	return (
		<div className="billing-form-container">
			<div className="tab-list">
				<div className={`tab-button ${cardType === 'INDIVIDUAL'
					? 'selected'
					: ''}`} onClick={() => dispatchPaymentInfo({ type: 'CHANGE_CARD_TYPE', value: 'INDIVIDUAL' })}>개인카드</div>
				<div className={`tab-button ${cardType === 'CORPORATION'
					? 'selected'
					: ''}`} onClick={() => dispatchPaymentInfo({ type: 'CHANGE_CARD_TYPE', value: 'CORPORATION' })}>법인카드</div>
			</div>

			<div className="payment-form">
				<div className="card-box">
					<input type="text" maxLength="4" inputMode="numeric" onInput={comportTyping} placeholder="1234" value={paymentInfo.billingKeyContext.cardNumbers[0]} onChange={(e) => dispatchPaymentInfo({ type: 'CHANGE_CARD_NUMBER', index: 0, value: e.target.value })} className="card-number"></input>
					<input type="text" maxLength="4" inputMode="numeric" onInput={comportTyping} placeholder="****" value={paymentInfo.billingKeyContext.cardNumbers[1]} onChange={(e) => dispatchPaymentInfo({ type: 'CHANGE_CARD_NUMBER', index: 1, value: e.target.value })} className="card-number text-security"></input>
					<input type="text" maxLength="4" inputMode="numeric" onInput={comportTyping} placeholder="****" value={paymentInfo.billingKeyContext.cardNumbers[2]} onChange={(e) => dispatchPaymentInfo({ type: 'CHANGE_CARD_NUMBER', index: 2, value: e.target.value })} className="card-number text-security"></input>
					<input type="text" maxLength="4" inputMode="numeric" onInput={comportTyping} placeholder="1234" value={paymentInfo.billingKeyContext.cardNumbers[3]} onChange={(e) => dispatchPaymentInfo({ type: 'CHANGE_CARD_NUMBER', index: 3, value: e.target.value })} className="card-number"></input>
				</div>
				<div className="expire-box">
					<div className="month-select-box">
						<FormControl className="select-form">
							<InputLabel>MM</InputLabel>
							<Select
								onOpen={toggleMonthSelectView}
								onClose={toggleMonthSelectView}
								open={monthSelectOpen}
								className="month-select"
								placeholder={'MM'}
								onChange={(e) => dispatchPaymentInfo({ type: 'SELECT_EXPIRY_MONTH', value: e.target.value })}
								value={paymentInfo.billingKeyContext.expiry.month}
							>
								{range(1, 12).map((n) => <MenuItem value={n.toString().padStart(2, '0')} key={n}>{n.toString().padStart(2, '0')} </MenuItem>)}
							</Select>
						</FormControl>
					</div>
					<div className="year-select-box">
						<FormControl className="select-form">
							<InputLabel>YY</InputLabel>
							<Select
								onOpen={toggleYearSelectView}
								onClose={toggleYearSelectView}
								open={yearSelectOpen}
								placeholder={'YY'}
								onChange={(e) => dispatchPaymentInfo({ type: 'SELECT_EXPIRY_YEAR', value: e.target.value })}
								value={paymentInfo.billingKeyContext.expiry.year}
								className="year-select"
							>
								{range(2021, 2030).map((n) => <MenuItem value={n.toString()} key={n}>{n.toString()}</MenuItem>)}
							</Select>
						</FormControl>
					</div>
				</div>
				<div className="input-box">
					<input
						type="text"
						maxLength={cardType === 'INDIVIDUAL'
							? 6
							: 10}
						inputMode="numeric"
						placeholder={cardType === 'INDIVIDUAL'
							? '명의자 생년월일 (예 : 900101)'
							: '사업자 등록번호 (예 7518100830)'}
						value={paymentInfo.billingKeyContext.identifyCode}
						className="birthday"
						onChange={(e) => dispatchPaymentInfo({ type: 'CHANGE_IDENTIFY_CODE', value: e.target.value })}
					></input>
				</div>
				<div className="input-box">
					<input
						type="password"
						maxLength="2"
						inputMode="numeric"
						placeholder="비밀번호 앞 2자리 숫자"
						value={paymentInfo.billingKeyContext.password}
						className="password"
						onChange={(e) => dispatchPaymentInfo({ type: 'CHANGE_PASSWORD', value: e.target.value })}
					></input>
				</div>
			</div>
		</div>
	)
}

export default BillingForm
