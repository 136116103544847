import React from 'react'
import { format } from 'date-fns'
import { useHistory, useParams } from 'react-router-dom'

export default function PickupInfo(props) {
	const history = useHistory()
	const params = useParams()
	function moveMall() {
		history.push(`/pickup/${params.mallUri}`)
	}
	return (
		<div className="pickup-info-container">
			{/* <div className="status-text">[픽업] 음식준비중</div> */}
			<div className="mall-name">
				{props.orderInfo.mallInfo.name}
				<svg width="14" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={moveMall}>
					<path d="M2 2L12 12L2 22" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
				</svg>
			</div>
			<div>
				<div className="row">
					<div className="label">주문일시</div>
					<div className="value">{format(new Date(props.orderInfo.serviceOrder.logs[0].time), 'yyyy년 MM월 dd일 HH:mm')}</div>
				</div>
				<div className="row">
					<div className="label">픽업일시</div>
					<div className="value">{format(new Date(props.orderInfo.serviceOrder.reserveDatetime), 'yyyy년 MM월 dd일 HH:mm')}</div>
				</div>
				<div className="row">
					<div className="label">매장주소</div>
					<div className="value">{props.orderInfo.mallInfo.location}</div>
				</div>
				<div className="row">
					<div className="label">연락처</div>
					<div className="value">{props.orderInfo.mallInfo.phone}</div>
				</div>
			</div>
		</div>
	)
}
