import React, { useLayoutEffect, useState } from 'react'
import { ReactComponent as ChevronLeft } from '../../../../assets/icon/chevronLeft.svg'
import { ReactComponent as DeactiveChevronLeft } from '../../../../assets/icon/chevronLeftGray.svg'
import { ReactComponent as ChevronRight } from '../../../../assets/icon/chevronRight.svg'
import { ReactComponent as DeactiveChevronRight } from '../../../../assets/icon/chevronRightGray.svg'
import { ReactComponent as LeftArrow } from '../../../../assets/icon/arrow-left.svg'
import { ReactComponent as RightArrow } from '../../../../assets/icon/arrow-right.svg'
import moment from 'moment/moment'
import NumberToDate from '../../../../utils/NumberToDate'
import useDynamicComponent from '../../../../hooks/useDynamicComponent'
import classNames from 'classnames'

const DateSelector = (props) => {
	const { viewDate, setViewDate, calendar } = props.state
	const [isActive, setIsActive] = useState({ backward: false, forward: false })
	const { renderComponent } = useDynamicComponent()

	const handleCalendar = (type) => {
		if (!isActive[type])
			return
		if (type === 'backward')
			setViewDate((prev) => moment(prev.subtract(1, 'M')))
		else
			setViewDate((prev) => moment(prev.add(1, 'M')))
	}

	useLayoutEffect(() => {
		if (!calendar)
			return
		isActive.backward = viewDate.isAfter(moment())
		isActive.forward = viewDate.isBefore(NumberToDate(calendar.at(-1)?.date), 'month')
		setIsActive({ ...isActive })
	}, [viewDate, calendar])

	return (
		<div className="month-handler">
			<div className="date-text t4">{viewDate.format('YYYY년 MMM')}</div>
			<div className="action-container">
				{renderComponent({
					default: (
						<>
							<div className="prev-button">
								{isActive.backward
									? (
										<ChevronLeft className="icon" onClick={() => handleCalendar('backward')} />
									)
									: (
										<DeactiveChevronLeft className="icon" />
									)}
							</div>
							<div className="next-button">
								{isActive.forward
									? (
										<ChevronRight className="icon" onClick={() => handleCalendar('forward')} />
									)
									: (
										<DeactiveChevronRight className="icon" />
									)}
							</div>
						</>
					),
					gyeongjuro: (
						<>
							<LeftArrow className={classNames({ enable: isActive.backward })} onClick={() => handleCalendar('backward')} />
							<RightArrow className={classNames({ enable: isActive.forward })} onClick={() => handleCalendar('forward')} />
						</>
					)
				})}
			</div>
		</div>
	)
}

export default DateSelector
