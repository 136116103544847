import React from 'react'
import Subtitle from '../../Share/Subtitle'
import './Payment.scss'
// import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import BillingForm from './BillingForm'
import { usePaymentContext } from '../../../context/Pickup/payment'

const Payment = () => {
	// const { dispatchPaymentInfo, paymentInfo } = usePaymentContext()

	return (
		<div className="payment-container">
			<Subtitle subtitle="결제 수단"></Subtitle>
			<div className="payment-radio-group">
				{/* <RadioGroup value={paymentInfo.paymentType} onChange={(e) => dispatchPaymentInfo({ type: 'CHANGE_PAYMENT_TYPE', value: e.target.value })}>
          <FormControlLabel value="BILLING_KEY" control={<Radio color="primary"/>} label="카드 결제"/>
          {paymentInfo.paymentType === 'BILLING_KEY' ? <BillingForm></BillingForm> : null}
          <FormControlLabel value="PG" control={<Radio color="primary"/>} label="나이스페이"/>
        </RadioGroup> */}
			</div>
			<BillingForm></BillingForm>
		</div>
	)
}

export default Payment
