import { create } from 'zustand'

const initialState = { isTimeModified: false, }

const useModify = create((set) => ({
	// State
	...initialState,

	// Setter
	setModify: (type, value) => set(({ [type]: value })),

	// Reset
	resetModify: () => set(initialState)
}))

export default useModify
