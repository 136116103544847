/* eslint-disable no-unused-expressions */
import './VoucherDetail.scss'

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { getBrandVoucherProducts, getInflowIdbyKakaoInflowId } from '../../../lib/voucher'

import FooterArrowDown from '../../../assets/icon/footerarrowdown.png'
import { ReactComponent as ShareIcon } from '../../../assets/icon/share-ios.svg'
import Minus from '../../../assets/icon/system/voucherActiveMinus.png'
import Plus from '../../../assets/icon/system/voucherActivePlus.png'
import DeactiveMinus from '../../../assets/icon/system/voucherDeactiveMinus.png'
import DeactivePlus from '../../../assets/icon/system/voucherDeactivePlus.png'
import { ReactComponent as Ticket } from '../../../assets/icon/ticket.svg'
import MallFooter from '../../../Components/Voucher/MallFooter/MallFooter'
import { getSaleRate } from '../../../Components/Voucher/utils'
import referralConfigDict from '../../../configs/referralDict.json'
import { useAccountContext } from '../../../context/Common/account'
import { useLoginContext } from '../../../context/Common/login'
import { usePartnerContext } from '../../../context/Common/partner'
import { useVoucherContext } from '../../../context/Common/voucher'
import { useVoucherContext as useVoucherHomeContext } from '../../../context/Voucher/voucher'
import { sendKakaoMessage } from '../../../lib/kakao'
import { PartnerAPI } from '../../../lib/partner'
import TrackingAPI from '../../../lib/tracking'
import { isMobile } from '../../../utils'
import { useModalContext } from '../../../context/Common/modal'
import BottomSheet from '../../../Components/Voucher/BottomSheet/BottomSheet'
import BottomButton from '../../../Components/Voucher/BottomSheet/BottomButton'

const mobile = isMobile()

export default function VoucherDetail() {
	const params = useParams()
	const history = useHistory()
	const { user } = useAccountContext()
	const trackingAPI = useMemo(() => new TrackingAPI(user, null), [user])
	const { voucherInfo, dispatchVoucherInfo, voucherProduct, setVoucherProduct } = useVoucherContext()
	const { changeVoucherMainScrollY, setReferralConfig } = useVoucherHomeContext()
	const { partnerName, partnerInfo, headerExists, apiKey, setPartnerInfo } = usePartnerContext()
	const { uriReferral, setUriReferral, setLoginPath, setIsOpenDrawer } = useLoginContext()
	const { alertOpen } = useModalContext()
	const [isOpenNotes, setIsOpenNotes] = useState(false)
	const [currentTicketNotesDepth, setCurrentTicketNotesDepth] = useState(0)
	const [isShare, setIsShare] = useState(false)
	const [openVoucher, setOpenVoucher] = useState(false)
	const [mediaData, setMediaData] = useState(null)
	const [chatbotLink, setChatbotLink] = useState('')
	const [scrollHeight, setScrollHeight] = useState(0)
	const [viewFloatButton, setViewFloatButton] = useState(false)
	const [closeFloatButton, setCloseFloatButton] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [sellout, setSellout] = useState(false)
	const brandId = useMemo(() => params.brandId, [])
	const search = history.location.search
	const inflowId = new URLSearchParams(search).get('inflowId')
	const kakaoInflowId = new URLSearchParams(search).get('kakaoInflowId')
	const kakaoChannelId = new URLSearchParams(search).get('kakaoChannelId')
	const loginParam = sessionStorage.getItem('tParam') || new URLSearchParams(search).get('tParam')
	const partnerAPI = new PartnerAPI(apiKey, null)

	useEffect(() => {
		window.addEventListener('scroll', handleScroll, { passive: true })
		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])

	const getProductsData = () => {
		getBrandVoucherProducts(brandId, sessionStorage.getItem('referral'))
			.then((voucher) => {
				setVoucherProduct(voucher)
				setMediaData(voucher.service.landingMultimediaUrl)
				let selectedProducts = voucherInfo.products.filter(product => product.count > 0)
				let products = voucher.products.filter(product => product.leftCount && product.leftCount > 0).map((product) => {
					return {
						product,
						count: selectedProducts.find(sProduct => sProduct.product.externalId === product.externalId)
							? selectedProducts.find(sProduct => sProduct.product.externalId === product.externalId).count
							: 0
					}
				})

				if (products.length === 0) {
					setSellout(true)
					return dispatchVoucherInfo({
						type: 'SET_PRODUCTS',
						value: []
					})
				}

				if (history.location.state && history.location.state.openRequestVoucherPopup) {
					const productNumber = history.location.state.openRequestVoucherPopup
					setOpenVoucher(true)
					return dispatchVoucherInfo({
						type: 'SET_PRODUCTS',
						value: voucher.products.filter(product => product.leftCount && product.leftCount > 0).map((product) => {
							const purchasedProduct = productNumber.find(item => item.id === product.id)
							return {
								product,
								count: purchasedProduct
									? purchasedProduct.purchaseCount
									: 0
							}
						})
					})
				}
				return dispatchVoucherInfo({
					type: 'SET_PRODUCTS',
					value: products
				})

			}).catch(() => {
				setSellout(true)
				dispatchVoucherInfo({
					type: 'SET_PRODUCTS',
					value: []
				})
			}).finally(() => {
				setIsLoading(false)
			})
	}

	useEffect(async () => {
		if (loginParam)
			sessionStorage.setItem('tParam', loginParam)
		if (apiKey) {
			if (loginParam && partnerName === 'shinhan') {
				if (!sessionStorage.getItem('authorization')) {
					await partnerAPI.shinhanLogin(loginParam).then(res => {
						sessionStorage.setItem('authorization', res.authorization)
						setPartnerInfo({
							name: res.name,
							phone: res.phone,
						})
						sessionStorage.setItem('referral', 'shinhanplay')
						setUriReferral('shinhanplay')
					})
				}
				getProductsData()
			}
		}
	}, [apiKey, brandId])

	useEffect(() => {
		if (brandId) {
			const urlParam = {}
			if (history.location.search) {
				history.location.search.replace('?', '').split('&').forEach(value => {
					const [key, token] = value.split('=')
					urlParam[key] = token
				})
				if (urlParam.referral) {
					sessionStorage.setItem('referral', urlParam.referral)
					setUriReferral(urlParam.referral)
					history.replace(history.location.pathname)
				}
			}
			setIsLoading(true)
			if (partnerName !== 'shinhan')
				getProductsData()
		}
	}, [brandId])

	useEffect(() => {
		if (inflowId)
			sessionStorage.setItem('inflowId', inflowId)
	}, [inflowId])

	useEffect(() => {
		let link = ''
		if (kakaoInflowId) {
			if (kakaoChannelId) {
				link = getChatBotLink(kakaoChannelId, kakaoInflowId)
				setChatbotLink(link)
			} else {
				if (voucherProduct?.brand?.kakaoChannelId) {
					link = getChatBotLink(voucherProduct.brand.kakaoChannelId, kakaoInflowId)
					setChatbotLink(link)
				} else if (voucherProduct?.brand?.kakaoChannelId === '') {
					setInflowId(kakaoInflowId)
				}
			}
		}
	}, [kakaoChannelId, kakaoInflowId, voucherProduct])

	useEffect(() => {
		let referralText = sessionStorage.getItem('referral') || uriReferral
		if (referralText) {
			let configKeys = Object.keys(referralConfigDict)
			if (configKeys.includes(referralText)) {
				setReferralConfig(referralConfigDict[referralText])
				return
			}
			setReferralConfig(referralConfigDict.default)
		} else {
			setReferralConfig(referralConfigDict.default)
		}
	}, [uriReferral])

	const setInflowId = async (kakaoInflowIdValue) => {
		let inflowUuid = ''
		try {
			let { voucherInflows } = await getInflowIdbyKakaoInflowId(kakaoInflowIdValue)
			if (voucherInflows && voucherInflows.length > 0)
				inflowUuid = voucherInflows[0].inflowUuid
			sessionStorage.setItem('inflowId', inflowUuid)
		} catch (e) {
			console.log(e)
		}
	}

	const getChatBotLink = (channelId, kakaoInflowIdValue = '') => {
		const kakaoInflowIdData = parseInt(kakaoInflowIdValue, 10)
		let message = '상품권구매'
		if (kakaoInflowIdData) {
			message += ` ${kakaoInflowIdData}번`
			message = decodeURIComponent(message)
		}
		return `https://plus.kakao.com/talk/bot/${channelId}/${message}`
	}

	const changeVoucherPopup = () => {
		if (openVoucher && voucherInfo.amount > 0) {
			// voucher 모달이 열려있고, select한 상품이 있을 때 (실제로 구매하기 페이지로 진입할 때 도는 로직)
			if (partnerInfo && partnerName === 'skt') {
				dispatchVoucherInfo({ type: 'CHANGE_PHONE_NUMBER', value: partnerInfo.phone })
				dispatchVoucherInfo({ type: 'SET_USER_NAME', value: partnerInfo.name })
				history.push('/voucher/step/payment')
				return
			}
			if (user && partnerName === 'tablemanager') {
				dispatchVoucherInfo({ type: 'CHANGE_PHONE_NUMBER', value: user.phone })
				dispatchVoucherInfo({ type: 'SET_USER_NAME', value: user.name })
				history.push('/voucher/step/payment')
				trackingAPI.purchase(voucherProduct.brand.name, '구매진행 페이지 진입')
				return
			}
			if (partnerInfo && partnerName === 'shinhan') {
				dispatchVoucherInfo({ type: 'CHANGE_PHONE_NUMBER', value: partnerInfo.phone })
				dispatchVoucherInfo({ type: 'SET_USER_NAME', value: partnerInfo.name })
			}
			history.push('/voucher/step/info')
		}

		if (partnerName === 'tablemanager') {
			if (!user) {
				let path = history.location.pathname
				if (history.location.search) {
					path += history.location.search
					sessionStorage.setItem('referral', history.location.search)
					setUriReferral(history.location.search)
				}
				setLoginPath(path)
				setIsOpenDrawer(true)
				return
			}
		}

		// 일괄차감권 (빕스 예약상품권) 구매 유의사항 modal
		if (voucherProduct.notes?.length) {
			setIsOpenNotes(!openVoucher)
			setOpenVoucher(false)
			return
		}
		setOpenVoucher(!openVoucher)
	}

	const moveKakao = () => {
		window.location.href = chatbotLink
	}

	useEffect(() => {
		if (partnerName === 'tablemanager' && uriReferral !== 'shinhan')
			setIsShare(true)
		else
			setIsShare(false)
	}, [partnerName, uriReferral])

	const closeVoucherPopup = () => {
		setOpenVoucher(false)
	}

	const calcVoucher = (product, count) => {
		const products = voucherInfo.products.map(item => {
			if (item.product.id === product.id)
				return { product, count: item.count + count }
			return item
		})
		dispatchVoucherInfo({ type: 'SET_PRODUCTS', value: products })
	}

	const findVoucher = (productId) => {
		if (voucherInfo.products)
			return voucherInfo.products.find(item => item.product.id === productId) || { count: 0 }
		return { count: 0 }
	}

	useEffect(() => {
		if (voucherProduct && voucherProduct.products && voucherInfo.products.length === 0)
			dispatchVoucherInfo({ type: 'SET_PRODUCTS', value: voucherProduct.products.map((product) => ({ product, count: 0 })) })
	}, [voucherProduct])

	useEffect(() => {
		dispatchVoucherInfo({ type: 'SET_AMOUNT', value: voucherInfo.products.reduce((a, b) => a + b.product.salePrice * b.count, 0) })
	}, [voucherInfo.products])

	useEffect(() => {
		if (scrollHeight > 1400 && !closeFloatButton)
			setViewFloatButton(true)
	}, [scrollHeight])

	const getMultiImageElements = () => {
		if (!mediaData)
			return []

		let detailElements = []
		if (mediaData.imageUrls.length > 0) {
			detailElements = detailElements.concat(mediaData.imageUrls.map(value => (
				<div key={value.index} className="detail-image-area">
					<img className="detail-image" src={value.url} alt="" />
				</div>
			)))
		}
		if (mediaData.videoUrls.length > 0) {
			detailElements = detailElements.concat(mediaData.videoUrls.map(value => (
				<div key={value.index} className={headerExists
					? 'video-area'
					: 'video-area none-header'}>
					<iframe
						className="video-iframe"
						title={voucherProduct.service
							? voucherProduct.service.name
							: ''}
						src={value.url}
						frameBorder="0"
						allow="accelerometer;autoplay;encrypted-media;gyroscope;picture-in-picture;"
						allowFullScreen
					>
					</iframe>
				</div>
			)))
		}

		return detailElements.sort((a, b) => a.key - b.key)
	}

	const getSelectedVoucherInfo = (type) => {
		if (!voucherInfo.products || voucherInfo.products.length === 0)
			return 0

		return voucherInfo.products.reduce((prev, next) => {
			if (type === 'count')
				return prev + next.count
			if (type === 'price')
				return prev + next.product.salePrice * next.count
			return 0
		}, 0)
	}

	const getImageLink = (imageLink) => {
		const imageLinkItems = imageLink.split('/')
		const fileName = imageLinkItems[imageLinkItems.length - 1].split('.')
		const imageId = fileName[0]
		const extension = fileName[1]
		return `https://mytable-mall-image.s3.ap-northeast-2.amazonaws.com/${imageId}/original.${extension}`
	}

	const share = () => {
		trackingAPI.share(voucherProduct.brand.name, 'before share')
		sendKakaoMessage('feed', {
			content: {
				title: '',
				imageUrl: getImageLink(voucherProduct.service.thumbnail),
				imageWidth: 200,
				imageHeight: 100,
				link: {
					mobileWebUrl: `${window.location.origin}/voucher/detail/${voucherProduct.brand.id}`,
					webUrl: `${window.location.origin}/voucher/detail/${voucherProduct.brand.id}`,
				},
				description: '친구로부터 공유받은 더예약 예약상품권을 지금 바로 확인해보세요!'
			},
			callback: (() => {
				trackingAPI.share(voucherProduct.brand.name, 'complete')
			})
		})
	}

	const handleScroll = () => {
		const position = window.pageYOffset
		setScrollHeight(position)
	}

	const goHome = () => {
		changeVoucherMainScrollY(0)
		history.replace('/voucher/main')
	}

	const goAnotherVoucher = () => {
		sessionStorage.removeItem('referral')
		setUriReferral('')
		history.replace('/voucher/main')
	}

	const clickAgreePurchaseNotesHandler = (isAgree) => {
		if (isAgree) {
			const isCurrentNoteLastDepth = currentTicketNotesDepth === voucherProduct.notes?.length - 1

			if (isCurrentNoteLastDepth) {
				setIsOpenNotes(false)
				setOpenVoucher(true)
				setCurrentTicketNotesDepth(0)
			} else {
				setCurrentTicketNotesDepth(prev => prev + 1)
			}
		} else {
			alertOpen('구매 전 동의사항에 모두 동의해야 예약상품권 구매가 가능합니다.', '확인', '다시 구매 시도해주세요.')
			setCurrentTicketNotesDepth(0)
			setIsOpenNotes(false)
		}

	}

	if (!voucherInfo || isLoading)
		return null
	if (voucherInfo.products.length === 0 && sellout) {
		return (
			<div className="not-found-container">
				<div className="description">
					본 이벤트는 종료되었습니다.<br />
					성원에 감사드리며 이후에<br />
					더 좋은 이벤트로 인사드리겠습니다.<br />
				</div>
				<button className="antoher-voucher-button" onClick={goAnotherVoucher}>다른 예약상품권 보러가기</button>
			</div>
		)
	}

	return (
		<div className="voucher-detail-container">
			<div className="voucher-detail-wrapper">
				{getMultiImageElements()}
			</div>
			<MallFooter type={voucherInfo.products.some(product => product.product.voucherType === 'TICKET')
				? 'TICKET'
				: 'POINT'}></MallFooter>
			{/* purchase bottom sheet */}
			<div className={
				`footer-wrapper ${mobile && 'mobile'} voucher-bottom-sheet-wrapper`
			}>
				{
					openVoucher ?
						<div className="voucher-select-popup open">
							<div className="close-button" onClick={closeVoucherPopup}>
								<img className="close-arrow-image" src={FooterArrowDown} alt="" />
							</div>
							{
								voucherInfo.products && voucherInfo.products.length > 0 ?
									(
										<div className="voucher-items">
											{
												voucherInfo.products.map((item, index) => (
													<div className="voucher-item" key={index}>
														<h3 className="voucher-product-name">{item.product.name}</h3>
														<div className="voucher-information">
															<div className="icon-group">
																<div className="icon-box minus" >
																	{
																		findVoucher(item.product.id).count > 0
																			? <img src={Minus} className="icon active" onClick={() => calcVoucher(item.product, -1)} alt="-" />
																			: <img src={DeactiveMinus} className="icon deactive" alt="-" />
																	}
																</div>
																<span className="value">
																	{findVoucher(item.product.id).count}
																</span>
																<div className="icon-box plus" >
																	{
																		findVoucher(item.product.id).count < 10
																			? <img src={Plus} className="icon active" onClick={() => calcVoucher(item.product, 1)} alt="-" />
																			: <img src={DeactivePlus} className="icon deactive" alt="-" />
																	}
																</div>
															</div>
															<div className="voucher-price-wrapper">
																<p className="voucher-origin-price">
																	<s>{item.product.amount.toLocaleString()}원</s>
																</p>
																<p className="voucher-saled-price">
																	<span className="discount-rate">{getSaleRate(item.product.amount, item.product.salePrice)}%</span>
																	<span className="actual-purchase-price">{item.product.salePrice.toLocaleString()}원</span>
																</p>
															</div>
														</div>
													</div>
												))
											}
										</div>
									)
									: (
										<div className="no-voucher-items">
											<p className="description">판매중인 예약상품권이 없습니다.</p>
										</div>
									)
							}
							<div className="voucher-total-purchase-price-wrapper">
								<div className="total-information">
									<span className="total-information-title">총 수량</span>
									<span className="total-number">
										{
											voucherInfo.products ?
												getSelectedVoucherInfo('count')
												: 0
										}장
									</span>
								</div>
								<div className="total-information">
									<span className="total-information-title">총 상품금액</span>
									<span className="total-price">
										{
											voucherInfo.products ?
												getSelectedVoucherInfo('price').toLocaleString()
												: 0
										}원
									</span>
								</div>
							</div>
						</div>
						: <></>
				}

				<div className={
					isShare ?
						'button-wrapper share'
						: 'button-wrapper'
				}>
					{
						isShare ?
							<button className="share-button" onClick={share}>
								<ShareIcon></ShareIcon>
							</button>
							: <></>
					}
					<button
						className="buy-button"
						onClick={chatbotLink
							? moveKakao
							: changeVoucherPopup}
					>
						<span className="buy-button-text">구매하기</span>
					</button>
				</div>
			</div>
			{/* 빕스 예약상품권 - 유의사항 */}
			{isOpenNotes && <BottomSheet title="구매 전 동의">
				{
					voucherProduct.notes.length ?
						<section className='voucher-ticket-notes-content'>
							<h3>{voucherProduct.notes[currentTicketNotesDepth][0]}</h3>
							<p>{voucherProduct.notes[currentTicketNotesDepth][1]}</p>
						</section>
						: <></>
				}
				<BottomButton className="button-wrapper ticket-notes-button-wrapper share" cancelFunc={() => clickAgreePurchaseNotesHandler(false)} confirmFunc={() => clickAgreePurchaseNotesHandler(true)} cancelBtn="동의안함" confirmBtn="동의" />
			</BottomSheet>}
			<div className={
				viewFloatButton && !closeFloatButton && partnerName === 'tablemanager' ?
					'recommend-voucher-box show' :
					'recommend-voucher-box'
			} onClick={goHome}>
				<div className="icon-box">
					<Ticket className="icon" />
				</div>
				<div className={
					viewFloatButton && !closeFloatButton ?
						'recommend-text show' :
						'recommend-text'
				}><span className="recommend-aonther-text">다른 예약상품권은?</span></div>
			</div>
		</div>
	)
}
