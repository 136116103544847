import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useBookingContext } from '../../../context/Booking/booking'
import { useHistory, useParams } from 'react-router-dom'
import { BookingMallDetailAPI } from '../../../lib/mytable'
import { usePartnerContext } from '../../../context/Common/partner'
import BookingFooter from '../../../Components/Share/Booking/BookingFooter/BookingFooter'
import Timer from '../../../Components/Share/Booking/Timer/Timer'
import { useModalContext } from '../../../context/Common/modal'
import NavigationModal from '../../../Components/Share/NavigationModal/NavigationModal'
import { authorizationWithPrefix } from '../../../lib/utils'
import { getUserInfo } from '../../../lib/voucher'
import { useMall, useProvisionalReservation, useReservation, useTimer, useValidation } from '../../../store'
import moment from 'moment/moment'
import {
	AdditionalInfoForm,
	BookingInfoView,
	DetailPartySize,
	MenuSelector,
	RequestForm,
	UserInfoForm,
} from '../../../Components/Booking'
import Format from '../../../utils/Format'
import { gyeongjuroShopBaseUrl } from '../../../constants'

export default function DetailInfo() {
	const { headerAction, setHeaderAction } = useBookingContext()
	const { partnerName } = usePartnerContext()
	const { mall, policyMenus, setMall } = useMall()
	const { voucherData, additionalInfos, setReservation } = useReservation()
	const { cancelReservation, reservationToken } = useProvisionalReservation()
	const { expire, setTimer } = useTimer()
	const {
		name: nameValidation,
		phone: phoneValidation,
		phoneAuth,
		email: emailValidation,
		detailPartySize: partySizeValidation,
		menus: menusValidation,
		additionalInfo: additionalInfoValidation,
		setValidation,
	} = useValidation()
	const validation = useMemo(
		() =>
			[
				nameValidation,
				phoneValidation,
				phoneAuth,
				emailValidation,
				partySizeValidation,
				policyMenus?.length > 0
					? menusValidation
					: true,
				additionalInfos?.filter((item) => item.enabled).length > 0
					? additionalInfoValidation
					: true,
			].every((item) => item === true),
		[
			nameValidation,
			phoneValidation,
			phoneAuth,
			emailValidation,
			partySizeValidation,
			policyMenus,
			menusValidation,
			additionalInfos,
			additionalInfoValidation,
		]
	)

	const { apiKey } = usePartnerContext()
	const [isBlocked, setIsBlocked] = useState(true)
	const [nextLocation, setNextLocation] = useState('')
	const { alertOpen } = useModalContext()
	const [footerActive, setFooterActive] = useState(false)
	const { mallUri } = useParams()
	const history = useHistory()
	const bookingMallDetailAPI = useMemo(
		() => new BookingMallDetailAPI(apiKey, authorizationWithPrefix()),
		[apiKey, voucherData]
	)

	const handleBackward = () => {
		setHeaderAction('back')
		window.history.pushState(null, null, window.location.href)
	}

	useLayoutEffect(() => {
		if (!partnerName)
			return
		bookingMallDetailAPI
			.getProvisionalReservationInfo(reservationToken)
			.then((res) => {
				if (!res.result)
					throw new Error(res.message)

				const {
					menu,
					date_expire: remainExpire,
					date_y: year,
					date_m: month,
					date_d: day,
					date_h_start: hour,
					count_people: people,
					price: prePrice,
				} = res
				setMall('policyMenus', menu)
				setReservation('totalPartySize', people)
				setReservation('deposit', prePrice)
				setTimer('expire', moment(remainExpire))
			})
			.catch((err) => {
				console.error(err)
				setIsBlocked(false)
				if (expire)
					alertOpen('예약 유효기간이 만료되었습니다.')
				if (partnerName === 'gyeongjuro') {
					const shopId = sessionStorage.getItem('shopId')
					window.location.assign(gyeongjuroShopBaseUrl + shopId)
				} else {
					setNextLocation(`/booking/mall-detail/${mallUri}`)
				}
			})
	}, [partnerName])

	const clickFooter = () => {
		setHeaderAction(null)
		setIsBlocked(false)
		setNextLocation(`/booking/${mallUri}/step/payment?token=${reservationToken}`)
	}

	useEffect(() => setFooterActive(validation), [validation])

	useEffect(() => {
		handleBackward()
		window.addEventListener('popstate', handleBackward)

		return () => {
			window.removeEventListener('popstate', handleBackward)
		}
	}, [])

	useEffect(() => {
		if (!isBlocked && nextLocation) {
			sessionStorage.setItem('visitedBookingPayment', 1)
			history.push(nextLocation)
		}
	}, [isBlocked, nextLocation])

	useLayoutEffect(() => {
		if (mallUri)
			setMall('mallUri', mallUri)
	}, [mallUri])

	useEffect(() => {
		if (voucherData) {
			getUserInfo(voucherData.theyeyakUserToken)
				.then((res) => {
					setReservation('name', res.user.name)
					setReservation('phone', Format.phone(res.user.phone))
					setReservation('email', res.user.email)
					setValidation('name', true)
					setValidation('phone', true)
					setValidation('phoneAuth', true)
				})
				.catch((e) => console.log(e.message))
		}
	}, [voucherData])

	if (!mall)
		return null

	return (
		<div>
			<BookingInfoView mini />
			<Timer />
			<UserInfoForm />
			<DetailPartySize />
			{policyMenus?.length > 0 && <MenuSelector />}
			{mall.info_additional.filter((item) => item.enabled).length > 0 && <AdditionalInfoForm />}
			<RequestForm />
			<BookingFooter active={footerActive} text="다음" action={clickFooter} />
			<NavigationModal
				isBlocked={isBlocked}
				okayText="네"
				cancelText="아니오"
				header="예약 취소하기"
				confirmFunc={() => cancelReservation(apiKey, mallUri)}
				isWarning
			>
				{headerAction === 'home'
					? '진행중인 예약이 취소됩니다. 취소하시겠습니까?'
					: '진행중인 예약이 취소되며, 매장상세 페이지로 돌아갑니다. 취소하시겠습니까?'}
			</NavigationModal>
		</div>
	)
}
