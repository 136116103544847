import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import DateTimePicker from '../../../Components/Pickup/Pickup/DateTimePicker'
import { DateTimePicker as DateTimePickerGyeongju } from '../../../Components/Gyeongju/DateTimePicker'
import MallLocation from '../../../Components/Pickup/Pickup/MallLocation'
import MallTitle from '../../../Components/Pickup/Pickup/MallTitle'
import MenuList from '../../../Components/Pickup/Pickup/MenuList'
import MenuShortcut from '../../../Components/Pickup/Pickup/MenuShortcut'
import { MenuShortcut as MenuShortcutGyeongju } from '../../../Components/Gyeongju/MenuShrotcut'
import { MenuList as MenuListGyeongju } from '../../../Components/Gyeongju/MenuList'
import Tab from '../../../Components/Pickup/Pickup/Tab'
import Footer from '../../../Components/Share/Footer'
import ImageCarousel from '../../../Components/Share/ImageCarousel'
import { usePickupContext } from '../../../context/Pickup/pickup'
import { PickupAPI } from '../../../lib/api'
import './Pickup.scss'
import { usePartnerContext } from '../../../context/Common/partner'
import useDynamicComponent from '../../../hooks/useDynamicComponent'
import Seperator from '../../../Components/Share/Seperator'
import { authorizationWithPrefix } from '../../../lib/utils'

const seperatorStyle = { margin: 0 }

function Pickup() {
	const params = useParams()
	const [mallUri, setMallUri] = useState('')
	const [schedules, setSchedules] = useState([])
	const [categories, setCategories] = useState([])
	const [disablePickup, setDisablePickup] = useState(false)

	const { mall, setMall, searchOption, setSearchOption, orderSheet, setOrderSheet } = usePickupContext()
	const { apiKey, partnerName } = usePartnerContext()
	const { renderComponent } = useDynamicComponent()

	const pickupAPI = useMemo(
		() => new PickupAPI(apiKey, authorizationWithPrefix()),
		[apiKey]
	)

	useEffect(() => {
		console.log(params)
		setMallUri(params.mallUri)
	}, [params])

	useEffect(() => {
		const fetchMall = () => pickupAPI.getMall(mallUri).then((res) => {
			if (res.mall && res.mall.serviceTypes && !res.mall.serviceTypes.includes('takeout') && partnerName !== 'gyeongjuro')
				window.location.href = `https://www.mytable.info/m/${mallUri}`
			res.mall.images = res.mall.images || []
			res.mall.images = res.mall.images.map(image => {
				return {
					imageUrl: image,
					type: 'IMAGE'
				}
			})
			setMall(res.mall)
		})
		const fetchPolicy = () => pickupAPI.getService(mallUri).then((res) => {
			if (res && res.services && Object.keys(res.services).length > 0) {
				setSearchOption({
					...searchOption,
					service: res.services[0]
				})
			} else {
				setDisablePickup(true)
			}
		}).catch((err) => {
			console.log(err)
		})
		if (mallUri && partnerName) {
			fetchMall()
			fetchPolicy()
		}
	}, [mallUri, partnerName])

	useEffect(() => {
		const fetchSchedule = async (uri, serviceId) => {
			let schedule
			await pickupAPI.getSchedules(params.mallUri, serviceId).then(res => {
				schedule = res.schedule
			})
			if (schedule && Object.keys(schedule).length > 0) {
				let keySchedules = Object.keys(schedule)
				let times = schedule[keySchedules[0]]
				let timeKeys = Object.keys(times)
				if (!searchOption.policy) {
					setSearchOption({
						...searchOption,
						policy: times[timeKeys[0]]
					})
				}
				setSchedules(schedule)
			} else {
				setDisablePickup(true)
			}
		}
		if (searchOption.service)
			fetchSchedule(mallUri, searchOption.service._id)
	}, [mallUri, searchOption.service])

	const fetchMenuCategories = async (policy) => {
		const { menuCategories } = await pickupAPI.getMenus(policy)
		let totalMenus = []
		menuCategories.forEach(category => {
			totalMenus = totalMenus.concat(category.menus)
		})

		if (orderSheet.some(item => totalMenus.findIndex(menu => menu._id === item.menuId) === -1))
			setOrderSheet([])

		setCategories(menuCategories)
	}
	useEffect(() => {
		if (searchOption.policy)
			fetchMenuCategories(searchOption.policy)
	}, [mallUri, searchOption.service, searchOption.policy])



	if (mall === null) {
		return <div className={'loader-container'}>
			<ClipLoader></ClipLoader>
		</div>
	}

	return (

		<div>
			{renderComponent({
				default: (
					<div>
						<ImageCarousel images={mall.images}></ImageCarousel>
						<MallTitle mall={mall}></MallTitle>
						{mall.serviceTypes
							? <Tab mallUri={mallUri} serviceTypes={mall.serviceTypes}></Tab>
							: null}
						{disablePickup
							? null
							: <DateTimePicker schedules={schedules}></DateTimePicker>}
						<MallLocation coordinate={mall.coordinate} location={mall.location}></MallLocation>
						{disablePickup
							? <div className="disabled-pickup">현재 예약 가능한 시간이 없습니다.</div>
							: null}
						{categories.length > 0
							? <><MenuShortcut categories={categories}></MenuShortcut> <MenuList categories={categories}></MenuList></>
							: null}
						<Footer></Footer>
						<div className="button-container-placeholder"></div>
					</div>
				),
				gyeongjuro: (
					<div>
						{disablePickup
							? null
							: <DateTimePickerGyeongju schedules={schedules}></DateTimePickerGyeongju>}
						<MallLocation coordinate={mall.coordinate} location={mall.location}></MallLocation>
						<Seperator style={seperatorStyle}></Seperator>
						{disablePickup
							? <div className="disabled-pickup">현재 예약 가능한 시간이 없습니다.</div>
							: null}
						{categories.length > 0
							? <><MenuShortcutGyeongju categories={categories}></MenuShortcutGyeongju> <MenuListGyeongju categories={categories}></MenuListGyeongju></>
							: null}
						<div className="button-container-placeholder"></div>
					</div>
				),
			})}

		</div>
	)
}

export default Pickup
