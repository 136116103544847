/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import React, { useEffect } from 'react'
import { ReactComponent as NaverLoginButtonIcon } from '../../../../assets/icon/soical/naverLoginIcon.svg'
import './NaverLoginButton.scss'

const NAVER_CLIENT_ID = process.env.REACT_APP_NAVER_CLIENT_ID
declare var naver

const NaverLoginButton = () => {
	const callbackUrl = `${location.origin}/voucher/login/auth`

	const initNaverLogin = () => {
		const naverLogin = new naver.LoginWithNaverId(
			{
				clientId: NAVER_CLIENT_ID,
				callbackUrl: callbackUrl,
				isPopup: false,
			}
		)

		naverLogin.init()
		const naverLoginButtonElement = document.querySelector('#naverIdLoginIcon')
		naverLoginButtonElement.href = naverLogin.generateAuthorizeUrl()
	}

	useEffect(() => {
		initNaverLogin()
	}, [])

	return (
		<div id="naverIdLogin">
			<a id="naverIdLoginIcon" href="#">
				<NaverLoginButtonIcon className="icon" />
			</a>
		</div>
	)
}

export default NaverLoginButton
