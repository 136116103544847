/* eslint-disable camelcase */
import React from 'react'
import './Notice.scss'
import { useMall } from '../../../../store'
export default function Notice() {
	const { info_notice } = useMall(state => state.mall)
	if (info_notice && info_notice.enabled) {
		return (
			<div className="notice-container">
				<div className="separator-line"></div>
				<div className="notice-title t3">매장 공지사항</div>
				<div className="notice b1" dangerouslySetInnerHTML={{ __html: info_notice.notice }}></div>
			</div>
		)
	}
	return null
}
