import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@material-ui/core'
import React from 'react'

export default function ConfirmDialog(props) {
	const { dialog, onClose, open } = props

	const handleCancel = () => {
		onClose(false)
	}

	const handleOk = () => {
		onClose(true)
	}

	return (
		<Dialog
			disableBackdropClick
			disableEscapeKeyDown
			maxWidth="xs"
			open={open}
			className="confirm-dialog-container"
		>
			<DialogTitle className="confirm-dialog-title">{dialog.title}</DialogTitle>
			<DialogContent dividers>
				{dialog.content}
			</DialogContent>
			<DialogActions>
				<Button autoFocus onClick={handleCancel} color="primary">
          취소
				</Button>
				<Button onClick={handleOk} color="primary">
          확인
				</Button>
			</DialogActions>
		</Dialog>
	)
}
