import React, { useEffect, useState } from 'react'
import { ClipLoader } from 'react-spinners'
import { useLocation } from 'react-router-dom'
import { getAccountInfo } from '../../../lib/voucher'
import AccountCard from '../../../Components/Account/AccountCard'
import HistoryInfo from '../../../Components/Account/HistoryInfo'
import Seperator from '../../../Components/Share/Seperator'
import HistroyList from '../../../Components/Account/HistoryList'

export default function VoucherHistory() {
	const search = useLocation().search
	const userId = new URLSearchParams(search).get('appUserId')
	const brandId = new URLSearchParams(search).get('brandId')
	const [brand, setBrand] = useState(null)
	const [leftAmount, setLeftAmount] = useState(0)
	const [histories, setHistories] = useState([])
	const [nonUser, setNonUser] = useState(false)
	useEffect(() => {
		document.title = '예약상품권 이용내역'
	}, [])
	useEffect(() => {
		const fetchAccount = () => getAccountInfo(brandId, userId).then(res => {
			setBrand(res.brand)
			setLeftAmount(res.leftAmount)
			setHistories(res.histories)
		}).catch((err) => {
			if (err.response && err.response.status === 401)
				setNonUser(true)
		})

		if (userId && brandId)
			fetchAccount()

	}, [userId, brandId])

	if (!brand) {
		return (
			<div className="loader-container">
				<ClipLoader></ClipLoader>
			</div>
		)
	}
	if (nonUser) {
		return (
			<div className="non-user">
        캐시 정보가 없습니다.<br />
        자세한 내용은 더예약으로 문의주세요.
			</div>
		)
	}
	return (
		<div>
			<AccountCard name={brand.name} amount={leftAmount}></AccountCard>
			<HistoryInfo></HistoryInfo>
			<Seperator></Seperator>
			<HistroyList histories={histories}></HistroyList>
		</div>
	)
}
