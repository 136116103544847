
import React, { useState, useEffect } from 'react'
import { ReactComponent as ActiveAlert } from '../../../assets/icon/icon-active.svg'
import { ReactComponent as InactiveAlert } from '../../../assets/icon/icon-inactive.svg'
import './Button.scss'

const iconMapping = {
	isAlert: <ActiveAlert className="button-icon"></ActiveAlert>,
	notAlert: <InactiveAlert className="button-icon"></InactiveAlert>
}

export default function Button(props) {
	const { label, style, isIcon, iconType, clickEvent } = props
	const [icon, setIcon] = useState([])

	useEffect(() => {
		setIcon(iconMapping[iconType])
	}, [iconType])

	return (
		<button
			className="button"
			style={style.button}
			onClick={clickEvent}
		>
			<p style={style.label}>
				{
					isIcon
						? icon
						: <></>
				}
				<span>{label}</span>
			</p>
		</button>
	)
}
