import React from 'react'
import { isMobile } from '../../../../utils'
import { Carousel } from 'react-responsive-carousel'
import { inquiryType } from '../../utils'
import { addKakaoChannel } from '../../../../lib/kakao'

export default function InquiryBanner(props) {
	const { moveInquiryPage } = props
	const mobileWidthResizing = () => {
		if (isMobile())
			return { width: `${window.innerWidth}px` }

		return { width: '100%' }
	}
	const addCreateKakaoChannel = () => {
		addKakaoChannel()
	}
	return (
		<div className="inquiry-banner-wrapper">
			<div className="carousel-wrapper" style={mobileWidthResizing()}>
				<Carousel
					className="carousel-container"
					showStatus={false}
					showThumbs={false}
					swipeable={true}
					emulateTouch={true}
					showArrows={false}
					autoPlay={true}
					showIndicators={false}
					centerMode={true}
					centerSlidePercentage={100}
					infiniteLoop={true}
					interval={5000}
				>
					<div className="banner partnership-inquiry-banner" onClick={(() => moveInquiryPage(inquiryType.PARTNERSHIP))}></div>
					<div className="banner kakao-channel-banner" onClick={addCreateKakaoChannel}></div>
				</Carousel>
			</div>
		</div>
	)
}
