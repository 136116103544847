import React, { useState, useEffect } from 'react'
import { getSaleRate } from '../../Voucher/utils'
import './VoucherCard.scss'

export default function VoucherCard(props) {
	const { productName, voucherInfo } = props
	const [discountRate, setDiscountRate] = useState(0)

	const getSaledAmount = (amount, salePrice) => {
		return getSaleRate(amount, salePrice)
	}

	useEffect(() => {
		if (voucherInfo.amount && voucherInfo.salePrice !== undefined)
			setDiscountRate(getSaledAmount(voucherInfo.amount, voucherInfo.salePrice))
	}, [voucherInfo])

	return (
		<div className="voucher-card-wrapper">
			<div className="voucher-card-image-area" style={{ backgroundColor: voucherInfo.product?.thumbnail.backgroundColor }}>
				<img
					className="voucher-card-image"
					src={voucherInfo.product?.thumbnail.link}
					alt=""
				/>
			</div>

			<div className="voucher-information">
				<span className="voucher-name">{productName}</span>
				<div className="voucher-price">
					{
						discountRate ?
							<p className="sale-price">
								{discountRate}<span className="percent">%</span>
							</p> :
							<></>
					}
					<p className="amount">
						{
							voucherInfo.salePrice
								? voucherInfo.salePrice.toLocaleString()
								: 0
						}<span className="text">원</span>
					</p>
				</div>
			</div>
		</div>
	)
}
