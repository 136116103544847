import React from 'react'
import { ReactComponent as Close } from '../../../assets/icon/closeIcon.svg'
import { isMobile } from '../../../utils'
import './PageModal.scss'

export default function PageModal(props) {
	const { open, onClose, closeText, header, contents } = props

	return (
		<>
			{
				open ?
					<div className={
						isMobile() ?
							'page-entire-modal mobile'
							: 'page-entire-modal'
					}>
						<div className="modal-container">
							<div className="modal-header">
								<Close className="close-icon" onClick={(() => onClose('mallScrollY'))}/>
								<h3 className="header-title">{header}</h3>
							</div>
							<div className="modal-body">
								<div className="contents-title">
									{contents.title}
								</div>
								<p className="contents-description">
									{contents.description}
								</p>
							</div>
							<div className="modal-footer">
								<button className="button" variant="contained" color="primary" onClick={(() => onClose(''))}>
									<span className="text">{closeText}</span>
								</button>
							</div>
						</div>
					</div>
					: null
			}
		</>
	)
}
