import React, { useCallback, useLayoutEffect, useRef } from 'react'
import { usePartnerContext } from '../../../../context/Common/partner'
import { BookingMallDetailAPI } from '../../../../lib/mytable'
import { useState, useMemo, useEffect } from 'react'
import { useModalContext } from '../../../../context/Common/modal'
import { authorizationWithPrefix } from '../../../../lib/utils'
import { useFooter, useModify, useReservation } from '../../../../store'
import classNames from 'classnames'
import { useParams } from 'react-router-dom'

export default function TimeSelector() {
	const { mallUri } = useParams()
	const { totalPartySize, dateTime, setReservation, voucherData } = useReservation()
	const { isTimeModified, setModify } = useModify()
	const { setFooter } = useFooter()
	const { apiKey } = usePartnerContext()
	const { alertOpen } = useModalContext()
	const [times, setTimes] = useState()
	const targetTime = useMemo(() => dateTime?.format('kk:mm'), [dateTime])
	const [prevCalloutDate, setPrevCalloutDate] = useState()
	const mouseMove = useRef(false)
	const bookingMallDetailAPI = useMemo(
		() => new BookingMallDetailAPI(apiKey, authorizationWithPrefix()),
		[apiKey, voucherData]
	)
	let scrollPosition = { left: 0, x: 0 }
	const savedDate = sessionStorage.getItem('selectedDate')

	const hourToLocalizedTime = useCallback(
		(item) => dateTime.clone().set({ hour: 0, minute: Math.round(item * 60) }),
		[dateTime]
	)

	const handleTime = (item) => {
		setReservation('dateTime', hourToLocalizedTime(item))
		setModify('isTimeModified', true)
	}

	const handleMouseDown = (e) => {
		setTimeout(() => {
			mouseMove.current = true
		}, 100)
		const ele = document.getElementById('time-slider')
		scrollPosition = {
			left: ele.scrollLeft,
			x: e.clientX,
		}
		ele.style.cursor = 'grab'
		document.addEventListener('mousemove', handleMouseMove)
		document.addEventListener('mouseup', handleMouseUp)
	}

	const handleMouseMove = (e) => {
		if (!mouseMove.current)
			return

		const dx = e.clientX - scrollPosition.x
		const dy = e.clientY - scrollPosition.y
		const ele = document.getElementById('time-slider')
		ele.style.cursor = 'grabbing'
		ele.scrollTop = scrollPosition.top - dy
		ele.scrollLeft = scrollPosition.left - dx
	}

	const handleMouseUp = () => {
		const ele = document.getElementById('time-slider')
		ele.style.cursor = 'pointer'
		document.removeEventListener('mousemove', handleMouseMove)
		document.removeEventListener('mouseup', handleMouseUp)
	}

	const restoreSelectedTime = () => {
		if (!times || !savedDate)
			return

		if (times?.find((item) => hourToLocalizedTime(item.hour).format('kk:mm') === targetTime))
			setModify('isTimeModified', true)
		sessionStorage.removeItem('selectedDate')
	}

	useLayoutEffect(() => {
		if (dateTime.isSame(prevCalloutDate || new Date(0), 'day') || !mallUri)
			return

		bookingMallDetailAPI
			.getTimes(mallUri, totalPartySize, dateTime, voucherData?.voucherOrderItemId)
			.then((res) => {
				setModify('isTimeModified', false)
				if (!res.result) {
					throw new Error(
						res?.message || '선택하신 날짜에 현재 예약 가능한 시간대가 없습니다. 다른 날짜를 선택해주세요.'
					)
				}
				setTimes(res.list)
			})
			.catch((err) => {
				setTimes(undefined)
				console.error(err.message)
				alertOpen('선택하신 날짜에 현재 예약 가능한 시간대가 없습니다. 다른 날짜를 선택해주세요.')
			})
			.finally(() => setPrevCalloutDate(dateTime))
	}, [dateTime, mallUri])

	useEffect(() => setFooter('active', isTimeModified), [isTimeModified])

	useEffect(() => restoreSelectedTime(), [times])

	return (
		<div
			className="time-selector-container"
			id="time-slider"
			onMouseDown={(e) => handleMouseDown(e)}
			onMouseUp={() =>
				setTimeout(() => {
					mouseMove.current = false
				}, 100)
			}
		>
			{times?.map((time, idx) => {
				const localizeTime = hourToLocalizedTime(time.hour)
				return (
					<div
						className={classNames('time-box b1', { selected: isTimeModified && localizeTime.isSame(dateTime) })}
						key={idx}
						onClick={() => !mouseMove.current && handleTime(time.hour)}
					>
						{localizeTime.format('LT')}
					</div>
				)
			})}
		</div>
	)
}
