import React, { useState, useEffect } from 'react'
import { Prompt, useLocation, useHistory } from 'react-router-dom'
import modalConfig from '../../../configs/partnerModal.json'
import { usePartnerContext } from '../../../context/Common/partner'
import { useBookingContext } from '../../../context/Booking/booking'
import { useReservation } from '../../../store'
import classNames from 'classnames'
import { gyeongjuroShopBaseUrl } from '../../../constants'
const NavigationModal = (props) => {
	const { isBlocked, okayText, cancelText, header, confirmFunc, isWarning } = props
	const [isModalOpen, setIsModalOpen] = useState(false)
	const location = useLocation()
	const history = useHistory()
	const [lastLocation, setLastLocation] = useState(location)
	const [shouldUnload, setShouldUnload] = useState(false)
	const [confirmedNavigation, setConfirmedNavigation] = useState(false)
	const { partnerName } = usePartnerContext()
	const { headerAction, setHeaderAction } = useBookingContext()
	const { voucherData } = useReservation()
	const containerStyle = modalConfig.modal[partnerName].containerStyle
	const headerStyle = modalConfig.modal[partnerName].headerStyle
	const bodyStyle = modalConfig.modal[partnerName].bodyStyle
	const footerStyle = modalConfig.modal[partnerName].footerStyle
	const okayButtonStyle = modalConfig.modal[partnerName].okayButtonStyle
	const cancelButtonStyle = modalConfig.modal[partnerName].cancelButtonStyle

	const closeModal = () => {
		setIsModalOpen(false)
		setShouldUnload(false)
	}

	const openModal = () => {
		setIsModalOpen(true)
	}

	const showModal = (nextLocation) => {
		openModal()
		setLastLocation(nextLocation)
	}

	const handleBlockedRoute = (nextLocation) => {
		if (!confirmedNavigation && isBlocked) {
			showModal(nextLocation)
			return false
		}

		return true
	}

	const handleConfirmNavigationClick = () => {
		confirmFunc?.()
		closeModal()
		setConfirmedNavigation(true)
	}

	const unload = (event) => {
		if (!shouldUnload)
			event.returnValue = props.children

		if (shouldUnload)
			event.returnValue = ''
	}

	useEffect(() => {
		if (!confirmedNavigation || !lastLocation || !headerAction)
			return

		setShouldUnload(true)
		setHeaderAction(null)
		if (headerAction === 'back') {
			if (partnerName === 'gyeongjuro') {
				const shopId = sessionStorage.getItem('shopId')
				window.location.assign(gyeongjuroShopBaseUrl + shopId)
			} else {
				window.removeEventListener('beforeunload', unload)
				const visitedBookingPayment = sessionStorage.getItem('visitedBookingPayment')
				if (visitedBookingPayment)
					history.go(-5)
				else
					history.go(-4)
			}
			sessionStorage.removeItem('visitedBookingPayment')
		}

		if (headerAction === 'home') {
			// 예약상품권을 통한 진입 구분
			if (voucherData) {
				if (partnerName === 'shinhan')
					history.replace('/booking/main')
				else
					history.replace('/voucher/main')
			} else if (
				/iPhone|iPad|iPod/i.test(navigator.userAgent) ||
				(location.pathname === '/booking/list' && partnerName !== 'tablemanager')
			) {
				history.replace('/booking/main')
			} else {
				if (location.pathname === '/booking/list') {
					history.replace('/voucher/main')
				} else {
					for (let i = history.length; i > 0; i--)
						history.go(-i)
				}

			}
		}
	}, [confirmedNavigation, lastLocation, history, headerAction])

	useEffect(() => {
		window.addEventListener('beforeunload', unload)
		return () => window.removeEventListener('beforeunload', unload)
	}, [shouldUnload])

	return (
		<>
			<Prompt when message={handleBlockedRoute} />
			{isModalOpen
				? (
					<div className="modal openModal">
						<div className="modal-container" style={containerStyle}>
							<div className="modal-header" style={headerStyle}>
								{header}
							</div>
							<div className="modal-body" style={bodyStyle}>
								{props.children}
							</div>
							<div className="modal-footer" style={footerStyle}>
								<button onClick={closeModal} style={cancelButtonStyle}>
									{cancelText}
								</button>
								<button
									onClick={handleConfirmNavigationClick}
									className={classNames({ warning: isWarning })}
									style={okayButtonStyle}
								>
									{okayText}
								</button>
							</div>
						</div>
					</div>
				)
				: null}
		</>
	)
}

export default NavigationModal
