import React from 'react'
import BookingSubtitle from '../../../Share/Booking/BookingSubtitle/BookingSubtitle'
import { useMall, useReservation } from '../../../../store'
import Format from '../../../../utils/Format'
import useDynamicComponent from '../../../../hooks/useDynamicComponent'
export default function UserInfo() {
	const { mall } = useMall()
	const { name, phone, request } = useReservation()
	const { renderComponent } = useDynamicComponent()

	if (!name || !phone || !mall)
		return null

	return (
		<div className="user-info-container">
			<BookingSubtitle subtitle="예약자 정보"></BookingSubtitle>
			{renderComponent({
				default: (
					<>
						<div className="info-box">
							<div className="label b1">예약자</div>
							<div className="value b1">{name}</div>
						</div>
						<div className="info-box">
							<div className="label b1">연락처</div>
							<div className="value b1">{Format.blindPhone(phone)}</div>
						</div>
						<div className="info-box">
							<div className="label b1">요청사항</div>
							<div className="value b1">{request || '없음'}</div>
						</div>
					</>
				),
				gyeongjuro: (
					<ul className='info-container'>
						<li className='info-list'>
							<div className="info-box">
								<label className="label">예약자</label>
								<span className="value">{name}</span>
							</div>
						</li>
						<li className='info-list'>
							<div className="info-box">
								<label className="label">연락처</label>
								<span className="value">{Format.blindPhone(phone)}</span>
							</div>
						</li>
						<li className='info-list'>
							<div className="info-box">
								<label className="label">요청사항</label>
								<span className="value">{request || '없음'}</span>
							</div>
						</li>
					</ul>
				)
			})}
		</div>
	)
}
