import React from 'react'
import { useHomeContext } from '../../../../context/Booking/home'
import VoucherItem from '../VoucherItem/VoucherItem'
import { useState } from 'react'
import BookingSubtitle from '../../../Share/Booking/BookingSubtitle/BookingSubtitle'
import './VoucherMalls.scss'

export default function VoucherMalls() {
	const { brands } = useHomeContext()
	const [mouseDown, setMouseDown] = useState(false)
	let pos = {
		left: 0,
		x: 0
	}
	function handleMouseDown(e) {
		const ele = document.getElementById('drag-voucher')
		pos = {
			left: ele.scrollLeft,
			x: e.clientX,
		}
		ele.style.cursor = 'grabbing'
		ele.style.userSelect = 'none'
		document.addEventListener('mousemove', handleMouseMove)
		document.addEventListener('mouseup', handleMouseUp)
	}
	function handleMouseMove(e) {
		if (!mouseDown)
			setMouseDown(true)

		const dx = e.clientX - pos.x
		const dy = e.clientY - pos.y
		const ele = document.getElementById('drag-voucher')
		ele.scrollTop = pos.top - dy
		ele.scrollLeft = pos.left - dx
	}
	function handleMouseUp(e) {
		const ele = document.getElementById('drag-voucher')
		ele.style.cursor = 'default'
		ele.style.removeProperty('user-select')
		document.removeEventListener('mousemove', handleMouseMove)
		document.removeEventListener('mouseup', handleMouseUp)
		setTimeout(() => {
			setMouseDown(false)
		}, 100)
	}

	if (!brands || brands.length === 0)
		return null

	return (
		<div className="voucher-malls-container">
			<div className="title-container">
				<BookingSubtitle subtitle="예약상품권"></BookingSubtitle>
			</div>
			<div className="slider-container" id="drag-voucher" onMouseDown={handleMouseDown}>
				{brands.map((brand, idx) => <VoucherItem voucher={brand} key={idx}></VoucherItem>)}
			</div>
		</div>
	)
}
