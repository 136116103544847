
import React, { Component } from 'react'
import Map from '../../Share/Map'
export default class MallLocation extends Component {
	render() {
		return (
			<div className="mall-location-container">
				<div className="header-text">
					픽업 장소
				</div>
				<Map lat={this.props.coordinate.lat} lng={this.props.coordinate.lng} ></Map>
				<div className='detail-info-container'>
					<div className='address-text'>
						{this.props.location}
					</div>
				</div>
			</div>
		)
	}
}
