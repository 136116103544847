import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import AccountCard from '../../../Components/Account/AccountCard'
import AccountInfo from '../../../Components/Account/AccountInfo'
import ChatbotInfo from '../../../Components/Account/ChatbotInfo'
import HistoryInfo from '../../../Components/Account/HistoryInfo'
import HistroyList from '../../../Components/Account/HistoryList'
import VoucherInfo from '../../../Components/Account/VoucherInfo'
import Seperator from '../../../Components/Share/Seperator'
import { getAccountInfo } from '../../../lib/api'
import './Account.scss'

export default function Account() {
	const params = useParams()
	const search = useLocation().search
	const token = new URLSearchParams(search).get('token')
	const type = new URLSearchParams(search).get('type')
	const [account, setAccount] = useState(null)
	const [nonUser, setNonUser] = useState(false)
	useEffect(() => {
		document.title = '예약상품권 이용내역'
		if (params.mallUri)
			getAccount(params.mallUri, token)
	}
	, [])

	function getAccount(uri, Authorization) {
		getAccountInfo(uri, Authorization).then(res => {
			setAccount(res.account)
		}).catch((err) => {
			if (err.response.status === 401)
				setNonUser(true)
		})
	}
	if (!account && !nonUser) {
		return (
			<div className="loader-container">
				<ClipLoader></ClipLoader>
			</div>
		)
	}
	if (nonUser) {
		return (
			<div className="non-user">
        캐시 정보가 없습니다.<br />
        자세한 내용은 더예약으로 문의주세요.
			</div>
		)
	}
	return (
		<div>
			{type === 'history' ?
				<>
					<AccountCard name={account.organization.name} amount={account.leftAmount}></AccountCard>
					<HistoryInfo></HistoryInfo>
					<Seperator></Seperator>
					<HistroyList histories={account.histories}></HistroyList>
				</> :
				<>
					<VoucherInfo name={account.organization.name} amount={account.leftAmount}></VoucherInfo>
					<AccountInfo name={account.user.name} phone={account.user.phone} email={account.user.email}></AccountInfo>
					<ChatbotInfo></ChatbotInfo>
				</>
			}</div>
	)
}
