import React, { useEffect, useReducer, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import OutlineInput from '../../../../Components/Share/Input/OutlineInput/OutlineInput'
import { getQuestionEmail, requestQuestions } from '../../../../lib/voucher'
import { useModalContext } from '../../../../context/Common/modal'
import { isMobile } from '../../../../utils'
import './LiveCommerceInquiry.scss'
import { useAccountContext } from '../../../../context/Common/account'

const reducer = (state, action) => ({ ...state, [action.name]: action.value })
const useInput = (initialState) => {
	const [state, dispatch] = useReducer(reducer, initialState)
	const onChange = (e) => {
		dispatch(e.target)
	}

	const setValue = (name, value) => {
		dispatch({ name, value })
	}

	return [state, onChange, setValue]
}

export default function LiveCommerceInquiry() {
	const history = useHistory()
	const [inquiryForm, onChange, setValue] = useInput({
		type: 'LIVE_COMMERCE',
		mallName: '',
		brandName: '',
		homepageLink: '',
		contactInfo: '',
		phone: '',
		email: '',
		content: '',
	})
	const [isValid, setIsValid] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const { user } = useAccountContext()
	const { pageModalOpen } = useModalContext()

	useEffect(() => {
		if (user) {
			getQuestionEmail(user.token, 'LIVE_COMMERCE').then(res => {
				if (res.email)
					setValue('email', res.email)

			}).catch((err) => {
				console.log(err)
				setIsLoading(false)
			})
		}
	}, [user])

	const validationInquiryForm = () => {
		if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(inquiryForm.email))
			return false
		return Object.values(inquiryForm).every(value => value)
	}

	const requestInquiry = () => {
		setIsLoading(true)
		requestQuestions(inquiryForm)
			.then((res) => {
				setIsLoading(false)
				pageModalOpen({
					path: '/voucher/main',
					content: {
						title: (
							<>
								고객님의 문의가<br/>
								더예약 팀에게 전달되었습니다! 💌
							</>
						),
						description: <>
							더예약에 관심을 갖고 문의 남겨 주셔서<br/>
							진심으로 감사드립니다!<br/>
							더예약 전문 컨설턴트가 최대한 빠른 시일내에<br/>
							연락 드리도록 하겠습니다 :)<br/>
							<br/>
							감사합니다.
						</>
					}
				})
			}).catch((err) => {
				console.log(err)
				setIsLoading(false)
			})
	}

	useEffect(() => {
		const valid = validationInquiryForm()
		setIsValid(valid)
	}, [inquiryForm])

	return (
		<div className={
			isMobile() ?
				'live-commerce-inquiry-container mobile'
				: 'live-commerce-inquiry-container'
		}>
			<div className="live-commerce-inquiry-wrapper">
				<div className="live-commerce-inquiry-description">
					<h2 className="live-commerce-inquiry-description-title">
						더예약에서는<br/>
						전문 호스트와 함께<br/>
						라이브커머스를 진행해요 🎥
					</h2>
				</div>

				<div className="live-commerce-inquiry-form">
					<OutlineInput label="매장(법인)명" name="mallName" value={inquiryForm.mallName} placeholder="매장명을 입력해주세요" onChange={onChange} required={true}></OutlineInput>
					<OutlineInput label="브랜드명" name="brandName" value={inquiryForm.brandName} placeholder="진행을 희망하시는 브랜드를 입력해주세요" onChange={onChange} required={true}></OutlineInput>
					<OutlineInput label="홈페이지 주소" name="homepageLink" value={inquiryForm.homepageLink} placeholder="www.theyeyak.com" onChange={onChange} required={true}></OutlineInput>
					<OutlineInput label="담당자 정보" name="contactInfo" value={inquiryForm.contactInfo} placeholder="담당자 성함/부서/직급" onChange={onChange} required={true}></OutlineInput>
					<OutlineInput type="tel" label="담당자 전화번호" name="phone" value={inquiryForm.phone} placeholder="‘-’ 없이 숫자만 입력해주세요" onChange={onChange} required={true}></OutlineInput>
					<OutlineInput type="email" label="담당자 이메일" name="email" value={inquiryForm.email} placeholder="theyeyak@example.com" onChange={onChange} required={true}></OutlineInput>
					<OutlineInput label="문의내용" name="content" value={inquiryForm.content} placeholder={'상담받고 싶으신 내용을 입력해주세요\n(최대 500자 이내)'} onChange={onChange} multiline={true} required={true}></OutlineInput>
					<p className="inquiry-content-count">{inquiryForm.content.length}</p>
				</div>
			</div>

			<div
				className={
					isValid
						? 'button-area valid'
						: 'button-area invalid'
				}
				onClick={
					isValid
						? requestInquiry
						: (() => {})
				}
			>
				{
					isLoading
						? <div className={'loader-container'}>
							<ClipLoader></ClipLoader>
						</div>
						: null
				}
				<button className="inquiry-button">{
					isLoading
						? ''
						: '문의하기'
				}</button>
			</div>
		</div>
	)
}
