import './RadioboxGroup.scss'

import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'

import React from 'react'
import { useMenuOptionContext } from '../../../context/Pickup/menuOption'
import { usePickupContext } from '../../../context/Pickup/pickup'

export default function RadioboxGroup(props) {
	const { detailMenu } = usePickupContext()
	const { dispatchMenuOption, menuOption } = useMenuOptionContext()
	const optionGroup = menuOption[props.optionGroup._id]
	if (detailMenu === null || !optionGroup)
		return <div>loading</div>

	function setValue(type, optionId) {
		dispatchMenuOption({ type, optionGroupId: props.optionGroup._id, optionId: optionId })
	}

	return (
		<div className="radiobox-group">
			<RadioGroup value={optionGroup.value}>
				{props.optionGroup.options.map(option =>
					<div className="radio-box" key={option._id}>
						<FormControlLabel name={props.optionGroup.name} value={option._id} control={<Radio color="primary"/>} label={option.name} onChange={()=> setValue('SELECT', option._id)}/>
						<div>{option.price
							? `${option.price.toLocaleString()}원`
							: null}</div>
					</div>
				)}
			</RadioGroup>
		</div>
	)
}
