import React from 'react'
import { usePickupContext } from '../../../context/Pickup/pickup'
import './MenuAction.scss'

import { ReactComponent as Minus } from '../../../assets/icon/minus.svg'
import { ReactComponent as MinusGray } from '../../../assets/icon/minusGray.svg'
import { ReactComponent as Plus } from '../../../assets/icon/plus.svg'

export default function MenuAction(props) {
	const { orderSheet, setOrderSheet } = usePickupContext()

	function setValue(number) {
		let tmpOrderSheet = JSON.parse(JSON.stringify(orderSheet))
		tmpOrderSheet.find(menu => menu.uniqueKey === props.uniqueKey).count += number
		if (tmpOrderSheet.find(menu => menu.uniqueKey === props.uniqueKey).count < 1)
			return

		setOrderSheet(tmpOrderSheet)
	}

	function deleteMenu() {
		setOrderSheet(orderSheet.filter(menu => menu.uniqueKey !== props.uniqueKey))
	}

	return (
		<div className="menu-action-container">
			<div className="icon-container">
				<div className="icon" onClick={() => setValue(-1)}>
					{props.count === 1
						? <MinusGray />
						: <Minus />}
				</div>
				<div className="count">
					{props.count}
				</div>
				<div className="icon" onClick={() => setValue(1)}>
					<Plus />
				</div>
			</div>
			<div className="delete-button" onClick={deleteMenu}>
        삭제
			</div>
		</div>
	)
}
