/* eslint-disable camelcase */
import { create } from 'zustand'
import { BookingMallDetailAPI } from '../lib/mytable'
import { Toast } from '../Components/Gyeongju'

const useMall = create((set) => ({
	mall: null,
	malls: JSON.parse(sessionStorage.getItem('BookingMalls')) || undefined,
	mallUri: undefined,
	policyMenus: undefined,

	// Getter
	fetchMall: async (apiKey, mallUri) => {
		await new BookingMallDetailAPI(apiKey, sessionStorage.getItem('authorization') || null)
			.getMall(mallUri)
			.then((res) => {
				if (!res?.result)
					throw new Error(res?.message)
				res.representativeMenus = []
				res.menus = []
				res.images = (res.info_images || []).map((image) => {
					return {
						imageUrl: image.src,
						type: 'moveGallery',
						value: mallUri,
					}
				})
				res.foods = (res.food || []).map((food) => {
					return food.name
				})

				if (res.mall_group && res.mall_group['해시태그'])
					res.hashtags = res.mall_group['해시태그'].split(',')

				if (res.info_menu && res.info_menu.length) {
					res.info_menu.forEach((category) => {
						res.representativeMenus = res.representativeMenus.concat(
							category.menus.filter((menu) => menu.isRepresentation),
						)
						res.menus = res.menus.concat(category.menus)
					})
				}
				set({ mall: res })
			})
			.catch((e) => Toast(e.message))
	},

	// Setter
	setMall: (type, value) => set({ [type]: value }),
}))

export default useMall
