import React from 'react'
import { Route } from 'react-router-dom'

/* Provider */
import { PickupContextProvider } from '../context/Pickup/pickup'
/* Voucher */
import Pickup from '../pages/Pickup/Pickup/Pickup'
import MenuDetail from '../pages/Pickup/MenuDetail/MenuDetail'
import OrderSheet from '../pages/Pickup/OrderSheet/OrderSheet'
import Order from '../pages/Pickup/Order/Order'
import Complete from '../pages/Pickup/Complete/Complete'
import Confirm from '../pages/Pickup/Confirm/Confirm'
import Account from '../pages/Account/Account/Account'
import Incoming from '../pages/Pickup/Incoming/Incoming'
import { Switch } from 'react-router-dom/cjs/react-router-dom.min'

export default () => {
	return (
		<PickupContextProvider>
			<Switch>
				<Route
					exact={true}
					path="/pickup/incoming"
					component={Incoming}
				/>
				<Route
					exact={true}
					path="/pickup/:mallUri/:policyId/menu-detail/:menuId"
					component={MenuDetail}
				/>
				<Route
					exact={true}
					path="/pickup/:mallUri/order-sheet"
					component={OrderSheet}
				/>
				<Route
					exact={true}
					path="/pickup/:mallUri/complete/:completeId"
					component={Complete}
				/>
				<Route
					exact={true}
					path="/pickup/:mallUri/order"
					component={Order}
				/>
				<Route
					exact={true}
					path="/pickup/:mallUri/account"
					component={Account}
				/>
				<Route exact={true} path="/pickup/:mallUri" component={Pickup} />
				<Route
					exact={true}
					path="/pickup/confirm/:orderId"
					component={Confirm}
				/>
			</Switch>
		</PickupContextProvider>
	)
}
