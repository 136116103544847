import React, { useEffect, useMemo, useState } from 'react'
import { useChangeNameContext } from '../../../context/User/changeName'
import { useParams } from 'react-router'
import { useHistory, useLocation } from 'react-router-dom'
import { KTNameChangeAPI } from '../../../lib/mytable'
import { usePartnerContext } from '../../../context/Common/partner'
import ReservationInfo from '../../../Components/User/ChangeName/ReservationInfo/ReservationInfo'
import ChangeForm from '../../../Components/User/ChangeName/ChangeForm/ChangeForm'
import { useModalContext } from '../../../context/Common/modal'

export default function ChangeName() {
	const { order, setOrder, name } = useChangeNameContext()
	const history = useHistory()
	const params = useParams()
	const location = useLocation()
	const search = location.search
	const token = new URLSearchParams(search).get('token')
	const { apiKey } = usePartnerContext()
	const { alertOpen } = useModalContext()
	const KTnameChangeAPI = useMemo(() => new KTNameChangeAPI(apiKey, null), [apiKey])

	const [step, setStep] = useState(1)

	function patchName() {
		let regex = /[^가-힇A-Za-z0-9ㄱ-ㅎㅏ-ㅣ\x20]/g//
		if (!name || name.trim() === '') {
			alertOpen('이름을 입력해주세요.')
			return
		} else if (regex.test(name)) {
			alertOpen('특수문자 입력은 불가합니다.')
			return
		}

		KTnameChangeAPI.patchUserName(params.id, token, name).then(res => {
			if (res.result)
				history.push('/user/change-name/step/complete')
			else
				alertOpen(res.message)
		})
	}

	useEffect(() => {
		if (token && params.id && apiKey) {
			KTnameChangeAPI.getKTReservation(params.id, token).then(res => {
				if (res.result)
					setOrder(res.data)
				else
					alert('오류')
			})
		}
	}, [params.id, apiKey])
	if (!order)
		return null
	return (
		<div className="kt-user-container">
			<ReservationInfo></ReservationInfo>
			<ChangeForm patchName={patchName}></ChangeForm>
		</div >
	)
}
