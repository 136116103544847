import './RadioboxGroup.scss'

import { FormControlLabel, RadioGroup } from '@material-ui/core'

import React from 'react'
import { useMenuOptionContext } from '../../../context/Pickup/menuOption'
import { usePickupContext } from '../../../context/Pickup/pickup'
import Radio from '../Radio/Radio'

export default function RadioboxGroup(props) {
	const { detailMenu } = usePickupContext()
	const { dispatchMenuOption, menuOption } = useMenuOptionContext()
	const optionGroup = menuOption[props.optionGroup._id]
	if (detailMenu === null || !optionGroup)
		return <div>loading</div>

	function setValue(type, optionId) {
		dispatchMenuOption({ type, optionGroupId: props.optionGroup._id, optionId: optionId })
	}

	return (
		<div className="gyeongjuro-radiobox-group">
			{props.optionGroup.options.map((option, idx) => (
				<Radio
					key={idx}
					name={option.name}
					value={option._id}
					func={() => setValue('SELECT', option._id)}
					checked={optionGroup.value === option._id}
					labelClassName="gyengjuro-radio-label"
				/>
			))}
		</div>
	)
}
