import React from 'react'
import { Drawer as AppDrawer } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { usePartnerContext } from '../../../context/Common/partner'
import './Drawer.scss'

const useStyles = makeStyles((theme, param) => {
	return {
		root: {
			display: 'flex',
			justifyContent: 'center',
			...theme.root,
			'& div:nth-child(3)': { ...theme.custom.drawer }
		}
	}
})

function Drawer(props) {
	const classes = useStyles()
	const { partnerName } = usePartnerContext()

	return (
		partnerName === 'tablemanager' ?
			(<AppDrawer
				{...props}
				className={classes.root}
				ModalProps={{ onBackdropClick: props.onClose }}
			></AppDrawer>)
			: <></>
	)
}

export default Drawer
