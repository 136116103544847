import React from 'react'
import './SideMenu.scss'
import { ReactComponent as Close } from '../../assets/icon/close.svg'
import { useHistory } from 'react-router-dom'

export default function SideMenu(props) {
	const history = useHistory()
	function moveRegisterVoucher() {
		props.setShowSide(false)
		history.push('/voucher/register/voucher')
	}
	function moveBulkPurchase() {
		props.setShowSide(false)
		history.push('/voucher/register/bulk-purchase')
	}
	function moveRegisterReseller() {
		props.setShowSide(false)
		history.push('/voucher/register/reseller')
	}

	return (
		<div className="side-menu-container">
			<div className="side-header-container">
				<Close onClick={() => props.setShowSide(false)}/>
			</div>
			<div className="side-body-container">
				<div className="menu-container">
					<div className="menu-item" onClick={moveRegisterVoucher}>
            상품권 등록
					</div>
					<div className="menu-item" onClick={moveBulkPurchase}>
            대량 구입 문의
					</div>
					<div className="menu-item" onClick={moveRegisterReseller}>
            리셀러 등록
					</div>
				</div>
			</div>
		</div>
	)
}
