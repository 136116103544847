import { useLocation } from 'react-router-dom'

export default function usePathQuery() {
	const { search } = useLocation()

	if (!search.length)
		return {}

	return search
		.split('?')[1]
		.split('&')
		.reduce((acc, cur) => {
			const [key, val] = cur.split('=')
			return { ...acc, [key]: val }
		}, {})
}
