import React from 'react'
import MenuAction from '../MenuAction/MenuAction'
import MenuOptionGroup from '../MenuOptionGroup/MenuOptionGroup'
import './PickupMenu.scss'
import { generatePriceString } from '../../../utils'
import { usePickupContext } from '../../../context/Pickup/pickup'
import { ReactComponent as Close } from '../../../assets/icon/closeGyeongju.svg'


export default function PickupMenu(props) {
	const { orderSheet, setOrderSheet } = usePickupContext()
	const deleteMenu = () => {
		setOrderSheet(orderSheet.filter(menu => menu.uniqueKey !== props.menu.uniqueKey))
	}
	return (
		<div className="gyeongju-pickup-menu-container">
			<div className="row">
				<div className="menu-name">{props.menu.name}</div>
				<div className="menu-delete-button" onClick={deleteMenu}><Close /></div>
			</div>
			{props.menu.optionGroups.length > 0 ?
				<div className="menu-option-group-container">
					{props.menu.optionGroups.map((optionGroup, idx) => <MenuOptionGroup optionGroup={optionGroup} key={idx} />)}
				</div>
				: null
			}

			<div className="row">
				{props.type === 'edit'
					? <MenuAction count={props.menu.count} uniqueKey={props.menu.uniqueKey}></MenuAction>
					: <div className="count">{props.menu.count}개</div>}
				<div className="menu-price">{(props.menu.totalPrice || props.menu.price).toLocaleString()}원</div>
			</div>
		</div>
	)
}
