import React from 'react'
import { useChangeNameContext } from '../../../../context/User/changeName'
import './ReservationInfo.scss'
export default function ReservationInfo() {
	const { order } = useChangeNameContext()
	return (
		<div className="reservation-info-container">
			<div className="title">예약정보 확인</div>
			<div className="row">
				<div className="label">매장명</div>
				<div className="value">{order.mall.name}</div>
			</div>
			<div className="row">
				<div className="label">예약일시</div>
				<div className="value">{order.date}</div>
			</div>
			<div className="row">
				<div className="label">예약인원</div>
				<div className="value">{order.people}명</div>
			</div>
			<div className="row">
				<div className="label">예약자명</div>
				<div className="value">{order.user.name}</div>
			</div>
		</div>
	)
}
