import React from 'react'
import { useSearchContext } from '../../../../context/Booking/search'
import AutocompleteItem from '../AutocompleteItem/AutocompleteItem'
import './AutocompleteList.scss'

export default function AutocompleteList() {
	const { autoCompletedMalls, searchInfo } = useSearchContext()
	if (searchInfo.isSearching) {
		return (
			<div className="autocomplete-list-container">
				{autoCompletedMalls.map((mall, idx) => <AutocompleteItem mall={mall} key={idx}></AutocompleteItem>)}
			</div>
		)
	}
	return null


}
