import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import { makeStyles } from '@material-ui/core/styles'
import { isMobile } from '../../../utils'
import './Toast.scss'

const styles = {
	root: {
		'& .MuiSnackbar-root': {
			display: 'flex',
			alignItems: 'inherit',
			justifyContent: 'center',
			minWidth: '328px',
			width: '100%',
			height: '50px',
			top: '62px',
			borderRadius: '5px',
			padding: 0,
			zIndex: 100,
			position: 'fixed'
		},
		'& .MuiSnackbarContent-root': {
			background: 'var(--black-200)',
			justifyContent: 'center',
			margin: '0 16px'
		},
		'& .MuiSnackbarContent-message': {
			fontFamily: 'AppleSanDolGothicNeo',
			fontStyle: 'normal',
			fontWeight: 600,
			fontSize: '14px',
			lineHeight: '18px',
			textAlign: 'center',
			color: 'var(--voucher-white-color)',
		}
	}
}
if (isMobile()) {
	styles.root['& .MuiSnackbar-root'].left = 'auto'
	styles.root['& .MuiSnackbar-root'].right = 'auto'
}

const useStyles = makeStyles(styles)
const anchorOrigin = { vertical: 'top', horizontal: 'center' }

export default function Toast(props) {
	const { open, onClose, text, autoHideDuration = 3000 } = props
	const classes = useStyles()

	return (
		<div className={
			isMobile() ?
				`${classes.root} toast-popup mobile`
				: `${classes.root} toast-popup`
		}>
			<Snackbar
				open={open}
				anchorOrigin={anchorOrigin}
				autoHideDuration={autoHideDuration}
				onClose={onClose}
				message={text}
			/>
		</div>
	)
}
