import React from 'react'
import { Button, Badge } from '@material-ui/core'
import './ActionButton.scss'
import { usePickupContext } from '../../context/Pickup/pickup'
import { withStyles } from '@material-ui/core/styles'

const ActionButton = (props) => {
	const { onClick, label = 'action', disabled, className, variant = 'contained' } = props
	const { orderSheet } = usePickupContext()
	const StyledBadge = withStyles((theme) => ({
		badge: {
			right: -13,
			top: 13,
			padding: '0 4px',
		},
	}))(Badge)


	const getOrderSheetCount = () => {
		if (orderSheet == null || label !== '장바구니')
			return 0
		return orderSheet.length
	}

	return (
		<Button
			onClick={onClick}
			disabled={disabled}
			variant={variant}
			color="primary"
			fullWidth={true}
			className={className}
		>
			<StyledBadge badgeContent={getOrderSheetCount()} color="secondary">
				{label}
			</StyledBadge>
		</Button>
	)
}

export default ActionButton
