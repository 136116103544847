import React, { useEffect } from 'react'
import './DateTimePicker.scss'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { ko } from 'date-fns/locale'
import { Select } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import { format } from 'date-fns'
import { usePickupContext } from '../../../context/Pickup/pickup'
import { useReducer, useState } from 'react'
import ConfirmDialog from '../../Share/ConfirmDialog'
import PickupSubtitle from '../PickupSubtitle/PickupSubtitle'
import { ReactComponent as SelectArrow } from '../../../assets/icon/selectArrowGyeongju.svg'

export default function DateTimePicker(props) {
	const [ confirmDialogOpen, setConfirmDialogOpen ] = useState(false)
	const [ value, setValue ] = useState(null)
	const confirmDialog = {
		title: '장바구니 비우기',
		content: '날짜를 변경하게되면 장바구니가 초기화 됩니다.'
	}
	const initialPickerOption = {
		calendarOpen: false,
		timeSelectOpen: false,
		selectedDate: new Date(),
		minDate: new Date(),
		maxDate: new Date(),
		enableDates: [],
		enableTimes: [],
		selectedTime: {}
	}
	function pickerReducer(state, action) {
		switch (action.type) {
		case 'SET_SELECTED_DATE':
			return { ...state, selectedDate: action.value }
		case 'SET_MINIMUM_DATE':
			return { ...state, minDate: action.value }
		case 'SET_MAXIMUM_DATE':
			return { ...state, maxDate: action.value }
		case 'TOGGLE_CALENDAR':
			return { ...state, calendarOpen: !state.calendarOpen }
		case 'TOGGLE_TIME_SELECT':
			return { ...state, timeSelectOpen: !state.timeSelectOpen }
		case 'SET_ENABLED_DATES':
			return { ...state, enableDates: action.value }
		case 'SET_ENABLED_TIMES':
			return { ...state, enableTimes: action.value }
		case 'SET_SELECTED_TIME':
			return { ...state, selectedTime: action.value }
		case 'CHANGE_STATE':
			return { ...state, ...action.value }
		default:
			return state
		}
	}
	const [ pickerOption, dispatchPickerOption ] = useReducer(pickerReducer, initialPickerOption)
	const { searchOption, setSearchOption, orderSheet, setOrderSheet } = usePickupContext()
	useEffect(() => {
		if (props.schedules && Object.keys(props.schedules).length > 0) {
			if (pickerOption.enableDates.length === 0) {
				let keySchedules = Object.keys(props.schedules)
				let minDate = new Date(keySchedules[0])
				minDate.setHours(0, 0, 0, 0)
				let maxDate = new Date(keySchedules[keySchedules.length - 1])
				maxDate.setHours(0, 0, 0, 0)
				let enableDates = []
				keySchedules.forEach(key => {
					let date = new Date(key)
					date.setHours(0, 0, 0, 0)
					enableDates.push(date)
				})
				let times = props.schedules[keySchedules[0]]
				let timeKeys = Object.keys(times)
				let enableTimes = []
				timeKeys.forEach(key => {
					let hour = Math.floor(key / 60)
					let minute = key % 60
					enableTimes.push({
						name: `${hour}:${minute.toString().padStart(2, '0')}`,
						value: key,
						id: times[key]
					})
				})
				let selectedDate = minDate
				let selectedTime = enableTimes[0]
				let ordDateTime = new Date(minDate)
				ordDateTime.setHours(Math.floor(enableTimes[0].value / 60), enableTimes[0].value % 60, 0, 0)
				setSearchOption({ ...searchOption, orderDateTime: ordDateTime })

				dispatchPickerOption({
					type: 'CHANGE_STATE',
					value: {
						minDate,
						maxDate,
						selectedDate,
						selectedTime,
						enableDates,
						enableTimes,
					}
				})
			} else if (searchOption.orderDateTime) {
				let savedSelectedDate = new Date(searchOption.orderDateTime)
				savedSelectedDate.setHours(0, 0, 0, 0)
				let selectedDate = savedSelectedDate

				let keySchedules = Object.keys(props.schedules)
				let minDate = new Date(keySchedules[0])
				minDate.setHours(0, 0, 0, 0)
				let maxDate = new Date(keySchedules[keySchedules.length - 1])
				maxDate.setHours(0, 0, 0, 0)
				let enableDates = []
				keySchedules.forEach(key => {
					let date = new Date(key)
					date.setHours(0, 0, 0, 0)
					enableDates.push(date)
				})
				let times = props.schedules[format(selectedDate, 'yyyy-MM-dd')]
				let timeKeys = Object.keys(times)
				let enableTimes = []
				timeKeys.forEach(key => {
					let hour = Math.floor(key / 60)
					let minute = key % 60
					enableTimes.push({
						name: `${hour}:${minute.toString().padStart(2, '0')}`,
						value: key,
						id: times[key]
					})
				})
				let selectedTime = enableTimes[0]
				let ordDateTime = new Date(savedSelectedDate)
				ordDateTime.setHours(Math.floor(enableTimes[0].value / 60), enableTimes[0].value % 60, 0, 0)
				setSearchOption({ ...searchOption, orderDateTime: ordDateTime })

				dispatchPickerOption({
					type: 'CHANGE_STATE',
					value: {
						minDate,
						maxDate,
						selectedDate,
						selectedTime,
						enableDates,
						enableTimes,
					}
				})
			}

		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.schedules])


	function handleSelectedDateChange (date) {
		if (format(pickerOption.selectedDate, 'yyyy-MM-dd') !== format(date, 'yyyy-MM-dd') && orderSheet && orderSheet.length > 0) {
			setValue(date)
			setConfirmDialogOpen(true)
		} else {
			setSelectedDateChange(date)
		}
	}

	function confirmDateChange(confirmed) {
		setConfirmDialogOpen(false)
		dispatchPickerOption({ type: 'TOGGLE_CALENDAR' })
		if (confirmed)
			setSelectedDateChange(value)

	}

	function setSelectedDateChange(date) {
		setOrderSheet([])

		let times = props.schedules[format(date, 'yyyy-MM-dd')]
		let timeKeys = Object.keys(times)
		let enableTimes = []
		timeKeys.forEach(key => {
			let hour = Math.floor(key / 60)
			let minute = key % 60
			enableTimes.push({
				name: `${hour}:${minute.toString().padStart(2, '0')}`,
				value: key,
				id: times[key]
			})
		})

		dispatchPickerOption({
			type: 'CHANGE_STATE',
			value: {
				selectedDate : date,
				selectedTime: enableTimes[0],
				enableTimes,
				calendarOpen: false
			}
		})
		let ordDateTime = new Date(date)
		ordDateTime.setHours(Math.floor(enableTimes[0].value / 60), enableTimes[0].value % 60, 0, 0)
		setSearchOption( { ...searchOption, policy: enableTimes[0].id, orderDateTime: ordDateTime })
	}

	function checkDisabledDates (date) {
		return pickerOption.enableDates.findIndex(item => item.getTime() === date.getTime()) === -1
	}

	function changeTime (event) {
		dispatchPickerOption({
			type:'SET_SELECTED_TIME',
			value: event.target.value
		})
		let ordDateTime = new Date(pickerOption.selectedDate)
		ordDateTime.setHours(Math.floor(event.target.value.value / 60), event.target.value.value % 60, 0, 0)
		setSearchOption( { ...searchOption, policy: pickerOption.selectedTime.id, orderDateTime: ordDateTime })
	}
	if (!props.schedules)
		return <div>loading...</div>

	return (
		<div className="gyeongju-date-time-picker-container">
			<PickupSubtitle subtitle="픽업날짜/시간 선택"></PickupSubtitle>
			<div className="select-container">
				<div className="relative-target first">
					<div className="select-box" onClick={() => dispatchPickerOption({ type: 'TOGGLE_CALENDAR' })}>
						<span>{pickerOption.selectedDate.toLocaleDateString()}</span>
						<SelectArrow className="icon"/>
					</div>
					<div className="date-picker-box">
						<MuiPickersUtilsProvider locale={ko} utils={DateFnsUtils}>
							<KeyboardDatePicker
								disableToolbar
								fullWidth
								variant="inline"
								format="yyyy.MM.dd"
								margin="normal"
								value={pickerOption.selectedDate}
								open={pickerOption.calendarOpen}
								onClose={() => dispatchPickerOption({ type: 'TOGGLE_CALENDAR' })}
								onOpen={() => dispatchPickerOption({ type: 'TOGGLE_CALENDAR' })}
								onChange={handleSelectedDateChange}
								minDate={pickerOption.minDate}
								maxDate={pickerOption.maxDate}
								shouldDisableDate={checkDisabledDates}
							/>
						</MuiPickersUtilsProvider>
					</div>
				</div>
				<div className="relative-target">
					<div className="select-box" onClick={() => dispatchPickerOption({ type: 'TOGGLE_TIME_SELECT' })}>
						<span> {pickerOption.selectedTime.name} </span>
						<SelectArrow className="icon"/>
					</div>
					<Select
						onOpen={() => dispatchPickerOption({ type: 'TOGGLE_TIME_SELECT' })}
						onClose={() => dispatchPickerOption({ type: 'TOGGLE_TIME_SELECT' })}
						open={pickerOption.timeSelectOpen}
						value={pickerOption.selectedTime}
						onChange={changeTime}
					>
						{pickerOption.enableTimes.map((time, idx) => <MenuItem value={time} key={idx}>{time.name}</MenuItem>)}
					</Select>
				</div>
			</div>
			<ConfirmDialog dialog={confirmDialog} open={confirmDialogOpen} onClose={confirmDateChange}></ConfirmDialog>
		</div>
	)
}
