import React from 'react'
import classNames from 'classnames'

export default function Subtitle(props) {
	const { mini } = props
	return (
		<div className={classNames('subtitle', { mini })}>
			{props.subtitle} {props.firebrick
				? <span className="firebrick">*</span>
				: null}
		</div>
	)
}
