const loginFromKakao = async () => {
	return await new Promise((resolve, reject) => {
		Kakao.Auth.login({
			scope: 'profile,account_email,gender,age_range,birthday,birthyear,phone_number',
			success: function(authObj) {
				resolve({ auth: authObj })
			},
			fail: function(err) {
				console.log(`Login Fail: ${JSON.stringify(err)}`)
			},
		})
	})
}

const logoutKakao = () => {
	if (Kakao.Auth.getAccessToken()) {
		Kakao.API.request({
			url: '/v1/user/logout',
			success: function (response) {
			},
			fail: function (error) {
			},
		})
		Kakao.Auth.setAccessToken(undefined)
	}
}

const getUserInfo = () => {
	return Kakao.API.request({
		url: '/v2/user/me',
		success: function (response) {
			console.log(response)
		},
		fail: function (error) {
			console.log(error)
		}
	})
}

const addKakaoChannel = (buttonId) => {
	return Kakao.Channel.addChannel({
		channelPublicId: '_aPkxkT'
	});
}

const sendKakaoMessage = (objectType = 'feed', settings) => {
	return Kakao.Link.sendDefault({
		objectType,
		...settings
	});
}

export { getUserInfo, loginFromKakao, logoutKakao, addKakaoChannel, sendKakaoMessage }
