import React from 'react'
import { useSearchContext } from '../../../../context/Booking/search'
import { ReactComponent as Back } from '../../../../assets/icon/back.svg'
import { ReactComponent as Delete } from '../../../../assets/icon/deleteCircle.svg'
import { ReactComponent as Search } from '../../../../assets/icon/search.svg'
import { useHistory } from 'react-router-dom'
import './SearchBar.scss'
import { usePartnerContext } from '../../../../context/Common/partner'
import { useMall } from '../../../../store'
export default function SearchBar() {
	const history = useHistory()
	const { searchInfo, dispatchSearchInfo, setAutoCompletedMalls, search } = useSearchContext()
	const { malls } = useMall()
	const { headerExists } = usePartnerContext()
	function goBack() {
		history.goBack()
	}

	function searchAction(e) {
		if (e.key !== 'Enter')
			return
		search(e.target.value)

	}
	function autoComplete(e) {
		let name = e.target.value
		dispatchSearchInfo({ type: 'CHANGE_SEARCH_WORD', value: name })
		let autoCompletedMalls = []
		if (name.length > 0) {
			const sliceName = name.slice(0, name.length - 1).toLowerCase()
			if (name.slice(-1).match('.*[ㄱ-ㅎㅏ-ㅣ]+.*') && sliceName.length > 0) {
				autoCompletedMalls = malls
					.filter((mall) =>
						mall.mall_name.toLowerCase().indexOf(sliceName) > -1
					)
					.map((item) => {
						const obj = { characters: [], name: item.mall_name }
						let index = 0
						let lowerMallName = item.mall_name.toLowerCase()
						let splitName = lowerMallName.split(sliceName)
						splitName.forEach((charcter) => {
							if (index !== splitName.length - 1) {
								obj.characters.splice(1 + index * 2, 0, {
									character: sliceName.toUpperCase(),
									isHighlight: true,
								})
							} else {
								obj.characters.push({
									character: charcter.toUpperCase(),
									isHighlight: false,
								})
							}
							index++
						})
						return obj
					})
			} else {
				let lowerName = name.toLowerCase()
				autoCompletedMalls = malls
					.filter((mall) => mall.mall_name.toLowerCase().indexOf(lowerName) > -1)
					.map((item) => {
						const obj = { characters: [], name: item.mall_name }
						let lowerMallName = item.mall_name.toLowerCase()
						let splitName = lowerMallName.split(lowerName)
						let index = 0
						splitName.forEach((charcter) => {
							obj.characters.push({
								character: charcter.toUpperCase(),
								isHighlight: false,
							})
							if (index !== splitName.length - 1) {
								obj.characters.splice(1 + index * 2, 0, {
									character: name.toUpperCase(),
									isHighlight: true,
								})
							}
							index++
						})
						return obj
					})
			}
			dispatchSearchInfo({ type :'SEARCHING' })
		} else {
			autoCompletedMalls = []
			dispatchSearchInfo({ type :'SEARCH_FINISH' })
		}
		setAutoCompletedMalls(autoCompletedMalls)
	}
	function clear() {
		dispatchSearchInfo({ type :'CHANGE_SEARCH_WORD', value : '' })
		dispatchSearchInfo({ type :'SEARCH_FINISH' })
		dispatchSearchInfo({ type :'SEARCH_INITALIZE' })
	}
	return (
		<div className="search-bar-container">
			{headerExists
				? <div className="back-button" onClick={goBack}>
					<Back className="icon" />
				</div>
				: null}

			<div className="search-container">
				<div className="input-container">
					<input type="text" className="search-input" onInput={autoComplete} onKeyPress={searchAction} value={searchInfo.searchWord} placeholder="어떤 레스토랑을 찾으세요?" maxLength="25" autoFocus/>
					{searchInfo.isSearching
						? <div className="clear-button" onClick={clear}><Delete className="icon"/></div>
						: null}
				</div>
			</div>
			<div className="search-button" onClick={() => search(searchInfo.searchWord)}>
				<Search className="icon"/>
			</div>
		</div>
	)
}
