import './CheckboxGroup.scss'

import { Checkbox, FormControlLabel, Typography } from '@material-ui/core'

import React from 'react'
import { useMenuOptionContext } from '../../../context/Pickup/menuOption'
import { usePickupContext } from '../../../context/Pickup/pickup'
import { ReactComponent as CheckedIcon } from '../../../assets/icon/checkedIconGyeongju.svg'
import { ReactComponent as UnCheckedIcon } from '../../../assets/icon/unCheckedIconGyeongju.svg'
import { generatePriceString } from '../../../utils'


export default function CheckboxGroup(props) {
	const { detailMenu } = usePickupContext()
	const { dispatchMenuOption, menuOption } = useMenuOptionContext()
	const optionGroup = menuOption[props.optionGroup._id]
	if (detailMenu === null || !optionGroup)
		return <div>loading</div>

	function changeCheckbox(event, type, optionId) {
		dispatchMenuOption({ type, optionGroupId: props.optionGroup._id, optionId: optionId })
	}
	return (
		<div className="checkbox-container">
			{props.optionGroup.options.map(option =>
				<div className="checkbox-box" key={option.name}>
					<FormControlLabel value={option.value}
						control={<Checkbox color="primary"
							checkedIcon={<CheckedIcon className="check-icon" />}
							icon={<UnCheckedIcon className="check-icon" />}
						/>}
						label={<Typography className="checkbox-label">{option.name}</Typography>}
						onChange={(e) => changeCheckbox(e, 'MULTI_SELECT', option._id)} />
					<div> {option.price
						? <span className="option-price">{generatePriceString(option.price)}원</span>
						: null}</div>
				</div>
			)}
		</div>
	)
}
