import React, { useMemo } from 'react'
import './VoucherAgreement.scss'
import BookingSubtitle from '../../../Share/Booking/BookingSubtitle/BookingSubtitle'
import BookingAgreeCheckbox from '../../../Share/Booking/BookingAgreeCheckbox/BookingAgreeCheckbox'
import { FormControlLabel, Checkbox } from '@material-ui/core'
import { ReactComponent as AllActiveCheckbox } from '../../../../assets/icon/allActiveCheckbox.svg'
import { ReactComponent as AllDeactiveCheckbox } from '../../../../assets/icon/allDeactiveCheckbox.svg'
import { useVoucherContext } from '../../../../context/Common/voucher'
import { usePartnerContext } from '../../../../context/Common/partner'
import { useFetchPolicies } from '../../../../hooks'
import ModalPortal from '../../../Share/Modal/ModalPortal'
import PolicyModal from '../../../Share/Modal/PolicyModal'

export default function VoucherAgreement() {
	const { voucherInfo, dispatchVoucherInfo } = useVoucherContext()
	const { servicePolicies, loading, openServiceTerm, currentPolicy, policiesList } = useFetchPolicies({ url: '/service/policies', dispatchInfo : dispatchVoucherInfo })
	const { partnerName } = usePartnerContext()

	function changeAllCheckbox() {
		let agreementNameList = [...policiesList]
		if (getAgreements.find(agreement => agreement === 'saveCard'))
			agreementNameList = agreementNameList.concat('saveCard')
		if (agreementNameList.every(agreementName => voucherInfo.agreement[agreementName]))
			dispatchVoucherInfo({ type: 'TOGGLE_AGREEMENT_ALL', value: agreementNameList })
		else
			dispatchVoucherInfo({ type: 'TOGGLE_AGREEMENT_ALL', value: getAgreements.filter(agreement => !voucherInfo.agreement[agreement]) })
	}

	const getAgreements = useMemo(() => {
		const requiredAgreements = Object.keys(voucherInfo.agreement)
		if (partnerName === 'tablemanager')
			return requiredAgreements
		return requiredAgreements.filter(item => item !== 'saveCard')
	}, [voucherInfo.agreement])

	const checkingSaveCard = () => {
		const requiredAgreements = Object.keys(voucherInfo.agreement)
		return requiredAgreements.includes('saveCard')
	}

	const showServiceTerm = (type) => {
		window.history.pushState(null, null, '')
		openServiceTerm(type)
	}

	return (
		<div className="payment-agreement-container">
			<BookingSubtitle subtitle="서비스 이용 및 약관 동의"></BookingSubtitle>
			<FormControlLabel
				control={
					<Checkbox
						color="primary"
						checked={getAgreements.every(agreement => voucherInfo.agreement[agreement])}
						onChange={changeAllCheckbox}
						checkedIcon={<AllActiveCheckbox className="check-icon" />}
						icon={<AllDeactiveCheckbox className="check-icon" />}
						className="all-check"
					/>
				}
				label="전체 동의"
				className="checkbox-label checkbox-all"
			/>
			<div className="separator-line"></div>
			{
				!loading && policiesList.map(policyType => {
					const policy = servicePolicies.find(item => item.type === policyType)
					return (
						<BookingAgreeCheckbox
							value={voucherInfo.agreement[policy.type]}
							setValue={() => dispatchVoucherInfo({ type: 'TOGGLE_AGREEMENT', value: policy.type })}
							required={policy.required}
							desc={policy.content.KO.name}
							expand={policy.content.KO.contentUrl}
							expandFunction={() => showServiceTerm(policy.type)}
							key={policyType}
						/>
					)
				})}
			{
				checkingSaveCard()
					? (
						<BookingAgreeCheckbox
							value={voucherInfo.agreement.saveCard}
							setValue={() => dispatchVoucherInfo({ type: 'TOGGLE_AGREEMENT', value: 'saveCard' })}
							required={false}
							expand={false}
							desc='카드정보를 저장하여 다음 결제 시 저장된 카드 정보를 사용하겠습니다.'
						/>
					)
					: <></>
			}
			<ModalPortal>
				<PolicyModal url={currentPolicy} openServiceTerm={openServiceTerm} />
			</ModalPortal>
		</div>
	)
}

