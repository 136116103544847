import React from 'react'
import modalConfig from '../../../../configs/partnerModal.json'
import { usePartnerContext } from '../../../../context/Common/partner'
import useDynamicComponent from '../../../../hooks/useDynamicComponent'

export default function RefundModal(props) {
	const { open, onClose, depositInfo } = props
	const { partnerName } = usePartnerContext()
	const { renderComponent } = useDynamicComponent()
	const containerStyle = modalConfig.modal[partnerName].containerStyle
	const headerStyle = modalConfig.modal[partnerName].headerStyle
	const bodyStyle = modalConfig.modal[partnerName].bodyStyle
	const footerStyle = modalConfig.modal[partnerName].footerStyle
	const okayButtonStyle = modalConfig.modal[partnerName].okayButtonStyle
	const cancelButtonStyle = modalConfig.modal[partnerName].cancelButtonStyle
	return (
		<div className={open
			? 'openModal modal'
			: 'modal'}>
			{open
				? (
					<div className="modal-container" style={containerStyle}>
						<div className="modal-header" style={headerStyle}>
							{renderComponent({
								default: null,
								gyeongjuro: <div>예약 취소 안내</div>,
							})}
						</div>
						<div className="modal-body" style={bodyStyle}>
							<div className="message-container">
								{renderComponent({ default: <div className="title">예약 취소 안내</div>, gyeongjuro: null })}
								<div className="desc">
									{renderComponent({
										default: (
											<>
											취소한 예약은 복구할 수 없으며, 결제하신 예약금은 취소 수수료 규정에 따라 환불됩니다.
												<br />
											취소 완료까지 약 1분 정도 소요될 수 있습니다.
											</>
										),
										gyeongjuro: '취소한 예약은 복구할 수 없으며, 결제하신 예약금은 취소 수수료 규정에 따라 환불됩니다.',
									})}
								</div>
							</div>
							<div className="message-container">
								<div className="bold">환불 안내</div>
								{renderComponent({
									default: (
										<>
											<div className="inline">
												<div className="item-name">예약금</div>
												<div className="item-value right-text">{depositInfo.depositPrice.toLocaleString()}원</div>
											</div>
											<div className="inline">
												<div className="item-name">취소 수수료</div>
												<div className="item-value right-text">
													{(depositInfo.depositPrice - depositInfo.refundPrice).toLocaleString()}원
												</div>
											</div>
										</>
									),
									gyeongjuro: (
										<ul className="refund-info">
											<li>
												<div>
													<label>예약금</label>
													<span>{depositInfo.depositPrice.toLocaleString()}원</span>
												</div>
											</li>
											<li>
												<div>
													<label>취소 수수료 {depositInfo.refundPercent}%</label>
													<span>{(depositInfo.depositPrice - depositInfo.refundPrice).toLocaleString()}원</span>
												</div>
											</li>
										</ul>
									),
								})}
								<div className="bottom-line"></div>
								<div className="inline emphasis">
									{renderComponent({
										default: (
											<>
												<div className="refund-money-text">환불 받을 금액</div>
												<div className="right-text money">{depositInfo.refundPrice.toLocaleString()}원</div>
											</>
										),
										gyeongjuro: (
											<>
												<div className="refund-money-text">환불 금액</div>
												<div className="right-text money">{depositInfo.refundPrice.toLocaleString()}원</div>
											</>
										),
									})}
								</div>
							</div>
						</div>
						<div className="modal-footer" style={footerStyle}>
							<button onClick={() => onClose(false)} style={cancelButtonStyle}>
							닫기
							</button>
							<button onClick={() => onClose(true)} style={okayButtonStyle}>
							예약취소
							</button>
						</div>
					</div>
				)
				: null}
		</div>
	)
}
