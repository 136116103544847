import moment from 'moment'

export default function NumberToDate(value) {
	let date = value

	if (!date)
		return moment()
	if (typeof date === 'string')
		date = value.replace(/^(\d{4})(\d{2})(\d{2})$/g, '$1-$2-$3')

	return moment(date)
}
