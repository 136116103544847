import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import ImageCard from '../../../Share/ImageCard/ImageCard'
import VoucherCard from '../../../Share/VoucherCard/VoucherCard'
import { getSaleRate } from '../../utils'
import './NowLive.scss'

export default function NowLive(props) {
	const history = useHistory()
	const { data } = props
	const [liveInfo, setLiveInfo] = useState({})
	const [voucherInfo, setVoucherInfo] = useState({})

	const getVoucherInfo = () => {
		if (!data.products || data.products.length === 0) {
			return {
				amount: 0,
				salePrice: 0,
				rate: 0
			}
		}
		const minProduct = data.products.reduce((prev, next) => {
			return (next.amount < prev.amount)
				? next
				: prev
		})
		return {
			amount: minProduct.amount,
			salePrice: minProduct.salePrice,
			rate: getSaleRate(minProduct.amount, minProduct.salePrice),
			product: minProduct
		}
	}

	useEffect(() => {
		setLiveInfo({
			name: data.service.name,
			description: data.liveCommerce.description,
			thumbnail: data.service.thumbnail,
			videoLink: data.liveCommerce.videoLink,
		})
		const voucher = getVoucherInfo()
		setVoucherInfo(voucher)
	}, [])

	const openLiveLink = () => {
		history.push(`/voucher/detail/${data.brand.id}`, '_blank')
	}

	return (
		<div className="now-live-product" onClick={openLiveLink}>
			<div className="image-card-wrapper">
				<ImageCard imageCardType="live" imageUrl={liveInfo.thumbnail} mallInfo={liveInfo} badges={data.badges}></ImageCard>
			</div>
			<VoucherCard productName={data.service.name} voucherInfo={voucherInfo}></VoucherCard>
		</div>
	)
}
